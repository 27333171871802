<template>
    <div>
        <div style="display: flex; justify-content: center">
            <h5>
                <p><b>Título do Checklist:</b> {{ selectedAnswerFromList.checklist_title }}.</p>
                <p><b>Nome do Operador:</b> {{ selectedAnswerFromList.operator_name }}.</p>
                <p><b>Identificação do Veículo:</b> {{ selectedAnswerFromList.vehicle_display_id }}.</p>
                <p><b>Data das Resposta:</b> {{ selectedAnswerFromList.date_string }}.</p>
            </h5>
        </div>
        <div style="padding-bottom: 1rem">
            <div v-for="data in selectedAnswerResponse">
                <div v-if="data.type === 'TITLE'" style="margin-bottom: 1rem">
                    <hr class="solid">
                    <Tag> {{ data.number }}) {{ data.text }}</Tag>
                </div>
                <div v-if="(data.type === 'TEXT' || data.type === 'NUMBER_TYPING') && !data.is_null">
                    <div :class="{'child-style': data.is_child, 'parent-style': !data.is_child}">
                        <p><b> {{ data.number }}) {{ data.text }} </b><br> R: {{ data.answer }}</p>
                    </div>
                </div>
                <div v-if="(data.type === 'TEXT' || data.type === 'NUMBER_TYPING') && data.is_null">
                    <div :class="{'child-style': data.is_child, 'parent-style': !data.is_child}">
                        <p><b> {{ data.number }}) {{ data.text }} </b><br> R: <i>resposta não
                            preenchida </i></p>
                    </div>
                </div>
                <div v-if="data.type === 'CHECKBOX_EXCLUSIVE' && !data.is_null">
                    <div :class="{'child-style': data.is_child, 'parent-style': !data.is_child}">
                        <p><b> {{ data.number }}) {{ data.text }} </b><br> R: {{ data.answer }} </p>
                    </div>
                </div>
                <div v-if="data.type === 'CHECKBOX_INCLUSIVE' && data.is_null">
                    <div :class="{'child-style': data.is_child, 'parent-style': !data.is_child}">
                        <p><b> {{ data.number }}) {{ data.text }} </b><br> R: <i> resposta não
                            preenchida </i></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tag from "primevue/tag";

export default {
    props: {
        selectedAnswerFromList: {
            type: Object,
            default: null
        },
        selectedAnswerResponse: {
            type: Array,
            default: null
        }
    },
    components: {
        Tag
    }
}
</script>

<style>

</style>
