<template>
    <Dialog :header="dialogTitle" :visible.sync="showDialog"
            @hide="handleClose" :modal="true" :style="{width: width_dialog}">
        <p v-if="loading" style="font-size: 15px; line-height: 1.7; text-align: center">
            <ProgressSpinner/>
            <br>
            Carregando, aguarde...
        </p>
        <div v-else>
            <app-line-chart v-if="chartData.length > 0" :chart_data="chartData" :series_symbol="seriesSymbol"
                            :reflow_chart="all_fields_details"/>
            <DataTable v-if="gridData.length > 0"
                       :value="gridData" dataKey="id"
                       class="p-datatable-striped mt-3"
                       :paginator="true" :rows="5"
                       :loading="loading"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} Ordens"
                       :sortOrder="-1" sortField="order_id"
                       filterDisplay="row" responsiveLayout="scroll"
                       removableSort>
                <template #empty>
                    <div style="text-align: center">
                        Nenhuma ordem localizada
                    </div>
                </template>

                <Column field="order_id" header="Id" headerClass="headerClass" bodyClass="bodyClass" 
                        :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.order_id}}
                    </template>
                </Column>

                <Column field="fields_ids_list" header="# de trechos" headerClass="headerClass"
                        :sortable="true" bodyClass="bodyClass">
                    <template #body="slotProps">
                        {{slotProps.data.fields_ids_list.length}}
                    </template>
                </Column>

                <Column field="start_date" header="Início" headerClass="headerClass"
                        :sortable="true" bodyClass="bodyClass">
                    <template #body="slotProps">
                        {{dateHourFormat(slotProps.data.start_date)}}
                    </template>
                </Column>

                <Column field="end_date" header="Fim" headerClass="headerClass"
                        :sortable="true" bodyClass="bodyClass">
                    <template #body="slotProps">
                        {{dateHourFormat(slotProps.data.end_date)}}
                    </template>
                </Column>

                <Column field="observation" header="Observação" headerClass="headerClass">
                    <template #body="slotProps" bodyClass="bodyClass">
                        {{slotProps.data.observation}}
                    </template>
                </Column>

                <Column field="status" header="Status" headerClass="headerClass"
                        :sortable="true" bodyClass="bodyClass">
                    <template #body="slotProps">
                        <span :class="'badge status-' + slotProps.data.status">
                            {{getStatus(slotProps.data.status)}}
                        </span>
                    </template>
                </Column>
            </DataTable>
            <div v-else-if="!all_fields_details" style="text-align: center">
                Nenhuma ordem localizada
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import AnomaliasOrderService from '../../../services/AnomaliasOrderService'
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import dateHourFormat from '../../../mixins/DateMixin'
import AppLineChart from "@/components/views/analysis/AppLineChart";
import moment from "moment";


export default {
    mixins: [dateHourFormat],
    mounted() {
        this.anomaliasOrderService = new AnomaliasOrderService();
        this.width_dialog = this.isScreenMobile ? '85vw' : '50vw';
    },
    data() {
        return {
            width_dialog: null,
            showDialog: false,
            loading: false,
            gridData: [],
            statuses: [
                {name: 'PROGRAMADO', code: 'SCHEDULED'},
                {name: 'ATRASADO', code: 'DELAYED'},
                {name: 'FINALIZADO', code: 'CLOSED'},
            ],
            chartData: [],
            seriesSymbol: 'ocorrências/km'
        }
    },
    methods: {
        handleClose() {
            this.gridData = [];
            this.chartData = [];
            this.$emit('handleClose');
        },
        processLineChartData() {
            let tempSeriesData = [];
            this.chartData = [];
            let fieldKmTraveled = this.fields_kilometers_traveled.find(obj => obj.name === this.selected_field.name);

            if (!fieldKmTraveled) {
                return;
            }

            let data = [];
            fieldKmTraveled.kilometersTraveledByDay.forEach(dayKm => {
                let occurrencesByDay = this.selected_field.fieldOccurrencesByDay.find(obj => obj.day.getTime() === dayKm.day.getTime())

                if (!occurrencesByDay) {
                    data.push([(dayKm.day.valueOf()), 0]);
                    return;
                }
                data.push([(dayKm.day.valueOf()), parseFloat((occurrencesByDay.occurrences/dayKm.kms).toFixed(2))]);
            })

            data.sort(function (a, b) {
                return a[0] - b[0];
            });

            tempSeriesData.push({data: data, findNearestPointBy: 'x', name: 'Ocorrências/km', step: false})

            this.chartData = tempSeriesData;
        },
        processLineChartDataAllFields() {
            let fieldsKmOccurrencesByDay = [];
            this.chartData = [];
            //Para cada taçhão com mais de 0 km rodados
            this.fields_kilometers_traveled.forEach(field => {
                //para cada dia do talhão
                let currentField = this.fields.find(obj => obj.name === field.name);

                if (!currentField) {
                    return;
                }

                field.kilometersTraveledByDay.forEach(dayKm => {
                    //pega as ocorrências do dia, se tiver
                    let occurrencesByDay = currentField.fieldOccurrencesByDay.find(obj => obj.day.getTime() === dayKm.day.getTime())
                    let index = fieldsKmOccurrencesByDay.findIndex(obj => obj.day.getTime() === dayKm.day.getTime())
                    let occurrences = 0;

                    if (occurrencesByDay) {
                        occurrences = occurrencesByDay.occurrences;
                    }

                    if (index < 0) {
                        fieldsKmOccurrencesByDay.push({
                            day: dayKm.day,
                            kms: dayKm.kms,
                            occurrences: occurrences
                        })
                        return;
                    }

                    fieldsKmOccurrencesByDay[index].kms += dayKm.kms;
                    fieldsKmOccurrencesByDay[index].occurrences += occurrences;
                })
            })

            let data = [];
            fieldsKmOccurrencesByDay.forEach(fieldKmOccurrencesByDay => {
                data.push([(fieldKmOccurrencesByDay.day.valueOf()),
                    parseFloat((fieldKmOccurrencesByDay.occurrences/fieldKmOccurrencesByDay.kms).toFixed(2))]);
            })

            data.sort(function (a, b) {
                return a[0] - b[0];
            });

            this.chartData.push({data: data, findNearestPointBy: 'x', name: 'Ocorrências/km', step: false})
        },
        getFieldOrders() {
            this.loading = true;
            this.anomaliasOrderService.getFieldOrders(this.selected_field.id)
                .then((response) => {
                    this.processGridData(response);
                }).catch((error) => {
                    this.$toast.add({severity:'error', summary: 'Não foi possível obter informações sobre o trecho', life: 3000});
                    this.handleClose()
                }).finally(() => {
                    this.loading = false
                })
        },
        getStatus(status) {
            let result = this.statuses.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        processGridData(val) {
            let tempGridData = [];

            val.forEach(order => {
                let orderDate =  new Date(0);
                orderDate.setUTCSeconds(order.start_date);

                if (order.status === 'SCHEDULED') {
                    if (moment(orderDate).isBefore(moment().startOf('day'))) {
                        order.status = 'DELAYED';
                    }
                }
                tempGridData.push(order);
            })
            this.gridData = tempGridData;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    },
    props: {
        display_dialog: {
            type: Boolean,
            default: false,
            required: true
        },
        selected_field: {
            type: Object,
            required: true
        },
        fields_kilometers_traveled: {
            type: Array,
            required: true
        },
        fields: {
            type: Array,
            required: true
        },
        all_fields_details: {
            type: Boolean,
            required: true
        },
    },
    watch: {
        display_dialog: async function (val) {
            this.showDialog = val;
            if (val) {
                await this.sleep(1);
                if (!this.all_fields_details) {
                    this.getFieldOrders();
                    this.processLineChartData();
                    return;
                }
                this.processLineChartDataAllFields();
            }
        },
    },
    components: {
        Dialog, ProgressSpinner, DataTable, Column, AppLineChart
    },
    computed: {
        dialogTitle: function () {
            return this.all_fields_details ? 'Ocorrências/km total' : this.selected_field.name;
        },
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    }
}
</script>

<style scoped lang="scss">
@import "./ordersStyle";

</style>
