<template>
    <Dialog header="Seleção de Veículos" :visible.sync="showDialog"
            :closable="false" :modal="true" :style="{width: '30vw'}">
        Você fez uma seleção de mais de um veiculo, manter a seleção atual ou iniciar nova seleção?
        <template #footer>

            <app-button @handleClick="handleYes" type="primary" style="min-width: 30%">
                Iniciar nova seleção
            </app-button>

            <app-button @handleClick="handleNo" type="secondary"
                        style="position: fixed;left: 20px;min-width: 30%">
                Manter seleção atual
            </app-button>

        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import AppButton from "../../common/AppButton";

    export default {
        mounted() {
        },
        data() {
            return {
                showDialog: false,
            }
        },
        methods: {
            handleYes() {
                this.$emit('handleClose', true);
            },
            handleNo() {
                this.$emit('handleClose', false);
            }
        },
        props: {
            display_dialog: {
                type: Boolean,
                default: false,
                required: true
            },
        },
        watch: {
            display_dialog: function (val) {
                this.showDialog = val;
            },
        },
        components: {
            Dialog, AppButton
        },
        computed: {},
    }
</script>

<style scoped>
</style>
