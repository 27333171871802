<template>
        <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <app-profile-aside-menu></app-profile-aside-menu>
                    <div class="kt-grid__item kt-grid__item--fluid kt-app__content">

                        <div class="col-xl-12">
                            <div class="kt-portlet">
                                <div class="kt-portlet__head">
                                    <div class="kt-portlet__head-label">
                                        <h3 class="kt-portlet__head-title">
                                            {{ $t('personal_information') }}
                                            <small>{{ $t('update_personal_information') }}</small>
                                        </h3>
                                    </div>
                                </div>
                                <app-alert :type="'danger'" :icon="'glyphicons-exclamation-sign'" @handleClick="errors = []" v-show="errors.length">
                                    <b>{{ $t('please_fix_this_errors') }}</b>
                                    <ul>
                                        <li v-for="error in errors" :key="error">{{ error }}</li>
                                    </ul>
                                </app-alert>
                                <form class="kt-form kt-form--label-right" v-on:submit.prevent>
                                    <div class="kt-portlet__body">
                                        <div class="kt-section kt-section--first">
                                            <div class="kt-section__body">
                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h3 class="kt-section__title kt-section__title-sm">{{ $t('customer') }}</h3>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">
                                                        {{ $t('fist_name') }}
                                                    </label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                            </div>
                                                            <app-input v-model="user.name" :placeholder="$t('fist_name')"></app-input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h3 class="kt-section__title kt-section__title-sm">{{ $t('contact') }}</h3>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">
                                                        {{ $t('email_address') }}
                                                    </label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                            </div>
                                                            <app-input v-model="user.username" :placeholder="$t('email_address')"></app-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="kt-portlet__foot">
                                        <div class="kt-form__actions">
                                            <div class="row">
                                                <div class="col-lg-3 col-xl-3">
                                                </div>
                                                <div class="col-lg-3 col-xl-3">
                                                    <app-button type="primary" @handleClick="validateForm">{{ $t('submit') }}</app-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import AppInput from '../../../common/AppInput';
    import AppButton from '../../../common/AppButton';
    import AppProfileAsideMenu from './AppProfileAsideMenu';
    import AppAlert from '../../../common/AppAlert';

    import axios from '../../../../axios/axios-auth';
    import {router} from '../../../../router';

    export default {
        mounted() {
            this.user = this.$store.getters.user;
        },
        data() {
            this.$i18n.locale = this.$store.getters.user.language;
            return {
                user: {
                    name: '',
                    user_email: "",
                },
                show_alert: false,
                errors: []
            }
        },
        components: {
            AppInput,
            AppButton,
            AppProfileAsideMenu,
            AppAlert
        },
        methods: {
            validateForm() {
                if (!this.user.name) {
                    this.errors.push(this.$t('name_required'));
                }
                if (!this.user.username) {
                    this.errors.push(this.$t('email_required'));
                }
                if (this.errors.length === 0) {
                    this.updateProfile();
                }
            },
            updateProfile() {
                let vm = this;
                axios.post(process.env.VUE_APP_BACKEND_ADDRESS + '/me/email', {
                    email: vm.user.username,
                    name: vm.user.name
                }).then(function (response) {
                    if (response.status === 200) {
                        vm.showToast(vm.$t('update_profile_success'), 'success');
                        router.push({name: 'login'});
                    }
                }).catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 400 && error.response.statusText === 'RECORD_ALREADY_EXISTS') {
                            vm.showToast(vm.$t('update_profile_error_email'), 'error')
                        } else {
                            vm.showToast(vm.$t('update_profile_error'), 'error');
                        }
                    } else if (error.request) {
                        vm.showToast(vm.$t('update_profile_error'), 'error');
                    } else {
                        vm.showToast(vm.$t('update_profile_error'), 'error');
                    }
                });
            },
            showToast(message, type) {
                this.$toast.add({
                    severity: type,
                    summary: message,
                    life: 5000
                });
            },
        },
        watch: {
            locale(val) {
                this.$i18n.locale = val
            }
        },
    }
</script>

<style scoped>
    a {
        color: #74788d;
    }

    a:hover {
        color: #2c77f4;
    }
</style>

<i18n>
    {
        "pt-BR": {
            "email_required": "Email obrigatório",
            "name_required": "Nome obrigatório",
            "fist_name": "Nome",
            "email_address": "Email",
            "email": "Email",
            "personal_information": "Informações Pessoais",
            "update_personal_information": "Atualize suas informações pessoais",
            "customer": "Informações pessoais:",
            "contact": "Informações de contato",
            "submit": "Salvar",
            "update_profile_error": "Não foi possível atualizar as informações",
            "update_profile_error_email": "Não foi possível atualizar as informações, este email já está em uso",
            "update_profile_success": "Informações atualizadas com sucesso, necessário efetuar login novamente"
        },
        "en-US": {
            "email_required": "Email required",
            "name_required": "Nome required",
            "fist_name": "Fist Name",
            "email_address": "Email Address",
            "email": "Email",
            "personal_information": "Personal Information",
            "update_personal_information": "update your personal informaiton",
            "customer": "Personal Info:",
            "contact": "Contact Info:",
            "submit": "Submit",
            "update_profile_error": "We cannot update your informations Não foi possível atualizar as informações",
            "update_profile_error_email": "We cannot update your informations, email already used",
            "update_profile_success": "Update success, login again"
        }
    }

</i18n>
