var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('DataTable',{attrs:{"value":_vm.answersList,"paginator":true,"rows":10,"filters":_vm.filters,"filterDisplay":"menu","globalFilterFields":['checklist_title', 'operator_name', 'vehicle_display_id', 'vehicle_chassis', 'date_string'],"dataKey":"answer_id","rowHover":true,"loading":_vm.answersListLoading,"responsiveLayout":"scroll","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink\n                       CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":[10,25,50],"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} registros","selection":_vm.selectedAnswerFromList,"selectionMode":"single"},on:{"update:filters":function($event){_vm.filters=$event},"update:selection":function($event){_vm.selectedAnswerFromList=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex"},[_c('span',{staticClass:"p-input-icon-left w-25"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{attrs:{"placeholder":"Busca por palavra-chave"},model:{value:(_vm.filters['global'].value),callback:function ($$v) {_vm.$set(_vm.filters['global'], "value", $$v)},expression:"filters['global'].value"}})],1)])]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" Sem registros encontrados para a organização selecionada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando dados, por favor aguarde. ")]},proxy:true}])},[_c('Column',{attrs:{"field":"answerDate","header":"Data e Horário","sortable":"","dataType":"date","sortField":"answer_date"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(data.date_string)+" ")])]}}])}),_c('Column',{attrs:{"field":"checklistTitle","header":"Título do Checklist","sortable":"","sortField":"checklist_title"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(data.checklist_title)+" ")])]}}])}),_c('Column',{attrs:{"field":"operatorName","header":"Nome do Operador","sortable":"","sortField":"operator_name"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(data.operator_name)+" ")])]}}])}),_c('Column',{attrs:{"field":"vehicleDisplayId","header":"Frota","sortable":"","sortField":"vehicle_display_id"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(data.vehicle_display_id)+" ")])]}}])}),_c('Column',{attrs:{"field":"vehicleChassis","header":"Chassis","sortable":"","sortField":"vehicle_chassis"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(data.vehicle_chassis)+" ")])]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',[_vm._v(" Selecione um registro e depois clique em continuar: ")])])}]

export { render, staticRenderFns }