<template>
    <div>
        <DataTable :value="data" removableSort :paginator="true" :rows="15" :rowHover="true" :sortOrder="-1"
                   dataKey="id" style="text-align: center" :filters.sync="filters" filterDisplay="row"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} pontos de captação">
            <template #empty>
                Nenhum ponto de captação cadastrado
            </template>
            <template #loading>
                Carregando pontos de captação
            </template>
            <template #header>
                <div class="table-header">
                    Pontos de captação
                </div>
            </template>
            <Column field="location" header="Local de captação" :sortable="true" headerClass="headerClass"
                    bodyClass="bodyClass" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.location }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="state" header="Estado" :sortable="true" headerClass="headerClass" bodyClass="bodyClass"
                    filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.state }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="city" header="Município" :sortable="true" headerClass="headerClass" bodyClass="bodyClass"
                    filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.city }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="up" header="UP" :sortable="true" headerClass="headerClass" bodyClass="bodyClass"
                    filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.up }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="point_id" header="ID ponto" :sortable="true" headerClass="headerClass" bodyClass="bodyClass"
                    filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.point_id }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="flow" header="Vazão" :sortable="true" headerClass="headerClass" bodyClass="bodyClass"
                    filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.flow }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="period" header="Período" :sortable="true" headerClass="headerClass" bodyClass="bodyClass"
                    filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.period }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import dateFormat from '@/mixins/DateMixin'
import WaterWithdrawalService from "@/services/WaterWithdrawalService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import filterValue from "../../../mixins/FilterFunctionsDataTables";
import {FilterMatchMode} from 'primevue/api';

export default {
    mixins: [dateFormat, filterValue],
    beforeMount() {
        this.waterWithdrawalService = new WaterWithdrawalService();
        this.getWaterPoints()
    },
    data() {
        return {
            waterWithdrawalService: null,
            data: [],
            filters: {
                'period': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'flow': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'point_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'up': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'city': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'state': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'location': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
        }
    },
    props: {
        organization: {
            type: Object
        }
    },
    methods: {
        getWaterPoints() {
            this.waterWithdrawalService.getWaterPoints(this.organization.id).then(data => {
                this.data = data
            }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Problemas ao carregar as organizações',
                    life: 5000
                });
            })
        }
    },
    components: {
        DataTable, Column, InputText
    }
}
</script>

<style scoped>

</style>
