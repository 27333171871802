<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile ">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <div class="row center">
                        <Dropdown v-model="selectedOrg" :options="organizations"
                                  optionLabel="display_name" placeholder="Organização"
                                  class="auto-center" :dataKey="'id'"
                                  :filter="true"
                                  style="width: 250px"
                                  @input="saveChosenOptionsToLocalStorage">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingOrgs"> Carregando, aguarde</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding data-table-width">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rows="50" :rowHover="true" :loading="loadingOperators"
                                :sortOrder="-1" sortField="last_modified_date" stripedRows 
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                :rowsPerPageOptions="[10,25,50]" :showGridlines="true"
                                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} Motoristas"
                                selectionMode="single" dataKey="mac_address"
                                class="p-datatable-customers"
                                :filters.sync="filters" filterDisplay="row" responsiveLayout="scroll">
                        <template #empty>
                            Nenhum motorista localizado
                        </template>
                        <template #loading>
                            Carregando motoristas, por favor aguarde
                        </template>
                        <Column field="last_modified_date" header="Horário" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width':'10rem'}"
                             :sortable="true" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                            <template #body="slotProps">
                                <span>{{dateHourFormat(slotProps.data.last_modified_date)}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="city" header="Município" headerClass="headerClass" :styles="{'min-width':'12rem'}" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                            <template #body="slotProps">
                                <span>{{slotProps.data.city}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="state" header="Estado" headerClass="headerClass" :styles="{'min-width':'5rem'}" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                            <template #body="slotProps">
                                <span>{{slotProps.data.state}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="identification" header="Cadastro" headerClass="headerClass" :styles="{'min-width':'12rem'}"
                                :sortable="true" filterMatchMode="custom" bodyClass="bodyClass"
                                :filterFunction="filterValue" :showFilterMenu="false" :showClearButton="false">
                            <template #body="slotProps">
                                <span>{{maskCpfCnpj(slotProps.data.identification)}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="full_name" header="Nome" headerClass="headerClass" :styles="{'min-width':'12rem'}" bodyClass="bodyClass"
                                :sortable="true" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                            <template #body="slotProps">
                                <span>{{slotProps.data.full_name}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column header="Ação" headerClass="headerClass" bodyClass="bodyClass">
                            <template #body="slotProps">
                                <div style="min-width: 60px">
                                    <Button @click="editAcc(slotProps.data)" icon="pi pi-user-edit"
                                            class="p-button-rounded actions" v-tooltip.bottom="'Editar'"/>

                                    <Button @click="sendMessage(slotProps.data)" icon="pi pi-comment"
                                            class="p-button-rounded actions p-button-help"
                                            v-tooltip.bottom="'Mensagem'"/>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {router} from '../../../router';

import AppTable from "../../common/AppTable";
import maskCpfCnpj from "../../../mixins/StringsMaskMixin";
import appendPercent from "../../../mixins/StringsMaskMixin";
import dateHourFormat from "../../../mixins/DateMixin";
import getTimeFromSecondsMixin from "../../../mixins/DateMixin";
import AppButton from "../../common/AppButton";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';
import OperatorsService from "../../../services/OperatorsService";
import OrganizationsService from "@/services/OrganizationsService";
import filterValue from "../../../mixins/FilterFunctionsDataTables";
import Tooltip from 'primevue/tooltip';
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import moment from "moment";
import {FilterMatchMode} from 'primevue/api';
import {FilterService} from 'primevue/api';
const DATE_FILTER = 'DATE FILTER';
const IDENTIFICATION_FILTER = 'IDENTIFICATION FILTER';

export default {
    mixins: [maskCpfCnpj, dateHourFormat, appendPercent, filterValue, getTimeFromSecondsMixin, orderCustomFieldByNaturalOrder],
    mounted() {
        FilterService.register(DATE_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.dateHourFormat(value).includes(filter);
        });
        FilterService.register(IDENTIFICATION_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.maskCpfCnpj(value).includes(filter);
        });
        this.operatorsService = new OperatorsService();
        this.organizationsService = new OrganizationsService();
        this.getOrganizations();
    },
    data() {
        return {
            operatorsService: null,
            filters: {
                'full_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'identification': {value: null, matchMode: IDENTIFICATION_FILTER},
                'state': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'city': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'last_modified_date': {value: null, matchMode: DATE_FILTER}
            },
            selectedOperators: [],
            gridData: [],
            organizations: [],
            organizationsService: null,
            loadingOperators: false,
            loadingOrgs: false,
            selectedOrg: null,
            storedChosenOptions: {}
        }
    },
    methods: {
        editAcc(data) {
            router.push({name: 'operators-edit', params: {id: data.id}})
        },
        sendMessage(data) {
            if (!data.phone) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Número de telefone não informado',
                    life: 5000
                });
                return;
            }
            let link = "https://wa.me/+55" + data.phone;
            window.open(link, '_blank');
        },
        formatDecimals(val) {
            if (!val) {
                return "";
            }
            return val.toFixed(0) + "%";
        },
        onRowSelect(event) {
            router.push({
                name: 'operators-edit',
                params: {cpf_cnpj: event.data.cpf_cnpj, parent: event.data.parent}
            })
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('OPERATORS').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            })
            .catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        goToNew() {
            router.push({name: 'operators-new'})
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
    },
    watch: {
        selectedOrg: function (val) {
            this.loadingOperators = true;
            this.gridData = [];
            this.operatorsService.getAllV3(val.id)
                .then((response) => {
                    this.gridData = response;
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.loadingOperators = false;
            })
        }
    },
    directives: {
        tooltip: Tooltip
    },
    components: {
        AppTable, DataTable, Column, InputText, MultiSelect,
        Calendar, Dropdown, ProgressBar, Button, AppButton
    },

}
</script>


<style lang="scss">
@import "./src/assets/styles/primevue";

.margin-25 {
    margin-right: 25px;
}

@media (max-width: 1024px){
    .data-table-width{
        width: 100vw;
    }

    .margin-25 {
        margin-right: 10px;
    }
}

@media (min-width: 1025px){
    .data-table-width{
        max-width: calc(100vw - 140px);
    }
}
</style>


