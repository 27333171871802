<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                    </span>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="card-settings col-5 col-md-2 ml-2 ml-md-3 mr-2 mr-md-0" v-if="hasFeatureEnabled('ALERTS')">
                            <router-link :to="{name: 'alerts-dictionary'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-alerta.svg" alt=""
                                     style="margin-top: 15px">
                                <div class="card-text">
                                    Dicionário Alertas
                                </div>
                            </router-link>
                        </div>
                        <div class="card-settings col-5 col-md-2 ml-md-3" v-if="hasFeatureEnabled('FIELDS')">
                            <router-link :to="{name: 'fields'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-mapa.svg" alt=""
                                     style="margin-top: 15px">
                                <div class="card-text">
                                    Locais
                                </div>
                            </router-link>
                        </div>
                        <div v-if="hasFeatureEnabled('OPERATIONS_CENTER_CONNECTION')" class="card-settings-oc col-5 col-md-2 ml-2 ml-md-3 mr-2 mr-md-0">
                            <router-link :to="{name: 'operations-center-connection'}">
                                <img :src="getLogoOperationsCenter()" alt="" width="130"
                                    style="margin-top: 3px;">
                                <div class="card-text" style="position: relative; bottom: 3px;">
                                    Sincronizar com Operations Center
                                </div>
                            </router-link>
                        </div>
                        <div class="card-settings col-5 col-md-2 ml-md-3 mr-md-3" v-if="hasFeatureEnabled('APPCONFIGS')">
                            <router-link :to="{name: 'checklists'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-checklist.svg" alt=""
                                     style="margin-top: 15px">
                                <div class="card-text">
                                    Checklist
                                </div>
                            </router-link>
                        </div>
                        <div class="card-settings col-5 ml-2 col-md-2 ml-md-0 mr-2 mr-md-0"  v-if="hasFeatureEnabled('APPCONFIGS')">
                            <router-link :to="{name: 'activities-list'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-activities-list.svg" alt=""
                                     style="margin-top: 15px">
                                <div class="card-text">
                                    Gerenciar Atividades
                                </div>
                            </router-link>
                        </div>
                        <div class="card-settings col-5 col-md-2 ml-md-3 other-row" v-if="hasFeatureEnabled('APPCONFIGS')">
                            <router-link :to="{name: 'stop-reasons'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-relogio.svg" alt=""
                                     style="margin-top: 6px">
                                <div class="card-text">
                                    Gerenciar Motivos de Parada
                                </div>
                            </router-link>
                        </div>
                        <div class="card-settings col-5 ml-2 col-md-2 ml-md-3 mr-2 mr-md-0 other-row" v-if="hasFeatureEnabled('APPCONFIGS')">
                            <router-link :to="{name: 'configuration-list'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-config-lista.svg" alt=""
                                     style="margin-top: 6px">
                                <div class="card-text">
                                    Sincronizar Atividades e Motivos de Parada
                                </div>
                            </router-link>
                        </div>
                        <div class="card-settings col-5 ml-2 col-md-2 ml-md-3 mr-2 mr-md-0 other-row" v-if="hasFeatureEnabled('WEBHOOK')">
                            <router-link :to="{name: 'webhook'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-webhook.svg" alt=""
                                     style="margin-top: 6px">
                                <div class="card-text">
                                    Webhook
                                </div>
                            </router-link>
                        </div>
                        <div v-if="showWaterCatchmentDashboard" class="card-settings col-5 col-md-2 ml-md-3">
                            <router-link :to="{name: 'water-withdrawal-dashboard'}" class="kt-menu__link kt-menu__toggle">
                                <img src="../../../assets/icons/icone-agua.svg" alt=""
                                     style="margin-top: 6px">
                                <div class="card-text">
                                    Captação de Água
                                </div>
                            </router-link>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import hasFeatureEnabled from "@/mixins/OrganizationFeatureFlagsMixin";

export default {
    mixins: [hasFeatureEnabled],
    computed: {
        showWaterCatchmentDashboard: function (){
            let email = this.getUserEmail;

            if (!email) {
                return false;
            }

            if (email.includes('@suzano') || email.includes('iotag.com.br')) {
                return true;
            }
            return false;
        },
        ...mapGetters([
            'getUserEmail'
        ])
    },
    methods:{
        getLogoOperationsCenter(){
            if (process.env.VUE_APP_THEME_ACTIVE === 'IOTAG') {
                return require("../../../assets/icons/icone-john-deere-connected.svg")
            } else if(process.env.VUE_APP_THEME_ACTIVE === 'VIVO') {
                return require("../../../assets/icons/icone-john-deere-connected-black.svg")
            } else {
                return require("../../../assets/icons/icone-john-deere-connected-white.svg")
            }
        }
    },
    components: {
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";

.card-settings {
    background-color: $aside-menu-background;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    width: 200px;
    border-radius: 10px;
    max-height: 150px;
    margin-top: 10px
}

.card-settings-oc {
    background-color: $aside-menu-background;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    width: 200px;
    border-radius: 10px;
    margin-top: 10px
}

.card-settings img {
    width: 50px;
    filter: $aside-menu-item-inactive;
}

.card-text {
    font-size: 1em;
    margin-top: 3px;
    color: $aside-menu-text;
}

.other-row{
    margin-top: 1rem;
    min-height: 91.78px;
}

</style>
