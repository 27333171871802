<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile ">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <!-----------------DROPDOWN DE ORGANIZAÇÃO-------------------------->
                <Dropdown v-model="organizationSelected" :options="orgsList"
                          @change="handleOrganizationChange($event.value)"
                          optionLabel="display_name" placeholder="Selecione a organização"
                          class="auto-center dropdown-width" :dataKey="'id'"
                          :filter="true">
                    <template #option="slotProps">
                        <div>
                            <div v-if="loadingOrgs">Carregando, aguarde</div>
                            <div v-else-if="slotProps.option.display_name === 'ND'">
                                -
                            </div>
                            <div v-else>
                                {{ slotProps.option.display_name }}
                            </div>
                        </div>
                    </template>
                </Dropdown>

                <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon">
                        </span>
                </div>
                <div class="kt-portlet__head-toolbar ">
                    <div class="kt-portlet__head-wrapper">
                        <app-button type="secondary" @handleClick="back" :class="{'margin-25-right': !is_col_12, 'width-button': is_col_12}">
                            <i class="glyphicons glyphicons-arrow-left"></i><span v-if="!is_col_12">Voltar</span>
                        </app-button>
                    </div>
                </div>
            </div>
            <!-----------------CORPO DA PÁGINA-------------------------->
            <div v-if="!organizationSelected" class="activities-body">
                <h3>
                    Selecione uma organização para visualizar as atividades
                </h3>
            </div>
            <div v-else class="activities-body">
                <div class="activities-list white-background">
                    <div class="activities-list_title">
                        <h3 style="margin: 0rem">Lista de Atividades</h3>
                        <app-button type="primary" @handleClick='openDialog("import")'>
                            <span class="pi pi-arrow-up-right mr-2"/>
                            Importar Atividades
                        </app-button>
                    </div>

                    <div class="activities-list_table">
                        <div v-if="activitiesList != null" style="width: 100%;">
                            <DataTable :value="activitiesList" :rowHover="true"
                                       :loading="activitiesLoading" responsiveLayout="scroll">
                                <template #loading>
                                    Carregando atividades, por favor aguarde
                                </template>
                                <Column field="description" bodyClass="bodyClass">
                                    <template #body="slotProps">
                                        <div style="display: flex; justify-content: start;">
                                            <h5>{{ slotProps.data.description }}</h5>
                                        </div>
                                    </template>
                                </Column>
                                <Column bodyClass="bodyClass">
                                    <template #body="slotProps">
                                        <div style="display: flex; justify-content: end;">
                                            <Button icon="pi pi-trash" class="p-button-rounded delete-btn"
                                                    @click='openDialog("delete",slotProps.data)'/>
                                        </div>
                                    </template>
                                </Column>

                            </DataTable>
                        </div>
                        <div v-else>
                            <h4>Nenhuma atividade registrada para a organização.</h4>
                        </div>
                    </div>

                    <div class="activities-add">
                        <app-button type="primary" @handleClick='openDialog("add")'>
                            <span class="pi pi-plus mr-2"/>
                            Adicionar Atividade
                        </app-button>
                    </div>
                </div>

                <!------------------------ DIALOG: ADD ACTIVITIES --------------------------------->
                <Dialog header="Adicionar Atividade" :visible="displayAddActivities" :modal="true"
                        @update:visible='closeDialog("add")' :contentStyle="{'overflow': 'visible'}">
                    <div class="dialog-body" :style="{'width': widthDialog}">
                        <div>
                        <h5>Adicione um nome para essa nova atividade:</h5>

                        <app-input type="text" v-model="newActivity" :style="{'width': widthDialog}"
                                   @keydown.native="removeComma($event)"

                                   placeholder="Exemplos: fertlização do solo, colheita, etc."></app-input>
                        </div>
                        <div class="activities-code-div" :style="{'width': widthDialog}">
                            <h5>Escolha uma categoria para a atividade:</h5>

                            <Dropdown v-model="selectedActivityCode" :options="activityCodes" optionLabel="description"
                                      :style="{'width': widthDialog}" placeholder="Selecione um tipo de atividade"
                                      dataKey="id" class="auto-center dropdown-width"></Dropdown>

                        </div>
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" @click='closeDialog("add")'
                                class="p-button-text"/>
                        <Button :disabled="disableButton" label="Adicionar" icon="pi pi-check" @click="addActivities()"/>
                    </template>
                </Dialog>

                <!------------------------ DIALOG: DELETE ACTIVITIES --------------------------------->
                <Dialog header="Deletar Atividade" :visible="displayDeleteActivities" :modal="true"
                        @update:visible='closeDialog("delete")'>
                    <div class="dialog-body">
                        <h5>Deseja deletar a atividade:</h5>
                        <div style="display: flex; justify-content:center; min-width: 300px">
                            <h3>{{ deleteActivity.description }}</h3>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text"
                                @click='closeDialog("delete")'/>
                        <Button :disabled="disableButton" label="Deletar" icon="pi pi-check" @click="deleteActivities()"/>
                    </template>
                </Dialog>

                <!------------------------ DIALOG: IMPORT ACTIVITIES --------------------------------->
                <Dialog header="Importar Atividade" :visible="displayImportActivities" :modal="true"
                        @update:visible='closeDialog("import")'>
                    <div v-if="!importSelected" style="max-height: 50vh" :style="{'width': widthDialog}">
                        <p>De qual organização você deseja importar?</p>
                        <Listbox v-model="organizationImportSelected" :options="orgsList" optionLabel="display_name"
                                 :filter="true" filterPlaceholder="Procure pelo nome da organização" class="existent-list">
                        </Listbox>
                    </div>
                    <div v-else style="max-height: 50vh" :style="{'width': widthDialog}">
                        <p>Selecione as atividades que deseja importar:</p>
                        <DataTable :value="importedActivities" :rowHover="true"
                                   :selection.sync="selectedImportedActivities" dataKey="id" responsiveLayout="scroll"
                                   :loading="importedActivitiesLoading" class="p-datatable-striped">
                            <template #loading>
                                Carregando atividades, por favor aguarde
                            </template>
                            <Column selectionMode="multiple" headerStyle="width: 3rem" headerClass="headerClass" bodyClass="bodyClass"></Column>
                            <Column field="description" header="Atividades" headerClass="headerClass" bodyClass="bodyClass"></Column>
                        </DataTable>
                    </div>
                    <template #footer>
                        <div style="padding-top: 1rem">
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" style="border: 1px solid #2196f3"
                                    @click='closeDialog("import")'/>
                            <Button v-if="!importSelected" label="Prosseguir" icon="pi pi-angle-right" @click="importNextPage()" />
                            <Button :disabled="disableButton" v-else label="Salvar" icon="pi pi-check" @click="importActivities()" />
                        </div>
                    </template>
                </Dialog>

            </div>
        </div>
    </div>
</template>

<script>
import {router} from '../../../router';
import AppButton from "../../common/AppButton";
import AppInput from "../../common/AppInput";
import Button from "primevue/button";
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Listbox from 'primevue/listbox';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import OrganizationsService from "../../../services/OrganizationsService";
import ActivitiesService from "../../../services/ActivitiesService";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import {ActivityCodes} from "@/components/views/configuration-list/ActivityCodes";

export default {
    mixins: [orderCustomFieldByNaturalOrder],
    beforeMount() {
        this.organizationsService = new OrganizationsService();
        this.activitiesService = new ActivitiesService();
        this.getOrganizations();
    },
    mounted(){
        this.widthDialog = this.isScreenMobile ? '85vw' : '40vw';
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            is_col_12: false,
            widthDialog: '',
            disableButton: false,
            //organizations
            organizationsService: null,
            organizationSelected: null,
            organizationImportSelected: null,
            orgsList: null,
            loadingOrgs: true,
            //activities
            activitiesService: null,
            activitiesLoading: false,
            importedActivitiesLoading: false,
            importedActivities: null,
            selectedImportedActivities: null,
            newActivity: '',
            deleteActivity: '',
            selectedActivityCode: null,
            activityCodes: ActivityCodes,
            //dialogos
            displayAddActivities: false,
            displayDeleteActivities: false,
            displayImportActivities: false,
            importSelected: false,
            //resposta para o backend
            activitiesList: [],
            storedChosenOptions: null
        }
    },
    methods: {
        back() {
            router.push('/settings');
        },
        removeComma(e){
            if (/,/g.test(e.key)) {
                e.preventDefault();
            }
        },
        //Carrega todas as organizaçãoes que o usuário tem acesso no ínicio da sessão = beforeMount()
        getOrganizations() {
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('APPCONFIGS').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.orgsList = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.organizationSelected = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];

            if (this.organizationSelected) {
                this.handleOrganizationChange(this.organizationSelected);
            }
        },
        //Carrega a lista de atividades organização selecionada ao alterar o dropdown e ao fazer qualquer alteração (adicionar, deletar e importar).
        handleOrganizationChange(organization) {
            this.activitiesLoading = true;
            this.activitiesList = [];
            this.activitiesService.getActivitiesByOrganization(organization.id)
                .then((response) => {
                    this.activitiesList = response;
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.activitiesLoading = false;
            })

            this.saveChosenOptionsToLocalStorage();
        },
        saveChosenOptionsToLocalStorage() {
            if (!this.storedChosenOptions) {
                this.storedChosenOptions = {}
            }

            this.storedChosenOptions['clients'] = [this.organizationSelected];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        //Abre a caixa de diálogo referente ao botão clicado
        openDialog(str, activity_description){
            if(str === "add"){
                this.displayAddActivities = true;
            } else if (str === "delete"){
                this.deleteActivity = JSON.parse(JSON.stringify(activity_description));
                this.displayDeleteActivities = true;
            }else if (str === "import"){
                this.displayImportActivities = true;
            }
        },
        //Fecha a caixa de diálogo aberta
        closeDialog(str){
            if(str === "add"){
                this.selectedActivityCode = null;
                this.displayAddActivities = false;
                this.newActivity = '';
            } else if (str === "delete"){
                this.deleteActivity = '';
                this.displayDeleteActivities = false;
            } else if (str === "import"){
                this.displayImportActivities = false;
                this.importSelected = false;
                this.importedActivities = null;
                this.organizationImportSelected = null;
                this.selectedImportedActivities = null
            }
        },
        checkActivities(activity) {
            let answer = true;
            let activities = this.activitiesList;

            if(activity === null || activity === undefined || activity === ""){
                answer = false;
            } else if(activities !== null){
                for (const element of activities) {
                    if (activity.toUpperCase() === element.description.toUpperCase()) {
                        answer = false;
                    }
                }
            }

            if(this.selectedActivityCode === null || this.selectedActivityCode === undefined) {
                answer = false
            }

            return answer;
        },
        addActivities() {
            this.disableButton = true;
            if (this.checkActivities(this.newActivity)) {
                let newAct = [{"description": this.newActivity, "code": this.selectedActivityCode.id}];
                this.activitiesService.addActivity(this.organizationSelected.id, newAct)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso!',
                            detail: 'Atividade cadastrada',
                            life: 5000
                        });
                    }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação. Tente novamente',
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Você não tem acesso a esta funcionalidade',
                        life: 5000
                    });
                    console.log(error)
                }).finally(() => {
                    this.disableButton = false;
                    this.displayAddActivities = false;
                    this.selectedActivityCode = null;
                    this.newActivity = null;
                    this.handleOrganizationChange(this.organizationSelected);
                })
            } else {
                if(this.newActivity === null || this.newActivity === undefined || this.newActivity === '') {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Digite um nome válido para a atividade',
                        life: 5000
                    });
                } else if (this.selectedActivityCode === null || this.selectedActivityCode === undefined) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Escolha uma categoria para a atividade',
                        life: 5000
                    })
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Você já possui uma atividade com esse nome',
                        life: 5000
                    });
                }
                this.disableButton = false;
            }
        },
        deleteActivities() {
            this.disableButton = true;
            this.activitiesService.deleteActivity(this.deleteActivity.id)
                .then((response) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso!',
                        detail: 'Atividade deletada',
                        life: 5000
                    });
                }).catch((error) => {
                if (error.response && error.response.status === 409) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Não foi possível deletar a atividade, pois está presente em uma lista de configuração',
                        life: 5000
                    });

                    return;
                }

                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.disableButton = false;
                this.displayDeleteActivities = false;
                this.deleteActivity = '';
                this.handleOrganizationChange(this.organizationSelected);
            })
        },
        importNextPage(){
            if(this.organizationImportSelected === null){
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Selecione uma organização.',
                    life: 5000
                });
            } else {
                this.importSelected = true;
                this.importedActivitiesLoading = true;
                this.getImportedOrgActivities();
            }
        },
        getImportedOrgActivities(){
            this.activitiesService.getActivitiesByOrganization(this.organizationImportSelected.id)
                .then((response) => {
                    this.importedActivities = response;
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.importedActivitiesLoading = false;
                if(this.importedActivities === null){
                    this.importSelected = false;
                }
            })
        },

        isThereDuplicatedActivities(){
            let equals;
            let activities = JSON.parse(JSON.stringify(this.selectedImportedActivities));
            if(this.activitiesList){
                for (let i = 0; i < this.activitiesList.length; i++){
                    equals = activities.filter(activity => activity.description === this.activitiesList[i].description);
                    if(equals.length > 0){
                        return true
                    }
                }
            }

            return false;
        },
        importActivities(){
            this.disableButton = true;
            //Retorna erro se o usuário não selecionar nenhuma atividade
            if(this.selectedImportedActivities === null || this.selectedImportedActivities.length === 0){
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Selecione uma atividade',
                    life: 5000
                });
                this.disableButton = false;
                return;
            }

            let request = this.selectedImportedActivities;

            if(!this.isThereDuplicatedActivities()) {
                this.activitiesService.addActivity(this.organizationSelected.id, request)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso!',
                            detail: 'Atividades cadastradas',
                            life: 5000
                        });
                    }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação. Tente novamente',
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Você não tem acesso a esta funcionalidade',
                        life: 5000
                    });
                }).finally(() => {
                    this.disableButton = false;
                    this.displayImportActivities = false;
                    this.importedActivities = null;
                    this.importSelected = false;
                    this.handleOrganizationChange(this.organizationSelected);
                    this.selectedImportedActivities = null
                })
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro!',
                    detail: 'Você já possui uma das atividades selecionadas',
                    life: 5000
                });
                this.disableButton = false;
            }


        },
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    components: {
        AppButton, AppInput, Button, Dropdown, DataTable, Listbox, Column, Dialog
    }
}

</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";

::v-deep .p-datatable-thead {
    display:none !important;
}

.activities-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.white-background {
    background: white;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 5px;
    margin: 0rem;
}

.activities-list {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.activities-list_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activities-list_table {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem 2rem 0rem;
}

.activities-add {
    display: flex;
    justify-content: center;
}

.activities-code-div {
    margin-top: 2rem;
}

.delete-btn{
    border: none;
    background-color: #E51111;
}

.delete-btn:enabled:hover{
    background-color: #AD1111;
}

.width-button{
    max-width: 55px;
    margin: 5px;
}

@media(max-width: 1024px){
    .dropdown-width{
        width: 250px;
    }

    .margin-25-right{
        margin: 0px !important;
    }

    .activities-list {
        width: 100%;
        margin: 0rem 2rem 0rem 2rem;
    }
}



</style>
