<template>
    <div class="row no-gutters" style="width: 100%;">
        <div class="col-12">
                <div @click="remove" v-show="showStyle" class="button-remove" style="top: 23px;">
                    <i class="pi pi-times" style="font-size: 12px; margin-top: 6px; color: black;"/>
                </div>
                <Dropdown v-model="chartOne" v-show="showStyle" :options="chartsOptions"
                          optionLabel="name" class="select-chart" style="margin-top: 15px;"
                          @change="handleDropDownChange($event)" placeholder="Selecione" appendTo="tableDiv"/>

                <app-button type="primary" v-show="showStyle" @handleClick="groupChart('chartOne')" class="button-group" style="margin-top: 10px;">
                    {{chartOneButtonText}}
                </app-button>

                <h2 v-show="!showStyle" style="font-size: 20px; text-align: center;">{{ chartOne.name }}</h2>

                <app-simple-column-chart style="margin: 0 auto;"
                                         :is_analysis="true"
                                         :is_pdf="is_pdf"
                                         :chart_data="chartOneData"
                                         :reflow_chart="chartUpdater"
                                         :chart_name="'chartOne'"
                                         :chart_code="chartOneCode"
                                         @setChartIntervalAndCalculateSeries="setChartIntervalAndCalculateSeries"
                                         ref="chartOne">
                </app-simple-column-chart>
        </div>
    </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import AppButton from "../../common/AppButton";
import AppPieChart from "./AppPieChart";
import {optionsColumnCharts} from './chartsOptions';
import AppSimpleColumnChart from "./AppColumnChart";
import moment from 'moment-timezone';

export default {
    beforeMount() {
        this.initializeStartAndEndDates();
    },
    mounted() {
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            is_col_12: false,
            is_pdf: false,
            showStyle: true,
            startDate: null,
            endDate: null,
            chartsData: [],
            chartsOptions: optionsColumnCharts,
            chartOne: [],
            chartOneData: [],
            HOUR: 'hour',
            DAY: 'day',
            MONTH: 'month',
            chartOneInterval: '',
            chartOneButtonText: 'Agrupar',
            chartOneGrouped: false,
            isLoading: false,
            storedChosenOptions: {},
            chartOneCode: '',
            chartUpdater: false,
            id: 0,
            modelLocalStorage: 0
        }
    },
    methods: {
        setModelLocalStorage(val){
            this.modelLocalStorage = val;
        },
        isPdf(val){
            this.is_pdf = val;
        },
        cleanStyle(){
            this.showStyle = !this.showStyle
        },
        remove(){
            this.$emit('remove', this.id);
        },
        correctHeight(val){
            this.$refs.chartOne.redimensionar(val);
        },
        groupChart(id) {
            this.changeButtonText();

            this[id + 'Grouped'] = !this[id + 'Grouped'];

            this.$refs[id].changeStaking(this[id + 'Grouped']);

            if (!this[id].sumInGroupMode) {
                this[id + 'Data'] = this.getChartData(this[id].code, this[id + 'Grouped'], this[id + 'Interval']);
            }
        },
        initializeStartAndEndDates() {
            let storedFilterBarOptions = {}
            storedFilterBarOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));
            if (storedFilterBarOptions) {
                this.startDate = new Date(storedFilterBarOptions['startDate']);
                this.endDate = new Date(storedFilterBarOptions['endDate']);
            }
            this.setChartInterval(this.calculateChartInterval(), 'chartOne');
        },
        changeButtonText() {
            this.chartOneButtonText = this.chartOneButtonText.includes('Des') ? 'Agrupar' : 'Desagrupar';
        },
        fillCharts() {
            this.chartOneData = this.getChartData(this.chartOne.code, this.chartOneGrouped, this.chartOneInterval);
        },
        getChartData(code, chartGrouped, interval) {

            if (!this.chartsData || !code) {
                return [];
            }
            switch (code) {
                case 'ANALISE_ATIVIDADES':
                    return this.getAnaliseAtividade(interval);
                case 'ANALISE_COMBUSTIVEL':
                    return this.getAnaliseCombustivel(interval);
                case 'AREA':
                    return this.getArea(interval);
                case 'PRODUTIVIDADE':
                    if (chartGrouped) {
                        return this.getProdutividadeGrouped(interval);
                    }
                    return this.getProdutividadeSeparated(interval);
                case 'DISP_MECANICA':
                    if (chartGrouped) {
                        return this.getDispMecanicaGrouped(interval);
                    }
                    return this.getDispMecanicaSeparated(interval);
                case 'RENDIMENTO':
                    if (chartGrouped) {
                        return this.getRendimentoGrouped(interval);
                    }
                    return this.getRendimentoSeparated(interval);
                case 'CONSUMO':
                    if (chartGrouped) {
                        return this.getConsumoGrouped(interval);
                    }
                    return this.getConsumoSeparated(interval);
                case 'COMBUSTIVEL_MEDIO':
                    return this.getCombustivelMedio(interval);
                case 'VELOCIDADE':
                    if (chartGrouped) {
                        return this.getVelocidadeGrouped(interval);
                    }
                    return this.getVelocidadeSeparated(interval);
                case 'HORAS_TRABALHADAS':
                    return this.getHorasTrabalhadas(interval);
                case 'MOTIVOS_DE_PARADAS':
                    return this.getMotivosParadas(interval);
                case 'QUILOMETRO_LITRO':
                    if (chartGrouped) {
                        return this.getKmPerLiterGrouped(interval);
                    }
                    return this.getKmPerLiterSeparated(interval);
                default:
                    console.log("Valor desconhecido " + code);
                    break;

            }
        },
        setChartIntervalAndCalculateSeries(val) {
            this.setChartInterval(val.chartInterval, val.chartName);

            this[val.chartName + 'Data'] = this.getChartData(this[val.chartName].code, this[val.chartName + 'Grouped'],
                    this[val.chartName + 'Interval']);
        },
        setChartInterval(intervalValue, chartName) {
            this[chartName + 'Interval'] = intervalValue
        },
        calculateChartInterval() {
            //dias * horas
            let firstLevelInHours = 2 * 24  * 1000;
            let secondLevelInHours = 60 * 24  * 1000;

            let a = moment.tz(this.startDate * 1000, 'America/Sao_Paulo').utc();
            let b = moment.tz(this.endDate * 1000, 'America/Sao_Paulo').utc();


            let diff = b.diff(a, 'hours');

            if (diff < firstLevelInHours) {
                return this.HOUR;
            } else if (diff < secondLevelInHours) {
                return this.DAY;
            } else {
                return this.MONTH;
            }
        },
        getAnaliseAtividade(interval) {
            let data = {
                workData: [],
                idleData: [],
                maneuverData: [],
                travelData: [],
                offData: [],
                waitingData: [],
                lostData: [],
                maintenanceData: []
            };

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {

                        if (typeof event.event_start === 'number' && typeof event.time === 'number') {
                            if (event.mode == 1) {
                                data.workData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else if (event.mode == 2) {
                                data.idleData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else if (event.mode == 3) {
                                data.maneuverData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else if (event.mode == 4) {
                                data.travelData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else if (event.mode == 5) {
                                data.offData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else if (event.mode == 6) {
                                data.waitingData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else if (event.mode == 7) {
                                data.lostData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else if (event.mode === 8) {
                                data.maintenanceData.push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            }
                        }
                    });
                }
            });

            data.workData = data.workData.sort((a, b) => a.x - b.x);
            data.idleData = data.idleData.sort((a, b) => a.x - b.x);
            data.maneuverData = data.maneuverData.sort((a, b) => a.x - b.x);
            data.travelData = data.travelData.sort((a, b) => a.x - b.x);
            data.offData = data.offData.sort((a, b) => a.x - b.x);
            data.waitingData = data.waitingData.sort((a, b) => a.x - b.x);
            data.lostData = data.lostData.sort((a, b) => a.x - b.x);
            data.maintenanceData = data.maintenanceData.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatArray(data.workData,'Produzindo', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.maneuverData, 'Manobrando', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.travelData, 'Deslocando', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.idleData, 'Ocioso', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.offData, 'Desligado', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.waitingData, 'Aguardando Dados', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.lostData, 'Não Monitorado', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.maintenanceData, 'Manutenção', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;

        },
        getAnaliseCombustivel(interval) {
            let data = {
                workData: [],
                idleData: [],
                maneuverData: [],
                travelData: [],
                maintenanceData: [],
            };

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {

                        if (typeof event.event_start === 'number' && typeof event.consumption === 'number') {
                            if (event.mode == 1) {
                                data.workData.push({
                                    x: event.event_start,
                                    y: event.consumption
                                })
                            } else if (event.mode == 2) {
                                data.idleData.push({
                                    x: event.event_start,
                                    y: event.consumption
                                })
                            } else if (event.mode == 3) {
                                data.maneuverData.push({
                                    x: event.event_start,
                                    y: event.consumption
                                })
                            } else if (event.mode == 4) {
                                data.travelData.push({
                                    x: event.event_start,
                                    y: event.consumption
                                })
                            } else if (event.mode === 8) {
                                data.maintenanceData.push({
                                    x: event.event_start,
                                    y: event.consumption
                                })
                            }
                        }
                    });
                }
            });

            data.workData = data.workData.sort((a, b) => a.x - b.x);
            data.idleData = data.idleData.sort((a, b) => a.x - b.x);
            data.maneuverData = data.maneuverData.sort((a, b) => a.x - b.x);
            data.travelData = data.travelData.sort((a, b) => a.x - b.x);
            data.maintenanceData = data.maintenanceData.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatArray(data.workData,'Produzindo', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.idleData, 'Ocioso', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.maneuverData, 'Manobrando', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.travelData, 'Deslocando', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }
            temp = this.formatArray(data.maintenanceData, 'Manutenção', interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;

        },
        getArea(interval) {

            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {

                        if (typeof event.event_start === 'number' && typeof event.area === 'number') {

                            let index = data.findIndex(i => i.chassis === event.chassis);
                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    y: event.area
                                })
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        y: event.area
                                    }]
                                })
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        formatArray(data, chassis, interval) {

            let response = {
                name: chassis,
                data: []
            };

            let lastDate = 0;
            let lastData = null;
            let first = true;

            let diff = interval;

            data.forEach((event) => {

                if (first) {

                    first = false;
                    lastDate = event.x * 1000;

                    lastData = {
                        y: event.y,
                        x: event.x * 1000
                    }

                } else {
                    //se for < ou = a 0.5 vai arredondar para 0 então ignora
                    let a = moment.tz(lastDate, 'America/Sao_Paulo').utc();
                    let b = moment.tz(event.x * 1000, 'America/Sao_Paulo').utc();

                    let diffDate = b.diff(a, diff);

                    if (diffDate === 0) {
                        lastData.y += event.y;
                    } else {
                        response.data.push({
                            y: parseFloat(lastData.y.toFixed(2)),
                            x: moment.tz(lastData.x, 'America/Sao_Paulo').startOf(diff).valueOf()
                        });

                        lastData = {
                            y: event.y,
                            x: event.x * 1000
                        }
                        lastDate = event.x * 1000;
                    }
                }
            });

            if (lastData && lastData.y > 0.5) {
                response.data.push({
                    y: parseFloat(lastData.y.toFixed(2)),
                    x: moment.tz(lastData.x, 'America/Sao_Paulo').startOf(diff).valueOf()
                });
            }

            response.data = response.data.sort((a, b) => a.x - b.x);

            return response;
        },
        formatRelationArray(data, chassis, interval) {

            let response = {
                name: chassis,
                data: []
            };

            let lastDate = 0;
            let lastData = null;
            let first = true;

            let diff = interval;

            data.forEach((event) => {

                if (first) {

                    first = false;
                    lastDate = event.x * 1000;

                    lastData = {
                        numerator: event.numerator,
                        denominator: event.denominator,
                        x: event.x * 1000
                    }

                } else {
                    //se for < ou = a 0.5 vai arredondar para 0 então ignora
                    let a = moment.tz(lastDate, 'America/Sao_Paulo').utc();
                    let b = moment.tz(event.x * 1000, 'America/Sao_Paulo').utc();

                    let diffDate = b.diff(a, diff);

                    if (diffDate === 0) {
                        lastData.numerator += event.numerator;
                        lastData.denominator += event.denominator;
                    } else {
                        if (lastData && lastData.denominator > 0 && !isNaN(lastData.numerator/lastData.denominator)) {
                            response.data.push({
                                y: parseFloat((lastData.numerator / lastData.denominator).toFixed(2)),
                                x: moment.tz(lastData.x, 'America/Sao_Paulo').startOf(diff).valueOf()
                            });
                        }

                        lastData = {
                            numerator: event.numerator,
                            denominator: event.denominator,
                            x: event.x * 1000
                        }
                        lastDate = event.x * 1000;
                    }
                }
            });


            if (lastData && lastData.denominator > 0 && !isNaN(lastData.numerator/lastData.denominator)) {
                response.data.push({
                    y: parseFloat((lastData.numerator / lastData.denominator).toFixed(2)),
                    x: moment.tz(lastData.x, 'America/Sao_Paulo').startOf(diff).valueOf()
                });
            }

            response.data = response.data.sort((a, b) => a.x - b.x);
            return response;
        },
        formatRelationArrayGrouped(data, interval) {

            let response = {
                name: 'Agrupado',
                data: []
            };

            let lastDate = 0;
            let lastData = null;
            let first = true;

            let diff = interval;

            data.forEach((event) => {
                if (event.denominator > 0 && !isNaN(event.numerator / event.denominator)) {
                    if (first) {

                        first = false;
                        lastDate = event.x * 1000;

                        lastData = {
                            numerator: event.numerator,
                            denominator: event.denominator,
                            x: event.x * 1000
                        }

                    } else {
                        //se for < ou = a 0.5 vai arredondar para 0 então ignora
                        let a = moment.tz(lastDate, 'America/Sao_Paulo').utc();
                        let b = moment.tz(event.x * 1000, 'America/Sao_Paulo').utc();

                        let diffDate = b.diff(a, diff);

                        if (diffDate === 0) {
                            lastData.numerator += event.numerator;
                            lastData.denominator += event.denominator;
                        } else {
                            if (lastData && lastData.denominator > 0 && !isNaN(lastData.numerator / lastData.denominator)) {
                                response.data.push({
                                    y: parseFloat((lastData.numerator / lastData.denominator).toFixed(2)),
                                    x: moment.tz(lastData.x, 'America/Sao_Paulo').startOf(diff).valueOf()
                                });
                            }

                            lastData = {
                                numerator: event.numerator,
                                denominator: event.denominator,
                                x: event.x * 1000
                            }
                            lastDate = event.x * 1000;
                        }
                    }
                }
            });


            if (lastData && lastData.denominator > 0 && !isNaN(lastData.numerator / lastData.denominator)) {
                response.data.push({
                    y: parseFloat((lastData.numerator / lastData.denominator).toFixed(2)),
                    x: moment.tz(lastData.x, 'America/Sao_Paulo').startOf(diff).valueOf()
                });
            }

            response.data = response.data.sort((a, b) => a.x - b.x);
            return response;
        },
        getProdutividadeSeparated(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' && typeof event.time === 'number') {

                            let index = data.findIndex(i => i.chassis === event.chassis);
                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    numerator: event.area,
                                    denominator: event.time
                                })
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        numerator: event.area,
                                        denominator: event.time
                                    }]
                                })
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatRelationArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getProdutividadeGrouped(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' && typeof event.time === 'number') {
                            data.push({
                                x: event.event_start,
                                numerator: event.area,
                                denominator: event.time
                            })
                        }
                    });
                }
            });

            data = data.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatRelationArrayGrouped(data, interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;
        },
        getDispMecanicaSeparated(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' && typeof event.time === 'number') {

                            let index = data.findIndex(i => i.chassis === event.chassis);
                            let maintenanceStopTime = 0;
                            if (event.mode === 8) {
                                maintenanceStopTime = event.time;
                            }

                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    numerator: (event.time - maintenanceStopTime) * 100,
                                    denominator: event.time
                                })
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        numerator: (event.time - maintenanceStopTime) * 100,
                                        denominator: event.time
                                    }]
                                })
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatRelationArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getDispMecanicaGrouped(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' && typeof event.time === 'number') {

                            let maintenanceStopTime = 0;
                            if (event.reason_type && event.mode === 8) {
                                maintenanceStopTime = event.time;
                            }

                            data.push({
                                x: event.event_start,
                                numerator: (event.time - maintenanceStopTime) * 100,
                                denominator: event.time
                            })
                        }
                    });
                }
            });

            data = data.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatRelationArrayGrouped(data, interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;
        },
        getRendimentoSeparated(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' &&
                                typeof event.consumption === 'number' && typeof event.area === 'number') {
                            let index = data.findIndex(i => i.chassis === event.chassis);
                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    numerator: event.consumption,
                                    denominator: event.area
                                })
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        numerator: event.consumption,
                                        denominator: event.area
                                    }]
                                })
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatRelationArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getRendimentoGrouped(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' &&
                                typeof event.consumption === 'number' && typeof event.area === 'number') {
                            data.push({
                                x: event.event_start,
                                numerator: event.consumption,
                                denominator: event.area
                            })
                        }
                    });
                }
            });

            data = data.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatRelationArrayGrouped(data, interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;
        },
        getConsumoGrouped(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' &&
                                typeof event.consumption === 'number' && typeof event.time === 'number') {
                            data.push({
                                x: event.event_start,
                                numerator: event.consumption,
                                denominator: event.time
                            })
                        }
                    });
                }
            });

            data = data.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatRelationArrayGrouped(data, interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;
        },
        getConsumoSeparated(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' &&
                                typeof event.consumption === 'number' && typeof event.time === 'number') {

                            let index = data.findIndex(i => i.chassis === event.chassis);
                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    numerator: event.consumption,
                                    denominator: event.time
                                })
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        numerator: event.consumption,
                                        denominator: event.time
                                    }]
                                })
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatRelationArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getCombustivelMedio(interval) {

            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {

                        if (typeof event.event_start === 'number' && typeof event.consumption === 'number') {

                            let index = data.findIndex(i => i.chassis === event.chassis);
                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    y: event.consumption
                                })
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        y: event.consumption
                                    }]
                                })
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getVelocidadeSeparated(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' &&
                                typeof event.distance === 'number' && typeof event.time === 'number') {

                            let index = data.findIndex(i => i.chassis === event.chassis);
                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    numerator: event.distance,
                                    denominator: event.time,
                                })
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        numerator: event.distance,
                                        denominator: event.time,
                                    }]
                                })
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatRelationArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getVelocidadeGrouped(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' &&
                                typeof event.distance === 'number' && typeof event.time === 'number') {

                            let tempo = event.time;

                            data.push({
                                x: event.event_start,
                                numerator: event.distance,
                                denominator: tempo,
                            })
                        }
                    });
                }
            });

            data = data.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatRelationArrayGrouped(data, interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;
        },
        getHorasTrabalhadas(interval) {

            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' && typeof event.time === 'number') {

                            let workTime = 0;
                            if (event.mode === 1) {
                                workTime = event.time;
                            }

                            if (event.time < 100 && !isNaN(event.time)) {
                                let index = data.findIndex(i => i.chassis === event.chassis);
                                if (index >= 0) {
                                    data[index].data.push({
                                        x: event.event_start,
                                        y: workTime
                                    })
                                } else {
                                    data.push({
                                        chassis: event.chassis,
                                        vehicle_display_id: event.vehicle_display_id,
                                        data: [{
                                            x: event.event_start,
                                            y: workTime
                                        }]
                                    })
                                }
                            }
                        }
                    });
                }
            });

            let response = [];
            // //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getMotivosParadas(interval) {
            let data = {};

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {

                        if (event.type === 'Produzindo') {
                            return
                        }

                        if (typeof event.event_start === 'number' && typeof event.time === 'number') {

                            let event_name = event.reason_code;

                            if (data[event_name]) {
                                data[event_name].push({
                                    x: event.event_start,
                                    y: event.time
                                })
                            } else {
                                data[event_name] = [{
                                    x: event.event_start,
                                    y: event.time
                                }];
                            }

                        }
                    });
                }
            });


            let response = [];
            let vm = this;
            Object.keys(data).forEach(function(key) {
                data[key] = data[key].sort((a, b) => a.x - b.x);
                let temp = vm.formatArray(data[key], key, interval);
                if (temp.data.length > 0) {
                    response.push(temp);
                }
            });

            return response;
        },
        getKmPerLiterGrouped(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' &&
                            typeof event.distance === 'number' && typeof event.consumption === 'number') {
                            data.push({
                                x: event.event_start,
                                numerator: event.distance,
                                denominator: event.consumption
                            })
                        }
                    });
                }
            });

            data = data.sort((a, b) => a.x - b.x);

            let response = [];
            let temp = this.formatRelationArrayGrouped(data, interval);
            if (temp.data.length > 0) {
                response.push(temp);
            }

            return response;
        },
        getKmPerLiterSeparated(interval) {
            let data = [];

            //cria o array para ficar mais facil manipular
            this.chartsData.forEach((vehicle) => {
                if (vehicle.events) {
                    vehicle.events.forEach((event) => {
                        if (typeof event.event_start === 'number' && typeof event.distance === 'number' &&
                            typeof event.consumption === 'number') {

                            let index = data.findIndex(i => i.chassis === event.chassis);
                            if (index >= 0) {
                                data[index].data.push({
                                    x: event.event_start,
                                    numerator: event.distance,
                                    denominator: event.consumption
                                });
                            } else {
                                data.push({
                                    chassis: event.chassis,
                                    vehicle_display_id: event.vehicle_display_id,
                                    data: [{
                                        x: event.event_start,
                                        numerator: event.distance,
                                        denominator: event.consumption
                                    }]
                                });
                            }
                        }
                    });
                }
            });

            let response = [];
            //agora organiza cfme a seleção
            data.forEach((eventData) => {
                if (eventData.vehicle_display_id) {
                    let temp = this.formatRelationArray(eventData.data, eventData.vehicle_display_id, interval);
                    if (temp.data.length > 0) {
                        response.push(temp);
                    }
                }
            });

            return response;
        },
        getDataFromEvents(results) {
            let vm = this;
            let storedFilterBarOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            this.startDate = new Date(storedFilterBarOptions.startDate).valueOf().toString();
            this.endDate = new Date(storedFilterBarOptions.endDate).valueOf().toString();
            this.setChartInterval(this.calculateChartInterval(), 'chartOne');

            if (results.length > 0) {
                let tempByMachineWrapper = [];

                //Para o side cards não precisa diferenciar por máquina, por isso jogamos tudo nesse array.
                let tempDataToChild = [];

                results.forEach((singleMachineResult) => {
                    if (singleMachineResult.length > 0) {
                        let temp = {
                            chassis: "",
                            events: [],
                            faults: null,
                            time: Date.now().valueOf().toString()
                        };
                        singleMachineResult.forEach((event) => {
                            temp.events.push(event);
                            temp.chassis = event.chassis;
                            tempDataToChild.push(event);
                        })
                        tempByMachineWrapper.push(temp);
                    }
                });
                vm.chartsData = tempByMachineWrapper;
                vm.fillCharts();

            }
        },
        findChartOptionByCode(chartName) {
            if (this.chartsOptions.find(x => x.code === this.storedChosenOptions[chartName]) !== undefined) {
                return this.chartsOptions.find(x => x.code === this.storedChosenOptions[chartName]);
            }
            return {};
        },
        handleDropDownChange(event) {
            let val = {
                name: event.value.name,
                code: event.value.code,
                sumInGroupMode: event.value.sumInGroupMode,
                id: this.id
            }

            this.$emit('dropdownChanged', val);
        },
        dataWatch(filterEventsResults, chartDataFromIdb, filterTime, chartUpdater, chartDataToSideCards){
            this.chartsData = chartDataFromIdb;
            this.fillCharts();

            this.chartUpdater = chartUpdater;

            this.getDataFromEvents(filterEventsResults);
        },
        setLocalStorage(count){
            this.id = count;
        },
        setOption(){
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

            temp[this.modelLocalStorage].value.forEach(element => {
                if(element.id === this.id){
                    this.chartOne = {name: element.name, code: element.code, sumInGroupMode: element.sumInGroupMode};
                }
            });
        },
        setNoValue(){
            this.chartOneData = [];
        },
    },
    watch: {
        chartOne: function (val) {
            if (!val.code) {
                return;
            }
            this.chartOneCode = val.code;
            this.chartOneData = this.getChartData(val.code, this.chartOneGrouped, this.chartOneInterval);
        },
        filter_events_results: function(val) {
            this.getDataFromEvents(val);
        },
        chart_data_from_idb: function(val) {
            this.chartsData = val;
            this.fillCharts();
        },
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    components: {
        Dropdown, AppSimpleColumnChart, AppButton, AppPieChart
    },

}
</script>

<style scoped type="scss">

.containers-charts {
    position: relative;
}

.select-chart {
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 230px;
}

.button-group {
    z-index: 1;
    position: absolute;
    top: 5px;
    left: 97%;
    transform: translate(-98%, 0);
}

/* sm >  Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .cascade-select {
        min-width: 200px;
    }
    .containers-charts {
        padding-bottom: 5px;
    }

}

/* ms >  Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

}

/* lg > Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {


}

/* xl > X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .cascade-select {
        min-width: 270px;
    }

}


</style>
