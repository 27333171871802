<template>
    <div class="row no-gutters" style="width: 100%;">
        <div class="col-12">
            <div @click="remove" v-show="showStyle" class="button-remove">
                <i class="pi pi-times" style="font-size: 12px; margin-top: 6px; color: black;"/>
            </div>

            <h2 v-show="!showStyle" style="font-size: 20px; text-align: center;">{{ chartOne.name }}</h2>

            <Dropdown v-model="chartOne" v-show="showStyle" :options="chartsOptions" optionLabel="name"
                    class="select-chart" style="z-index: 4; margin-bottom: 50px;"
                    @change="handleDropDownChange($event)" placeholder="Selecione" appendTo="tableDiv"/>

            <app-line-chart style="margin: 0 auto" ref="lineChart"
                            :is_analysis="true"
                            :is_pdf="is_pdf"
                            :chart_data="chartOneData.seriesData"
                            :reflow_chart="chartUpdater"
                            :series_symbol="chartOneData.symbol">
            </app-line-chart>
        </div>
    </div>
</template>

<script>
    import Dropdown from 'primevue/dropdown';
    import AppLineChart from "./AppLineChart";
    import {optionsLineCharts} from "./chartsOptions"

    export default {
        data() {
            return {
                chartsData: null,
                is_pdf: false,
                showStyle: true,
                chartsOptions: optionsLineCharts,
                chartOne: [],
                chartOneData: {
                    seriesData: [],
                    symbol: '',
                    name: ''
                },
                storedChosenOptions: {},
                chartUpdater: false,
                id: 0,
                modelLocalStorage: 0
            }
        },
        methods: {
            setModelLocalStorage(val){
                this.modelLocalStorage = val;
            },
            isPdf(val){
                this.is_pdf = val;
            },
            cleanStyle(){
                this.showStyle = !this.showStyle
            },
            remove(){
                this.$emit('remove', this.id);
            },
            correctHeight(val){
                this.$refs.lineChart.redimensionar(val);
            },
            fillCharts() {
                this.chartOneData = this.getChartData(this.chartOne.code);
            },
            getChartData(code) {
                if (!this.chartsData || !code) {
                    return {
                        seriesData: [],
                        symbol: ''
                    };
                }
                switch (code) {
                    case 'AREA':
                        return this.getAreaData();
                    case 'PRODUTIVIDADE':
                        return this.getProdutividade();
                    case 'RENDIMENTO':
                        return this.getRendimento();
                    case 'CONSUMO':
                        return this.getConsumo();
                    case 'COMBUSTIVEL_MEDIO':
                        return this.getCombustivelMedio();
                    case 'VELOCIDADE':
                        return this.getVelocidade();
                    default:
                        break;
                }
            },
            getAreaData() {
                let responseData = [];

                this.chartsData.forEach((chartData) => {
                    let data = [];
                    let seriesName = "";

                    if (chartData.events) {
                        chartData.events.forEach((event) => {
                            if (typeof event.event_start === 'number' && typeof event.event_end === 'number' &&
                                    typeof event.area === 'number') {
                                data.push([(event.event_start) * 1000, parseFloat(event.area.toFixed(2))]);
                                data.push([(event.event_end) * 1000, parseFloat(event.area.toFixed(2))]);
                            }
                            seriesName = event.vehicle_display_id;
                        });
                        responseData.push({data: data, findNearestPointBy: 'xy', step: true, name: seriesName})
                    }

                });
                return {seriesData: responseData, symbol: 'ha'};
            },
            getProdutividade() {
                let responseData = [];

                this.chartsData.forEach((chartData) => {
                    let data = [];
                    let seriesName = "";
                    let lastEvent = null;

                    if (chartData.events) {
                        chartData.events.forEach((event) => {
                            if (typeof event.event_start === 'number' && typeof event.event_end === 'number' &&
                                    typeof event.area === 'number' && typeof event.time === 'number') {

                                if (lastEvent) {
                                    if (lastEvent.event_end !== event.event_start) {
                                        data.pop();
                                        data.push([lastEvent.event_end * 1000, 0]);
                                    }
                                }


                                let prod = event.area / event.time;
                                if (prod > 10 || isNaN(prod) || event.mode !== 1) {
                                    data.push([event.event_start * 1000, 0]);
                                    data.push([event.event_end * 1000, 0]);
                                } else {
                                    data.push([event.event_start * 1000, parseFloat(prod.toFixed(2))]);
                                    data.push([event.event_end * 1000, parseFloat(prod.toFixed(2))]);
                                }
                            }
                            seriesName = event.vehicle_display_id;
                            lastEvent = event;
                        });
                    }
                    responseData.push({data: data, findNearestPointBy: 'xy', step: true, name: seriesName})
                });
                return {seriesData: responseData, symbol: 'ha/h'};
            },
            getRendimento() {

                let responseData = [];

                this.chartsData.forEach((chartData) => {
                    let data = [];
                    let seriesName = "";
                    let lastEvent = null;

                    if (chartData.events) {
                        chartData.events.forEach((event) => {
                            if (typeof event.event_start === 'number' && typeof event.event_end === 'number' &&
                                    typeof event.consumption === 'number' && typeof event.area === 'number') {

                                if (lastEvent) {
                                    if (lastEvent.event_end !== event.event_start) {
                                        data.pop();
                                        data.push([lastEvent.event_end * 1000, 0]);
                                    }
                                }

                                let rendimento = 0;

                                if (event.consumption > 0 && event.area > 0) {
                                    rendimento = event.consumption / event.area;
                                }

                                if (rendimento > 50 || isNaN(rendimento) || event.type !== 'Produzindo') {
                                    // console.log("rendimento com problemas " + rendimento);
                                    data.push([event.event_start * 1000, 0]);
                                    data.push([event.event_end * 1000, 0]);
                                } else {
                                    data.push([event.event_start * 1000, parseFloat(rendimento.toFixed(2))]);
                                    data.push([event.event_end * 1000, parseFloat(rendimento.toFixed(2))]);
                                }
                            }
                            seriesName = event.vehicle_display_id;

                            lastEvent = event;
                        });
                    }
                    if (data.length > 0) {
                        responseData.push({data: data, findNearestPointBy: 'xy', step: true, name: seriesName})
                    }
                });
                return {seriesData: responseData, symbol: 'L/ha'};
            },
            getConsumo() {

                let responseData = [];

                this.chartsData.forEach((chartData) => {
                    let data = [];
                    let seriesName = "";
                    let lastEvent = null;

                    if (chartData.events) {
                        chartData.events.forEach((event) => {
                            if (typeof event.event_start === 'number' && typeof event.event_end === 'number' &&
                                    typeof event.consumption === 'number' && typeof event.time === 'number') {

                                if (lastEvent) {
                                    if (lastEvent.event_end !== event.event_start) {
                                        data.pop();
                                        data.push([lastEvent.event_end * 1000, 0]);
                                    }
                                }

                                let tempoTotal = event.time;

                                let value = event.consumption / tempoTotal;
                                if (value > 200 || isNaN(value) || event.mode !== 1) {
                                    data.push([event.event_start * 1000, 0]);
                                    data.push([event.event_end * 1000, 0]);
                                } else {
                                    data.push([event.event_start * 1000, parseFloat(value.toFixed(2))]);
                                    data.push([event.event_end * 1000, parseFloat(value.toFixed(2))]);
                                }
                            }
                            seriesName = event.vehicle_display_id;
                            lastEvent = event;
                        });
                    }
                    responseData.push({data: data, findNearestPointBy: 'xy', step: true, name: seriesName})
                });
                return {seriesData: responseData, symbol: 'L/h'};

            },
            getCombustivelMedio() {

                let responseData = [];

                this.chartsData.forEach((chartData) => {
                    let data = [];
                    let seriesName = "";

                    if (chartData.events) {
                        chartData.events.forEach((event) => {
                            if (typeof event.event_start === 'number' && typeof event.consumption === 'number') {
                                data.push([event.event_start * 1000, parseFloat(event.consumption.toFixed(2))]);
                                data.push([event.event_end * 1000, parseFloat(event.consumption.toFixed(2))]);
                            }
                            seriesName = event.vehicle_display_id;
                        });
                    }
                    responseData.push({data: data, findNearestPointBy: 'xy', step: true, name: seriesName});
                });
                return {seriesData: responseData, symbol: 'Litros'};
            },
            getVelocidade() {
                //deslocamento total / (tempo total - tempo parado)

                let responseData = [];

                this.chartsData.forEach((chartData) => {
                    let data = [];
                    let seriesName = "";
                    let lastEvent = null;

                    if (chartData.events) {
                        chartData.events.forEach((event) => {
                            if (typeof event.event_start === 'number' && typeof event.event_end === 'number' &&
                                    typeof event.distance === 'number' && typeof event.time === 'number') {

                                if (lastEvent) {
                                    if (lastEvent.event_end !== event.event_start) {
                                        data.pop();
                                        data.push([lastEvent.event_end * 1000, 0]);
                                    }
                                }

                                let tempo = event.time;

                                let velocidade = event.distance / tempo;
                                if (velocidade < 0 || velocidade > 50 || isNaN(velocidade) || event.mode !== 1) {
                                    data.push([event.event_start * 1000, 0]);
                                    data.push([event.event_end * 1000, 0]);
                                } else {
                                    data.push([event.event_start * 1000, parseFloat(velocidade.toFixed(2))]);
                                    data.push([event.event_end * 1000, parseFloat(velocidade.toFixed(2))]);
                                }
                            }
                            seriesName = event.vehicle_display_id;
                            lastEvent = event;
                        });
                    }
                    responseData.push({data: data, findNearestPointBy: 'xy', step: true, name: seriesName});
                });
                return {seriesData: responseData, symbol: 'km/h'};
            },
            findChartOptionByCode(chartName) {
                if (this.chartsOptions.find(x => x.code === this.storedChosenOptions[chartName]) !== undefined) {
                    return this.chartsOptions.find(x => x.code === this.storedChosenOptions[chartName]);
                }
                return {};
            },
            handleDropDownChange(event) {
                let val = {
                    name: event.value.name,
                    code: event.value.code,
                    sumInGroupMode: false,
                    id: this.id
                }

                this.$emit('dropdownChanged', val);
            },
            getDataFromEvents(results) {
                let vm = this;
                if (results.length > 0) {
                    let tempByMachineWrapper = [];

                    //Para o side cards não precisa diferenciar por máquina, por isso jogamos tudo nesse array.
                    let tempDataToChild = [];

                    results.forEach((singleMachineResult) => {
                        if (singleMachineResult.length > 0) {
                            let temp = {
                                chassis: "",
                                events: [],
                                faults: null,
                                time: Date.now().valueOf().toString()
                            };
                            singleMachineResult.forEach((event) => {
                                temp.events.push(event);
                                temp.chassis = event.chassis;
                                tempDataToChild.push(event);
                            })
                            tempByMachineWrapper.push(temp);
                        }
                    });
                    vm.chartsData = tempByMachineWrapper;
                    vm.fillCharts();

                }
            },
            dataWatch(filterEventsResults, chartDataFromIdb, filterTime, chartUpdater, chartDataToSideCards){
                this.chartsData = chartDataFromIdb;
                this.fillCharts();

                this.chartUpdater = chartUpdater;

                this.getDataFromEvents(filterEventsResults);
            },
            setLocalStorage(count){
                this.id = count;
            },
            setOption(){
                let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

                temp[this.modelLocalStorage].value.forEach(element => {
                    if(element.id === this.id){
                        this.chartOne = {name: element.name, code: element.code};
                    }
                });
            },
            setNoValue(){
                this.chartOneData.seriesData = [];
            },
        },

        watch: {
            chartOne: function (val) {
                if (!val.code) {
                    return;
                }
                this.chartOneData = this.getChartData(val.code);
            },
            filter_events_results: function(val) {
                this.getDataFromEvents(val);
            },
            chart_data_from_idb: function(val) {
                this.chartsData = val;
                this.fillCharts();
            },
        },


        components: {
            Dropdown, AppLineChart
        },

    }
</script>

<style scoped>

    .select-chart {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        min-width: 200px;
    }

</style>
