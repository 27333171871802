export const STATUSES = [
    {name: 'PENDENTE', code: 'NEW'},
    {name: 'EXECUTANDO', code: 'IN_PROGRESS'},
    {name: 'FINALIZADO', code: 'CLOSED'},
];

export const STATUSES_FAILURES = [
    {name: 'ATIVO', code: 'ACTIVE'},
    {name: 'INATIVO', code: 'INACTIVE'},
];

export const PRIORITIES = [
    {name: 'ALTO', code: 'HIGH'},
    {name: 'MÉDIO', code: 'MEDIUM'},
    {name: 'BAIXO', code: 'LOW'},
    {name: 'NULO', code: 'UNKNOWN'},
];

export const CSV_FIELDS = ['date', 'city', 'state', 'display_id', 'identification_number', 'hour_meter', 'trade_name', 'code',
    'priority', 'count', 'response_time', 'execution_time', 'total_time', 'assignee', 'work_order', 'status', 'observation'];

export const CSV_LABELS = [
    'Horário', 'Município', 'Estado', 'Identificação', 'Chassis', 'Horímetro', 'Propriedade',
    'Código', 'Nível', 'Contagem', 'Reação', 'Execução', 'Total', 'Responsável', 'Ordem', 'Serviço', 'Observação'
];

export const GRID_COLUMNS = [
    {field: 'date', header: 'Horário'},
    {field: 'city', header: 'Município'},
    {field: 'state', header: 'Estado'},
    {field: 'display_id', header: 'Identificação'},
    {field: 'identification_number', header: 'Chassis'},
    {field: 'hour_meter', header: 'Horímetro'},
    {field: 'trade_name', header: 'Propriedade'},
    {field: 'code', header: 'Código'},
    {field: 'priority', header: 'Nível'},
    {field: 'count', header: 'Contagem'},
    {field: 'response_time', header: 'Reação'},
    {field: 'execution_time', header: 'Execução'},
    {field: 'total_time', header: 'Total'},
    {field: 'assignee', header: 'Responsável'},
    {field: 'work_order', header: 'Ordem'},
    {field: 'status', header: 'Serviço'},
];
