<template>
    <div v-if="!show_cards && grid_data.length > 0 && showDashboard" class="kt-portlet__body p-2">
        <div class="row no-gutters status-height ml-2 mr-2 ml-md-0 mr-md-0">
            <div class="col mr-3 pl-2 status status-border">
                Finalizados
                <div class="status-description">
                    alertas distintos
                </div>
                <div class="statusCounter" style="background-color: #a8f0aa;">
                    {{ closedStatusCounter }}
                </div>
            </div>
            <div class="col mr-3 pl-2 status status-border">
                Executando
                <div class="status-description">
                    alertas distintos
                </div>
                <div class="statusCounter" style="background-color: #ffcd99;">
                    {{ inProgressStatusCounter }}
                </div>
            </div>
            <div class="col pl-2 status status-border">
                Pendentes
                <div class="status-description">
                    alertas distintos
                </div>
                <div class="statusCounter" style="background-color: #ff99a3;">
                    {{ newStatusCounter }}
                </div>
            </div>
        </div>

        <div class="row no-gutters mt-3 ml-2 mr-2 ml-md-0 mr-md-0" style="min-height: 100px">
            <div class="col-6 col-md-3 d-flex pr-md-2 pr-2">
                <div class="priority priority-border-left" style="background-color: #ff99a3;">
                    ALTO
                </div>
                <div class="priority-counter priority-border-right">
                    {{ priorityHighCounter }}
                    <div class="priority-description mr-md-2 mr-2">
                        alertas distintos
                    </div>
                </div>
            </div>

            <div class="col-6 col-md-3 d-flex pr-md-2 pl-md-2 pl-2">
                <div class="priority priority-border-left" style="background-color: #ffcd99;">
                    MÉDIO
                </div>
                <div class="priority-counter priority-border-right">
                    {{ priorityMediumCounter }}
                    <div class="priority-description mr-md-2">
                        alertas distintos
                    </div>
                </div>
            </div>

            <div class="col-6 col-md-3 d-flex pr-md-2 pl-md-2 pr-2 mt-3 mt-md-0">
                <div class="priority priority-border-left" style="background-color: #a8f0aa;">
                    BAIXO
                </div>
                <div class="priority-counter priority-border-right">
                    {{ priorityLowCounter }}
                    <div class="priority-description mr-md-2 mr-2">
                        alertas distintos
                    </div>
                </div>
            </div>

            <div class="col-6 col-md-3 d-flex pl-md-2 pl-2 mt-3 mt-md-0">
                <div class="priority priority-border-left" style="background-color: #e6e6e6;">
                    NULO
                </div>
                <div class="priority-counter priority-border-right">
                    {{ priorityUnknownCounter }}
                    <div class="priority-description">
                        alertas distintos
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters mt-3" style="width: 100%;">
            <div class="col-md-6 charts-space containers-charts pr-md-2">
                <DataTable :value="assigneeData" style="width: 100%" class="p-datatable-striped" :paginator="true" :rows="6"
                    responsiveLayout="scroll"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[6]"
                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} Responsáveis" :sortOrder="-1"
                    sortField="date" removableSort>
                    <template #header>
                        Lista de Responsáveis
                    </template>

                    <template #empty>
                        <div style="text-align: center">
                            Nenhum responsável com ordem finalizada
                        </div>
                    </template>
                    <Column field="code" header="" :headerStyle="{ width: '40px !important' }" bodyClass="bodyClass">
                        <template #body="slotProps">
                            <Avatar icon="pi pi-user" class="p-mr-2" shape="circle" />
                        </template>
                    </Column>
                    <Column field="name" header="Nome" :sortable="true" :headerStyle="{ 'font-size': '14px' }" bodyClass="bodyClass"></Column>
                    <Column field="numberOfOrders" header="Ordens" :sortable="true" :headerStyle="{ 'font-size': '14px' }" bodyClass="bodyClass">
                    </Column>
                    <Column field="averageExecutionTime" header="Tempo de médio de execução" :sortable="true"
                        :headerStyle="{ 'font-size': '14px' }" bodyClass="bodyClass">
                        <template #body="slotProps">
                            {{ getTimeFromSecondsDurationFormatMixinShowZero(slotProps.data.averageExecutionTime) }}
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-md-6 containers-charts pl-2 pr-2 pr-md-0 mt-3 mt-md-0">
                <div style="min-height: 100%;background-color: white">
                    <app-column-chart :chart_data="dataToColumnChart" :chart_name="columnChartName"
                        :chart_code="columnChartCode"></app-column-chart>
                </div>
            </div>
        </div>

        <div class="row no-gutters mt-3 mb-2 mb-md-3" style="width: 100%;">
            <div class="col-md-6 containers-charts pr-md-2 ml-2 pr-2 ml-md-0">
                <app-pie-chart style="width: 100%;" :chart_data="alertPieChartData" :chart_code="pieChartCode"
                    :chart_title="pieChartTitleAlertTypes" />
            </div>
            <div class="col-md-6 containers-charts mt-3 mt-md-0 pl-md-2 pl-2 pr-2 pr-md-0">
                <app-pie-chart style="width: 100%;" :chart_data="machineChartData" :chart_code="pieChartCode"
                    :chart_title="pieChartTitleAlertMachines" />
            </div>
        </div>
    </div>
</template>

<script>

import Avatar from "primevue/avatar";
import AppPieChart from "../../analysis/AppPieChart";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import getTimeFromSecondsDurationFormatMixinShowZero from '../../../../mixins/DateMixin';
import AppButton from "../../../common/AppButton";
import AppColumnChart from "../../analysis/AppColumnChart";


export default {
    mixins: [getTimeFromSecondsDurationFormatMixinShowZero],
    data() {
        return {
            newStatusCounter: 0,
            inProgressStatusCounter: 0,
            closedStatusCounter: 0,

            priorityHighCounter: 0,
            priorityMediumCounter: 0,
            priorityLowCounter: 0,
            priorityUnknownCounter: 0,

            assigneeData: [],

            dataToColumnChart: [],
            columnChartName: '',
            columnChartCode: 'ALERTAS_PARETO',

            pieChartCode: '',
            pieChartTitleAlertTypes: 'Alertas por Tipo',
            pieChartTitleAlertMachines: 'Alertas por Máquina',

            machineChartData: [],
            alertPieChartData: [],
        }
    },
    props: {
        grid_data: {
            type: Array,
            required: true
        },
        cards_data: {
            type: Array,
            required: true
        },
        show_cards: {
            type: Boolean,
            default: true
        },
        filters: {
            type: Object,
        },
        selected_status: {
            type: Array,
        },
        selected_priority: {
            type: Array,
        },
        showDashboard: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        setStatusCounter(status, count) {
            switch (status) {
                case 'NEW':
                    this.newStatusCounter += count;
                    break;
                case 'IN_PROGRESS':
                    this.inProgressStatusCounter += count;
                    break;
                case 'CLOSED':
                    this.closedStatusCounter += count;
                    break;
            }
        },
        setPriorityCounter(priority, count) {
            switch (priority) {
                case 'HIGH':
                    this.priorityHighCounter += count;
                    break;
                case 'MEDIUM':
                    this.priorityMediumCounter += count;
                    break;
                case 'LOW':
                    this.priorityLowCounter += count;
                    break;
                case 'UNKNOWN':
                    this.priorityUnknownCounter += count;
                    break;
            }
        },
        addAssigneeData(alert) {
            if (alert.status === 'CLOSED') {
                let assigneeIndex = this.assigneeData.findIndex(assigneeObj => { return assigneeObj.name.includes(alert.assignee) });
                if (assigneeIndex < 0) {
                    this.assigneeData.push({
                        name: alert.assignee,
                        numberOfOrders: 1,
                        executionTime: alert.execution_time > 0 ? alert.execution_time : 0,
                        averageExecutionTime: alert.execution_time > 0 ? alert.execution_time : 0,
                    })
                    return;
                }

                this.assigneeData[assigneeIndex].numberOfOrders++;
                this.assigneeData[assigneeIndex].executionTime += alert.execution_time > 0 ? alert.execution_time : 0;
                this.assigneeData[assigneeIndex].averageExecutionTime =
                    this.assigneeData[assigneeIndex].executionTime / this.assigneeData[assigneeIndex].numberOfOrders;
            }
        },
        processAlertDataToCharts(val) {
            let alertsToColumnChartTemp = [];
            let total = 0;

            val.forEach(alert => {
                let index = alertsToColumnChartTemp.findIndex(i => i.code === alert.code);
                if (index >= 0) {
                    alertsToColumnChartTemp[index].count += alert.count;
                } else {
                    alertsToColumnChartTemp.push({
                        code: alert.code,
                        count: alert.count
                    })
                }
                total += alert.count;
            });

            alertsToColumnChartTemp.forEach(alertProcessed => {
                this.alertPieChartData.push({
                    name: 'Alerta ' + alertProcessed.code,
                    y: parseFloat(((alertProcessed.count / total) * 100).toFixed(2)),
                    rawValue: alertProcessed.count,
                    rawSymbol: ' vezes'
                });
            })

            this.dataToColumnChart = alertsToColumnChartTemp;
        },
        processDataToPieChartForMachines() {
            let total = 0;

            this.cards_data.forEach(card => {
                total += card.count;
            });

            this.cards_data.forEach(card => {
                this.machineChartData.push({
                    name: 'Máquina ' + card.display_id,
                    y: parseFloat(((card.count / total) * 100).toFixed(2)),
                    rawValue: card.count,
                    rawSymbol: ' alertas'
                });
            });
        },
        clearData() {
            this.newStatusCounter = 0;
            this.inProgressStatusCounter = 0;
            this.closedStatusCounter = 0;

            this.priorityHighCounter = 0;
            this.priorityMediumCounter = 0;
            this.priorityLowCounter = 0;
            this.priorityUnknownCounter = 0;

            this.assigneeData = [];

            this.dataToColumnChart = []

            this.alertPieChartData = []
            this.machineChartData = [];

        },
        checkIfAlertIsInFilters(alert) {
            if (!this.filters) {
                return true;
            }

            if (this.selected_priority) {
                if ((this.selected_priority.length !== 0 && this.selected_priority.indexOf(alert.priority) === -1)) {
                    return false;
                }
            }

            if (this.selected_status) {
                if ((this.selected_status.length !== 0 && this.selected_status.indexOf(alert.status) === -1)) {
                    return false;
                }
            }

            return true;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
    },
    watch: {
        showDashboard: async function (val) {
            if (!val) {
                return;
            }

            await this.sleep(1);

            this.clearData();
            let alertsToProcess = [];
            this.grid_data.forEach(alertData => {

                if (!this.checkIfAlertIsInFilters(alertData)) {
                    return;
                }

                alertsToProcess.push(alertData);
                this.setStatusCounter(alertData.status, 1);
                this.setPriorityCounter(alertData.priority, 1);
                this.addAssigneeData(alertData);
            })
            this.processAlertDataToCharts(alertsToProcess);
            this.processDataToPieChartForMachines();
        },
        filters: {
            deep: true,
            handler() {
                this.clearData();
                let alertsToProcess = [];
                this.grid_data.forEach(alertData => {

                    if (!this.checkIfAlertIsInFilters(alertData)) {
                        return;
                    }

                    alertsToProcess.push(alertData);
                    this.setStatusCounter(alertData.status, 1);
                    this.setPriorityCounter(alertData.priority, 1);
                    this.addAssigneeData(alertData);
                })
                this.processAlertDataToCharts(alertsToProcess);
                this.processDataToPieChartForMachines();
            }
        }
    },
    components: {
        AppColumnChart, AppButton, Avatar, AppPieChart, DataTable, Column
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/alerts/style";

.status {
    background-color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: black;
    font-size: 18px;
}

.status-description {
    position: absolute;
    bottom: 1px;
    left: 10px;
    font-size: 10px;
    color: #8f8f8f;
}

.statusCounter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
}

.priority {
    font-weight: bold;
    color: #8f8f8f;
    font-size: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    width: 50%;
}

.priority-counter {
    background-color: white;
    font-weight: bold;
    color: black;
    font-size: 34px;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    width: 50%;

}

.priority-description {
    position: absolute;
    bottom: 1px;
    right: 5px;
    font-size: 10px;
    color: #8f8f8f;
}

.containers-charts {
    position: relative;
}

.status-height {
    min-height: 60px;
}

.priority-border-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.priority-border-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.status-border {
    border-radius: 5px;
}

@media (max-width: 767px) {
    .padding-container {
        padding-right: 5px;
    }

    .priority {
        font-size: 18px;
    }

    .priority-counter {
        font-size: 22px;
        align-items: start;
    }

    .status {
        font-size: 14px;
        align-items: start;
        padding-top: 13px;
    }

    .status-description {
        bottom: 12px;
        left: 6px;
    }

    .statusCounter {
        min-width: 25px;
        min-height: 25px;
        margin-top: 6px;
    }
}
</style>
