<template>
    <div>
        <!-- begin:: Header Mobile -->
        <div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed " :class="{'brightness': mobile_brightness}">
            <div class="kt-header-mobile__logo">
                <a href="#" @click.prevent="goToHome">
                    <img style="width: 85px;" alt="Logo" class="img-fluid" :src="headerImage"/>
                </a>
            </div>
            <div class="kt-header-mobile__toolbar">
                <button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
                        :class="{'kt-header-mobile__toolbar-toggler--active':asideMenu}"
                        @click="openAsideMenu">
                    <span/>
                </button>
                <!--                <button class="kt-header-mobile__toolbar-toggler"-->
                <!--                        @click="openHeaderMenu"-->
                <!--                        :class="{'kt-header-mobile__toolbar-toggler&#45;&#45;active':headerMenu}">-->
                <!--                    <span/>-->
                <!--                </button>-->
                <!--                <button class="kt-header-mobile__toolbar-topbar-toggler"-->
                <!--                        :class="{'kt-header-mobile__toolbar-topbar-toggler&#45;&#45;active':mobileHeaderMenu}"-->
                <!--                        id="kt_header_mobile_topbar_toggler"-->
                <!--                        @click="togglerHeaderMobile">-->
                <!--                    <i class="glyphicons glyphicons-more"/>-->
                <!--                </button>-->
            </div>
        </div>
        <!-- end:: Header Mobile -->
        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: Aside -->
                <button class="kt-aside-close " id="kt_aside_close_btn" @click="closeMenus()">
                    <i class="glyphicons glyphicons-remove"/>
                </button>
                <div class="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
                     :class="{'kt-aside--on':asideMenu, 'no-brightness': mobile_brightness}"
                     id="kt_aside">
                    <!-- begin:: Aside -->
                    <div class="kt-aside__brand kt-grid__item" id="kt_aside_brand">
                        <div class="kt-aside__brand-logo">
                            <!--                            <router-link :to="{name:'home'}">-->
                            <img alt="Logo" class="img-fluid" :src="headerImage"/>
                            <!--                            </router-link>-->
                        </div>
                    </div>
                    <!-- end:: Aside -->
                    <!-- begin:: Aside Menu -->
                    <app-aside-menu/>
                    <!-- end:: Aside Menu -->
                </div>
                <!-- end:: Aside -->
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                    <!-- begin:: Header -->
                    <!--                    <div id="kt_header" class="kt-header kt-grid__item  kt-header&#45;&#45;fixed ">-->

                    <!--                        &lt;!&ndash; begin: Header Menu &ndash;&gt;-->
                    <!--                        <button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn" @click="closeMenus()">-->
                    <!--                            <i class="glyphicons glyphicons-remove"/>-->
                    <!--                        </button>-->
                    <!--                        <div class="kt-header-menu-wrapper"-->
                    <!--                             :class="{'kt-header-menu-wrapper&#45;&#45;on':headerMenu}"-->
                    <!--                             id="kt_header_menu_wrapper">-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash; end: Header Menu &ndash;&gt;-->
                    <!--                        &lt;!&ndash; begin:: Header Topbar &ndash;&gt;-->
                    <!--                        <div class="kt-header__topbar">-->
                    <!--                            &lt;!&ndash;                            <app-organizations-bar/>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;begin: Language bar &ndash;&gt;-->
                    <!--                            <app-language-bar/>-->
                    <!--                            &lt;!&ndash;end: Language bar &ndash;&gt;-->
                    <!--                            &lt;!&ndash;begin: User Bar &ndash;&gt;-->
                    <!--                            <app-user-bar/>-->
                    <!--                            &lt;!&ndash;end: User Bar &ndash;&gt;-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash; end:: Header Topbar &ndash;&gt;-->
                    <!--                    </div>-->
                    <!-- end:: Header -->
                    <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                        <!-- begin:: Content -->
                        <transition name="container-transitions" mode="out-in">
                            <div v-if="show">
                                <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" :class="{'brightness': mobile_brightness}">
                                    <router-view/>
                                </div>
                                <!-- end:: Content -->
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppAsideMenu from './AppAsideMenu.vue';
    import AppLanguageBar from './AppLanguageBar.vue';
    import AppUserBar from './AppUserBar.vue';


    import {ORGANIZATION_PRIVILEGES, USER_ORGANIZATION_PRIVILEGES} from "../../constants/privileges"

    import {mapGetters} from 'vuex';
    import {router} from "../../router";

    let myBody = document.getElementsByTagName('body')[0];

    export default {
        created() {
            this.closeMenus();
        },
        mounted() {
            this.fade();
            myBody.classList.remove("kt-page--loading");
            myBody.classList.add("custom-back");
        },
        beforeDestroy() {
            this.fade();
            myBody.classList.remove("custom-back");
        },
        data() {
            this.$i18n.locale = this.getLocale();
            return {
                headerImage: require("../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_HEADER + ".png"),
                asideMenu: false,
                mobile_brightness: false,
                asideOverlay: false,
                headerMenu: false,
                headerOverlay: false,
                mobileHeaderMenu: false,
                time1: null,
                USER_ORGANIZATION_PRIVILEGES: USER_ORGANIZATION_PRIVILEGES,
                ORGANIZATION_PRIVILEGES: ORGANIZATION_PRIVILEGES,
                show: false,
                current_organization: this.$store.getters.getCurrentOrganization
            };
        },
        components: {
            AppAsideMenu, AppLanguageBar, AppUserBar,
        },
        methods: {
            goToHome() {
                if (this.$router && this.$router.currentRoute) {
                    if (this.$router.currentRoute.name !== 'home') {
                        router.push({name: 'home'})
                    }
                }
            },
            logout() {
                this.$store.dispatch('logout');
            },
            getLocale() {
                return this.$store.getters.user.language;
            },
            togglerHeaderMobile() {
                if (this.mobileHeaderMenu === true) {
                    myBody.classList.remove("kt-header__topbar--mobile-on");
                    this.mobileHeaderMenu = false;
                } else {
                    myBody.classList.add("kt-header__topbar--mobile-on");
                    this.mobileHeaderMenu = true;
                }
            },
            closeMenus() {
                //fecha aside menu e seu overlay
                this.asideMenu = false;
                this.mobile_brightness = false;
                this.asideOverlay = false;
                //fecha header menu e seu overlay
                this.headerMenu = false;
                this.headerOverlay = false;
                //atualiza classe do body que governa os 'X' de fechamento das abas
                myBody.classList.remove("kt-aside--on");
                myBody.classList.remove("kt-header-menu-wrapper--on");
            },
            openAsideMenu() {
                myBody.classList.add("kt-aside--on");
                this.asideOverlay = true;
                this.asideMenu = true;
                this.mobile_brightness = true;
            },
            openHeaderMenu() {
                myBody.classList.add("kt-header-menu-wrapper--on");
                this.headerOverlay = true;
                this.headerMenu = true;
            },

            openUserBar() {
                const el = document.body;
                let class_user_bar = 'kt-header__topbar--mobile-on';
                if (el.classList.contains(class_user_bar)) {
                    el.classList.remove(class_user_bar);
                } else {
                    el.classList.add(class_user_bar);
                }
                console.log("openUserBar")
            },
            openTopMenu() {
                console.log("openTopMenu")
            },

            fade() {
                this.show = !this.show;
            },
        },
        watch: {
            locale(val) {
                this.$i18n.locale = val
            },
        },
    }
</script>

<style>


    .container-transitions-enter-active, .container-transitions-leave-active {
        transition-duration: 1.1s !important;
        transition-property: opacity !important;
        transition-timing-function: ease !important;
    }

    .container-transitions-enter, .container-transitions-leave-active {
        opacity: 0 !important;
    }

    .custom-back {
        background-color: #f4f4f4 !important;
    }

    .brightness{
        filter: brightness(60%);
        transition: filter .5s;
        position: fixed;
        display: hide;
    }

    .no-brightness{
        filter: brightness(100%);
    }

</style>

<i18n>
    {
    "pt-BR": {
    "clients_details": "Detalhes da organização",
    "users_details": "Detalhes do usuário",
    "organization_details": "Detalhes da organização",
    "home": "Início",
    "manage": "Gerenciar",
    "administration": "Administração",
    "users": "Usuários",
    "organizations": "Organizações",
    "new": "Novo",
    "vehicles": "Veículos",
    "account_information": "Informações Pessoais",
    "change_password": "Trocar senha",
    "vehicles_new": "Cadastrar novo Veículo",
    "vehicles_edit": "Editar Veículo",
    "farms": "Fazendas",
    "vehicles_track": "Localizar veículo",
    "drivers": "Motoristas",
    "one_page_report": "One page report",
    "users_new": "Novo usuário",
    "clients_new": "Nova organização",
    "devices": "Dispositivos",
    "apps_android": "Aplicatívos android",
    "firmwares": "Firmwares",
    "devices_new": "Cadastrar Dispositivos",
    "firmwares_new": "Cadastrar Firmwares",
    "edit_devices": "Editar Dispositivos",
    "users_edit": "Editar usuário"
    },
    "en-US": {
    "clients_details": "Client Details",
    " users_details": "Users Details",
    "organization_details": "Organization details",
    "home": "Home",
    "manage": "Manage",
    "administration": "Administration",
    "users": "Users",
    "clients": "Clients",
    "new": "New",
    "vehicles": "Vehicle",
    "account_information": "Personal information",
    "change_password": "Change Password",
    "vehicles_new": "Register new vehicle",
    "vehicles_edit": "Edit Vehicle",
    "farms": "Farms",
    "vehicles_track": "Vehicles track",
    "drivers": "Drivers",
    "one_page_report": "One page report",
    "users_new": "New user",
    "clients_new": "New client",
    "devices": "Devices",
    "apps_android": "Android apps",
    "firmwares": "Firmwares",
    "devices_new": "New device",
    "firmwares_new": "New Firmware",
    "edit_devices": "Devices edit",
    "users_edit": "User edit"
    }
    }
</i18n>
