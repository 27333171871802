<template>
    <div style="height: 100%;background-color: #fff; ">
        <div v-if="!selected_client">
            <h5 style="text-align: center;margin: 10px">
                Selecione um cliente para carregar os locais
            </h5>
        </div>

        <div v-else>
            <div v-if="loading">
                <ProgressSpinner
                    style="display: flex;align-items: center;justify-content: center;height: 40px"/>

                <h5 style="text-align: center;margin-top: 10px">
                    Carregando...
                </h5>
            </div>
            <div v-else-if="client_with_too_many_fields" style="text-align: center;font-size: 14px;margin-top:20px">
                Não foi possível recuperar os talhões.
            </div>

            <div v-else style="height: 100%" class="list">
                <div class="mt-4" style="font-size:20px;font-weight: 800;color: black;display: flex; justify-content: center">
                    Lista de Locais
                </div>
                <div style="width: 100%;display: flex;justify-content:center;align-items:center;" class="mt-4">
                    <div style="width: 70%;">
                        <span class="p-input-icon-left" style="width: 100%;">
                            <i class="pi pi-search"/>
                            <InputText type="text" v-model="search" placeholder="Procurar Local"/>
                        </span>
                    </div>
                </div>
                <perfect-scrollbar id="container" class="mt-4" :class="{'height-list': is_col_12}">
                    <div v-for="field in fieldsListToShow" :id="field.name" v-tooltip="showAreaText(field.area)"
                         :class="{selectedField: isSelectedField(field.id), field: true}"
                         @click="changeSelectedField(field)">
                        {{ field.name }}
                    </div>
                </perfect-scrollbar>

                <div class="add-button">
                    <app-button type="primary" @handleClick='addNewField'>
                        + Adicionar Local
                    </app-button>
                </div>
            </div>
        </div>
        <Dialog :visible.sync="fieldNameDialog" :style="{width: '450px'}" header="Nome das colunas"
                :modal="true" class="p-fluid" >
            <div class="p-field mt-4 p-float-label">
                <InputText id="name" v-model.trim="fieldNameColumn" required="true"
                           autofocus :class="{ 'p-invalid': submitted && !fieldNameColumn }"/>
                <small class="p-invalid" v-if="submitted && !fieldNameColumn">Nome é obrigatório.</small>
                <label for="name">Nome da coluna de polígonos</label>
            </div>
            <div class="p-field mt-5 p-float-label">
                <InputText id="attribute" v-model.trim="attributeNameColumn" autofocus/>
                <label for="attribute">Atributo</label>
            </div>
            <template #footer>
                <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveFieldName"/>
            </template>
        </Dialog>

        <Dialog :visible.sync="addFieldDialog" :style="{width: '500px'}" header="Adicionar Local" :modal="true">
            <div class="row" >
                <div class="col">
                    <app-button type="primary" style="width: 100%;height: 47px;font-size: 18px" @handleClick='createField'>
                        <span class="pi pi-pencil pr-2"></span>
                        Desenhar
                    </app-button>
                </div>
                <div class="col">
                    <FileUpload mode="basic" :disabled="disableFileUpload"
                                accept=".zip" :maxFileSize="100000000"
                                chooseLabel="Adicionar Shapefile" @select="fileSelected"
                                :customUpload="true" @uploader="myUploader"/>

                </div>
            </div>
        </Dialog>

    </div>
</template>

<script>

import FieldsService from "@/services/FieldsService";

import AppButton from "@/components/common/AppButton";
import Divider from 'primevue/divider';
import FileUpload from 'primevue/fileupload';
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';

import axios from "axios";

export default {
    mounted() {
        this.fieldsService = new FieldsService();
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            is_col_12: false,
            drawFields: false,
            fieldNameDialog: false,
            fieldNameColumn: '',
            attributeNameColumn: '',
            submitted: false,
            fileName: '',
            disableFileUpload: false,
            search: null,
            fieldsListToShow: [],
            selectedField: null,
            addFieldDialog: false,
            fieldsService: null,
        }
    },
    props: {
        selected_client: {
            type: Object,
        },
        selected_field: {
            type: Object,
        },
        loading: {
            type: Boolean,
        },
        client_with_too_many_fields: {
            type: Boolean,
        },
        fields_list: {
            type: Array,
        }
    },
    methods: {
        createField() {
            this.addFieldDialog = false;
            this.$emit('createNewFieldClicked')
        },
        uploadShapefile() {
            this.addFieldDialog = false;
        },
        scrollTo(element, to, duration) {
            let vm = this;
            let start = element.scrollTop,
                change = to - start,
                currentTime = 0,
                increment = 20;

            let animateScroll = function () {
                currentTime += increment;
                let val = vm.easeInOutQuad(currentTime, start, change, duration);
                element.scrollTop = val;
                if (currentTime < duration) {
                    setTimeout(animateScroll, increment);
                }
            };
            animateScroll();
        },
        easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        },
        showAreaText(val) {
            return 'Área: ' + val + ' ha';
        },
        isSelectedField(fieldId) {
            if (!this.selectedField) {
                return false;
            }

            if (fieldId === this.selectedField.id) {
                return true;
            }
        },
        changeSelectedField(val) {
            this.selectedField = val;
            this.$emit('changeSelectedField', val);
        },
        fileSelected(event) {
            if (event.files.length > 0) {
                this.fileName = event.files[0].name.substr(0, event.files[0].name.indexOf('.'));
            }

            this.fieldNameDialog = true;
        },
        saveFieldName() {
            this.submitted = true;
            if (!this.fieldNameColumn) {
                return;
            }
            this.fieldNameDialog = false;
        },
        myUploader(event) {
            let vm = this;
            let formData = new FormData();
            formData.append('multipartFile', event.files[0]);
            formData.append('fields_name', this.fieldNameColumn);
            formData.append('attribute_name',this.attributeNameColumn === '' ? 'default' : this.attributeNameColumn);
            vm.disableFileUpload = true;

            this.fieldsService.postShapefileV3(this.selected_client.id, formData)
                .then(function (response) {
                    vm.$toast.add({
                        severity: 'success', summary: 'Sucesso',
                        detail: 'Locais adicionados com sucesso', life: 3000
                    });
                }).catch(function (error) {
                vm.$toast.add({
                    severity: 'error', summary: 'Falha',
                    detail: 'Não foi possível fazer o upload do shapefile', life: 3000
                });
            }).finally(() => {
                vm.disableFileUpload = false;
                vm.addFieldDialog = false;
                this.$emit('getFields');
            })
        },
        addNewField() {
            this.addFieldDialog = true;
        },
        showFileName(val) {
            const MAXIMUM_NUMBER_OF_CHARACTERS = 10;
            return val.substring(0, MAXIMUM_NUMBER_OF_CHARACTERS) + ".zip";
        },
        handleChangeClient() {
            this.loading = true;
            this.$emit('setShowDrawingTools', false);
        },
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    watch: {
        fields_list: function (val) {
            this.fieldsListToShow = val;
        },
        selected_field: function (val) {
            this.selectedField = val;

            if (!val) {
                return;
            }

            let topPos = document.getElementById(this.selectedField.name).offsetTop;
            this.scrollTo(document.getElementById('container'), topPos - 100, 600);
        },
        search: function (val) {
            let tempFieldsToShow = [];

            if (!val) {
                this.fieldsListToShow = this.fields_list;
                return;
            }

            this.fields_list.forEach(field => {
                if (field.name.toLowerCase().includes(val.toLowerCase())) {
                    tempFieldsToShow.push(field);
                }
            })
            this.fieldsListToShow = tempFieldsToShow;
        },
    },
    components: {
        AppButton, Divider, FileUpload, Dialog, InputText, Button, ProgressSpinner, Tooltip
    },
    directives: {
        tooltip: Tooltip
    },
}
</script>

<style lang="scss">
.p-fileupload {
    margin-top: 0;
    .p-button {
        background-color: #e6b43c;
        border: none;
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.list > .ps > .ps__rail-y > .ps__thumb-y  {
    width: 8px !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    background: gray !important;
    min-height: 40px !important;
}

</style>

<style scoped lang="scss">

.add-button {
    padding: 10px;
    margin-top: 25px;
    color: #4b4b4b;
    min-width: 100px;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
}

.p-inputtext {
    border-radius: 5px;
    width: 100%;
}

.ps {
    max-height: calc(100vh - 300px);
}

.field {
    border-bottom: 1px solid #e0e0e0;
    height: 50px;
    display: flex;
    align-items: center;
    color: #393939;
    padding-left: 10px;
}

.field:hover {
    background-color: #addaff;
    cursor: pointer;
}

.selectedField {
    background-color: #e8e8e8;
    border-right: 1px solid #b0b0b0;
    border-top: 1px solid #b0b0b0;
    border-bottom: 1px solid #b0b0b0;
}

.height-list{
    max-height: 275px;
}

</style>
