<template>
    <div>
        <div class="row no-gutters">
            <div class="col-6 col-md-3">
                <div class="resume-cards total">
                    <span style="font-size: large;">Total captado</span>
                    <span style="font-size: xx-large;">{{ resume.total }} m³</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="resume-cards regular">
                    <span style="font-size: large;">Em conformidade</span>
                    <span style="font-size: xx-large;">{{ resume.regular }}%</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="resume-cards desvio">
                    <span style="font-size: large;">Acima do permitido</span>
                    <span style="font-size: xx-large;">{{ resume.deviation }}%</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="resume-cards sem-permissao">
                    <span style="font-size: large;">Sem permissão</span>
                    <span style="font-size: xx-large;">{{ resume.no_permission }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resume: {
            type: Object
        }
    }
}
</script>

<style scoped>
.resume-cards {
    height: 8rem;
    border-radius: 0.5rem;
    background-color: #fff;
    margin: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.total {
    color: #000;
}

.regular {
    color: #0b57d5;
}

.desvio {
    color: #e19900;
}

.sem-permissao {
    color: #E51111;
}

</style>
