<template>
    <Dialog header="Recomendação" :visible.sync="showDialog"
            @hide="handleClose"
            :style="{width: width_dialog}" :modal="true">
        <p v-if="faultDetails === 'LOADING'" style="font-size: 15px; line-height: 1.7; text-align: center">
            <ProgressSpinner/>
            <br>
            Carregando, aguarde...
        </p>
        <p v-else-if="faultDetails === 'ERROR'"
           style="font-size: 15px; line-height: 1.7; text-align: center">
            Não foi possivel completar a operação
        </p>
        <p v-else class="p-m-0" style="font-size: 15px; line-height: 1.7">
            <b>Código: </b> {{faultDetails.code}} <br>
            <b>Descrição: </b> {{showDescription}}
            <br>
        </p>
        <template #footer>
            <app-button @handleClick="handleClose" type="primary">
                <span class="pi pi-times"></span>
                Fechar
            </app-button>
        </template>
    </Dialog>

</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressSpinner from 'primevue/progressspinner';
    import Button from 'primevue/button';
    import AppButton from "../../common/AppButton";

    export default {
        data() {
            return {
                showDialog: false,
                width_dialog: null
            }
        },
        mounted() {
            this.width_dialog = this.isScreenMobile ? '80vw' : '30vw';
        },
        methods: {
            handleClose() {
                this.$emit('handleClose');
            },
        },
        props: {
            displayDialogCodigo: {
                type: Boolean,
                default: false,
                required: true
            },
            faultDetails: {
                required: true
            }
        },
        watch: {
            displayDialogCodigo: function (val) {
                this.showDialog = val;
            }
        },
        computed: {
            showDescription: function () {
                if (this.faultDetails && this.faultDetails.description) {
                    return this.faultDetails.description;
                }
                return "Descrição não cadastrada"
            },
            isScreenMobile: function() {
                return window.screen.width <= 1024;
            }
        },
        components: {
            Dialog, ProgressSpinner, Button, AppButton
        },
    }
</script>

<style scoped>

</style>
