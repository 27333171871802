<template>
    <div>
        <DataTable :value="gridData" :expandedRows.sync="expandedRows"
                   removableSort :paginator="true" :rows="14" :rowHover="true" :sortOrder="-1"
                   dataKey="name" style="text-align: center" filterDisplay="row" :filters.sync="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} estados">
            <template #empty>
                Nenhuma captação registrada
            </template>
            <template #loading>
                Carregando captações
            </template>
            <template #header>
                <div class="table-header d-flex">
                    <div class="text-right col-6">
                        Captações
                    </div>
                    <div class="text-right col-6">
                        <Button label="Exportar CSV" @click="exportCSV"/>
                    </div>
                </div>
            </template>

            <Column :expander="true" :header-style="{'width': '3rem'}"/>
            <Column field="name" header="Estado" sortable headerClass="headerClass" 
                    bodyClass="bodyClass" :showFilterMenu="false" :showClearButton="false">
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="regular" header="Captação em conformidade" sortable headerClass="headerClass"
                    bodyClass="bodyClass" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.regular }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="deviation" header="Acima do permitido" sortable headerClass="headerClass"
                    bodyClass="bodyClass" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.deviation }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="no_permission" header="Sem permissão" sortable headerClass="headerClass"
                    bodyClass="bodyClass" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.no_permission }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="protocol" header="Protocolizada" sortable headerClass="headerClass" 
                    bodyClass="bodyClass" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.protocol }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>
            <Column field="volume" header="Total captado" sortable headerClass="headerClass" 
                    bodyClass="bodyClass" :showFilterMenu="false" :showClearButton="false">
                <template #body="slotProps">
                    {{ slotProps.data.volume }}
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                </template>
            </Column>

            <template #expansion="slotProps">
                <div>
                    <DataTable :value="slotProps.data.withdrawals" removableSort>
                        <Column field="data" header="Horário" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass"
                                filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ dateHourFormat(slotProps.data.timestamp) }}
                            </template>
                        </Column>
                        <Column field="id_point" header="ID Ponto" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass"
                                filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ slotProps.data.id_point }}
                            </template>
                        </Column>
                        <Column field="vehicle" header="Veículo" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass"
                                filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ slotProps.data.vehicle }}
                            </template>
                        </Column>
                        <Column field="process" header="Processo" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass"
                                filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ getENUM(slotProps.data.process, withdrawalProcess) }}
                            </template>
                        </Column>
                        <Column field="activity" header="Atividade" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass"
                                filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ getENUM(slotProps.data.activity, withdrawalActivities) }}
                            </template>
                        </Column>
                        <Column field="volume" header="Volume" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass"
                                filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ slotProps.data.volume }} m³
                            </template>
                        </Column>
                        <Column field="latitude" header="Latitude" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass" filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ slotProps.data.latitude }}
                            </template>
                        </Column>
                        <Column field="longitude" header="Longitude" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass" filterMatchMode="contains">
                            <template #body="slotProps">
                                {{ slotProps.data.longitude }}
                            </template>
                        </Column>
                        <Column field="status" header="Status" :sortable="true" headerClass="headerClass"
                                bodyClass="bodyClass" filterMatchMode="in">
                            <template #body="slotProps">
                                <span :class="'badge withdrawal-status-' + slotProps.data.status">
                                    {{ getENUM(slotProps.data.status, withdrawalStatus) }}
                                </span>
                            </template>
                            <template #filter class="headerClass">
                                <MultiSelect v-model="filters['status']" :options="withdrawalStatus"
                                             placeholder="Selecione"
                                             class="p-column-filter" :showClear="true" optionLabel="name"
                                             optionValue="code" style="width: 100%">
                                    <template #option="slotProps">
                                        <span :class="'badge withdrawal-status-' + slotProps.option.code">
                                            {{ slotProps.option.name }}
                                        </span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column header="Ações" headerClass="headerClass" bodyClass="bodyClass">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-rounded actions mx-1"
                                        @click="editWithdrawalButton(slotProps.data)"></Button>
                                <Button icon="pi pi-trash" class="p-button-rounded actions mx-1 delete-button"
                                        @click="deleteWithdrawalButton(slotProps.data)"></Button>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>

        <Dialog header="Deletar retirada" :visible.sync="showDeleteDialog" :modal="true">
            <p class="m-0">Deseja deletar este registro de retirada?</p>
            <template #footer>
                <Button label="Não" @click="closeDialog" class="p-button-text"/>
                <Button label="Sim" @click="deleteWithdrawalConfirmed" autofocus />
            </template>
        </Dialog>

        <Dialog header="Editar retirada" :visible.sync="showEditDialog" :modal="true" :contentStyle="{'overflow': 'visible'}">
            <div class="d-flex flex-column" style="min-width: 25vw">
                <div class="d-flex flex-column">
                    <label for="input-volume">Editar volume da retirada:</label>
                    <input-number id="input-volume" v-model="withdrawalToEdit.volume"  mode="decimal" suffix=" m³"
                                  :minFractionDigits="2"></input-number>
                </div>
                <div class="d-flex flex-column mt-4">
                    <label for="input-processo">Editar processo:</label>
                    <dropdown id="input-processo" v-model="withdrawalToEdit.process" :options="processList"
                              optionLabel="name">
                    </dropdown>
                </div>
                <div class="d-flex flex-column mt-4">
                    <label for="input-atividade">Editar atividade:</label>
                    <dropdown id="input-atividade" v-model="withdrawalToEdit.activity" :options="activityList"
                              optionLabel="name">
                    </dropdown>
                </div>
                <div class="d-flex flex-column mt-4">
                    <label for="input-tipo">Editar tipo de retirada</label>
                    <dropdown id="input-tipo" v-model="withdrawalToEdit.type" :options="typeList" optionLabel="name">
                    </dropdown>
                </div>
            </div>
            <template #footer>
                <Button label="cancelar" icon="pi pi-times" @click="closeDialog" class="p-button-text"/>
                <Button label="salvar" icon="pi pi-check" @click="saveEditedWithdrawal" autofocus />
            </template>
        </Dialog>
    </div>
</template>

<script>
import {ACTIVITIES, PROCESSES, STATUS} from './AppOptions'
import dateHourFormat from "@/mixins/DateMixin";
import filterValue from "@/mixins/FilterFunctionsDataTables";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import WaterWithdrawalService from "@/services/WaterWithdrawalService";
import {FilterMatchMode} from 'primevue/api';
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown"

export default {
    mixins: [dateHourFormat, filterValue],
    beforeMount() {
        this.waterWithdrawalService = new WaterWithdrawalService()
    },
    data() {
        return {
            waterWithdrawalService: null,
            filters: {
                'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'regular': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'deviation': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'no_permission': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'protocol': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'volume': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            withdrawalStatus: STATUS,
            withdrawalProcess: PROCESSES,
            withdrawalActivities: ACTIVITIES,
            expandedRows: [],
            gridData: null,
            showDeleteDialog: false,
            idToDelete: null,
            showEditDialog: false,
            withdrawalToEdit: {},
            typeList: [
                {value: 'UNKNOWN', name: 'Desconhecido',  code: 0},
                {value: 'REGULAR', name: 'Regular',  code: 1},
                {value: 'DEVIATION', name: 'Desvio',  code: 2},
                {value: 'NO_AUTHORIZATION', name: 'Sem Permissão',  code: 3},
                {value: 'PROTOCOL', name: 'Protocolizado',  code: 4}
            ],
            processList: [
                {value: 'UNKNOWN', name: 'Desconhecido',  code: 0},
                {value: 'HARVEST', name: 'Colheita',  code: 1},
                {value: 'DTI', name: 'DTI',  code: 2},
                {value: 'PI', name: 'Inteligência Patrimonial',  code: 3},
                {value: 'LOGISTICS', name: 'Logistica',  code: 4},
                {value: 'FORESTRY', name: 'Silvicultura',  code: 5},
                {value: 'PLANT_NURSERY', name: 'Viveiro',  code: 6},
                {value: 'ROAD', name: 'Estrada',  code: 7}
            ],
            activityList: [
                {value: 'UNKNOWN', name: 'Desconhecido', code: 0},
                {value: 'PESTICIDE', name: 'Aplicação de Defensivos', code: 1},
                {value: 'FIRE_FIGHTING', name: 'Combate a Incêndio', code: 2},
                {value: 'MACHINE_WASHING', name: 'Lavagem de Máquinas', code: 3},
                {value: 'PLANTING', name: 'Plantio, Replantio e Irrigação', code: 4},
                {value: 'SEEDLING_PRODUCTION', name: 'Produção de Mudas', code: 5},
                {value: 'LANDSCAPING', name: 'Terraplanagem', code: 6},
                {value: 'ROAD_WETTING', name: 'Umectação de Estradas', code: 7},
                {value: 'ROAD_CONSTRUCTION', name: 'Construção e Manutenção de Estradas', code: 8}
            ]
        }
    },
    props: {
        data: {
            type: Array
        },
        organization: {
            type: Object
        },
        date_interval: {
            type: Array
        }
    },
    methods: {
        getENUM(value, list) {
            let result = list.find(({code}) => code === value);
            if (result) {
                return result.name;
            }
            return 'Desconhecido'
        },
        exportCSV() {
            let start = Math.floor(this.date_interval[0].getTime() / 1000);
            let end = Math.floor(this.date_interval[1].getTime() / 1000);

            this.waterWithdrawalService.getWaterWithdrawalsCSV(this.organization.id, start, end)
                .then(response => {
                    this.downloadCSV(response)
                }).catch((error) => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Não foi possível exportar o CSV, tente mais tarde.',
                    life: 5000
                });
            });
        },
        downloadCSV(csvData) {
            const blob = new Blob([csvData], {type: 'text/csv'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data_' + Date.now() + '.csv'); // Nome do arquivo
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        editWithdrawalButton(data){
            this.showEditDialog = true
            this.withdrawalToEdit = {
                "id": data.id,
                "volume": data.volume,
                "process": this.processList.find(process => process.value === data.process),
                "activity": this.activityList.find(activity => activity.value === data.activity),
                "type": this.typeList.find(type => type.value === data.status)
            }
        },
        saveEditedWithdrawal(){
            let updateRequest = JSON.parse(JSON.stringify(this.withdrawalToEdit))
            updateRequest.process = this.withdrawalToEdit['process'].code
            updateRequest.activity = this.withdrawalToEdit['activity'].code
            updateRequest.type = this.withdrawalToEdit['type'].code
            this.waterWithdrawalService.updateWaterWithdrawal(this.organization.id, updateRequest)
                .then((response) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Registro editado com sucesso!',
                        life: 3000
                    });
                    this.closeDialog()
                    this.$parent.getFilteredWaterWithdrawal()
                })
                .catch((error) => {
                    console.log(error)
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Não foi possível editar o registro, tente mais tarde.',
                        life: 5000
                    });
                })
        },
        deleteWithdrawalButton(data){
            this.showDeleteDialog = true
            this.idToDelete = data.id
        },
        deleteWithdrawalConfirmed(){
            this.waterWithdrawalService.deleteWaterWithdrawal(this.organization.id, this.idToDelete)
                .then(response => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Registro deletado com sucesso!',
                        life: 3000
                    });
                    this.closeDialog()
                    this.$parent.getFilteredWaterWithdrawal();
            }).catch((error) => {
                console.log(error)
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Não foi possível deletar o registro, tente mais tarde.',
                    life: 5000
                });
            })
        },
        closeDialog(){
            this.showDeleteDialog = false
            this.idToDelete = null

            this.showEditDialog = false
            this.withdrawalToEdit = {}
        }
    },
    watch:{
        data: {
            deep: true,
            handler(val) {
                this.gridData = val;
                this.gridData.forEach(element => {
                    element.volume = element.volume.toString().includes(" m³") ? element.volume : element.volume + " m³";
                    element.protocol = element.protocol.toString().includes(" m³") ? element.protocol : element.protocol + " m³";
                    element.no_permission = element.no_permission.toString().includes(" m³") ? element.no_permission : element.no_permission + " m³";
                    element.deviation = element.deviation.toString().includes(" m³") ? element.deviation : element.deviation + " m³";
                    element.regular = element.regular.toString().includes(" m³") ? element.regular : element.regular + " m³";
                });
            }
        }
    },
    components: {
        DataTable, Column, Button, InputText, MultiSelect, Dialog, InputNumber, Dropdown
    }
}
</script>

<style scoped lang="scss">
@import "src/components/views/water_withdrawal/style";

.delete-button {
    margin-left: 5px;
    background-color: red;
    border: none;
}

.delete-button:enabled:hover {
    background-color: #e01414;
}

</style>
