export const stopReasonsTypes = [
    {
        type: "UNKNOWN",
        description: "Desconhecido"
    },
    {
        type: "OPERATIONAL_STOP",
        description: "Parada Operacional"
    },
    {
        type: "MAINTENANCE_STOP",
        description: "Parada para manutenção"
    },
    {
        type: "TRAVEL",
        description: "Deslocamento"
    },
    {
        type: "FUEL_SUPPLY",
        description: "Abastecimento"
    },
]
