<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile ">
            <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-date-time-control-bar
                    @handleFilter="validateDataPreRequest"
                    :loading_historical_data="loading"
                    :start_date_open_date="datePickerStartDate"
                    :end_date_open_date="datePickerEndDate"
                    :display_time="false">
                    <template v-slot:selectone>
                        <MultiSelect v-model="selectedOrgs" :options="organizationsList"
                                     optionLabel="display_name" placeholder="Organização"
                                     class="auto-center"
                                     style="width: 250px" :dataKey="'id'"
                                     :filter="true"
                                     @change="handleClientChange($event)">
                            <template #value="slotProps">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" style="position: relative; bottom: 3px;">
                                        <span style="margin-right: 5px">{{ getSelectValue(value, "name") }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedOrg(value)"></i>
                                    </div>
                                </template>
                                <template v-else-if="loading">
                                    Carregando organizações...
                                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                </template>
                                <template v-else>
                                    Organizações
                                </template>
                            </template>
                            <template #option="slotProps">
                                <div>{{slotProps.option.display_name}}</div>
                            </template>
                        </MultiSelect>

                        <MultiSelect v-model="frenteCorteListSelected" :options="frenteCorteList"
                                     :filter="true" optionLabel="name"
                                     dataKey="id" emptyFilterMessage="Nenhum resultado encontrado"
                                     placeholder="Frente" class="auto-center"
                                     style="width: 150px; margin-left: 5px">
                            <template #value="slotProps">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" style="position: relative; bottom: 3px;">
                                        <span style="margin-right: 5px">{{ getSelectValue(value, "name") }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedFrente(value)"></i>
                                    </div>
                                </template>
                                <template v-else-if="loading">
                                    Carregando veículos...
                                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                </template>
                                <template v-else>
                                    Frente
                                </template>
                            </template>
                            <template #option="slotProps" style="width: 500px">
                                <div style="padding-right: 20px">
                                    {{ slotProps.option.name }}
                                </div>
                            </template>
                        </MultiSelect>

                        <MultiSelect v-model="selectedVehicles" :options="vehiclesList"
                                     :filter="true" optionLabel="display_id"
                                     dataKey="id" emptyFilterMessage="Nenhum resultado encontrado"
                                     placeholder="Veículos" class="auto-center"
                                     style="width: 250px; margin-left: 5px">
                            <template #value="slotProps">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" style="position: relative; bottom: 3px;">
                                        <span style="margin-right: 5px">{{ getSelectValue(value, "display_id") }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedVehicle(value)"></i>
                                    </div>
                                </template>
                                <template v-else-if="loading">
                                    Carregando veículos...
                                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                </template>
                                <template v-else>
                                    Veículos
                                </template>
                            </template>
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingVehicles"> Carregando, aguarde</div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_id }}
                                </div>
                            </template>
                        </MultiSelect>

                        <MultiSelect v-model="filters['priority']" :options="priorities" optionLabel="name"
                                     style="width: 150px; margin-left: 5px"
                                     optionValue="code" placeholder="Nível" class="auto-center">
                            <template #value="slotProps">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" :class="'badge priorities-' + value">
                                        <span style="margin-right: 5px">{{ getValue(getPriority(value)) }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedLevel(value)"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    Nível
                                </template>
                            </template>
                            <template #option="slotProps">
                                <span :class="'badge priorities-' + slotProps.option.code">
                                    {{ getPriority(slotProps.option.code) }}
                                </span>
                            </template>
                        </MultiSelect>

                        <MultiSelect v-model="filters['status']" :options="statusesOrder" placeholder="Serviço"
                                     style="width: 150px; margin-left: 5px"
                                     class="auto-center" optionLabel="name"
                                     optionValue="code">
                            <template #value="slotProps">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" :class="'badge status-' + value">
                                        <span style="margin-right: 5px">{{ getValue(getStatus(value)) }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedStatus(value)"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    Status
                                </template>
                            </template>
                            <template #option="slotProps">
                                    <span
                                        :class="'badge status-' + slotProps.option.code">{{
                                            slotProps.option.name
                                        }}</span>
                            </template>
                        </MultiSelect>
                    </template>
                    <template v-slot:selecttwo>
                        <app-button type="secondary" class="auto-center" style="margin-left: 5px"
                                    @handleClick="goToRealTime"
                                    :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--info': isRealtimeRunning }">
                            <span v-show="!isRealtimeRunning">Tempo Real</span>
                            <span v-show="isRealtimeRunning">Parar Tempo Real</span>

                        </app-button>

                        <app-button type="secondary" class="auto-center" style="margin-left: 5px;"
                                    @handleClick="goToCards">
                            <span v-show="!showCards">Cards</span>
                            <span v-show="showCards">Lista</span>
                        </app-button>

                        <Button icon="pi pi-external-link" label="Relatório OS" @click="goToAlertReport" style="font-size: 13px; margin-left: 5px; height: 38.38px;" />
                    </template>
                </app-date-time-control-bar>
            </div>
            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius" style="justify-content: center;">
                <div style="display: flex;">
                    <app-button v-show="showDialogFilters" type="primary" class="auto-center"
                                @handleClick="openMaximizable"
                                :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': loading }">
                                <span>Filtrar
                                </span>
                    </app-button>

                    <div class="auto-center row" style="margin-left: 5px; margin-right: 5px;" @click="goToRealTime">
                        <div v-show="isRealtimeRunning">
                            <div class="col-12" style="text-align: center; margin-top: 2px;">
                                <i class="pi pi-spin pi-clock" style="font-size: 20px; color: white;"></i>
                            </div>
                            <span class="col-12" style="text-align: center; color: white;">Tempo Real</span>
                        </div>
                    </div>

                    <app-button type="secondary" class="auto-center"
                                @handleClick="goToCards">
                        <span v-show="!showCards">Cards</span>
                        <span v-show="showCards">Lista</span>
                    </app-button>
                </div>

            </div>

            <Dialog header="Filtrar" :visible.sync="displayMaximizable" :containerStyle="{width: dialogWidth}" :maximizable="true" :modal="true">
                <app-date-time-control-bar style="display: flex; justify-content: center; margin-left: 0px;" class="row col-12"
                    @handleFilter="validateDataPreRequest"
                    :loading_historical_data="loading"
                    :start_date_open_date="datePickerStartDate"
                    :end_date_open_date="datePickerEndDate"
                    :is_col_12="true"
                    :historico="true"
                    :display_time="false">
                    <template v-slot:selectone>
                        <div class="col-12 row">
                            <label for="organizacao" class="col-12">Organização</label>
                            <MultiSelect v-model="selectedOrgs" :options="organizationsList"
                                        optionLabel="display_name" placeholder="Organização" appendTo="tableDiv"
                                        class="auto-center col-12"
                                        id="organizacao" :dataKey="'id'"
                                        :filter="true"
                                        @change="handleClientChange($event)" style="width: 150px;">
                            <template #value="slotProps" style="width: 500px">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" style="position: relative; bottom: 3px;">
                                        <span style="margin-right: 5px">{{ getSelectValue(value, "name") }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedOrg(value)"></i>
                                    </div>
                                </template>
                                <template v-else-if="loading">
                                    Carregando organizações...
                                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                </template>
                                <template v-else>
                                    Organizações
                                </template>
                            </template>
                            <template #option="slotProps">
                                <div>{{slotProps.option.display_name}}</div>
                            </template>
                            </MultiSelect>
                        </div>

                        <div class="col-12 row" style="margin-top: 5px;">
                            <label for="frente" class="col-12">Frente</label>
                            <MultiSelect v-model="frenteCorteListSelected" :options="frenteCorteList"
                                        :filter="true" optionLabel="name" id="frente" appendTo="tableDiv"
                                        dataKey="id" emptyFilterMessage="Nenhum resultado encontrado"
                                        placeholder="Frente" class="auto-center col-12"
                                        style="width: 150px;">
                            <template #value="slotProps" style="width: 500px">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" style="position: relative; bottom: 3px;">
                                        <span style="margin-right: 5px">{{ getSelectValue(value, "name") }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedFrente(value)"></i>
                                    </div>
                                </template>
                                <template v-else-if="loading">
                                    Carregando veículos...
                                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                </template>
                                <template v-else>
                                    Frente
                                </template>
                            </template>
                            <template #option="slotProps" style="width: 500px">
                                <div style="padding-right: 20px">
                                    {{ slotProps.option.name }}
                                </div>
                            </template>
                            </MultiSelect>
                        </div>

                        <div class="col-12 row" style="margin-top: 5px;">
                            <label for="veiculos" class="col-12">Veículos</label>
                            <MultiSelect v-model="selectedVehicles" :options="vehiclesList"
                                        :filter="true" optionLabel="display_id" appendTo="tableDiv"
                                        dataKey="id" emptyFilterMessage="Nenhum resultado encontrado"
                                        placeholder="Veículos" class="auto-center col-12" id="veiculos"
                                        style="width: 150px;">
                            <template #value="slotProps" style="width: 500px">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" style="position: relative; bottom: 3px;">
                                        <span style="margin-right: 5px">{{ getSelectValue(value, "display_id") }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedVehicle(value)"></i>
                                    </div>
                                </template>
                                <template v-else-if="loading">
                                    Carregando veículos...
                                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                                </template>
                                <template v-else>
                                    Veículos
                                </template>
                            </template>
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingVehicles"> Carregando, aguarde</div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_id }}
                                </div>
                            </template>
                            </MultiSelect>
                        </div>
                        <div class="col-12 row" style="margin-top: 5px;">
                            <label for="nivel" class="col-12">Nível</label>
                            <MultiSelect v-model="filters['priority']" :options="priorities" optionLabel="name"
                                        style="width: 150px;" appendTo="tableDiv"
                                        optionValue="code" placeholder="Nível" class="auto-center col-12">
                            <template #value="slotProps">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" :class="'badge priorities-' + value">
                                        <span style="margin-right: 5px">{{ getValue(getPriority(value)) }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedLevel(value)"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    Nível
                                </template>
                            </template>
                            <template #option="slotProps">
                                <span :class="'badge priorities-' + slotProps.option.code">
                                    {{ getPriority(slotProps.option.code) }}
                                </span>
                            </template>
                            </MultiSelect>
                        </div>

                        <div class="col-12 row" style="margin-top: 5px;">
                            <label for="servico" class="col-12">Serviço</label>
                            <MultiSelect v-model="filters['status']" :options="statusesOrder" placeholder="Serviço"
                                        style="width: 150px;" appendTo="tableDiv"
                                        class="auto-center col-12" optionLabel="name"
                                        optionValue="code">
                            <template #value="slotProps">
                                <template v-if="slotProps.value && slotProps.value.length > 0">
                                    <div v-for="value in slotProps.value" class="chip-multiselect" :class="'badge status-' + value">
                                        <span style="margin-right: 5px">{{ getValue(getStatus(value)) }}</span>
                                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedStatus(value)"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    Status
                                </template>
                            </template>
                            <template #option="slotProps">
                                    <span
                                        :class="'badge status-' + slotProps.option.code">{{
                                            slotProps.option.name
                                        }}</span>
                            </template>
                            </MultiSelect>
                        </div>
                        <div class="col-12 row" style="margin-top: 5px;">
                            <label for="servico" class="col-12">Período</label>
                        </div>
                    </template>

                    <template v-slot:selecttwo>
                        <span style="margin: 0px 10px">ou</span>
                        <app-button type="secondary" class="auto-center"
                                    @handleClick="goToRealTime"
                                    :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--info': isRealtimeRunning }">
                            <span v-show="!isRealtimeRunning">Tempo Real</span>
                            <span v-show="isRealtimeRunning">Parar Tempo Real</span>

                        </app-button>

                        <div style="display: block; margin: 20px auto 0 auto; text-align: center;">
                            <Button icon="pi pi-external-link" label="Relatório OS" @click="goToAlertReport" style="font-size: 13px; height: 38.38px;" />
                        </div>
                    </template>
                </app-date-time-control-bar>
            </Dialog>
            <app-alerts-dialog-alert-details :displayDialogCodigo="displayDialogCodigo"
                                             @handleClose="handleCloseModalAlerts"
                                             :faultDetails="faultDetails"/>

            <app-alerts-dialog-vehicle-details :displayDialogVehicle="displayDialogVehicle"
                                               @handleClose="handleCloseModalVehicle"
                                               :vehicleDetails="vehicleDetails"/>

            <app-alerts-alert-edit :displayDialogEditAlert="displayDialogEditAlert"
                                   @handleSalve="handleSalveEditAlert"
                                   @handleClose="handleCloseModalAlertsEdit"
                                   :alertEditDetails="alertEditDetails"/>

            <div class="kt-portlet__body no-padding" id="tableDiv">
                <div class="data-table-width" :class="{'card':showDialogFilters}">
                    <div v-if="!showCards && !showDashboard">
                        <DataTable :value="gridData" :paginator="true" class="p-datatable-striped" :rows="50"
                                    dataKey="id" :filters.sync="filters" filterDisplay="row" :loading="loading" responsiveLayout="scroll" :sortOrder="-1" sortField="date" removableSort
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} Alertas"
                                    :rowsPerPageOptions="[10,25,50]" style="margin-top: 15px;">
                            <template #empty>
                                <div style="text-align: center">
                                    Nenhum alerta localizado
                                </div>
                            </template>
                            <template #loading>
                                <div style="text-align: center">
                                    Carregando alertas, por favor aguarde
                                </div>
                            </template>
                            <Column :headerStyle="{width: '2rem', padding: '0px'}"
                                    :bodyStyle="{width: '2rem', padding: '0px'}" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    <Button :icon="'pi pi-angle-double-right'"
                                            style="height: 25px;width: 25px;"
                                            :class="'p-button-success'"
                                            class="p-button-rounded p-button-outlined p-0"
                                            @click="openDialog(slotProps.data)"/>
                                </template>
                            </Column>
                            <Column field="date" header="Horário" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :styles="{'min-width': '90px'}" bodyClass="bodyClass"
                                    :sortable="true">
                                <template #body="slotProps">
                                    <span>{{dateHourFormat(slotProps.data.date)}}</span>
                                </template>
                                <template #filter>
                                    <InputText type="text" v-model="filters['date']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="city" header="Município" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :styles="{'min-width': '110px'}" bodyClass="bodyClass"
                                    :sortable="true">
                                <template #body="slotProps">
                                    <span>{{ slotProps.data.city }} {{ slotProps.data.state }}</span>
                                </template>
                                <template #filter>
                                    <InputText type="text" v-model="filters['city']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="frente_corte" header="Frente" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :styles="{'min-width': '100px'}" bodyClass="bodyClass"
                                    :sortable="true">
                                <template #body="slotProps">
                                    {{ slotProps.data.frente_corte }}
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['frente_corte']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="display_id" header="Identificação" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :styles="{'max-width': '110px'}" bodyClass="bodyClass"
                                    :sortable="true">
                                <template #body="slotProps">
                                    <a href="#"
                                    @click.prevent="showModalVehicleAndGetDetails(slotProps.data.vehicle_id)"
                                    style="text-decoration: underline">
                                        {{ slotProps.data.display_id }}</a>
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['display_id']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="identification_number" header="Chassis" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    {{ slotProps.data.identification_number }}
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['identification_number']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="hour_meter" header="Horímetro" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    {{ roundNumber(slotProps.data.hour_meter) }}
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['hour_meter']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="trade_name" header="Propriedade" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    {{ getClientDisplayName(slotProps.data) }}
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['trade_name']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="code" header="Código" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" filterMatchMode="contains" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    <a href="#" @click.prevent="showModalAndGetDetails(slotProps.data.id)"
                                    style="text-decoration: underline">
                                        {{ formatCode(slotProps.data.code, slotProps.data.severity) }}</a>
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['code']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="priority" header="Nível" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    style="width: 100%" :styles="{'max-width': '90px'}" bodyClass="bodyClass"
                                    :sortable="true" filterMatchMode="in">
                                <template #body="{data}">
                                    <span :class="'badge priorities-' + data.priority">
                                        {{ getPriority(data.priority) }}
                                    </span>
                                </template>
                                <template #filter class="headerClass">
                                    <MultiSelect v-model="filters['priority']" :options="priorities" optionLabel="name"
                                                style="width: 100%" appendTo="tableDiv"
                                                optionValue="code" placeholder="Selecione" class="p-column-filter width-multiselect">
                                        <template #value="slotProps">
                                            <span v-for="singleValue in slotProps.value" :class="'badge priorities-' + singleValue">
                                                {{ getPriority(singleValue) }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                    <span :class="'badge priorities-' + slotProps.option.code">
                                        {{ slotProps.option.name }}
                                    </span>
                                        </template>
                                    </MultiSelect>
                                </template>
                            </Column>
                            <Column field="failure_status" header="Status" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :styles="{'max-width': '115px'}" bodyClass="bodyClass"
                                    :sortable="true" filterMatchMode="in">
                                <template #body="{data}">
                                    <span :class="'badge failure-status-' + data.failure_status">
                                        {{ getFailureStatus(data.failure_status) }}
                                    </span>
                                </template>
                                <template #filter class="headerClass">
                                    <MultiSelect v-model="filters['failure_status']" :options="statusesFailures"
                                                style="width: 100%" appendTo="tableDiv"
                                                placeholder="Selecione"
                                                class="p-column-filter width-multiselect" :showClear="true" optionLabel="name"
                                                optionValue="code">
                                        <template #value="slotProps">
                                            <span v-for="singleValue in slotProps.value" :class="'badge failure-status-' + singleValue">
                                                {{ getFailureStatus(singleValue) }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <span
                                                :class="'badge failure-status-' + slotProps.option.code">{{
                                                    slotProps.option.name
                                                }}</span>
                                        </template>
                                    </MultiSelect>
                                </template>
                            </Column>

                            <Column field="count" header="Contagem" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :styles="{'max-width': '90px'}" bodyClass="bodyClass"
                                    :sortable="true" filterMatchMode="contains">
                                <template #body="slotProps">
                                    {{ slotProps.data.count }}
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['count']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="response_time" header="Reação" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" :styles="{'max-width': '90px'}" bodyClass="bodyClass">
                                <template #body="{data}">
                                    <span>{{ getTimeFromSecondsDurationFormatMixinShowZero(data.response_time) }}</span>
                                </template>
                                <template #filter>
                                    <InputText type="text" v-model="filters['response_time']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="execution_time" header="Execução" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" :styles="{'max-width': '90px'}" bodyClass="bodyClass">
                                <template #body="{data}">
                                    <span>{{ getTimeFromSecondsDurationFormatMixinShowZero(data.execution_time) }}</span>
                                </template>
                                <template #filter>
                                    <InputText type="text" v-model="filters['execution_time']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="total_time" header="Total" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" :styles="{'max-width': '95px'}" bodyClass="bodyClass">
                                <template #body="{data}">
                                    <span>{{ getTimeFromSecondsDurationFormatMixinShowZero(data.total_time) }}</span>
                                </template>
                                <template #filter>
                                    <InputText type="text" v-model="filters['total_time']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="assignee" header="Responsável" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" :styles="{'max-width': '110px'}" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    {{ slotProps.data.assignee }}
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['assignee']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="work_order" header="Ordem" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :sortable="true" :styles="{'max-width': '88px'}" bodyClass="bodyClass">
                                <template #body="slotProps">
                                    {{ slotProps.data.work_order }}
                                </template>
                                <template #filter class="headerClass">
                                    <InputText type="text" v-model="filters['work_order']" class="p-column-filter"/>
                                </template>
                            </Column>
                            <Column field="status" header="Serviço" headerClass="headerClass" :showFilterMenu="false" :showClearButton="false"
                                    :styles="{'max-width': '90px'}" bodyClass="bodyClass"
                                    :sortable="true" filterMatchMode="in">
                                <template #body="{data}">
                                    <span :class="'badge status-' + data.status">
                                        {{ getStatus(data.status) }}
                                    </span>
                                </template>
                                <template #filter class="headerClass">
                                    <MultiSelect v-model="filters['status']" :options="statusesOrder"
                                                placeholder="Selecione" appendTo="tableDiv"
                                                class="p-column-filter width-multiselect" :showClear="true" optionLabel="name"
                                                optionValue="code">
                                        <template #value="slotProps">
                                            <span v-for="singleValue in  slotProps.value" :class="'badge status-' + singleValue">
                                                {{ getStatus(singleValue) }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <span :class="'badge status-' + slotProps.option.code">
                                                {{ slotProps.option.name }}
                                            </span>
                                        </template>
                                    </MultiSelect>
                                </template>
                            </Column>

                            <Column :headerStyle="{width: '4rem', padding: '0px'}" bodyClass="bodyClass"
                                    :bodyStyle="{width: '4rem', padding: '0px 0px 0px 12px'}">
                                <template #body="{data}">
                                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success"
                                            @click="showModalEditAndGetDetails(data)"/>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div v-else-if="!showDashboard">
                        <div class="container-fluid">
                            <div class="row" v-if="cardsData && cardsData.length > 0">
                                <app-alerts-card-vehicle :vehicle="vehicle"
                                                        class="col-md-2"
                                                        v-for="vehicle in cardsData"
                                                        :key="vehicle.id"
                                                        @handleClickCard="handleClickCard">
                                </app-alerts-card-vehicle>
                            </div>
                            <div v-else-if="loading" style="text-align: center; margin-top: 3%">
                                <h5>
                                    Carregando
                                </h5>
                            </div>
                            <div v-else-if="cardsData && cardsData.length === 0" style="text-align: center; margin-top: 3%">
                                <h5>
                                    Nenhum alerta localizado
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-alerts-dashboard class="dashboardAlerts" :cards_data="cardsData" :grid_data="originalResponse"
                              :show_cards="showCards" :filters="filters" :selected_status="filters.status" :selected_priority="filters.priority"
                              :showDashboard="showDashboard"/>

        <div v-if="!showCards && !loading && gridData.length > 0"
             style="position: fixed; z-index: 5; bottom: 20px; right: 20px">
            <app-button @handleClick="handleGoToDashboard" type="primary" :disabled="isRealtimeRunning">
                <span :class=dashboardButtonIcon></span>
            </app-button>
        </div>
        <app-alerts-dialog :gridData="alertsDialogData" ref="dialogAlerts"></app-alerts-dialog>
    </div>
</template>

<script>
import AppButton from "../../../common/AppButton";
import AppInput from "../../../common/AppInput";
import {STATUS} from "../../../../constants/status";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import AlertsService from "../../../../services/AlertsService";
import maskCpfCnpj from "../../../../mixins/StringsMaskMixin";
import dateHourFormat from "../../../../mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixinShowZero from "../../../../mixins/DateMixin";
import filterValue from "../../../../mixins/FilterFunctionsDataTables";
import ProgressSpinner from 'primevue/progressspinner';
import moment from 'moment';
import {router} from '../../../../router';
import Button from 'primevue/button';
import AppAlertsDialogAlertDetails from "../AppAlertsDialogAlertDetails";
import AppAlertsDialogVehicleDetails from "../AppAlertsDialogVehicleDetails";
import AppAlertsAlertEdit from "./AppAlertsAlertEdit";
import AppDateTimeControlBar from "../../../common/AppDateTimeControlBar";
import AppVehiclesSelectVehicles from "../../real-time/AppVehiclesSelectVehicles";
import MultiSelect from 'primevue/multiselect';
import orderVehiclesByDisplayIdentificationNaturalOrder from '../../../../mixins/ArrayMixin';
import {CSV_FIELDS, CSV_LABELS, GRID_COLUMNS, PRIORITIES, STATUSES, STATUSES_FAILURES} from './AppOptions';
import {ACTIVATION_STATE} from '../../../../constants/StompStatuses';
import AppAlertsDialog from "./AppAlertsDialog";
import AppAlertsCardVehicle from "./AppAlertsCardVehicle";
import * as SockJS from 'sockjs-client';
import {Client} from '@stomp/stompjs';
import {mapGetters} from "vuex";
import AppAlertsDashboard from "./AppAlertsDashboard";
import Dialog from 'primevue/dialog';
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from "firebase/messaging";
import UsersService from "@/services/UsersService";
import OrganizationsService from "@/services/OrganizationsService";
import VehiclesService from "@/services/VehiclesService";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";

export default {
    mixins: [maskCpfCnpj, dateHourFormat, filterValue, orderVehiclesByDisplayIdentificationNaturalOrder,
        getTimeFromSecondsDurationFormatMixinShowZero, orderCustomFieldByNaturalOrder],
    mounted() {
        this.alertsService = new AlertsService();
        this.organizationsService = new OrganizationsService();
        this.vehiclesService = new VehiclesService();
        this.queryParamVehicleId = this.$route.query.vehicle_id;
        this.queryParamStart = this.$route.query.start;
        this.queryParamEnd = this.$route.query.end;
        this.loadSavedLocalStorageOptions();
        this.getOrganizations();
        this.saveDates();
        this.stompjsClientSubscribeId = this.user.user_email;
        this.requestPermissionPushNotifications();
        this.usersService = new UsersService();
        this.showDialogFilters = this.isScreenMobile;
        this.dialogWidth = window.screen.width > 1024 && window.screen.width <= 1440 ? "50vw" : "85vw";
    },
    data() {
        return {
            dialogWidth: null,
            showDialogFilters: false,
            organizationsService: null,
            vehiclesService: null,
            usersService: null,
            alertsDialogData: [],
            statusesOrder: STATUSES,
            priorities: PRIORITIES,
            statusesFailures: STATUSES_FAILURES,
            displayMaximizable: false,
            stompActivationState: ACTIVATION_STATE,
            datePickerStartDate: moment().subtract(1, 'day').startOf('day').toDate(),
            datePickerEndDate: moment().toDate(),
            datePickerStartDateLastState: null,
            datePickerEndDateLastState: null,
            selectedClients: [],
            clientsList: [],
            organizationsList: [],
            selectedOrgs: [],
            optionsSelectWorkType: [],
            optionsSelectWorkTypeSelected: null,
            loadingClients: false,
            selectedVehicles: [],
            vehiclesList: [],
            loadingVehicles: false,
            faultDetails: 'LOADING',
            vehicleDetails: 'LOADING',
            alertEditDetails: 'LOADING',
            displayDialogCodigo: false,
            displayDialogVehicle: false,
            displayDialogEditAlert: false,
            expandedRows: [],
            alertsService: null,
            status_list: STATUS,
            filters: {},
            loading: false,
            gridData: [],
            gridColumns: GRID_COLUMNS,
            selectedGridColumns: [],
            showCards: false,
            clientVehicles: [],
            cardsData: [],
            stompjsClient: null,
            // Usado para controlar se precisa reiniciar o tempo real
            // apos editar algum alerta
            editAlertRealTime: false,
            showDashboard: false,
            isRealTimeOn: false,
            stompjsClientSubscribeId: null,
            queryParamVehicleId: null,
            queryParamStart: null,
            queryParamEnd: null,
            frenteCorteList: [],
            frenteCorteListSelected: [],
            savedLocalStorageOptions: null,
            refreshConnectionInterval: null,
            originalResponse: [],
            vehiclesOriginalList: []
        }
    },
    methods: {
        goToAlertReport(){
            router.push({name: 'alerts-orders'});
        },
        openMaximizable() {
            this.displayMaximizable = true;
        },
        filterMultiselect(value, filter) {
            let customersIncludesFilter = false;

            if (!filter || filter.length === 0) {
                return true;
            }

            filter.forEach(status => {
                if (status.code && status.code.toLowerCase().includes(value.toLowerCase())) {
                    customersIncludesFilter = true;
                }
            })

            return customersIncludesFilter;
        },
        getSelectValue(value, field) {
            if (value[field] && value[field].length > 3) {
                return value[field].substring(0,3) + "...";
            }

            return value[field];
        },
        getValue(value){
            if(value){
                return value.substring(0,3) + "...";
            }
            return
        },
        chipClickedOrg(value) {
            const index = this.selectedOrgs.indexOf(value);
            if (index > -1) {
                this.selectedOrgs.splice(index, 1);
            }
        },
        chipClickedVehicle(value) {
            const index = this.selectedVehicles.indexOf(value);
            if (index > -1) {
                this.selectedVehicles.splice(index, 1);
            }
        },
        chipClickedFrente(value) {
            const index = this.frenteCorteListSelected.indexOf(value);
            if (index > -1) {
                this.frenteCorteListSelected.splice(index, 1);
            }
        },
        chipClickedLevel(value) {
            const index = this.filters['priority'].indexOf(value);
            if (index > -1) {
                this.filters['priority'].splice(index, 1);
            }
        },
        chipClickedStatus(value) {
            const index = this.filters['status'].indexOf(value);
            if (index > -1) {
                this.filters['status'].splice(index, 1);
            }
        },
        requestPermissionPushNotifications() {

            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    this.requestTokenFromFCM();
                } else {
                    console.log('Unable to get permission to notify.');
                }
            });
        },
        requestTokenFromFCM() {

            const firebaseConfig = {
                apiKey: process.env.VUE_APP_FCM_API_KEY,
                authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
                projectId: process.env.VUE_APP_FCM_PROJECT_ID,
                storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
                messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID,
                appId: process.env.VUE_APP_FCM_APP_ID,
                measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID
            };

            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging(app);

            getToken(messaging, {vapidKey: process.env.VUE_APP_FCM_VAPID_KEY}).then((currentToken) => {

                if (currentToken) {
                    this.sendFCMTokenToBackend(currentToken)
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    this.requestTokenFromFCM();
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });

        },
        sendFCMTokenToBackend(currentToken) {
            this.usersService.sendFCMToken({token: currentToken})
                .then((response) => {
                }).catch((erro) => {
            })
        },
        handleGoToDashboard() {
            this.showDashboard = !this.showDashboard;
        },
        getClientDisplayName(val) {
            if (val.trade_name) {
                return val.trade_name;
            }
            return val.name;
        },
        getOrganizations() {
            this.organizationsService.getAllV3('ALERTS')
                .then((response) => {
                    this.organizationsList = this.orderClientsByDisplayNameNaturalOrder(response);
                    this.getVehicles()
                }).catch((error) => {
                console.log(error)
            }).finally(() => {
                this.loadingClients = false;
            });
        },
        getVehicles() {
            let vm = this;
            const promises = [];
            this.organizationsList.forEach(org => {
                promises.push(this.vehiclesService.getVehiclesByOrgV3(org.id));
            });

            Promise.allSettled(promises)
                .then(async (results) => {
                    if (results.map(obj => obj.status).includes("rejected")) {
                        vm.$toast.add({
                            severity: 'warn',
                            summary: 'Sem acesso',
                            detail: 'Você não tem acesso aos veículos de todos as organizações',
                            life: 5000
                        });
                    }

                    let vehicles = [];
                    results.forEach(result => {
                        if (result.status === 'rejected') {
                            return;
                        }
                        vehicles.push.apply(vehicles, result.value)
                    })
                    this.vehiclesOriginalList = this.orderCustomFieldByNaturalOrder(vehicles, "display_id");
                    this.loadSelectedOrgs()
                    this.frenteCorteList = vm.getFrenteDeCorteFromCustomers()
                    this.loadSelectedFrentes();
                    this.loadSelectedVehicles();
                })
                .catch((error) => {
                    vm.$toast.add({
                        severity: 'error',
                        summary: 'Operação falhou',
                        detail: 'Não foi possível buscar veículos',
                        life: 5000
                    });
                    console.log(error)
                }).finally(() => {
                vm.isLoading = false;
            });
        },
        loadSelectedOrgs() {
            if (this.savedLocalStorageOptions) {

                if (this.savedLocalStorageOptions.clients) {
                    this.populeSelectedOrgs(this.savedLocalStorageOptions.clients);
                }

                if (this.savedLocalStorageOptions.filters) {
                    this.filters = this.savedLocalStorageOptions.filters;
                }
            }
        },
        getFrenteDeCorteFromCustomers() {

            let temp = [];

            //n tem nenhum cliente selecionado então adiciona tudo da lista de clientes
            if (!this.selectedOrgs || this.selectedOrgs.length === 0) {
                return [];
            }

            //se tiver cliente seleciona filtra com base nesta seleção
            for (let i = 0; i < this.selectedOrgs.length; i++) {
                for (let j = 0; j < this.vehiclesOriginalList.length; j++) {
                    if (this.vehiclesOriginalList[j].frente_org_id !==  this.selectedOrgs[i].id) {
                        continue;
                    }

                    if (temp.find(obj => obj.id === this.vehiclesOriginalList[j].frente_id)) {
                        continue;
                    }

                    temp.push({
                        'id': this.vehiclesOriginalList[j].frente_id,
                        'name': this.vehiclesOriginalList[j].frente_name,
                        'orgId': this.vehiclesOriginalList[j].frente_org_id,
                    })
                }
            }

            this.vehiclesList = this.getVehiclesFromCustomers();

            return temp;
        },
        loadSelectedFrentes() {
            if (this.savedLocalStorageOptions) {

                if (this.savedLocalStorageOptions.frentes) {
                    this.frenteCorteListSelected = this.savedLocalStorageOptions.frentes;
                }
            }
        },
        loadSelectedVehicles() {
            if (!this.savedLocalStorageOptions) {
                return;
            }

            if (this.savedLocalStorageOptions.vehicles) {
                this.selectedVehicles = this.savedLocalStorageOptions.vehicles;
            }
            if (this.savedLocalStorageOptions.last_view) {
                if (this.savedLocalStorageOptions.last_view === 'CARDS') {
                    this.goToCards();
                } else if (this.savedLocalStorageOptions.last_view === 'REALTIME') {
                    this.goToRealTime();
                } else {
                    this.getFaults();
                }
            }
        },
        getVehiclesFromCustomers() {
            let temp = [];

            //tem frente selecionada então tem que selecionar so as maquinas das frentes
            if (this.frenteCorteListSelected && this.frenteCorteListSelected.length > 0) {
                for (let i = 0; i < this.frenteCorteListSelected.length; i++) {
                    for (let j = 0; j < this.vehiclesOriginalList.length; j++) {

                        if (this.vehiclesOriginalList[j].frente_id !== this.frenteCorteListSelected[i].id) {
                            continue;
                        }

                        temp.push(this.vehiclesOriginalList[j]);
                    }
                }
            } else {
                //Se tiver clientes selecionados, filtrar veículos pela frente destes clientes
                let vehicleByFrenteList = [];

                //Procura por todos os veículos associados as frente de organizações selecionadas
                if (this.selectedOrgs?.length > 0) {
                    for (let i = 0; i < this.selectedOrgs.length; i++) {
                        for (let j = 0; j < this.vehiclesOriginalList.length; j++) {

                            if (!this.vehiclesOriginalList[j].frente_org_id && (this.vehiclesOriginalList[j].organization_id ===
                                this.selectedOrgs[i].id)) {
                                temp.push(this.vehiclesOriginalList[j]);
                                continue;
                            }

                            if (this.vehiclesOriginalList[j].frente_org_id !== this.selectedOrgs[i].id) {
                                continue;
                            }

                            temp.push(this.vehiclesOriginalList[j]);
                        }
                    }
                }
            }

            return temp;
        },
        isFrenteSelected(frenteCorteList, frenteId) {
            for (let i = 0; i < frenteCorteList.length; i++) {
                if (frenteCorteList[i].id === frenteId) {
                    return true;
                }
            }
            return false;
        },
        isFrenteSelectedByName(frenteCorteList, frente) {
            for (let i = 0; i < frenteCorteList.length; i++) {
                // console.log(frenteCorteList[i].name)
                if (frenteCorteList[i].name === frente) {
                    return true;
                }
            }
            return false;
        },
        selectVehicleFromQueryParam() {
            let temp = [];

            this.vehiclesList.forEach((vehicle) => {
                if (this.queryParamVehicleId === vehicle.id) {
                    temp.push(vehicle);
                }
            });

            this.selectedVehicles = temp;
        },

        handleClientChange(event) {
        },
        exitDashboard() {
            this.showDashboard = false;
        },
        validateDataPreRequest(val) {
            if(this.displayMaximizable === true){
                this.displayMaximizable = false;
            }
            if (val) {
                if (moment(val.end_date).diff(moment(val.start_date), 'days') > 31 && this.getVehiclesRequest().length === 1) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Intervalo máximo de consulta para uma máquina é de 31 dias',
                        life: 5000
                    });
                    return;
                }
                if (moment(val.end_date).diff(moment(val.start_date), 'days') > 2 && this.getVehiclesRequest().length > 1) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Intervalo máximo de consulta para mais de uma máquina é de dois dias',
                        life: 5000
                    });
                    return;
                }
            }

            this.saveDates(val);
            this.cancelRealtime();
            this.exitDashboard();
            this.getAlertsInBatch(val);
        },
        getAlertsInBatch(val) {

            this.gridData = [];
            this.loading = true;
            let vehicles = this.getVehiclesRequest();


            let BATCH_ITENS_PER_PAGE = 30;

            let vehicleArrays = [];
            let tempArray = [];

            for (let i = 0; i < vehicles.length; i++) {

                tempArray.push(vehicles[i]);

                if (i > 0 && i % BATCH_ITENS_PER_PAGE === 0) {
                    vehicleArrays.push(tempArray);
                    tempArray = [];
                }
            }

            if (tempArray.length > 0) {
                vehicleArrays.push(tempArray);
            }


            let promisses = [];

            for (let i = 0; i < vehicleArrays.length; i++) {
                promisses.push(this.getAlertsV3(val.start_date, val.end_date, vehicleArrays[i]));
            }


            Promise.all(promisses).then(() => {
                this.loading = false;

                // Aplica o filtro
                this.originalResponse = this.gridData;
                this.filterData();

                if (this.gridData.length === 0) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Nenhum alerta localizado',
                        life: 5000
                    });
                }
            });
        },

        getAlertsV3(start_date, end_date, vehicles) {
            return this.alertsService.findAllByIdentificationNumberAndBetweenV3({
                start: moment(start_date).unix(),
                end: moment(end_date).unix(),
                vehicles_ids: vehicles
            }).then((response) => {
                if (response && response.length > 0) {
                    this.gridData.push(...this.treatResponse(response));
                }
            }).catch((error) => {
                console.log(error)
            });
        },
        treatResponse(response) {
            response.forEach(alert => {
                if (alert.status === 'NEW') {
                    return;
                }

                if (alert.in_progress_date) {
                    alert.response_time = alert.in_progress_date - alert.order_date;
                }

                if (alert.status === 'IN_PROGRESS') {
                    return;
                }

                if (alert.closed_date) {
                    alert.execution_time = alert.in_progress_date ? (alert.closed_date - alert.in_progress_date)
                        : (alert.closed_date - alert.order_date);

                    alert.total_time = (alert.closed_date - alert.order_date);
                }

            })

            response = this.treatResponseCount(response);

            response.sort(function (a, b) {
                if (a.date === b.date) {
                    return b.status.localeCompare(a.status);
                }
                return b.date - a.date;
            });

            return JSON.parse(JSON.stringify(this.treatResponseCount(response)));
        },
        showModalAndGetDetails(val) {
            this.displayDialogCodigo = true;
            this.getDetailsFault(val);
        },
        handleCloseModalAlerts() {
            this.displayDialogCodigo = false;
        },
        showModalEditAndGetDetails(val) {
            this.disconnectWebSocket();
            this.displayDialogEditAlert = true;
            this.alertEditDetails = val;

            if (this.refreshConnectionInterval) {
                clearInterval(this.refreshConnectionInterval);
                this.refreshConnectionInterval = null;
            }
        },
        handleCloseModalAlertsEdit() {
            this.displayDialogEditAlert = false;

            if (this.editAlertRealTime) {
                this.goToRealTime();
            }
        },
        handleSalveEditAlert(val) {

            let data = {
                id: val.id,
                daily_ids_and_counts: val.daily_ids_and_counts,
                assignee: val.assignee,
                work_order: val.work_order,
                status: val.status,
                observation: val.observation
            };


            this.sendUpdate(val.id, data).then((responses) => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Atualização realizada com sucesso',
                    life: 3000
                });
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: error.response.data.details,
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            })
            .finally(() => {
                this.handleCloseModalAlertsEdit();
            })
        },
        sendUpdate(alertId, data) {
            return this.alertsService.updateAlertV3(alertId, data);
        },
        showModalVehicleAndGetDetails(val) {
            this.displayDialogVehicle = true;
            this.getVehicleDetails(val);
        },
        handleCloseModalVehicle() {
            this.displayDialogVehicle = false;
        },
        getFaults() {
            this.validateDataPreRequest({
                start_date: this.datePickerStartDate,
                end_date: this.datePickerEndDate
            });
        },
        getDetailsFault(id) {
            this.faultDetails = "LOADING";
            this.alertsService.getDetails(id)
                .then((response) => {
                    this.faultDetails = response;
                }).catch((error) => {
                this.faultDetails = 'ERROR';
            });

        },
        getVehicleDetails(vid) {
            this.vehicleDetails = "LOADING";
            this.alertsService.getVehicleDetails(vid)
                .then((response) => {
                    this.vehicleDetails = response;
                }).catch((error) => {
                this.vehicleDetails = 'ERROR';
            });

        },
        formatCode(code, severity) {
            if (severity) {
                return code + "." + severity;
            }
            return code;
        },
        goToRealTime() {
            if(this.displayMaximizable === true){
                this.displayMaximizable = false;
            }
            let vm = this;

            if (this.isRealtimeRunning) {
                this.cancelRealtime();
                this.showCards = false;

                if (this.refreshConnectionInterval) {
                    clearInterval(this.refreshConnectionInterval);
                    this.refreshConnectionInterval = null;
                }

                return;
            }

            this.exitDashboard();
            this.realTime('REALTIME');

            if (!this.refreshConnectionInterval) {
                this.refreshConnectionInterval = setInterval(function () {
                    if (vm.loading) {
                        return;
                    }
                    vm.getRealTime();
                }, 300000) //5 minutos
            }
        },

        realTime(val) {

            let vm = this;

            //primeira vez q clicou no tempo real
            if (!this.stompjsClient || (this.stompjsClient.state === this.stompActivationState.INACTIVE)
                || (this.stompjsClient.state === this.stompActivationState.DEACTIVATING)) {
                vm.getRealTime();
            } else if (this.stompjsClient.state === this.stompActivationState.ACTIVE) {
                this.disconnectWebSocket();
            }

            this.saveDates();

            this.datePickerStartDate = null;
            this.datePickerEndDate = null;

            this.storeSelectedOptions(val);
        },

        saveDates(val) {
            //veio do componente de datas
            if (val) {
                //salva as datas para poder restaurar quando sair do tempo real
                this.datePickerStartDateLastState = val.start_date;
                this.datePickerEndDateLastState = val.end_date;
            } else if (this.datePickerStartDate && this.datePickerEndDate) {
                //salva as datas para poder restaurar quando sair do tempo real
                this.datePickerStartDateLastState = this.datePickerStartDate;
                this.datePickerEndDateLastState = this.datePickerEndDate;
            }
        },
        restoreDates() {
            // restaura os valores que estavam selecionados
            this.datePickerStartDate = this.datePickerStartDateLastState;
            this.datePickerEndDate = this.datePickerEndDateLastState;
        },
        cancelRealtime() {
            this.editAlertRealTime = false;
            this.disconnectWebSocket();
            this.restoreDates();
            this.storeSelectedOptions();
        },
        stopRealtimeBeforeLeavePage() {
            this.storeSelectedOptions();
        },
        getVehiclesRequest() {

            let tempList = [];

            //se tem maquinas seleciondas tem que pegar elas
            if (this.selectedVehicles && this.selectedVehicles.length > 0) {
                //
                tempList = this.selectedVehicles;
            } else {
                //caso contrario pega todas que estão na lista
                tempList = this.vehiclesList;
            }

            return tempList.map(function (item) {
                return item['id'];
            });
        },
        getRealTime() {
            this.loading = true;
            let vehicles = this.getVehiclesRequest();

            let BATCH_ITENS_PER_PAGE = 30;

            let vehicleArrays = [];
            let tempArray = [];

            for (let i = 0; i < vehicles.length; i++) {

                tempArray.push(vehicles[i]);

                if (i > 0 && i % BATCH_ITENS_PER_PAGE === 0) {
                    vehicleArrays.push(tempArray);
                    tempArray = [];
                }
            }

            if (tempArray.length > 0) {
                vehicleArrays.push(tempArray);
            }

            let promisses = [];

            let start_date = moment().subtract(1, 'day').startOf('day');
            let end_date = moment();

            for (const element of vehicleArrays) {
                promisses.push(this.alertsService.findAllByIdentificationNumberAndBetweenV3({
                    start: moment(start_date).unix(),
                    end: moment(end_date).unix(),
                    vehicles_ids:element
                }));
            }

            Promise.all(promisses).then((results) => {
                let tempData = [];

                if (results.length === promisses.length) {
                    results.forEach(result => {
                        if (result && result.length > 0) {
                            tempData.push(...this.treatResponse(result));
                        }
                    })
                    this.gridData = tempData;
                }

                // Aplica o filtro
                this.originalResponse = this.gridData;
                this.filterData();

                this.connWebSocket();
                this.editAlertRealTime = true;
            }).catch(() => {
                console.log("Erro na busca de alertas em paralelo")
                // console.log("catch")
            }).finally(() => {
                this.loading = false;
            })
        },
        connWebSocket() {

            //so deixa conectar se não está conectado
            if (this.stompjsClient && (this.stompjsClient.state !== this.stompActivationState.INACTIVE)) {
                return;
            }

            let vm = this;
            this.stompjsClient = new Client({
                // debug: function (str) {
                //     console.log(str);
                // },
                reconnectDelay: 10000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
            });

            this.stompjsClient.webSocketFactory = function () {
                return new SockJS(process.env.VUE_APP_BACKEND_ADDRESS + '/ws/?access_token=' + vm.user.access_token)
            };

            this.stompjsClient.onConnect = function (frame) {
                // Do something, all subscribes must be done is this callback
                // This is needed because this will be executed after a (re)connect

                vm.stompjsClient.subscribe('/user/secured/queue/alerts', function (val) {
                    vm.appendAlertsFromWebSocket(JSON.parse(val.body));
                }, {id: vm.stompjsClientSubscribeId});
            };

            this.stompjsClient.onStompError = function (frame) {
                // Will be invoked in case of error encountered at Broker
                // Bad login/passcode typically will cause an error
                // Complaint brokers will set `message` header with a brief message. Body may contain details.
                // Compliant brokers will terminate the connection after any error
                // console.log('Broker reported error: ' + frame.headers['message']);
                // console.log('Additional details: ' + frame.body);
            };
            this.stompjsClient.activate();

        },
        disconnectWebSocket() {

            if (this.stompjsClient) {

                try {
                    this.stompjsClient.unsubscribe(this.stompjsClientSubscribeId);
                } catch (e) {
                    //
                }

                this.stompjsClient.deactivate();
            }
        },
        appendAlertsFromWebSocket(alert) {

            if (this.frenteCorteListSelected && this.frenteCorteListSelected.length > 0) {
                if (!this.isFrenteSelectedByName(this.frenteCorteListSelected, alert.frente_corte)) {
                    // console.log(alert)
                    console.log("Não está na frente de corte");
                    return;
                }
            }

            let addedChild = false;
            let newGridData = this.originalResponse;

            //ver se nao troca direto
            for (let i = 0; i < newGridData.length; i++) {
                if (newGridData[i].id === alert.id) {
                    if (alert.in_progress_date) {
                        alert.response_time = alert.in_progress_date - alert.order_date;
                    }

                    if (alert.closed_date) {
                        alert.execution_time = alert.in_progress_date ? (alert.closed_date - alert.in_progress_date)
                            : (alert.closed_date - alert.order_date);

                        alert.total_time = (alert.closed_date - alert.order_date);
                    }

                    //Backend não refaz lógica para adicionar daily_ids_and_counts que não são o 'header', por isso deve manter o antigo
                    let oldDailyIdsAndCounts = newGridData[i].daily_ids_and_counts;
                    let headerIdsAndCounts = alert.daily_ids_and_counts.find(id_count => id_count.id === alert.id);
                    if (headerIdsAndCounts) {
                        let indexOldHeader = oldDailyIdsAndCounts.findIndex(id_count => id_count.id === alert.id);
                        if (indexOldHeader >= 0) {
                            oldDailyIdsAndCounts[indexOldHeader] = headerIdsAndCounts;
                        }
                    }
                    newGridData[i] = alert;
                    newGridData[i].daily_ids_and_counts = oldDailyIdsAndCounts;
                    addedChild = true;
                    break;
                }
            }


            for (let i = 0; i < newGridData.length; i++) {

                if (addedChild) {
                    break;
                }

                if (newGridData[i].vehicle_id !== alert.vehicle_id) {
                    continue;
                }

                if (!this.areCodesEqual(newGridData[i].code, newGridData[i].severity, alert.code, alert.severity)) {
                    continue;
                }

                if (newGridData[i].status === 'CLOSED') {
                    break;
                }

                addedChild = true;

                let index = newGridData[i].daily_ids_and_counts.findIndex(obj => obj.id === alert.id);
                if (index >= 0) {
                    newGridData[i].daily_ids_and_counts[index] = alert.daily_ids_and_counts[0]
                } else {
                    newGridData[i].daily_ids_and_counts.unshift(alert.daily_ids_and_counts[0]);
                }

                newGridData[i].date = alert.date;
                newGridData[i].hour_meter = alert.hour_meter;
                newGridData[i].city = alert.city;
                newGridData[i].state = alert.state;
                newGridData[i].status = alert.status;

                break;
            }

            if (!addedChild) {
                newGridData.push(alert)
            }

            newGridData.sort(function (a, b) {
                if (a.date === b.date) {
                    return b.status.localeCompare(a.status);
                }
                return b.date - a.date;
            });

            this.gridData = JSON.parse(JSON.stringify(this.treatResponseCount(newGridData)));

            // Aplica o filtro
            this.originalResponse = this.gridData;
            this.filterData();
        },
        areCodesEqual(code1, severity1, code2, severity2) {
            if ((severity1 === undefined || severity1 === null) && (severity2 === undefined || severity2 === null)) {
                return code1 === code2;
            }

            return code1 === code2 && severity1 === severity2;
        },
        treatResponseCount(gridData) {
            let newGridData = [];
            gridData.forEach(eachGridData => {
                let count = 0;
                eachGridData.daily_ids_and_counts.forEach(idAndCount => {
                    count += idAndCount.count;
                })
                eachGridData.count = count;
                newGridData.push(eachGridData);
            })

            return newGridData;
        },
        getStatus(status) {
            let result = this.statusesOrder.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        getFailureStatus(status) {
            let result = this.statusesFailures.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        openDialog(data) {
            if (data.daily_ids_and_counts) {
                this.$refs.dialogAlerts.changeVisibility();
                this.$refs.dialogAlerts.setLoading(true);
                this.alertsService.getAlertChildren({daily_ids_and_counts: data.daily_ids_and_counts})
                    .then((response) => {
                        this.alertsDialogData = this.setStatusToAlertsDialogData(response, data.status, data.priority);
                    }).catch((error) => {
                    console.log(error);
                    this.$refs.dialogAlerts.changeVisibility();
                }).finally(() => {
                    this.$refs.dialogAlerts.setLoading(false);
                })
            }
        },
        setStatusToAlertsDialogData(response, status, priority) {
            response.forEach(eachResponse => {
                eachResponse.status = status;
                eachResponse.priority = priority;
            })
            return response;
        },
        storeSelectedOptions(value) {

            let lastView = this.getLastView();

            if (value) {
                lastView = value;
            }

            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_ALERTS_OPTIONS,
                JSON.stringify({
                    clients: this.selectedOrgs,
                    vehicles: this.selectedVehicles,
                    frentes: this.frenteCorteListSelected,
                    last_view: lastView,
                    isNewLocalStorage: true,

                    //TODO quando tiver o select de colunas
                    columns: "",
                    filters: this.filters
                }));
        },
        loadSavedLocalStorageOptions() {

            if (JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_ALERTS_OPTIONS))
                && !JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_ALERTS_OPTIONS)).isNewLocalStorage) {
                localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_ALERTS_OPTIONS);
                return
            }

            this.savedLocalStorageOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_ALERTS_OPTIONS));
        },
        loadSelectedOptions() {

            if (this.savedLocalStorageOptions) {

                if (this.savedLocalStorageOptions.vehicles) {
                    this.selectedVehicles = this.savedLocalStorageOptions.vehicles;
                }

                if (this.savedLocalStorageOptions.frentes) {
                    this.frenteCorteListSelected = this.savedLocalStorageOptions.frentes;
                }

                if (this.savedLocalStorageOptions.clients) {
                    this.populeSelectedOrgs(this.savedLocalStorageOptions.clients);
                }

                //update lista de veículos com novas frentes selecionadas
                this.vehiclesList = this.getVehiclesFromCustomers();

                if (this.savedLocalStorageOptions.last_view) {
                    if (this.savedLocalStorageOptions.last_view === 'CARDS') {
                        this.goToCards();
                    } else if (this.savedLocalStorageOptions.last_view === 'REALTIME') {
                        this.goToRealTime();
                    } else {
                        this.getFaults();
                    }
                }
                if (this.savedLocalStorageOptions.filters) {
                    this.filters = this.savedLocalStorageOptions.filters;
                }
            }
        },
        populeSelectedOrgs(orgs) {
            let temp = [];

            if (!orgs || orgs.length === 0) {
                this.selectedOrgs = temp;
                return;
            }

            //se o cliente seleciona está na lista de clientes coloca
            //identification == CNPJ

            orgs.forEach((org) => {
                let found = this.organizationsList.some(element => {
                    if (element.id === org.id) {
                        return true;
                    }
                })
                if (found) {
                    temp.push(org);
                }

            });

            this.selectedOrgs = temp;
        },

        getPriority(priority) {
            let result = this.priorities.find(({code}) => code === priority);
            if (result) {
                return result.name;
            }
        },
        roundNumber(val, num) {

            if (!val) {
                return null;
            }

            if (!num) {
                num = 2;
            }
            return parseFloat(val).toFixed(num);
        },
        goToCards() {
            if (!this.isRealtimeRunning) {
                this.goToRealTime();
            }

            this.exitDashboard();
            this.showCards = !this.showCards;
            this.storeSelectedOptions();
        },
        handleClickCard(val) {
            this.showCards = false;
            this.filters.identification_number = val;
            this.filterData();
            this.storeSelectedOptions();
        },
        prepareCardData(val) {
            let temp = [];

            val.forEach((failure) => {

                let foundPriority = false;
                let foundStatus = false;

                //n tem nenhum selecionado então mostra tudo
                if (!this.filters.priority || (this.filters.priority && this.filters.priority.length === 0)) {
                    foundPriority = true;
                } else {
                    for (let i = 0; i < this.filters.priority.length; i++) {
                        if (failure.priority === this.filters.priority[i]) {
                            foundPriority = true;
                            break;
                        }
                    }
                }
                if (!this.filters.status || (this.filters.status && this.filters.status.length === 0)) {
                    foundStatus = true;
                } else {
                    for (let i = 0; i < this.filters.status.length; i++) {
                        if (failure.status === this.filters.status[i]) {
                            foundStatus = true;
                            break;
                        }
                    }
                }

                // se achou o nivel ou o serviço
                if (foundPriority && foundStatus) {
                    //agrupa por maquina
                    let index = this.findByVID(temp, failure.vehicle_id);
                    if (index < 0) {
                        temp.push(this.createCardData(failure))
                    } else {

                        // se achou vai permanecer o que tiver a prioridade mais alta
                        if (this.haveHighestPriority(failure.priority, temp[index].priority.code) >= 0) {
                            if (this.haveHighestStatus(failure.status, temp[index].status.code) === 1) {
                                temp[index] = this.createCardData(failure);
                            }
                        }
                    }
                }
            });

            //pega o contador de falhas do grid e soma
            temp.forEach(value => {
                value.count = this.getCountFromGridDataByVid(value.vehicle_id);
            });

            //ordenar os cards Pendente > executando > finalizado
            //dentro de cada um desses status ordenar pela data do alerta

            let vm = this;
            temp.sort(function (a, b) {
                //https://stackoverflow.com/a/13211728/6009128
                return vm.haveHighestStatus(b.status, a.status) || b.date - a.date;
            });

            this.cardsData = temp;
        },
        createCardData(data) {
            return {
                identification_number: data.identification_number,
                vehicle_id: data.vehicle_id,
                display_id: data.display_id,
                priority: data.priority,
                status: data.status,
                model: data.model,
                count: data.count,
                frente_corte: data.frente_corte,
                date: data.date
            }
        },
        haveHighestPriority(p1, p2) {
            if (p1 === p2) {
                return 0;
            }

            if (p1 === 'HIGH' || (p1 === 'MEDIUM' && p2 !== 'HIGH') ||
                (p1 === 'LOW' && p2 === 'UNKNOWN')) {
                return 1;
            }

            return -1;
        },
        haveHighestStatus(s1, s2) {
            if (s1 === s2) {
                return 0;
            }

            if (s1 === 'NEW' || (s1 === 'IN_PROGRESS' && s2 !== 'NEW')) {
                return 1;
            }
            return -1;
        },
        findByVID(array, vid) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].vehicle_id === vid) {
                    return i;
                }
            }
            return -1;
        },
        getLastView() {

            if (this.showCards) {
                return "CARDS";
            } else if (this.isRealtimeRunning) {
                return "REALTIME";
            } else {
                return "TABLE";
            }
        },
        checkIfAlertIsInFilters(alert) {
            if (!this.filters) {
                return true;
            }

            if (this.filters.priority) {
                if ((this.filters.priority.length !== 0 && this.filters.priority.indexOf(alert.priority) === -1)) {
                    return false;
                }
            }

            if (this.filters.status) {
                if ((this.filters.status.length !== 0 && this.filters.status.indexOf(alert.status) === -1)) {
                    return false;
                }
            }

            return true;
        },
        getCountFromGridDataByVid(vid) {
            let count = 0;

            this.gridData.forEach(value => {
                if (value.vehicle_id === vid) {
                    if (!this.checkIfAlertIsInFilters(value)) {
                        return;
                    }
                    count += value.count;
                }
            });

            return count;
        },
        saveFrentesSelected(val){

            let frentesList = val.map(v => v.name);

            this.alertsService.saveFrentesSelectedPushNotification({frentes: frentesList})
                .then((response) => {
                    console.log(response)
                }).catch((error) => {
                console.log(error);
            })

        },
        updateFrenteCorteListSelected(frenteCorteList) {
            let frenteCorteListSelectedTemp = [];

            this.frenteCorteListSelected.forEach(selected => {
                if (frenteCorteList.map(obj => obj.id).includes(selected.id)) {
                    frenteCorteListSelectedTemp.push(selected);
                }
            })

            this.frenteCorteListSelected = frenteCorteListSelectedTemp;
        },
        updateSelectedVehicles(vehiclesList) {
            let selectedVehiclesTemp = [];

            this.selectedVehicles.forEach(selected => {
                if (vehiclesList.map(obj => obj.id).includes(selected.id)) {
                    selectedVehiclesTemp.push(selected);
                }
            })

            this.selectedVehicles = selectedVehiclesTemp;
        },
        isEmpty(val){
            if (val === null || val === undefined || val < 0) {
                return "";
            } else{
                return val;
            }
        },
        filterData() {
            let newGridData = [];
            this.originalResponse.forEach((alert) => {
                // this.filters recebe os arrays dos multiselects para guardar no localStorage futuramente
                if (this.filters.status != null && this.filters.status.length > 0 && !this.filters.status.includes(alert.status)) {
                    return;
                }

                if (this.filters.priority != null && this.filters.priority.length > 0 && !this.filters.priority.includes(alert.priority)) {
                    return;
                }

                if (this.filters.failure_status != null && this.filters.failure_status.length > 0 &&
                    !this.filters.failure_status.includes(alert.failure_status)) {
                    return;
                }

                // Filtra de acordo com o que foi escrito nos InputTexts
                if(this.filters.date != null &&
                    !this.dateHourFormat(alert.date).includes(this.filters.date.toString())){
                    return;
                }

                if(this.filters.response_time != null &&
                    !this.getTimeFromSecondsDurationFormatMixinShowZero(alert.response_time).includes(this.filters.response_time.toString())){
                    return;
                }

                if(this.filters.execution_time != null &&
                    !this.getTimeFromSecondsDurationFormatMixinShowZero(alert.execution_time).includes(this.filters.execution_time.toString())){
                    return;
                }

                if(this.filters.total_time != null &&
                    !this.getTimeFromSecondsDurationFormatMixinShowZero(alert.total_time).includes(this.filters.total_time.toString())){
                    return;
                }

                let cityTemp = this.isEmpty(alert.city) === "" ? "" : alert.city + " " + alert.state;
                if(this.filters.city != null && !cityTemp.toLocaleLowerCase().includes(this.filters.city.toLocaleLowerCase())){
                    return;
                }

                if(this.filters.frente_corte != null && !this.isEmpty(alert.frente_corte).toLocaleLowerCase().includes(this.filters.frente_corte.toLocaleLowerCase())){
                    return;
                }

                if(this.filters.display_id != null && !alert.display_id.toLocaleLowerCase().includes(this.filters.display_id.toLocaleLowerCase())){
                    return;
                }

                if(this.filters.identification_number != null &&
                    !alert.identification_number.toLocaleLowerCase().includes(this.filters.identification_number.toLocaleLowerCase())){
                    return;
                }

                if(this.filters.hour_meter != null && !this.isEmpty(this.roundNumber(alert.hour_meter)).includes(this.filters.hour_meter.toString())){
                    return;
                }

                if(this.filters.trade_name != null && !this.getClientDisplayName(alert).toLocaleLowerCase().includes(this.filters.trade_name.toLocaleLowerCase())){
                    return;
                }

                if(this.filters.code != null && !this.formatCode(alert.code, alert.severity).toLocaleLowerCase().includes(this.filters.code.toLocaleLowerCase())){
                    return;
                }

                if(this.filters.count != null && !alert.count.toString().includes(this.filters.count)){
                    return;
                }

                if(this.filters.assignee != null && !this.isEmpty(alert.assignee).toLocaleLowerCase().includes(this.filters.assignee.toLocaleLowerCase())){
                    return;
                }

                if(this.filters.work_order != null && !this.isEmpty(alert.work_order).toLocaleLowerCase().includes(this.filters.work_order.toLocaleLowerCase())){
                    return;
                }

                newGridData.push(alert)
            })

            this.gridData = newGridData;
        }
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1440;
        },
        setDialogWidth() {
            const MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN = 1440;

            var width = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;

            if (width > MIN_WIDTH_TO_DISPLAY_WHOLE_SCREEN) {
                return {width: 'calc(100vw - 140px)'};
            }
            return {width: '100vw'};
        },
        ...mapGetters([
            'user'
        ]),
        isRealtimeRunning() {
            return this.stompjsClient && this.stompjsClient.state === this.stompActivationState.ACTIVE;
        },
        dashboardButtonIcon() {
            return this.showDashboard ? 'pi pi-align-justify' : 'pi pi-chart-line';
        }
    },
    watch: {
        selectedOrgs: function (val) {
            this.frenteCorteList = this.getFrenteDeCorteFromCustomers(val);
            this.storeSelectedOptions();
        },
        frenteCorteListSelected: function (val) {
            this.storeSelectedOptions();
            this.vehiclesList = this.getVehiclesFromCustomers();
            this.saveFrentesSelected(val);
        },
        frenteCorteList: function (val) {
            this.updateFrenteCorteListSelected(val);
        },
        vehiclesList: function (val) {
            this.updateSelectedVehicles(val);
        },
        selectedVehicles: function (val) {
            this.storeSelectedOptions();
        },
        gridData: function (val) {
            this.prepareCardData(val);
        },
        filters: {
            deep: true,
            handler() {
                this.filterData();
                this.prepareCardData(this.gridData);
                this.storeSelectedOptions();
            }
        }
    },
    beforeDestroy() {
        this.stopRealtimeBeforeLeavePage();
        this.disconnectWebSocket();
        if (this.refreshConnectionInterval) {
            clearInterval(this.refreshConnectionInterval);
            this.refreshConnectionInterval = null;
        }
    },
    components: {
        AppButton, AppInput, DataTable, Column, InputText, Dropdown,
        ProgressSpinner, Button, AppAlertsDialogAlertDetails, AppAlertsDialogVehicleDetails, AppAlertsAlertEdit,
        AppDateTimeControlBar, AppVehiclesSelectVehicles, MultiSelect, AppAlertsDialog, AppAlertsCardVehicle,
        AppAlertsDashboard, Dialog
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/alerts/style";


.kt-portlet {
    margin-bottom: 0 !important;
}

.width-auto {
    width: auto !important;
}

.auto-center {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

#table-multiselect .p-multiselect-label-container {
    display: none !important;
}

#table-multiselect .p-multiselect-panel {
    width: 200px !important;
}

.chip-multiselect {
    display:inline-block;
    border-radius: 15px 15px 15px 15px;
    margin-right: 4px;
    background-color: #dee2e6;
    padding: 3px 8px;
    justify-content:center;
    align-items:center;
}

.chip-multiselect:hover {
    cursor: default;
}

.chip-icon:hover {
    cursor: pointer;
}

@media (min-width: 1025px){
    .data-table-width{
        max-width: calc(100vw - 140px);
    }
}

@media (max-width: 1024px){
    .data-table-width{
        width: 100vw;
    }
}

</style>
