export const ActivityCodes = [
    {id: 0, value: "UNKNOWN", description: "Desconhecido"},
    {id: 1, value: "FERTILIZING", description: "Fertilização"},
    {id: 2, value: "SOWING_AND_PLANTING", description: "Semeadura e Plantio"},
    {id: 3, value: "CROP_PROTECTION", description: "Proteção de Culturas"},
    {id: 4, value: "TILLAGE", description: "Preparo de Solo"},
    {id: 5, value: "BALING", description: "Enfardamento"},
    {id: 6, value: "MOWING", description: "Corte"},
    {id: 7, value: "WRAPPING", description: "Empacotar"},
    {id: 8, value: "HARVESTING", description: "Colheita"},
    {id: 9, value: "FORAGE_HARVESTING", description: "Colheita de Forragem"},
    {id: 10, value: "TRANSPORT", description: "Transporte"},
    {id: 11, value: "SWATHING", description: "Enleiramento"},
    {id: 12, value: "MANURE_APPLICATION", description: "Aplicação de Esterco"},
    {id: 13, value: "SELF_LOADING_WAGON", description: "Vagão Autocarregável"},
    {id: 14, value: "TEDDING", description: "Espalhamento de Forragem"},
    {id: 15, value: "MEASURING", description: "Medição"},
    {id: 16, value: "IRRIGATION", description: "Irrigação"},
    {id: 17, value: "MIXING", description: "Mistura"},
    {id: 18, value: "MULCHING", description: "Cobertura Morta do Solo"},
]
