<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                </div>
            </div>
            <div class="row mt-5" style="width: 100%">
                <div class="col-lg-6 col-md-12">
                    <app-profile-edit-information></app-profile-edit-information>
                </div>
                <div class="col-lg-6 col-md-12 mt-5 mt-lg-0 mt-xl-0">
                    <app-profile-change-password></app-profile-change-password>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import AppProfileEditInformation from "@/components/views/auth/profile/AppProfileEditInformation.vue";
import AppProfileChangePassword from "@/components/views/auth/profile/AppProfileChangePassword.vue";

export default {
    data() {
        return {
        }
    },
    components: {
        AppProfileChangePassword, AppProfileEditInformation
    },
    methods: {

    },
}
</script>

<style scoped lang="scss">
@import "../../../../assets/styles/constants";

</style>
