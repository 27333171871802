<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile" id="tableDiv">
            <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :filter_button_is_loading="isLoading"
                                :selected-vehicles-prop="selectedVehicles"
                                :organization_selection_limit="1"
                                :show_historical_data="false"
                                :feature="'COSTS'"
                                @selectedVehiclesChanged="selectedVehiclesChanged"
                                ref="filterBar">

                    <template v-slot:slot-two>
                        <app-button v-show='thereIsDataForVehicles' :disabled="loadingReport"
                                    type="secondary"
                                    class="auto-center"
                                    style="margin-left: 15px"
                                    @handleClick="requestPDFReport">
                            Relatório
                        </app-button>
                    </template>
                </app-filter-bar>
            </div>

            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius" style="justify-content: center;">
                <div style="display: flex;">
                    <app-button v-show="showDialogFilters" type="primary" class="auto-center"
                                @handleClick="openMaximizable"
                                :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': isLoading }">
                                <span>
                                    Filtrar
                                </span>
                    </app-button>
                </div>
            </div>
            <Dialog header="Filtrar" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}" :maximizable="true" :modal="true">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :filter_button_is_loading="isLoading"
                                :selected-vehicles-prop="selectedVehicles"
                                :show_historical_data="false"
                                :feature="'COSTS'"
                                :is_col_12="true"
                                @selectedVehiclesChanged="selectedVehiclesChanged"
                                ref="filterBar">

                    <template v-slot:slot-two>
                        <app-button v-show='thereIsDataForVehicles' :disabled="loadingReport"
                                    type="secondary"
                                    class="auto-center"
                                    style="margin-top: 10px !important"
                                    @handleClick="requestPDFReport">
                            Relatório
                        </app-button>
                    </template>
                </app-filter-bar>
            </Dialog>
            <div class="kt-portlet__body no-padding reports" v-show="thereIsDataForVehicles">
                <div class="row no-gutters wraper text-center">
                    <div class="col-12 mt-2 mb-1">
                        <h2 class="title-font">Análise de Custo Operacional do Maquinário</h2>
                        <div class="subtitle">
                            {{ selectedDatesToShow }}
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-lg-4 col-sm-12 pt-3 pt-lg-0 mb-4 pl-4 pr-4 pr-lg-0" style="height: 100%;">
                        <div class="shadow p-3 rounded-lg pt-lg-4" style="background-color: white;">
                            <h4 class="center" style="margin-top: 10px;">Combustível (Litros)</h4>
                            <app-costs-report-chart-pie style="height:310px; margin-bottom: 20px; padding-bottom: 20px; border-bottom: 0.1px gray solid;" :chartData="gasto_combustivel_series" :tooltipSuffix="'litros'" />
                            <h4 class="center">Tempo (Horas)</h4>
                            <app-costs-report-chart-pie style="height:310px; margin-bottom: 7px;" :chartData="tempo_series" :tooltipSuffix="'horas'"/>
                        </div>

                    </div>

                    <div class="col-lg-8 col-sm-12 pr-4 pb-2 pl-4" style="height: 100%;">
                        <div class="shadow rounded-lg p-3" style="background-color: white; align-items: center;">
                            <div class="row no-gutters mt-lg-3">
                                <div class="col-lg-6 col-6 center pl-4 pr-2" v-tooltip=tooltipEnum.totalCosts>
                                    <div class="dashboard-card center"  style="background-color: #d62727; width: 100%;">
                                        <div class="row no-gutters full-width">
                                            <div class="col-12 card-text">
                                                <div class="card-title">
                                                    Custos Totais
                                                </div>
                                                <div class="card-value">
                                                    <p style="display: inline;">R$</p>
                                                    {{getDisplayValue(totalCosts)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-6 center pl-2 pr-4" v-tooltip.left=tooltipEnum.potentialSavings>
                                    <div class="dashboard-card center"  style="background-color: #2ca02c; width: 100%;">
                                        <div class="row no-gutters full-width">
                                            <div class="col-12 card-text">
                                                <div class="card-title">
                                                    Economia Potencial
                                                </div>
                                                <div class="card-value">
                                                    <p style="display: inline;">R$</p>
                                                    {{getDisplayValue(potentialSavings)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters mt-4">
                                <div class="col-lg-6 col-6">
                                    <div>
                                        <div class="center">Preço Diesel (R$)</div>
                                        <div class="center">
                                            <InputNumber v-model="dieselPrice"
                                                        :min="0" mode="currency" currency="BRL"
                                                        :max="100" :step="0.01" v-tooltip=tooltipEnum.dieselPrice />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-6">
                                    <div>
                                        <div class="center">Mão de Obra (R$)/hora</div>
                                        <div class="center">
                                            <InputNumber v-model="laborPrice" decrementButtonIcon="pi pi-minus"
                                                        :min="0" :max="1000" :step="0.01"
                                                        mode="currency" currency="BRL"
                                                        v-tooltip=tooltipEnum.laborPrice />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row no-gutters mt-2 mb-4 mb-lg-2">
                                <div class="col-lg-6 col-6">
                                    <div>
                                        <div class="center">Juros (%)/ano</div>
                                        <div class="center">
                                            <InputNumber v-model="interest" :min="0"
                                                        :max="100" :step="0.01"
                                                        :maxFracionDigits="2" :minFractionDigits="2" mode="decimal"
                                                        v-tooltip=tooltipEnum.interest />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-6">
                                    <div>
                                        <div class="center">Manutenção (R$)/hora</div>
                                        <div class="center">
                                            <InputNumber v-model="maintenancePrice" :min="0"
                                                        mode="currency" currency="BRL"
                                                        :max="100" :step="0.01" v-tooltip=tooltipEnum.maintenancePrice />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-costs-column-chart class="shadow mt-4" :chart_data="columnChartData"/>
                    </div>

                    <div class="col-12">
                        <div class="data-table-width pl-4 pr-4 pb-2 pb-lg-3">
                            <DataTable :value="tableCosts" style="text-align: center;" dataKey="field_id"
                                    class="p-datatable-striped text-size mt-3 shadow" responsiveLayout="scroll">
                                <template #header>
                                <div class="table-header">
                                    Tabela de Custos <i class="pi pi-arrow-right table-icon"></i>
                                </div>
                            </template>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass"
                                    field="operation" :sortable="true" header="Operação">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.operation >
                                        {{slotProps.data.operation}}
                                    </div>
                                </template>
                            </Column>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass"
                                    field="time_total" :sortable="true" header="Tempo (h)">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.timeTotal >
                                        {{getDisplayValue(slotProps.data.time_total)}}
                                    </div>
                                </template>
                            </Column>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass"
                                    field="time_percentage" :sortable="true" header="Outros (R$)">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.others >
                                        {{getCurrentModeDisplayValue(slotProps.data.fixed_costs)}}
                                    </div>
                                </template>
                            </Column>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass" field="labor_cost"
                                    :sortable="true" header="Mão de Obra (R$)">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.laborCost >
                                        {{getCurrentModeDisplayValue(slotProps.data.labor_cost)}}
                                    </div>
                                </template>
                            </Column>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass" field="maintenance_cost"
                                    :sortable="true" header="Manutenção (R$)">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.maintenanceCost >
                                        {{getCurrentModeDisplayValue(slotProps.data.maintenance_cost)}}
                                    </div>
                                </template>
                            </Column>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass" field="fuel_cost"
                                    :sortable="true" header="Combustível (R$)">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.fuelCost >
                                        {{getCurrentModeDisplayValue(slotProps.data.fuel_cost)}}
                                    </div>
                                </template>
                            </Column>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass" field="loss"
                                    :sortable="true" header="Custos (R$)">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.loss >
                                        {{getCurrentModeDisplayValue(slotProps.data.loss)}}
                                    </div>
                                </template>
                            </Column>
                            <Column class="column-width" headerClass="headerClass" bodyClass="bodyClass" field="potential_savings"
                                    :sortable="true" header="Economia Potencial (R$)">
                                <template #body="slotProps">
                                    <div v-tooltip=tooltipEnum.potentialSavings >
                                        {{getCurrentModeDisplayValue(slotProps.data.potential_savings)}}
                                    </div>
                                </template>
                            </Column>
                            <ColumnGroup type="footer">
                                <Row>
                                    <Column footerClass="bodyClass" :colspan="5"/>
                                    <Column footerClass="bodyClass" footer="Total:"/>
                                    <Column footerClass="bodyClass" :footer="getCurrentModeDisplayValue(totalCosts)"/>
                                    <Column footerClass="bodyClass" :footer="getCurrentModeDisplayValue(potentialSavings)"/>
                                </Row>
                            </ColumnGroup>
                        </DataTable>
                        </div>
                    </div>
                </div>

                <div v-for="vehicleStatistics in allVehiclesStatistics" :value=vehicleStatistics>
                    <app-costs-vehicle-card :vehicle_statistics="vehicleStatistics" :labor_price="laborPrice"
                                            :diesel_price="dieselPrice" :interest="interest"
                                            :maintenance_price="maintenancePrice"
                                            :number_of_days_filtered="numberOfDaysFiltered"
                                            :chassis="vehicleStatistics.chassis"
                                            :field_and_value_to_apply_same_config="fieldAndValueToApplySameConfig"
                                            @applySameConfiguration="applySameConfiguration"
                                            @potentialSavingsChanged="potentialSavingsChanged"
                                            @costsChanged="costsChanged"/>
                </div>
            </div>
            <div class="kt-portlet__body no-padding reports" v-show="!thereIsDataForVehicles">
                <div class="row no-gutters wraper text-center">
                    <div class="col-12">
                        <h3 v-show="isLoading">Gerando relatório aguarde...</h3>
                        <h3 v-show="!isLoading">Selecione os veículos e data de inicio e fim para gerar o
                            relatório</h3>
                    </div>
                </div>
            </div>
            <app-costs-report :generate_report="generateReport"
                              :table_costs="tableCosts" :tempo_series="tempo_series"
                              :gasto_combustivel_series="gasto_combustivel_series"
                              :total_costs="totalCosts"
                              :potential_savings="potentialSavings" :diesel_price="dieselPrice"
                              :labor_price="laborPrice" :interest="interest" :maintenance_price="maintenancePrice"
                              :selected_vehicles="selectedVehicles" :selected_dates_to_show="selectedDatesToShow"
                              :column_chart_data="columnChartData"
                              @reportHasBeenDownloaded="reportHasBeenDownloaded"/>

        </div>
    </div>
</template>

<script>

import AppDateTimeControlBar from "../../common/AppDateTimeControlBar";

import MultiSelect from 'primevue/multiselect';
import ReportService from "../../../services/ReportsService";

import AppFilterBar from "@/components/common/AppFilterBar";
import AppButton from "@/components/common/AppButton";
import InputNumber from 'primevue/inputnumber';
import moment from 'moment';
import axios from "../../../axios/axios-auth";
import maskMacAddress from "../../../mixins/StringsMaskMixin";
import isMacAddress from "../../../mixins/StringsMixins"
import dateFormatWithoutYear from "../../../mixins/DateMixin"
import orderVehiclesByNaturalOrder from "../../../mixins/ArrayMixin";
import getEventsFromMultipleVehicles from "../../../mixins/ReportsMixin";
import Tooltip from 'primevue/tooltip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import AppCostsVehicleCard from "./AppCostsVehicleCard";
import { tooltipTexts } from './AppCostsTooltipTextsEnum'
import AppCostsReport from "./AppCostsReport";
import AppCostsColumnChart from "./AppCostsColumnChart";
import AppCostsReportChartPie from "@/components/views/costs/AppCostsReportChartPie";
import Dialog from 'primevue/dialog';

export default {
mixins: [maskMacAddress, isMacAddress, orderVehiclesByNaturalOrder, getEventsFromMultipleVehicles,
    dateFormatWithoutYear],
mounted() {
    this.reportsService = new ReportService();
    this.showDialogFilters = this.isScreenMobile;
},

data() {
    return {
        showDialogFilters: false,
        localCostsData: null,
        reportsService: null,
        vehiclesService: null,
        datePickerStartDate: moment().startOf('month').toDate(),
        datePickerEndDate: moment().subtract(1, 'day').endOf('day').toDate(),
        disabledDates: [],
        show_historical_data: false,
        displayMaximizable: false,
        selectedVehicles: [],
        vehicles: [],
        gasto_combustivel_series: [],
        tempo_series: [],
        isLoading: false,

        //map com a lista de maquinas/datas que foram solicitadas anteriormente
        vehiclesDates: new Map(),

        //array passado para o componente de calendário
        vehiclesDatesDisabled: [],

        endDate: moment().format("YYYY-MM-DD"),
        initialDate: moment("2018-01-01", "YYYY-MM-DD"),

        rangeDates: [],
        dateAlreadySelected: false,

        fixedCosts: 0,
        totalCosts: 0,
        potentialSavings: 0,
        dieselPrice: 4.60,
        laborPrice: 15.00,
        interest: 5.25,
        maintenancePrice: 30.00,
        imgUrl: 'https://static.iotag.com.br/vehicles/vehicle.png',
        tableCosts: [],
        allVehiclesStatistics: [],
        costsArray: [],
        potentialSavingsArray: [],
        vehicleCostsConfiguration: {},
        allVehiclesStatisticsSummedUp: [],
        thereIsDataForVehicles: false,
        selectedDatesToShow: '',
        numberOfDaysFiltered: 0,
        tooltipEnum: tooltipTexts,
        generateReport: false,
        loadingReport: false,
        columnChartData: {},
        fieldAndValueToApplySameConfig: {}
    }
},
methods: {
    openMaximizable() {
        this.displayMaximizable = true;
    },
    requestPDFReport() {
        this.loadingReport = true;
        this.generateReport = !this.generateReport;
        if(this.isScreenMobile){
            this.displayMaximizable = false;
        }
    },
    reportHasBeenDownloaded() {
        this.loadingReport = false;
    },
    formatValue(value) {
        if (this.isMacAddress(value)) {
            return this.maskMacAddress(value);
        } else {
            return value;
        }
    },
    setupRangeDates() {
        while (this.initialDate.isBefore(this.endDate, 'day')) {
            this.rangeDates.push(moment(this.initialDate).format('YYYY-MM-DD'));
            this.initialDate = this.initialDate.add(1, 'days');
        }
    },
    changeVehicles(vehicles) {
        // limpou a seleção
        if (vehicles.length === 0) {
            this.vehiclesDatesDisabled = [];
        }

        let found = false;

        //primeiro vamos ver se já temos essas datas
        for (let i = 0; i < vehicles.length; i++) {
            if (this.vehiclesDates.has(vehicles[i].id)) {
                found = true
            } else {
                this.getDates(vehicles[i].id);
            }
        }
        if (found) {
            this.updateDatesDisabled();
        }
    },
    updateDatesDisabled() {

        let datesSet = new Set();
        let vm = this;

        this.selectedVehicles.forEach(function (vehicle) {
            let dates = vm.vehiclesDates.get(vehicle.id)
            if (dates) {
                dates.forEach(function (date) {
                    datesSet.add(date);
                })
            }
        });

        let datesList = Array.from(datesSet);
        datesList = this.sort(datesList);

        let disabledDates = this.rangeDates.slice();

        //se a data veio no request remove ela das datas desabilitadas
        datesList.forEach(function (data) {
            let index = disabledDates.indexOf(moment(data).format("YYYY-MM-DD"));
            if (index > -1) {
                disabledDates.splice(index, 1);
            }
        });

        //formata as datas cfme o componente pede
        let temp_dates = [];
        disabledDates.forEach(function (data) {
            temp_dates.push(moment(data, "YYYY-MM-DD")._d)
        })
        if (datesList && datesList.length > 0 && !this.dateAlreadySelected && !this.localCostsData) {
            this.datePickerStartDate = moment(datesList[0], "YYYY-MM-DD").startOf('day').toDate();
            this.datePickerEndDate = moment(datesList[0], "YYYY-MM-DD").endOf('day').toDate();
        }
        this.vehiclesDatesDisabled = temp_dates;
    },

    sort(array) {
        var len = array.length;
        if (len < 2) {
            return array;
        }
        var pivot = Math.ceil(len / 2);
        return this.merge(this.sort(array.slice(0, pivot)), this.sort(array.slice(pivot)));
    },
    merge(left, right) {
        var result = [];
        while ((left.length > 0) && (right.length > 0)) {
            if (left[0] > right[0]) {
                result.push(left.shift());
            } else {
                result.push(right.shift());
            }
        }
        result = result.concat(left, right);
        return result;
    },

    getDates(identification) {

        let vm = this;
        if (identification) {
            axios.get('/api/v1/vehicles/' + identification + '/track/datesById')
                    .then(function (response) {
                        if (response.data && response.data.dates) {
                            vm.vehiclesDates.set(response.data.vehicle_id, vm.removeInvalidDates(response.data.dates));
                            vm.updateDatesDisabled();
                        }
                    }).catch(function (error) {
                console.log(error)
            })
        }
    },

    removeInvalidDates(dates) {
        let endDate = moment().format("YYYY-MM-DD");
        let initialDate = moment("2018-01-01", "YYYY-MM-DD");

        let validDates = [];

        //se alguma data for antes de initialDate e depois de endDate remove elas
        for (let i = 0; i < dates.length; i++) {
            if (moment(dates[i], "YYYY-MM-DD").isSameOrAfter(initialDate, 'year') && moment(dates[i], "YYYY-MM-DD").isSameOrBefore(endDate, 'year')) {
                validDates.push(dates[i]);
            }
        }
        return validDates;
    },
    validateDataPreRequest(values) {
        if (!this.selectedVehicles.length > 0) {
            this.$toast.add({
                severity: 'error',
                summary: 'Selecione o veículo',
                detail: 'Você deve selecionar pelo menos um veículo',
                life: 5000
            });
        } else {
            this.displayMaximizable = false;
            this.localCostsData = {vehicles:this.selectedVehicles, date_filter:values};
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_COSTS_FILTER, JSON.stringify(this.localCostsData));
            this.requestReport(values);
        }
    },
    treatEventsResponse(results) {
        let allVehiclesStatisticsTemp = [];
        let allStatistics = {
            engine_on_time: 0,
            work_time: 0,
            idle_time: 0,
            maneuver_time: 0,
            travel_time: 0,
            total_fuel: 0,
            work_fuel: 0,
            idle_fuel: 0,
            maneuver_fuel: 0,
            travel_fuel: 0,
            maintenance_time: 0,
            chassis: '',
            display_id: '',
            vehicle_id: ''
        };

        results.forEach(result => {
            let eachVehicleStatistics = {
                engine_on_time: 0,
                work_time: 0,
                idle_time: 0,
                maneuver_time: 0,
                travel_time: 0,
                total_fuel: 0,
                work_fuel: 0,
                idle_fuel: 0,
                maneuver_fuel: 0,
                travel_fuel: 0,
                maintenance_time: 0,
            };

            if (result.length === 0) {
                return;
            }
            eachVehicleStatistics.vehicle = this.selectedVehicles.find(obj => {return obj.identification_number  === result[0].chassis});

            result.forEach(event => {
                eachVehicleStatistics.engine_on_time += (event.time/3600);
                eachVehicleStatistics.work_time += event.mode === 1 ? (event.time/3600) : 0;
                eachVehicleStatistics.idle_time += event.mode === 2 ? (event.time/3600) : 0;
                eachVehicleStatistics.maneuver_time += event.mode === 3 ? (event.time/3600) : 0;
                eachVehicleStatistics.travel_time += event.mode === 4 ? (event.time/3600) : 0;
                eachVehicleStatistics.total_fuel += event.consumption;
                eachVehicleStatistics.work_fuel += event.mode === 1 ? event.consumption : 0;
                eachVehicleStatistics.idle_fuel += event.mode === 2 ? event.consumption : 0;
                eachVehicleStatistics.maneuver_fuel += event.mode === 3 ? event.consumption : 0;
                eachVehicleStatistics.travel_fuel += event.mode === 4 ? event.consumption : 0;
                eachVehicleStatistics.maintenance_time += event.mode === 8 ? (event.time/3600) : 0;
                eachVehicleStatistics.chassis = event.chassis;
                eachVehicleStatistics.vehicle_id = event.vehicle_id;

                allStatistics.engine_on_time += (event.time/3600);
                allStatistics.work_time += event.mode === 1 ? (event.time/3600) : 0;
                allStatistics.idle_time += event.mode === 2 ? (event.time/3600) : 0;
                allStatistics.maneuver_time += event.mode === 3 ? (event.time/3600) : 0;
                allStatistics.travel_time += event.mode === 4 ? (event.time/3600) : 0;
                allStatistics.total_fuel += event.consumption;
                allStatistics.work_fuel += event.mode === 1 ? event.consumption : 0;
                allStatistics.idle_fuel += event.mode === 2 ? event.consumption : 0;
                allStatistics.maneuver_fuel += event.mode === 3 ? event.consumption : 0;
                allStatistics.travel_fuel += event.mode === 4 ? event.consumption : 0;
                allStatistics.maintenance_time += event.mode === 8 ? (event.time/3600) : 0;
            });
            allVehiclesStatisticsTemp.push(eachVehicleStatistics);
        })
        this.allVehiclesStatistics = allVehiclesStatisticsTemp;
        this.allVehiclesStatisticsSummedUp = allStatistics;

        this.populateChartsData(allStatistics);
        this.populateTableData(allStatistics);
    },
    buildSelectedDateString(data) {
        let dateToShowString;
        let startDateFormatted = this.dateFormatWithoutYear(data.start_date.valueOf()/1000);
        let endDateFormatted = this.dateFormatWithoutYear(data.end_date.valueOf()/1000);

        if (startDateFormatted !== endDateFormatted) {
            dateToShowString = '* ' + startDateFormatted + ' a ' + endDateFormatted
        } else {
            dateToShowString = '* ' + startDateFormatted;
        }

        this.selectedDatesToShow = dateToShowString;
    },
    clearStatistics() {
        this.allVehiclesStatistics = [];
        this.tableCosts = [];
        this.costsArray = [];
        this.potentialSavingsArray = [];
        this.fixedCosts = 0;
        this.totalCosts = 0;
        this.potentialSavings = 0;
        this.allVehiclesStatisticsSummedUp = [];
        this.thereIsDataForVehicles = false;
    },
    getDaysDiff(data) {
        let start = moment(data.start_date);
        let end = moment(data.end_date);
        return end.diff(start, "days") + 1;
    },
    requestReport(data) {
        const promises = [];
        let vm = this;
        this.isLoading = true;
        this.clearStatistics();

        promises.push(this.getEventsFromMultipleVehicles(data, this.selectedVehicles));
        Promise.all(promises)
                .then((results) => {
                    if (results.length > 0) {
                        vm.numberOfDaysFiltered = vm.getDaysDiff(data);
                        vm.treatEventsResponse(results[0]);
                        vm.thereIsDataForVehicles = true;
                        vm.buildSelectedDateString(data);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        vm.$toast.add({
                            severity: 'warn',
                            summary: 'Análise não realizada',
                            detail: 'Não foi possível realizar a análise no período informado',
                            life: 5000
                        });
                    } else {
                        vm.$toast.add({
                            severity: 'error',
                            summary: 'Operação falhou',
                            detail: 'Não foi possivel realizar a operação',
                            life: 5000
                        });
                    }
                }).finally(() => {
            vm.isLoading = false;
        });
    },
    populateTableData(allStatistics) {
        let tableData = [];

        let totalTime = allStatistics.engine_on_time;
        let totalFuel = allStatistics.total_fuel;
        tableData.push({
            operation: 'Manobrando',
            time_total: allStatistics.maneuver_time,
            fixed_costs: this.getModeFixedCosts(allStatistics.maneuver_time, totalTime),
            potential_savings: this.getModePotentialSavings(allStatistics.maneuver_time, totalTime),
            time_percentage: this.validatePercentage(allStatistics.maneuver_time, totalTime),
            labor_cost: allStatistics.maneuver_time * this.laborPrice,
            maintenance_cost: 0,
            fuel_percentage: this.validatePercentage(allStatistics.maneuver_fuel, totalFuel),
            fuel_cost: allStatistics.maneuver_fuel * this.dieselPrice,
            loss: allStatistics.maneuver_time * this.laborPrice + allStatistics.maneuver_fuel * this.dieselPrice
                    + this.getModeFixedCosts(allStatistics.maneuver_time, totalTime)
        });
        tableData.push({
            operation: 'Deslocando',
            fixed_costs: this.getModeFixedCosts(allStatistics.travel_time, totalTime),
            potential_savings: this.getModePotentialSavings(allStatistics.travel_time, totalTime),
            time_percentage: this.validatePercentage(allStatistics.travel_time, totalTime),
            time_total: allStatistics.travel_time,
            labor_cost: allStatistics.travel_time * this.laborPrice,
            maintenance_cost: 0,
            fuel_percentage: this.validatePercentage(allStatistics.maneuver_fuel, totalFuel),
            fuel_cost: allStatistics.travel_fuel * this.dieselPrice,
            loss: allStatistics.travel_time * this.laborPrice + allStatistics.travel_fuel * this.dieselPrice +
                    this.getModeFixedCosts(allStatistics.travel_time, totalTime),
        });
        tableData.push({
            operation: 'Ocioso',
            fixed_costs: this.getModeFixedCosts(allStatistics.idle_time, totalTime),
            potential_savings: this.getModePotentialSavings(allStatistics.idle_time, totalTime),
            time_percentage: this.validatePercentage(allStatistics.idle_time, totalTime),
            time_total: allStatistics.idle_time,
            labor_cost: allStatistics.idle_time * this.laborPrice,
            maintenance_cost: allStatistics.maintenance_time * this.maintenancePrice,
            fuel_percentage: this.validatePercentage(allStatistics.maneuver_fuel, totalFuel),
            fuel_cost: allStatistics.idle_fuel * this.dieselPrice,
            loss: allStatistics.idle_time * this.laborPrice + allStatistics.idle_fuel * this.dieselPrice +
                    allStatistics.maintenance_time * this.maintenancePrice +
                    this.getModeFixedCosts(allStatistics.idle_time, totalTime),
        });
        tableData.push({
            operation: 'Produzindo',
            fixed_costs: this.getModeFixedCosts(allStatistics.work_time, totalTime),
            potential_savings: this.getModePotentialSavings(allStatistics.work_time, totalTime),
            time_percentage: this.validatePercentage(allStatistics.work_time, totalTime),
            time_total: allStatistics.work_time,
            labor_cost: allStatistics.work_time * this.laborPrice,
            maintenance_cost: 0,
            fuel_percentage: this.validatePercentage(allStatistics.maneuver_fuel, totalFuel),
            fuel_cost: allStatistics.work_fuel * this.dieselPrice,
            loss: allStatistics.work_time * this.laborPrice + allStatistics.work_fuel * this.dieselPrice +
                    this.getModeFixedCosts(allStatistics.work_time, totalTime),
        });

        this.tableCosts = tableData;
        this.totalCosts = tableData[0].loss + tableData[1].loss + tableData[2].loss + tableData[3].loss;
    },
    getModeFixedCosts(mode, total) {
        if (total === 0) {
            return 0;
        }

        return mode/total * this.fixedCosts;
    },
    getModePotentialSavings(mode, total) {
        if (total === 0) {
            return 0;
        }

        return mode/total * this.potentialSavings;
    },
    populateChartsData(allStatistics) {
        let gasto_combustivel_total = 0;
        let gasto_combustivel_trabalho = 0;
        let gasto_combustivel_maquina_parada = 0;
        let gasto_combustivel_manobra = 0;
        let gasto_combustivel_estrada = 0;

        let tempo_motor_ligado = 0;
        let tempo_trabalho = 0;
        let tempo_maquina_parada = 0;
        let tempo_manobra = 0;
        let tempo_deslocamento = 0;

        tempo_motor_ligado += allStatistics.engine_on_time;
        tempo_trabalho += allStatistics.work_time;
        tempo_maquina_parada += allStatistics.idle_time;
        tempo_manobra += allStatistics.maneuver_time;
        tempo_deslocamento += allStatistics.travel_time

        gasto_combustivel_total += allStatistics.total_fuel;
        gasto_combustivel_trabalho += allStatistics.work_fuel;
        gasto_combustivel_maquina_parada += allStatistics.idle_fuel;
        gasto_combustivel_manobra += allStatistics.maneuver_fuel;
        gasto_combustivel_estrada += allStatistics.travel_fuel;

        let gasto_combustivel_trabalho_chart = Math.round((gasto_combustivel_trabalho / gasto_combustivel_total) * 100);
        let gasto_combustivel_maquina_parada_chart = Math.round((gasto_combustivel_maquina_parada / gasto_combustivel_total) * 100);
        let gasto_combustivel_manobra_chart = Math.round((gasto_combustivel_manobra / gasto_combustivel_total) * 100);
        let gasto_combustivel_estrada_chart = Math.round((gasto_combustivel_estrada / gasto_combustivel_total) * 100);

        //combustivel
        this.gasto_combustivel_series =
                [
                    {name: 'Ocioso', value: gasto_combustivel_maquina_parada_chart, itemStyle: {color: '#d62728'}, rawValue: gasto_combustivel_maquina_parada},
                    {name: 'Manobrando', value: gasto_combustivel_manobra_chart, itemStyle: {color: '#ff7e0e'}, rawValue: gasto_combustivel_manobra},
                    {name: 'Produzindo', value: gasto_combustivel_trabalho_chart, itemStyle: {color: '#2ca02c'}, rawValue: gasto_combustivel_trabalho},
                    {name: 'Deslocando', value: gasto_combustivel_estrada_chart, itemStyle: {color: '#1f76b4'}, rawValue: gasto_combustivel_estrada}
                ]

        let tempo_trabalho_chart = Math.round((tempo_trabalho / tempo_motor_ligado) * 100);
        let tempo_maquina_parada_chart = Math.round((tempo_maquina_parada / tempo_motor_ligado) * 100);
        let tempo_manobra_chart = Math.round((tempo_manobra / tempo_motor_ligado) * 100);
        let tempo_deslocamento_chart = Math.round((tempo_deslocamento / tempo_motor_ligado) * 100);

        //tempo mensal
        this.tempo_series =
                [
                    {name: 'Produzindo', value: tempo_trabalho_chart, itemStyle: {color: '#2ca02c'}, rawValue: tempo_trabalho},
                    {name: 'Ocioso', value: tempo_maquina_parada_chart, itemStyle: {color: '#d62728'}, rawValue: tempo_maquina_parada},
                    {name: 'Manobrando', value: tempo_manobra_chart, itemStyle: {color: '#ff7e0e'}, rawValue: tempo_manobra},
                    {name: 'Deslocando', value: tempo_deslocamento_chart, itemStyle: {color: '#1f76b4'}, rawValue: tempo_deslocamento}
                ]
    },
    validatePercentage(mode, total) {
        if (total === 0) {
            return 0;
        }

        return mode/total * 100;
    },
    formartValue(val) {
        try {
            return val.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        } catch (e) {
            return 0;
        }
    },
    dateSelected() {
        this.dateAlreadySelected = true;
    },
    potentialSavingsChanged(val) {
        let index = this.potentialSavingsArray.findIndex(i => i.chassis === val.chassis);
        if (index >= 0) {
            this.potentialSavingsArray[index].potentialSavings = val.potentialSavings;
        } else {
            this.potentialSavingsArray.push({
                chassis: val.chassis,
                potentialSavings: val.potentialSavings
            });
        }
        let tempPotentialSavings = 0;
        this.potentialSavingsArray.forEach(vehiclePotentialSavings => {
            tempPotentialSavings += vehiclePotentialSavings.potentialSavings;
        })
        this.potentialSavings = tempPotentialSavings;
        this.populateTableData(this.allVehiclesStatisticsSummedUp);
        this.populateChart();
    },
    costsChanged(val) {
        let index = this.costsArray.findIndex(i => i.chassis === val.chassis);
        if (index >= 0) {
            this.costsArray[index].totalFixedCost = val.deprecation + val.insurance + val.interest;
            this.costsArray[index].variableCost = val.variableCost
        } else {
            this.costsArray.push({
                chassis: val.chassis,
                totalFixedCost: val.deprecation + val.insurance + val.interest,
                variableCost: val.variableCost
            });
        }

        let tempFixedCosts = 0;
        this.costsArray.forEach(vehicleFixedCosts => {
            tempFixedCosts += vehicleFixedCosts.totalFixedCost;
        })
        this.fixedCosts = tempFixedCosts;
    },
    getDisplayValue(value) {
        if (!value || isNaN(value) || value < 0) {
            return 0;
        }
        return value.toFixed(2);
    },
    getCurrentModeDisplayValue(value) {
        return 'R$' + this.getDisplayValue(value);
    },
    applySameConfiguration(val) {
        this.fieldAndValueToApplySameConfig = val;
    },
    populateChart() {
        let chartDataTemp = {
            totalCosts: [],
            potentialSavings: [],
            vehicles: []
        };

        this.potentialSavingsArray.forEach(potentialSavingsObj => {
            let fixedCostsObj = this.costsArray.find(obj => {return obj.vehicle_id === potentialSavingsObj.vehicle_id});
            if (!fixedCostsObj) {
                return;
            }
            chartDataTemp.totalCosts.push(fixedCostsObj.totalFixedCost + fixedCostsObj.variableCost);
            chartDataTemp.potentialSavings.push(potentialSavingsObj.potentialSavings);
            chartDataTemp.vehicles.push(this.getDisplayIdFromChassis(potentialSavingsObj.chassis));
        })

        this.columnChartData = chartDataTemp;
    },
    getDisplayIdFromChassis(chassis) {
        let vehicle = this.vehicles.find(obj => {return obj.identification_number === chassis});
        if (!vehicle) {
            return chassis;
        }
        return vehicle.display_id;
    },
    selectedVehiclesChanged(val) {
        this.selectedVehicles = val;
    },
},
computed: {
    vehiclesSorted() {
        return this.orderVehiclesByNaturalOrder(this.vehicles);
    },
    isScreenMobile: function() {
        return window.screen.width <= 1024;
    }
},
watch: {
    selectedVehicles: function (val) {
        this.changeVehicles(val);

    }
},
components: {
    AppDateTimeControlBar, AppCostsReportChartPie, MultiSelect, InputNumber, Tooltip, DataTable,
    Column, AppCostsVehicleCard, AppCostsReport, Row, ColumnGroup, AppCostsColumnChart, Dialog, AppFilterBar, AppButton
},
directives: {
    'tooltip': Tooltip
}
}
</script>

<style scoped lang="scss">

@import "./src/assets/styles/primevue";

.reports {
margin-top: 10px;
}

.center {
display:flex;
justify-content:center;
align-items:center;
}

.subtitle {
color: gray;
font-size: 10px;
}

.dashboard-card {
width: 95%;
height: 100px;
border-radius: 20px;
}

.card-text {
color: white;
}

.card-title {
font-size: 14px;
margin: 0;
padding-left: 10px;
}

.card-value {
font-size: 38px;
padding-left: 10px;
}

.full-width {
width: 100%;
}

.column-width{
width: 100px;
}

.table-scroll{
width: 100%;
overflow-x: hidden;
}

.table-header{
font-size: 18px;
text-align: left;
}

.table-icon{
opacity: 0;
font-size: 20px;
position: relative;
top: 2px;
margin-left: 15px;
}

.data-table-width{
width: 100%;
}

@media (max-width:1024px){
.chart-pie{
    height: 250px;
}

.table-icon{
    opacity: 1;
}

.dashboard-card {
    width: 90%;
    height: 55px;
    border-radius: 10px;
    margin-top: 20px;
}

.select-width{
    width: 110px;
}

.card-title {
    font-size: 14px;
    padding-left: 10px;
}

.card-value{
    font-size: 19px;
}

.container-chart-pie{
    width: 50%;
}

h3{
    font-size: 20px;
}

.column-width{
    width: 50px;
}

.table-scroll{
    overflow-x: scroll;
}

.table-header{
    font-size: 15px;
    text-align: left;
}

.group-filters{
    margin: 0 auto;
    display:flex;
    justify-content:center;
    align-items:center;
}
}

@media (max-width: 390px){
.text-width{
    font-size: 17px;
}

.dashboard-card {
    width: 90%;
    height: 70px;
    align-items: center;
}

.card-title {
    font-size: 12px;
    padding-left: 10px;
}

.card-value{
    font-size: 15px;
}

.text-size{
    font-size: 12px;
}

.title-font{
    font-size: 20px;
}
}

@media (min-width: 1025px){
.data-table-width{
    max-width: calc(100vw - 140px);
}
}

@media (max-width: 1024px){
.data-table-width{
    width: 100vw;
}
}
</style>
