<template>
    <div style="height: 100%; position: relative">
        <div :id="google_maps_tasks_id" style="height: 100%"></div>
        <ProgressSpinner style="position: absolute;top: 40%; left: 45%;z-index: 9999" v-show="loading"/>
    </div>
</template>

<script>

import gmapsInit from '../../../../utils/gmaps';
import {mapGetters} from 'vuex';
import jstsWithHolesToGoogleMaps from "@/mixins/GeometryMixin";
import jstsWithoutHolesToGoogleMaps from "@/mixins/GeometryMixin";
import {PolygonLayer} from '@deck.gl/layers';
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps';
import * as jsts from 'jsts';
import dateHourFormat from "@/mixins/DateMixin";
import ProgressSpinner from "primevue/progressspinner";

import TasksService from "../../../../services/TasksService";


export default {
    mixins: [dateHourFormat, jstsWithHolesToGoogleMaps, jstsWithoutHolesToGoogleMaps],
    beforeMount() {
        this.tasksService = new TasksService();
    },
    async mounted() {
        let mapType = 'hybrid';

        if (this.isUserDemo) {
            mapType = 'satellite'
        }

        let vm = this;
        try {
            const google = await gmapsInit();
            vm.google_maps_reference = google;
            vm.infoWindow = new google.maps.InfoWindow();
            let mapElement = document.getElementById(vm.google_maps_tasks_id);
            this.map = new google.maps.Map(mapElement, {
                minZoom: 4,
                zoom: 16,
                center: vm.position_center,
                pan: true,
                mapTypeId: mapType,
                rotateControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                tilt: 0,
                disableDefaultUI: true
            });

            this.heatmap = new this.google_maps_reference.maps.visualization.HeatmapLayer({
                map: vm.map,
            });

            this.overlay = new DeckOverlay({
                getTooltip: ({object}) => this.createTooltip(object),
            });
            this.overlay.setMap(this.map);

            this.drawSelectedField()

            this.map.addListener('click', event => {

                let infoWindowField = new google.maps.InfoWindow();

                if(!this.overlay || !event.pixel){
                    return;
                }

                const deckPicked = this.overlay._deck.pickObject({
                    x: event.pixel.x,
                    y: event.pixel.y,
                    radius: 4
                });

                if (!deckPicked) {
                    infoWindowField.close();
                    return;
                }

                if (vm.getInfoWindowByIndex(deckPicked.index) >= 0) {
                    return;
                }

                this.tasksLoading = true;
                let selected_org = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS)).selectedOrg;
                this.getTasksByField(deckPicked, infoWindowField, selected_org);

                infoWindowField.setContent("<b>Carregando...</b>");
                infoWindowField.setPosition({lng: event.latLng.lng(), lat: event.latLng.lat()});
                infoWindowField.open(vm.map);
                infoWindowField.index = deckPicked.index;

                vm.fieldInfoWindows.push(infoWindowField);

                this.google_maps_reference.maps.event.addListener(infoWindowField, 'closeclick', function () {
                    vm.handleCloseClickInfoWindow(this);
                });

            });

            mapElement.addEventListener('wheel', vm.wheelEvent, true);

            vm.map.addListener('zoom_changed', () => vm.handleZoomChanged());
            vm.map.addListener('dragend', () => vm.handleBoundsChanged());
            vm.handleBoundsChanged();

        } catch (error) {
            console.error(error);
        }
    },
    beforeUpdate(){
        this.handleBoundsChanged();
    },
    data() {
        return {
            map: null,
            heatMap: {},
            google_maps_reference: {},
            INDEX_OVERLAY_WEATHER: 1,
            INDEX_OVERLAY_TILES_FIELDS: 2,
            overlay: null,
            layers: [],
            fieldInfoWindows: [],
            tasksService: null,
            tasksLoading: false,
        }
    },
    props: {
        //posição no mapa, ex: {lat: -14.916356, lng: -53.516697}
        position_center: {
            required: true,
        },
        google_maps_tasks_id: {
            type: String
        },
        task: {
            type: Object
        },
        selected_field: {
            type: Object
        },
        rate_steps: {
            type: Array
        },
        opacity: {
            type: Number,
            default: 100
        },
        stroke: {
            type: Number,
            default: 1
        },
        works: {
            type: Array
        },
        is_map_type_overlap: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
    },

    methods: {
        drawSelectedField() {
            let vm = this;

            if (!this.selected_field) {
                return;
            }

            let reader = new jsts.io.WKTReader();
            let jstsGeom = reader.read(this.selected_field.coordinates);

            let newPolygon = new this.google_maps_reference.maps.Polygon({
                paths: this.jstsWithHolesToGoogleMaps(jstsGeom, this.google_maps_reference.maps),
                strokeColor: '#000000',
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: '#8f8f8f',
                fillOpacity: 0.1,
                editable: false,
                clickable: false
            });

            newPolygon.setMap(vm.map);
            newPolygon.setOptions({zIndex: 0});
        },
        createTooltip(obj) {
            if (!obj) {
                return;
            }
            if(obj.type){
                return {
                    text: obj.type === 'OVERLAP' ? 'Área: ' + obj.area + ' ha \n' :
                        'Área: ' + obj.area + ' ha \n' + 'Valor: ' + obj.rate + '\n' + 'Início: ' + obj.start +
                        '\n Fim: ' + obj.end,
                    style: {
                        'z-index': 1,
                        'position': 'absolute',
                        'color': '#a0a7b4',
                        'background-color': '#29323c',
                        'padding': '10px',
                        'width': 'max-content'
                    }
                }
            }
        },
        setupWeatherOverlay(overlay) {

            let vm = this;

            const imageMapType = new vm.google_maps_reference.maps.ImageMapType({
                name: 'openweathermap',
                getTileUrl: function (coord, zoom) {
                    return "https://tile.openweathermap.org/map/" + overlay + "/" + zoom + "/" + coord.x +
                        "/" + coord.y + ".png?appid=" + process.env.VUE_APP_OPEN_WEATHER_MAP_KEY
                },
                tileSize: new vm.google_maps_reference.maps.Size(256, 256),
            });
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_WEATHER, imageMapType);
        },
        setUpMapType(type) {
            if (type === 'roadmap') {
                this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.ROADMAP);
            } else {
                this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.HYBRID);
                if (this.map.getZoom() > 18) {
                    this.map.setZoom(18);
                }
            }
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        isPointInsidePolygon(lat, lng, polygon) {
            let coordinate = new this.google_maps_reference.maps.LatLng(lat, lng);//replace with your lat and lng values
            return this.google_maps_reference.maps.geometry.poly.containsLocation(coordinate, polygon);
        },
        makeLatLngToGoogleMaps(lat, lng) {
            return {lat: parseFloat(lat), lng: parseFloat(lng)}
        },
        async setupWorks(val) { //TODO trocar nome

            if (!val || !this.rate_steps || this.rate_steps.length === 0) {
                this.layers = [];
                this.overlay.setProps({layers: Object.values(this.layers)});
                return;
            }

            if (!this.overlay) {
                this.layers = [];
                return;
            }

            let polygonCoordinates = [];
            let reader = new jsts.io.WKTReader();
            this.works.forEach(work => {
                let eachPolygonTemp = []
                let polygonCoordinatesTemp = [];
                let polygon = reader.read(work.geom);

                if (!polygon || polygon.getArea() === 0) {
                    return;
                }

                polygon._shell.getCoordinates().forEach(coord => {
                    eachPolygonTemp.push([coord.x, coord.y]);
                })
                polygonCoordinatesTemp.push(eachPolygonTemp)

                polygon._holes.forEach(hole => {
                    eachPolygonTemp = [];
                    hole.getCoordinates().forEach(coord => {
                        eachPolygonTemp.push([coord.x, coord.y]);
                    })
                    polygonCoordinatesTemp.push(eachPolygonTemp)
                })

                if (this.getVisible(work.value)) {
                    polygonCoordinates.push({
                        "contour": polygonCoordinatesTemp,
                        "rate": work.value,
                        "area": (work.area * 1000000).toFixed(4),
                        "start": this.dateHourFormat(work.start_date),
                        "end": this.dateHourFormat(work.end_date),
                        "type": work.type
                    })
                }
            })

            this.layers = [];
            this.layers.push(
                new PolygonLayer({
                    id: 'polygon-layer',
                    data: polygonCoordinates,
                    pickable: true,
                    stroked: true,
                    filled: true,
                    wireframe: true,
                    lineWidthMinPixels: 0,
                    lineWidthMaxPixels: this.stroke,
                    getPolygon: d => d.contour,
                    getFillColor: d => this.getColorFromRateSteps(d.rate, d.type),
                    getLineColor: [0, 0, 0],
                    opacity: this.opacity / 100,
                })
            );
            this.overlay.setProps({layers: Object.values(this.layers)});
            this.handleBoundsChanged();
        },
        getVisible(rate) {
            if (this.is_map_type_overlap) {
                return true
            }

            for (let i = 0; i < this.rate_steps.length; i++) {
                if (i === 0 && rate > this.rate_steps[i].value) {
                    return this.rate_steps[i].visible
                }

                if ((i === this.rate_steps.length - 1 ||
                    (this.rate_steps[i].value >= rate && this.rate_steps[i + 1].value < rate))) {
                    return this.rate_steps[i].visible
                }
            }
            return true
        },
        getColorFromRateSteps(rate, type) {
            if (this.is_map_type_overlap && type === 'OVERLAP') {
                return this.hexToRgb('d40000')
            }

            if (this.is_map_type_overlap && type !== 'OVERLAP') {
                return this.hexToRgb('029235')
            }

            for (let i = 0; i < this.rate_steps.length; i++) {
                if (i === 0 && rate > this.rate_steps[i].value) {
                    return this.hexToRgb(this.rate_steps[i].color.split('#')[1])
                }

                if ((i === this.rate_steps.length - 1 ||
                    (this.rate_steps[i].value >= rate && this.rate_steps[i + 1].value < rate))) {
                    return this.hexToRgb(this.rate_steps[i].color.split('#')[1])
                }
            }
        },
        hexToRgb(hex) {
            let bigint = parseInt(hex, 16);
            let r = (bigint >> 16) & 255;
            let g = (bigint >> 8) & 255;
            let b = bigint & 255;

            return [r, g, b];
        },
        handleZoomChanged(){
            let payload = this.buildPayloadBounds();

            if (payload) {
                this.$emit('handleZoomChanged', payload)
            }
        },
        handleBoundsChanged(){
            let payload = this.buildPayloadBounds();

            if (payload) {
                this.$emit('handleBoundsChanged', payload)
            }
        },
        buildPayloadBounds(){
            //TODO validação
            if (!this.map || !this.map.getBounds()) {
                return null;
            }
            return {
                zoom: this.map.getZoom(),
                sw_lat: parseFloat(this.map.getBounds().getSouthWest().lat().toFixed(6)),
                sw_lng: parseFloat(this.map.getBounds().getSouthWest().lng().toFixed(6)),
                ne_lat: parseFloat(this.map.getBounds().getNorthEast().lat().toFixed(6)),
                ne_lng: parseFloat(this.map.getBounds().getNorthEast().lng().toFixed(6))
            }
        },
        buildGeomFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        fieldsToMap(fields){
            let data = [];

            fields.forEach(field => {

                if (field.type === 'UNPRODUCTIVE') {
                    return
                }

                let polygon = this.buildGeomFromCoordinates(field.coordinates);

                let newField = {
                    area: (this.google_maps_reference.maps.geometry.spherical.computeArea(
                        this.jstsWithoutHolesToGoogleMaps(polygon,
                            this.google_maps_reference.maps)[0]) / 10000),
                    name: field.name,
                    id: field.id,
                    color: field.color === undefined ? '0335ad' : field.color
                };
                let eachPolygonTemp = []

                polygon._shell.getCoordinates().forEach(coord => {
                    eachPolygonTemp.push([parseFloat(coord.x), parseFloat(coord.y)]);
                })

                newField['coordinates'] = eachPolygonTemp;

                data.push(newField);
            });

            if (!this.overlay) {
                this.overlay = new DeckOverlay({});
                this.overlay.setMap(this.map);
            }

            this.newData(data);
        },
        newData(data) {
            //Alterar layer para manter campo atual e dar push nos vizinhos
            let current_field = this.layers[0];
            this.layers = [];
            this.layers.push(current_field);
            let filtered_data = data.filter(field => field.id !== this.selected_field.id);

            this.layers.push(
                new PolygonLayer({
                    id: 'fields-layer',
                    data: filtered_data,
                    pickable: true,
                    stroked: true,
                    filled: true,
                    wireframe: true,
                    lineWidthMinPixels: 2,
                    getPolygon: d => d.coordinates,
                    getFillColor: d => this.hexToRgb(d.color),
                    getLineColor: d => this.hexToRgb(d.color),
                    getLineWidth: 0.1,
                    opacity: 0.4
                })
            );
            this.overlay.setProps({layers: Object.values(this.layers)});
        },
        getInfoWindowByIndex(index) {

            if (!this.fieldInfoWindows || this.fieldInfoWindows.length === 0) {
                return -1;
            }

            for (let i = 0; i < this.fieldInfoWindows.length; i++) {
                if (this.fieldInfoWindows[i].index === index) {
                    return i;
                }
            }
            return -1;
        },
        handleCloseClickInfoWindow(InfoWindow) {

            let index = this.getInfoWindowByIndex(InfoWindow.index);

            if (index >= 0) {
                this.fieldInfoWindows.splice(index, 1)
            }
        },
        getTasksByField(deckPicked, infoWindowField, selected_org){
            let data = null;
            this.tasksService.getTasks(deckPicked.object.id)
                .then((response) => {
                    this.tasksLoading = false;
                    let tasks_list = '<ul>';

                    if(response.tasks.length === 1){
                        tasks_list += "<li>"+response.tasks[0].name.toLowerCase()+"</li></ul>";
                    } else if (response.tasks.length >= 1){
                        tasks_list += "<li>"+response.tasks[0].name.toLowerCase()+"</li>";
                        response.tasks.forEach(task => {
                            if (tasks_list.indexOf(task.name.toLowerCase()) === -1){
                                tasks_list += "<li>"+task.name.toLowerCase()+"</li>";
                            }
                        })
                        tasks_list += "</ul>"
                    } else {
                        tasks_list = "sem atividades registradas";
                    }

                    infoWindowField.setContent("<b>Talhão: " + deckPicked.object.name + "</b><br> Área: " + deckPicked.object.area.toFixed(2) + " ha" +
                        "<br>Atividades: " +  tasks_list +
                        "<center><a href='/agriculture/tasks?org_id=" + selected_org.id + "&field_id=" + deckPicked.object.id + "'" +
                        "style='text-decoration: underline !important;'>Analisar talhão</a></center>");
            }).catch((error) => {
                console.log(error);
            })
            return data;
        },
    },
    computed: {
        ...mapGetters([
            'isUserDemo'
        ])
    },
    watch: {
        position_center: {
            handler: function (val) {
                if (val && this.map) {
                    this.map.setCenter({lat: val.lat, lng: val.lng,})
                }
            },
            deep: true
        },
        works: {
            handler: function (val) {
                if (val) {
                    this.setupWorks(this.task)
                }
            },
            deep: true
        },
        rate_steps: {
            handler: function (val) {
                this.setupWorks(this.task)
            },
            deep: true
        },
        opacity: function (val) {
            this.setupWorks(this.task)
        },
        stroke: function (val) {
            this.setupWorks(this.task)
        },
    },
    components: {
        ProgressSpinner
    }
}
</script>

<style>


</style>

<style scoped>

</style>
