// @formatter:off

export const CROPS_LIST = [
    {name: 'Soja', src: require("../../../assets/icons/icone-soy.svg")},
    {name: 'Milho', src: require("../../../assets/icons/icone-corn.svg")},
    {name: 'Trigo', src: require("../../../assets/icons/icone-wheat.svg")},
    {name: 'Feijão', src: require("../../../assets/icons/icone-beans.svg")},
    {name: 'Arroz', src: require("../../../assets/icons/icone-rice.svg")},
    {name: 'Cevada', src: require("../../../assets/icons/icone-barley.svg")},
    {name: 'Algodão', src: require("../../../assets/icons/icone-cottom.svg")},
    {name: 'Mandioca', src: require("../../../assets/icons/icone-cassava.svg")},
    {name: 'Cana', src: require("../../../assets/icons/icone-sugarcane.svg")},
    {name: 'Laranja', src: require("../../../assets/icons/icone-orange.svg")},
    {name: 'Floresta', src: require("../../../assets/icons/icone-tree.svg")},
    {name: 'Café', src: require("../../../assets/icons/icone-coffee-beans.svg")},
];

export const TASKS_LIST = [
    {name: 'Plantio', src: require("../../../assets/icons/icone-seeding.svg")},
    {name: 'Colheita', src: require("../../../assets/icons/icone-harvest.svg")},
    {name: 'Adubação', src: require("../../../assets/icons/icone-fertilizing.svg")},
    {name: 'Preparo de Solo', src: require("../../../assets/icons/icone-soil-preparation.svg")},
    {name: 'Pulverização', src: require("../../../assets/icons/icone-fungicide.svg")}
];


export const MAP_TYPES = Object.freeze({
    UNKNOWN: [{title: 'Aplicação', value: 'application'}, {title: 'Velocidade', value: 'speed'},
        {title: 'Sobreposição', value: 'overlap'}],
    PLANTING: [{title: 'Aplicação', value: 'application'}, {title: 'Velocidade', value: 'speed'},
        {title: 'Sobreposição', value: 'overlap'}],
    PULVERIZATION: [{title: 'Aplicação', value: 'application'}, {title: 'Velocidade', value: 'speed'},
        {title: 'Sobreposição', value: 'overlap'}],
    MULCHING: [{title: 'Aplicação', value: 'application'}, {title: 'Velocidade', value: 'speed'},
        {title: 'Sobreposição', value: 'overlap'}],
    HARVESTING: [{title: 'Rendimento', value: 'yield'}, {title: 'Velocidade', value: 'speed'},
        {title: 'Umidade', value: 'umidity'}],
    SOIL_PREPARATION: [{title: 'Velocidade', value: 'speed'}, {title: 'Sobreposição', value: 'overlap'}]
});


// @formatter:on
