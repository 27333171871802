<template>
    <div class="col-sm-12 col-md-6">
        <div class="row">
            <div class="col-lg-6 col-12"
                 style="width: 100%; text-align: center;position: relative">
                <div style="color: black; font-size: 16px; font-weight: 800">
                    <div class="tooltip-title">
                        Lista de Culturas
                        <span class="pi pi-question-circle"/>
                        <span class="tooltip-content">
                            <img :src="helpGifCrop" alt="help-drag-crop" width="250"/>
                        </span>
                    </div>
                    <div class="center">
                        <div class="mt-2 crops-list">
                            <div style="width: 100%;" class="center mt-4">
                                <div style="width: 90%;">
                                    <span class="p-input-icon-left"
                                          style="width: 100%;position: relative">
                                        <span class="pi pi-search search-icon"/>
                                        <InputText type="text" v-model="searchCrop"
                                                   placeholder="Procurar Cultura"
                                                   style="width: 80%">
                                        </InputText>
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-6 col-xl-4" style="height: 100px;margin-bottom: 15px">
                                    <div id="add-crop"
                                         style="border: 3px dashed black"
                                         class="card card-add item"
                                         draggable="false"
                                         @click="handleAddCrop">
                                        <div style="font-size: 35px;" class="center">
                                            <i class="glyphicons glyphicons-plus"></i>
                                        </div>
                                        <div style="font-size: 10px">
                                            adicionar
                                        </div>
                                    </div>
                                </div>
                                <div v-for="crop in cropsToShow" class="col-6 col-xl-4"
                                     style="height: 100px;margin-bottom: 15px">
                                    <div :id="crop.name + '-crop'"
                                         class="card item"
                                         draggable="true">
                                        <div style="font-size: 35px;" class="center">
                                            <img :src="crop.src" width="45px" height="45px" draggable="false"/>
                                        </div>
                                        <div style="font-size: 10px;padding-top: 2px">
                                            {{ crop.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 mt-4 mt-md-0"
                 style="width: 100%; text-align: center;position: relative">
                <div style="color: black; font-size: 16px; font-weight: 800">
                    <div class="tooltip-title">
                        Lista de Trabalhos
                        <span class="pi pi-question-circle"/>
                        <span class="tooltip-content">
                            <img :src="helpGifTask" alt="help-drag-crop" width="250"/>
                        </span>
                    </div>
                    <div class="center">
                        <div class="mt-2 crops-list">
                            <div style="width: 100%;" class="center mt-4">
                                <div style="width: 90%;">
                                    <span class="p-input-icon-left"
                                          style="width: 100%;position: relative">
                                        <span class="pi pi-search search-icon"/>
                                        <InputText type="text" v-model="searchTask"
                                                   placeholder="Procurar Tarefa"
                                                   style="width: 80%"/>
                                    </span>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-6 col-xl-4" style="height: 100px;margin-bottom: 15px">
                                    <div id="add-task"
                                         style="border: 3px dashed black"
                                         class="card card-add item"
                                         draggable="false"
                                         @click="handleAddTask">
                                        <div style="font-size: 35px;" class="center">
                                            <i class="glyphicons glyphicons-plus"></i>
                                        </div>
                                        <div style="font-size: 10px">
                                            adicionar
                                        </div>
                                    </div>
                                </div>
                                <div v-for="task in tasksToShow" class="col-6 col-xl-4"
                                     style="height: 100px;margin-bottom: 15px">
                                    <div :id="task.name + '-task'"
                                         class="card item"
                                         draggable="true">
                                        <div style="font-size: 35px;" class="center">
                                            <img :src="task.src" width="45px" height="45px" draggable="false"/>
                                        </div>
                                        <div style="font-size: 10px;padding-top: 2px">
                                            {{ task.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog :visible.sync="addCropDialog" :style="{width: '450px'}" header="Cadastrar Nova Cultura" :modal="true">
            <InputText type="text" v-model="newCropName" placeholder="Nome" style="width: 80%"/>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="addCropDialog = false"/>
                <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="askToAddCrop"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="addTaskDialog" :style="{width: '450px'}" header="Cadastrar Novo Trabalho" :modal="true">
            <InputText type="text" v-model="newTaskName" placeholder="Nome" style="width: 80%"/>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="addTaskDialog = false"/>
                <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="askToAddTask"/>
            </template>
        </Dialog>
    </div>
</template>

<script>

import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button'

import CropOptionsService from "@/services/CropOptionsService";
import TaskOptionsService from "@/services/TaskOptionsService";
import {CROPS_LIST, TASKS_LIST} from "@/components/views/agriculture/AppAgricultureOptions";

export default {
    mounted() {
        this.cropsToShow = JSON.parse(JSON.stringify(this.cropsListDefault));
        this.cropsList = JSON.parse(JSON.stringify(this.cropsListDefault));
        this.tasksToShow = JSON.parse(JSON.stringify(this.tasksListDefault));
        this.tasksList = JSON.parse(JSON.stringify(this.tasksListDefault));
        this.addListeners();
        this.cropOptionsService = new CropOptionsService();
        this.taskOptionsService = new TaskOptionsService();
        this.loadOptions();
    },
    data() {
        return {
            cropOptionsService: null,
            taskOptionsService: null,
            helpGifCrop: require("../../../../assets/images/agriculture/help-crop.gif"),
            helpGifTask: require("../../../../assets/images/agriculture/help-task.gif"),
            cropsListDefault: CROPS_LIST,
            tasksListDefault: TASKS_LIST,
            tasksList: [],
            addCropDialog: false,
            newCropName: null,
            addTaskDialog: false,
            newTaskName: null,
            searchCrop: null,
            searchTask: null,
            cropsToShow: [],
            tasksToShow: [],
        }
    },
    props: {
        selected_client: {
            type: Object,
            required: true
        }
    },
    methods: {
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async addListeners() {
            await this.sleep(1);
            document.querySelectorAll(".item").forEach(item => {
                item.addEventListener("dragstart", this.handleDragStart.bind(this), false);
                item.addEventListener("dragend", this.handleDragEnd.bind(this), false);
            })
        },
        loadOptions() {
            this.cropOptionsService.getAllOptions(this.selected_client.id).then((response) => {
                response.forEach(item => {
                    this.cropsList.push({name: item, src: require("../../../../assets/icons/icone-leaf.svg")})
                })
                this.addListeners();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.cropsToShow = this.cropsList;
            })

            this.taskOptionsService.getAllOptions(this.selected_client.id).then((response) => {
                response.forEach(item => {
                    this.tasksList.push({name: item, src: require("../../../../assets/icons/icone-task.svg")})
                })
                this.addListeners();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.tasksToShow = this.tasksList;
            })
        },
        askToAddCrop() {
            this.cropOptionsService.saveNewOption(this.selected_client.id, this.newCropName).then((response) => {
                this.$toast.add({severity: 'success', summary: 'Cultura adicionada', life: 3000});
                this.cropsList.push({name: this.newCropName, src: require("../../../../assets/icons/icone-leaf.svg")})
                this.addListeners();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.addCropDialog = false;
                this.newCropName = null;
            })
        },
        handleAddCrop() {
            this.addCropDialog = true;
        },
        askToAddTask() {
            this.taskOptionsService.saveNewOption(this.selected_client.id, this.newTaskName).then((response) => {
                this.$toast.add({severity: 'success', summary: 'Tarefa adicionada', life: 3000});
                this.tasksList.push({name: this.newTaskName, src: require("../../../../assets/icons/icone-task.svg")})
                this.addListeners();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.addTaskDialog = false;
                this.newTaskName = null;
            })
        },
        handleAddTask() {
            this.addTaskDialog = true;
        },
        handleDragStart(event) {
            this.$emit('handleDragStart', event);
        },
        handleDragEnd(event) {
            this.$emit('handleDragEnd', event);
        }
    },
    watch: {
        searchCrop: function (val) {
            let tempCrops = [];

            if (!val) {
                this.cropsToShow = this.cropsList;
                return;
            }

            this.cropsList.forEach(crop => {
                if (crop.name.toLowerCase().includes(val.toLowerCase())) {
                    tempCrops.push(crop);
                }
            })
            this.cropsToShow = tempCrops;
        },
        searchTask: function (val) {
            let tempTasks = [];

            if (!val) {
                this.tasksToShow = this.tasksList;
                return;
            }

            this.tasksList.forEach(task => {
                if (task.name.toLowerCase().includes(val.toLowerCase())) {
                    tempTasks.push(task);
                }
            })
            this.tasksToShow = tempTasks;
        },
        selected_client: function (val) {
            this.cropsList = JSON.parse(JSON.stringify(this.cropsListDefault));
            this.cropsToShow = JSON.parse(JSON.stringify(this.cropsListDefault));
            this.tasksList = JSON.parse(JSON.stringify(this.tasksListDefault));
            this.tasksToShow = JSON.parse(JSON.stringify(this.tasksListDefault));

            this.loadOptions();
        }
    },
    components: {
        Dialog, InputText, Button
    }
}

</script>

<style scoped>


.search-icon {
    position: absolute;
    top: 10px;
    left: 13%;
}

.crops-list {
    border: 1px solid gray;
    padding: 10px;
    width: 370px;
    overflow-y: scroll;
    overflow-x: auto;
    border-radius: 5px;
    height: 450px;
}

.card {
    border: 3px solid black;
    width: 90px;
    background-color: white;
    border-radius: 5px;
    height: 90px;
    color: gray;
    font-weight: 800;
    display: inline-block;
    cursor: grab;
    position: relative;
    padding-top: 9px;
}

.card:active {
    border: 3px solid green;
    cursor: grabbing;
}

.card-add {
    cursor: pointer !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-title {
    position: relative;
    display: inline-block;
    cursor: help;
}

.tooltip-title .tooltip-content {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-title .tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip-title:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

</style>
