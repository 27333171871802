<template>
    <transition name="container-transitions" mode="out-in">
        <div v-if="show">
            <div class="background kt-grid kt-grid--ver kt-grid--root kt-page">
                <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin" id="kt_login">
                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                            <div class="kt-login__container card-container">
                                <div class="kt-login__logo">
                                    <a href="#">
                                        <img :src="loginImage" class="img-fluid" style="max-height: 182px">
                                    </a>
                                </div>
                                <div class="kt-login__signin">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="width: 200px;position:absolute; bottom:10px; right:10px;">
                <img :src="getPoweredByLogo()" class="img-fluid">
            </div>
        </div>
    </transition>
</template>
<script>
import AppButton from '../../common/AppButton.vue'
import AppInput from '../../common/AppInput';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import {router} from '../../../router';
import axios_global from "axios";
import {mapGetters} from "vuex";


export default {
    mounted() {
        this.fade();
        this.inviteId = this.$route.query.invite_id;
        if (!this.inviteId) {
            router.push({name: 'login'});
            return;
        }
        if (this.goHomeIfLoggedIn()) {
            this.goHome();
            return;
        }

        this.checkIfRegistered();
    },
    beforeDestroy() {
        this.fade();
    },
    data() {
        return {
            loginImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_LOGIN + ".png"),
            show: false,
            inviteId: null,
            loading: true
        }
    },
    watch: {

    },
    methods: {
        fade() {
            this.show = !this.show;
        },
        goHomeIfLoggedIn() {
            const data = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE));
            if (!data) {
                return false;
            }

            if (!data.access_token || !data.refresh_token) {
                return false;
            }

            if (Math.floor(Date.now() / 1000) >= data.expiration) {
                return false;
            }

            return true;
        },
        checkIfRegistered() {
            let vm = this;
            axios_global.get(process.env.VUE_APP_BACKEND_ADDRESS + '/api/v3/invites/' + this.inviteId + '/checkUserAccount')
                .then(function (response) {
                    if (response.data.is_registered) {
                        vm.gotToLogin()
                        return;
                    }
                    vm.goToSignUp()
                }
            ).catch(function (error) {
                console.log(error)
                vm.gotToLogin()
            }).finally(() => {
                vm.loading = false;
            })
        },
        goToSignUp() {
            router.push({name: 'signup', query: {invite_id: this.inviteId}});
        },
        gotToLogin() {
            router.push({name: 'login'});
        },
        goHome() {
            router.push({name: 'home'})
        },
        getPoweredByLogo() {
            if (process.env.VUE_APP_THEME_ACTIVE === 'ORION') {
                return require("../../../assets/images/brands/poweredByIotag.png")
            }
            return "";
        }
    },
    components: {
        AppButton, AppInput, Button, Dialog
    },
    computed: {
        ...mapGetters([
            'isAuthenticated',
        ])
    },
}
</script>

<style scoped>
@import "../../../assets/styles/login-4.css";

.container-transitions-enter-active {
    transition-duration: 2.1s !important;
    transition-property: opacity !important;
    transition-timing-function: ease !important;
}

.container-transitions-enter, .container-transitions-leave-active {
    opacity: 0 !important;
}

.margin-buttons {
    margin-right: 5px;
}

#kt_login_forgot {
    font-size: 1.50rem;
    color: #3b3b3b;
}

.aceite-termos {
    text-align: center;
    color: #343434;
    font-size: 1.10rem;
}
.aceite-termos  a {
    color: #00aff0;
    text-decoration: underline;
}

.card-container {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid black;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, .4);
}

</style>
