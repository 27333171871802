export const PERMISSIONS = [
    {name: 'Sem acesso', code: 'NOT_AUTHORIZED'},
    {name: 'Visualizar', code: 'VIEW'},
    {name: 'Editar', code: 'EDIT'},
];

export const PERMISSIONS_DESCRIPTIONS = [
    {description: 'Acesso à talhões, estradas, etc.', code: 'LOCATIONS'},
    {description: 'Acesso às máquinas, dispositivos, etc.', code: 'EQUIPMENT'},
    {description: 'Acesso aos membros da organização, configurações do aplicativo, etc.', code: 'MANAGEMENT'},
]
