export const goalsTypes = [
    {
        id: "810",
        type: "EVALUATION_GOAL",
        description: "Meta de Avaliação",
        unity: "%.0f%%",
        suffix: "%",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "811",
        type: "CONSUMPTION_GOAL",
        description: "Meta de Consumo",
        unity: "%.1f L/h",
        suffix: "L/h",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "812",
        type: "VELOCITY_GOAL",
        description: "Meta de Velocidade",
        unity: "%.1f km/h",
        suffix: "km/h",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "813",
        type: "AVAILABILITY_GOAL",
        description: "Meta de Disponibilidade",
        unity: "%.0f%%",
        suffix: "%",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "814",
        type: "EFFICIENCY_GOAL",
        description: "Meta de Eficiência",
        unity: "%.0f%%",
        suffix: "%",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "815",
        type: "AREA_GOAL",
        description: "Meta de Área",
        unity: "%.1f ha",
        suffix: "ha",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "816",
        type: "YIELD_GOAL",
        description: "Meta de Rendimento",
        unity: "%.1f L/ha",
        suffix: "L/ha",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "817",
        type: "PRODUCTIVITY_GOAL",
        description: "Meta de Produtividade",
        unity: "%.2f ha/h",
        suffix: "ha/h",
        value: null,
        editEnabled: true,
        active: false
    },{
        id: "818",
        type: "RPM_GOAL",
        description: "Meta de RPM",
        unity: "%.0f RPM",
        suffix: "RPM",
        value: null,
        editEnabled: true,
        active: false
    },
]
