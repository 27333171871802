import axios from '../axios/axios-auth'

export default class ReportsService {

    save(data) {
        return axios.post('/api/v1/reports', data).then(response => response.data)
    }

    getReportV2(chassis, start, end) {
        return axios.get('/events/v1/eventsData/vehicles/' + chassis + '/date/' + start + '/' + end).then(response => response.data)
    }

    getReportV2Anomalias(chassis, start, end) {
        return axios.get('/events/v1/eventsData/vehicles/' + chassis + '/date/' + start + '/' + end).then(response => response.data)
    }

}
