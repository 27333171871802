<template>
    <div>
        <div class="scale-details" v-show="show_temperature">
            <div>Temperatura, °C</div>
            <div class="scale-gradient" style="width: 260px;">
                <div class="scale-dividers">
                    <div>-40</div>
                    <div>-20</div>
                    <div>0</div>
                    <div>20</div>
                    <div>40</div>
                </div>
                <div class="horizontal-gradient-line"
                     style="background-image: linear-gradient(to right, rgb(159, 85, 181) 0%, rgb(44, 106, 187) 8.75%, rgb(82, 139, 213) 12.5%, rgb(103, 163, 222) 18.75%, rgb(142, 202, 240) 25%, rgb(155, 213, 244) 31.25%, rgb(172, 225, 253) 37.5%, rgb(194, 234, 255) 43.75%, rgb(255, 255, 208) 50%, rgb(254, 248, 174) 56.25%, rgb(254, 232, 146) 62.5%, rgb(254, 226, 112) 68.75%, rgb(253, 212, 97) 75%, rgb(244, 168, 94) 82.5%, rgb(244, 129, 89) 87.5%, rgb(244, 104, 89) 93.75%, rgb(244, 76, 73) 100%);"></div>
            </div>
        </div>


        <div class="scale-details" v-show="show_clouds">
            <div>Nuvens, %</div>
            <div class="scale-gradient" style="width: 260px;">
                <div class="scale-dividers">
                    <div>0</div>
                    <div>25</div>
                    <div>50</div>
                    <div>75</div>
                    <div>100</div>
                </div>
                <div class="horizontal-gradient-line"
                     style="background-image: linear-gradient(to right, rgba(247, 247, 255, 0) 0%, rgba(251, 247, 255, 0) 10%, rgba(244, 248, 255, 0.1) 20%, rgba(240, 249, 255, 0.2) 30%, rgba(221, 250, 255, 0.4) 40%, rgba(224, 224, 224, 0.9) 50%, rgba(224, 224, 224, 0.76) 60%, rgba(228, 228, 228, 0.9) 70%, rgba(232, 232, 232, 0.9) 80%, rgb(214, 213, 213) 90%, rgb(210, 210, 210) 95%, rgb(183, 183, 183) 100%);"></div>
            </div>
        </div>

        <div class="scale-details" v-show="show_precipitation">
            <div>Precipitação, mm/h</div>
            <div class="gradient-container">
                <div class="scale-dividers " style="width: 260px;">
                    <div>0</div>
                    <div>0.5</div>
                    <div>1</div>
                    <div>2</div>
                    <div>4</div>
                    <div>6</div>
                    <div>7</div>
                    <div>10</div>
                    <div>12</div>
                    <div>14</div>
                    <div>16</div>
                    <div>24</div>
                    <div>32</div>
                    <div>60</div>
                </div>
                <div class="horizontal-gradient-line"
                     style="background-image: linear-gradient(to left, rgb(255, 0, 0), rgb(255, 0, 0), rgb(255, 91, 0), rgb(255, 150, 0), rgb(255, 205, 0), rgb(239, 248, 0), rgb(0, 160, 0), rgb(0, 211, 0), rgb(25, 117, 215), rgb(52, 123, 186), rgb(224, 224, 224), rgba(0, 0, 0, 0) 100%);"></div>
            </div>
        </div>

    </div>


</template>

<script>

    import {MAPS_OVERLAY_OPEN_WEATHER_TYPES} from './AppOptions';

    export default {

        data() {
            return {
                show_precipitation: false,
                show_clouds: false,
                show_temperature: false
            }
        },

        methods: {
            handleShow(option) {
                switch (option) {
                    case MAPS_OVERLAY_OPEN_WEATHER_TYPES.PRECIPITATION:
                        this.show_precipitation = true;
                        this.show_clouds = false;
                        this.show_temperature = false;
                        break;
                    case MAPS_OVERLAY_OPEN_WEATHER_TYPES.TEMPERATURE:
                        this.show_temperature = true;
                        this.show_precipitation = false;
                        this.show_clouds = false;
                        break;
                    case MAPS_OVERLAY_OPEN_WEATHER_TYPES.CLOUDS:
                        this.show_clouds = true;
                        this.show_temperature = false;
                        this.show_precipitation = false;
                        break;
                    default:
                        this.show_clouds = false;
                        this.show_temperature = false;
                        this.show_precipitation = false;
                        console.log("Valor desconhecido: " + option)
                }
            },
        },
        props: {
            show_legend: {
                type: String,
            },
        },
        watch: {
            show_legend: function (val) {
                this.handleShow(val);
            }
        }
    }
</script>

<style scoped>
    .scale-details {
        color: rgb(72, 72, 74);
        height: 20pt;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 2pt 4pt;
        background-color: rgb(255, 255, 255);
        border-radius: 4pt;
        transition: box-shadow 0.1s ease-out 0s, -webkit-box-shadow 0.1s ease-out 0s;
        margin-bottom: 10px;
        -webkit-box-pack: justify;
        justify-content: space-between;
        font-size: 10px;
    }

    .scale-details:only-child {
        margin-bottom: 0;
    }

    .scale-details > div:first-child {
        width: 100px;
        white-space: nowrap;
        font-size: 10px;
    }

    .scale-dividers, .scale-gradient {
        display: flex;
    }

    .scale-gradient {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .scale-dividers {
        width: 100%;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .horizontal-gradient-line {
        border-radius: 4pt;
        height: 4px;
        opacity: 0.9;
        width: 260px;
        border-left: 1px solid rgb(242, 242, 242);
        border-bottom: 1px solid rgb(242, 242, 242);
    }
</style>
