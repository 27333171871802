<template>
    <Dialog header="Veículo" :visible.sync="showDialog"
            @hide="handleClose"
            :style="{width: width_dialog}" :modal="true">
        <p v-if="vehicleDetails === 'LOADING'"
           style="font-size: 15px; line-height: 1.7; text-align: center">
            <ProgressSpinner/>
            <br>
            Carregando, aguarde...
        </p>
        <p v-else-if="vehicleDetails === 'ERROR'"
           style="font-size: 15px; line-height: 1.7; text-align: center">
            Não foi possivel completar a operação
        </p>
        <p v-else class="p-m-0" style="font-size: 15px; line-height: 1.7">
            <b>Chassis: </b> {{vehicleDetails.identification_number}} <br>
            <b>Identificação: </b> {{vehicleDetails.identification}}<br>
            <b>Fabricante: </b> {{vehicleDetails.manufacturer}}<br>
            <b>Modelo: </b> {{vehicleDetails.model}}<br>
            <b>Tipo: </b> {{vehicleDetails.type}}<br>
            <b>Horímetro: </b> {{vehicleDetails.hour_meter}}<br>
        </p>
        <template #footer>
            <app-button @handleClick="handleClose" type="primary">
                <span class="pi pi-times"></span>
                Fechar
            </app-button>
        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressSpinner from 'primevue/progressspinner';
    import Button from 'primevue/button';
    import AppButton from "../../common/AppButton";

    export default {
        data() {
            return {
                showDialog: false,
                width_dialog: null
            }
        },
        mounted() {
            this.width_dialog = this.isScreenMobile ? '80vw' : '30vw';
        },
        methods: {
            handleClose() {
                this.$emit('handleClose');
            },
        },
        props: {
            displayDialogVehicle: {
                type: Boolean,
                default: false,
                required: true
            },
            vehicleDetails: {
                required: true
            }
        },
        watch: {
            displayDialogVehicle: function (val) {
                this.showDialog = val;
            }
        },
        computed: {
            isScreenMobile: function() {
                return window.screen.width <= 1024;
            }
        },
        components: {
            Dialog, ProgressSpinner, Button, AppButton
        },
    }
</script>

<style scoped>

</style>
