<template>
    <form @submit.prevent>
        <div class="kt-portlet__body">
                <div class="form-group ">
                    <h2 v-if="edit" class="title col-12">Editar Motorista</h2>
                    <h2 v-else class="title col-12">Cadastrar Motorista</h2>
                </div>

                <div class="form-group">
                    <app-input class="form-control" type="text" placeholder="Nome"
                               v-model="form.first_name"
                               :class="{'is-invalid' : $v.form.first_name.$invalid && $v.form.first_name.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="$v.form.first_name.$invalid && $v.form.first_name.$dirty">
                                            Nome obrigatório
                    </span>
                </div>
                <div class="form-group">
                    <app-input class="form-control" type="text" placeholder="Sobrenome"
                               v-model="form.last_name"
                               :class="{'is-invalid' : $v.form.last_name.$invalid && $v.form.last_name.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="$v.form.last_name.$invalid && $v.form.last_name.$dirty">
                                            Sobrenome obrigatório
                    </span>
                </div>
                <div class="form-group">
                    <app-input class="form-control" type="text" placeholder="Cadastro"
                               v-model="form.cpf"
                               v-mask="['###.###.###-##']"
                               :class="{'is-invalid' : $v.form.cpf.$invalid && $v.form.cpf.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="!$v.form.cpf.required && $v.form.cpf.$dirty">
                                            CPF obrigatório
                    </span>
                    <span class="form-text error"
                          v-if="$v.form.cpf.required && !$v.form.cpf.isCPFValid && $v.form.cpf.$dirty">
                                            CPF Inválido
                    </span>
                </div>
                <div class="form-group">
                    <app-input class="form-control" type="text" placeholder="Celular"
                               v-model="form.phone"
                               v-mask="['(##) ####-####', '(##) #####-####']"
                               :class="{'is-invalid' : $v.form.phone.$invalid && $v.form.phone.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="$v.form.phone.$invalid && $v.form.phone.$dirty">
                                           Celular obrigatório
                    </span>
                </div>
                <div class="form-group">
                    <Dropdown v-model="form.state" :options="statesAndCities"
                              optionLabel="name"
                              :filter="true"
                              placeholder="Selecione o estado"
                              :showClear="true" style="width: 100%;"
                              :class="{'p-invalid-custom' : $v.form.state.$invalid && $v.form.state.$dirty}">
                        <template #value="slotProps">
                            <div class="country-item country-item-value" v-if="slotProps.value.name">
                                <div>{{slotProps.value.name}}</div>
                            </div>
                            <span v-else>
                                  Selecione o Estado
                             </span>
                        </template>
                        <template #option="slotProps">
                            <div class="country-item">
                                <div>{{slotProps.option.name}}</div>
                            </div>
                        </template>
                    </Dropdown>
                    <span class="form-text error"
                          v-if="$v.form.state.$invalid && $v.form.state.$dirty">
                                           Estado obrigatório
                    </span>
                </div>
                <div class="form-group">
                    <Dropdown v-model="form.city" :options="form.state.cities"
                              :filter="true" filterPlaceholder="Procurar"
                              placeholder="Selecione a cidade"
                              :showClear="true" style="width: 100%;"
                              :class="{'p-invalid-custom': $v.form.city.$invalid && $v.form.city.$dirty}">
                        <template #value="slotProps">
                            <div v-if="slotProps.value">
                                <div>{{slotProps.value}}</div>
                            </div>
                            <span v-else>
                                  Selecione a cidade
                             </span>
                        </template>
                        <template #option="slotProps">
                            <div class="country-item">
                                <div>{{slotProps.option}}</div>
                            </div>
                        </template>
                    </Dropdown>

                    <span class="form-text error"
                          v-if="$v.form.city.$invalid && $v.form.city.$dirty">
                                           Cidade obrigatório
                    </span>
                </div>
                <div class="form-group kt-align-right">
                    <app-button class="col-12 col-lg-3 ml-lg-auto btn-sucess size" type="primary" @handleClick="validateForm">
                        Salvar
                    </app-button>
                </div>
        </div>
    </form>
</template>

<script>
    import AppButton from "../../common/AppButton";
    import AppInput from "../../common/AppInput";
    import AppInputCpfCnpj from "../../common/AppInputCpfCnpj";
    import AppInputPassword from "../../common/AppInputPassword";
    import Calendar from 'primevue/calendar';
    import maskCpfCnpj from "../../../mixins/StringsMaskMixin";
    import orderOrgByNaturalOrder from "../../../mixins/ArrayMixin";
    import {minLength, required} from "vuelidate/lib/validators";
    import {states} from "../../../constants/brazilianStatesWithCities";
    import CPFValidations from "../../../validations/CPFValidation";
    import MultiSelect from 'primevue/multiselect';
    import Dropdown from 'primevue/dropdown';
    import OrganizationsService from "../../../services/OrganizationsService";

    const isCPFValid = (strCPF) => CPFValidations.isCPFValid(strCPF);

    export default {
        mixins: [maskCpfCnpj, orderOrgByNaturalOrder],
        data() {
            return {
                statesAndCities: states,
                cities: [],
                form: {
                    first_name: null,
                    last_name: null,
                    cpf: null,
                    phone: null,
                    state: [],
                    city: ""
                },
            }
        },
        props: {
            edit: {
                type: Object
            }
        },
        components: {
            AppButton, AppInput, AppInputCpfCnpj, AppInputPassword, Calendar, MultiSelect, Dropdown
        },
        watch: {
            edit: function (val) {
                if (val.first_name) {
                    this.form.first_name = val.first_name;
                }
                if (val.last_name) {
                    this.form.last_name = val.last_name;
                }
                if (val.cpf) {
                    this.form.cpf = val.cpf;
                }
                if (val.phone) {
                    this.form.phone = val.phone;
                }
                if (val.state) {
                    this.selectStateByCode(val.state)
                }
                if (val.city) {
                    this.form.city = val.city;
                }
            },
        },
        methods: {
            selectStateByCode(code) {
                for (let i = 0; i < this.statesAndCities.length; i++) {
                    if (code === this.statesAndCities[i].stateCode) {
                        this.form.state = this.statesAndCities[i];
                        break;
                    }
                }
            },
            validateForm() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Por favor preencha todos os campos obrigatórios',
                        life: 5000
                    });
                } else {
                    this.form.state = this.form.state.stateCode;
                    this.$emit('handleSave', this.form);
                }
            },
            sortCustomerListByName(customerList) {
                try {
                    return customerList.sort((a, b) => a.name.localeCompare(b.name))
                } catch (e) {
                    return customerList;
                }
            },
            checkForm() {
                this.$v.operator.$touch();
                if (this.$v.operator.$invalid) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Por favor preencha todos os campos obrigatórios',
                        life: 5000
                    });
                } else {
                    this.$emit('handleSave', this.operator);
                }
            },
        },
        computed: {},
        validations: {
            form: {
                first_name: {required},
                last_name: {required},
                cpf: {required, isCPFValid},
                phone: {required,},
                state: {required, minLength: minLength(1)},
                city: {required, minLength: minLength(1)},
            }
        },
    }
</script>

<style scoped type="scss">

    label {
        margin-bottom: 0;
    }

    .title{
        border-bottom: 0.5px #666666 solid;
        color: #666666;
        padding-bottom: 15px;
        padding-top: 5px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
    }

</style>
