<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile ">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <!-----------------DROPDOWN DE ORGANIZAÇÃO-------------------------->
                <Dropdown v-model="organizationSelected" :options="orgsList"
                          @change="handleOrganizationChange($event.value)"
                          optionLabel="display_name" placeholder="Selecione a organização"
                          class="auto-center dropdown-width" :dataKey="'id'"
                          :filter="true">
                    <template #option="slotProps">
                        <div>
                            <div v-if="loadingOrgs">Carregando, aguarde</div>
                            <div v-else-if="slotProps.option.display_name === 'ND'">
                                -
                            </div>
                            <div v-else>
                                {{ slotProps.option.display_name }}
                            </div>
                        </div>
                    </template>
                </Dropdown>

                <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon">
                        </span>
                </div>
                <div class="kt-portlet__head-toolbar ">
                    <div class="kt-portlet__head-wrapper">
                        <app-button type="secondary" @handleClick="back" :class="{'margin-25-right': !is_col_12, 'width-button': is_col_12}">
                            <i class="glyphicons glyphicons-arrow-left"></i><span v-if="!is_col_12">Voltar</span>
                        </app-button>
                    </div>
                </div>
            </div>
            <!-----------------CORPO DA PÁGINA-------------------------->
            <div v-if="!organizationSelected" class="stop-reasons-body">
                <h3>
                    Selecione uma organização para visualizar os motivos de parada
                </h3>
            </div>
            <div v-else class="stop-reasons-body">
                <div class="stop-reasons-list white-background">
                    <div class="stop-reasons-list_title">
                        <h3 style="margin: 0rem">Lista de Motivos de Parada</h3>
                        <app-button type="primary" @handleClick='openDialog("import")'>
                            <span class="pi pi-arrow-up-right mr-2"/>
                            Importar Motivos de Parada
                        </app-button>
                    </div>

                    <div class="stop-reasons-list_table">
                        <div v-if="stopReasonsList != null" style="width: 100%;">
                            <DataTable :value="stopReasonsList" :rowHover="true"
                                       :loading="stopReasonsLoading" responsiveLayout="scroll">
                                <template #loading>
                                    Carregando motivos de parada, por favor aguarde
                                </template>
                                <Column field="code" header="Código" headerClass="headerClass" bodyClass="bodyClass">
                                    <template #body="slotProps">
                                        <h5>{{ slotProps.data.code }}</h5>
                                    </template>
                                </Column>
                                <Column field="description" header="Descrição" headerClass="headerClass" bodyClass="bodyClass">
                                    <template #body="slotProps">
                                        <h5>{{ slotProps.data.description }}</h5>
                                    </template>
                                </Column>
                                <Column field="type" header="Tipo de Parada" headerClass="headerClass" bodyClass="bodyClass">
                                    <template #body="slotProps">
                                        <h5>{{ slotProps.data.type_description }}</h5>
                                    </template>
                                </Column>
                                <Column header="Ações" headerClass="headerClass" bodyClass="bodyClass">
                                    <template #body="slotProps">
                                        <div>
                                            <Button icon="pi pi-times" class="p-button-rounded delete-btn"
                                                    @click='openDialog("delete",slotProps.data)'/>
                                        </div>
                                    </template>
                                </Column>

                            </DataTable>
                        </div>
                        <div v-else>
                            <h4>Nenhum motivo de parada registrado para a organização.</h4>
                        </div>
                    </div>

                    <div class="stop-reasons-add">
                        <app-button type="primary" @handleClick='openDialog("add")'>
                            <span class="pi pi-plus mr-2"/>
                            Adicionar Motivo de Parada
                        </app-button>
                    </div>
                </div>

                <!------------------------ DIALOG: ADD STOP REASONS --------------------------------->
                <Dialog header="Adicionar Motivo de Parada" :visible="displayAddStopReasons" :modal="true"
                        @update:visible='closeDialog("add")'>
                    <div class="dialog-body_add" :style="{'width': widthDialog}">
                        <div >
                            <p>Código: </p>
                            <app-input type="text" placeholder="Exemplo: AB12C" @keydown.native="removeComma($event)"
                                       v-model="newStopReason.code"></app-input>
                        </div>
                        <div class="mt-3">
                            <p>Descrição: </p>
                            <app-input type="text" placeholder="Exemplos: almoço, abastecimento, banheiro, etc."
                                       @keydown.native="removeComma($event)"
                                       v-model="newStopReason.description"></app-input>
                        </div>
                        <div class="mt-3">
                            <p>Tipo de parada: </p>
                            <Dropdown style="width: 100%" placeholder="Selecione o tipo de motivo de parada"
                                      v-model="newStopReason.type" appendTo="body"
                                      :options="stopTypesList" optionLabel="description" class="auto-center"></Dropdown>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" @click='closeDialog("add")'
                                class="p-button-text"/>
                        <Button :disabled="disableButton" label="Adicionar" icon="pi pi-check"
                                @click="addStopReasons()"/>
                    </template>
                </Dialog>

                <!------------------------ DIALOG: DELETE STOP REASONS --------------------------------->
                <Dialog header="Deletar Motivos de Parada" :visible="displayDeleteStopReasons" :modal="true"
                        @update:visible='closeDialog("delete")'>
                    <div class="dialog-body">
                        <h5>Deseja deletar o motivo de parada:</h5>
                        <div style="display: flex; justify-content:center; min-width: 300px; max-width: 800px;margin-top:1.5rem">
                            <h3>{{ deleteStopReason.code }}: {{ deleteStopReason.description }} ({{ deleteStopReason.type_description }}) ?</h3>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text"
                                @click='closeDialog("delete")'/>
                        <Button :disabled="disableButton" label="Deletar" icon="pi pi-check"
                                @click="deleteStopReasons()"/>
                    </template>
                </Dialog>

                <!------------------------ DIALOG: IMPORT ACTIVITIES --------------------------------->
                <Dialog header="Importar Motivo de Parada" :visible="displayImportStopReasons" :modal="true"
                        @update:visible='closeDialog("import")'>
                    <div v-if="!importSelected" style="max-height: 50vh" :style="{'width': widthDialog}">
                        <p>De qual organização você deseja importar?</p>
                        <Listbox v-model="organizationImportSelected" :options="orgsList"
                                 optionLabel="display_name"
                                 :filter="true" filterPlaceholder="Procure pelo nome da organização"
                                 class="existent-list">
                        </Listbox>
                    </div>
                    <div v-else style="max-height: 50vh" :style="{'width': widthDialog}">
                        <p>Selecione os motivos de parada que deseja importar:</p>
                        <DataTable :value="importedStopReasons" :rowHover="true"
                                   :selection.sync="selectedImportedStopReasons" dataKey="id" responsiveLayout="scroll"
                                   :loading="importedStopReasonsLoading" class="p-datatable-striped">
                            <template #loading>
                                Carregando motivos de parada, por favor aguarde
                            </template>
                            <Column selectionMode="multiple" headerStyle="width: 3rem" headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column field="code" header="Código" headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column field="description" header="Descrição" headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                            <Column field="type_description" header="Tipo de Parada" headerClass="headerClass"
                                    bodyClass="bodyClass"></Column>
                        </DataTable>
                    </div>
                    <template #footer>
                        <div style="padding-top: 1rem">
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text"
                                    style="border: 1px solid #2196f3"
                                    @click='closeDialog("import")'/>
                            <Button v-if="!importSelected" label="Prosseguir" icon="pi pi-angle-right"
                                    @click="importNextPage()"/>
                            <Button :disabled="disableButton" v-else label="Salvar" icon="pi pi-check"
                                    @click="importStopReasons()"/>
                        </div>
                    </template>
                </Dialog>

            </div>
        </div>
    </div>
</template>

<script>
import {router} from '../../../router';
import AppButton from "../../common/AppButton";
import AppInput from "../../common/AppInput";
import Button from "primevue/button";
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Listbox from 'primevue/listbox';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import OrganizationsService from "../../../services/OrganizationsService";
import StopReasonsService from "../../../services/StopReasonsService";
import {stopReasonsTypes} from "./stopReasonsList";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";

export default {
    mixins: [orderCustomFieldByNaturalOrder],
    beforeMount() {
        this.organizationsService = new OrganizationsService();
        this.stopReasonsService = new StopReasonsService();
        this.getOrganizations();
    },
    mounted(){
        this.widthDialog = this.isScreenMobile ? '85vw' : '30vw';
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            is_col_12: false,
            widthDialog: '',
            stopTypesList: stopReasonsTypes,
            disableButton: false,
            //organizations
            organizationsService: null,
            organizationSelected: null,
            organizationImportSelected: null,
            orgsList: null,
            loadingOrgs: true,
            //stop reasons
            stopReasonsService: null,
            stopReasonsLoading: false,
            importedStopReasonsLoading: false,
            importedStopReasons: null,
            selectedImportedStopReasons: null,
            newStopReason: {"code": '', "description": '', "type": ''},
            deleteStopReason: {"code": '', "description": '', "type": ''},
            //dialogos
            displayAddStopReasons: false,
            displayDeleteStopReasons: false,
            displayImportStopReasons: false,
            importSelected: false,
            //resposta para o backend
            stopReasonsList: [],
            storedChosenOptions: null
        }
    },
    methods: {
        back() {
            router.push('/settings');
        },
        removeComma(e){
            if (/,/g.test(e.key)) {
                e.preventDefault();
            }
        },
        //Carrega todas as organizaçãoes que o usuário tem acesso no ínicio da sessão = beforeMount()
        getOrganizations() {
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('APPCONFIGS').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.orgsList = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.organizationSelected = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];

            if (this.organizationSelected) {
                this.handleOrganizationChange(this.organizationSelected);
            }
        },
        //Carrega a lista de motivos de parada organização selecionada ao alterar o dropdown e ao fazer qualquer alteração (adicionar, deletar, editar e importar).
        handleOrganizationChange(organization) {
            this.stopReasonsLoading = true;
            this.stopReasonsList = [];
            this.stopReasonsService.getStopReasonsByOrganization(organization.id)
                .then((response) => {
                    this.stopReasonsList = response;
                    if (this.stopReasonsList) {
                        this.getStopTypes(this.stopReasonsList);
                    }
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.stopReasonsLoading = false;
            })

            this.saveChosenOptionsToLocalStorage();
        },
        saveChosenOptionsToLocalStorage() {
            if (!this.storedChosenOptions) {
                this.storedChosenOptions = {}
            }

            this.storedChosenOptions['clients'] = [this.organizationSelected];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        getStopTypes(stop_reasons) {
            for (let i = 0; i < stop_reasons.length; i++) {
                let type = this.stopTypesList.find(stop => stop.type === stop_reasons[i].type);
                stop_reasons[i].type_description = type.description;
            }
        },
        //Abre a caixa de diálogo referente ao botão clicado
        openDialog(str, stop_reason) {
            if (str === "add") {
                this.displayAddStopReasons = true;
            } else if (str === "delete") {
                this.deleteStopReason = JSON.parse(JSON.stringify(stop_reason));
                this.displayDeleteStopReasons = true;
            }  else if (str === "import") {
                this.displayImportStopReasons = true;
            }
        },
        //Fecha a caixa de diálogo aberta
        closeDialog(str) {
            if (str === "add") {
                this.displayAddStopReasons = false;
                this.newStopReason = {"code": '', "description": '', "type": ''};
            } else if (str === "delete") {
                this.deleteStopReason = '';
                this.displayDeleteStopReasons = false;
            } else if (str === "import") {
                this.displayImportStopReasons = false;
                this.importSelected = false;
                this.importedStopReasons = null;
                this.organizationImportSelected = null;
                this.selectedImportedStopReasons = null
            }
        },
        //verifica se já existe o motivo de parada vinculada a organização ao adicionar ou editar
        checkStopReasons(stop_reason) {
            let answer = true;
            let stop_reasons_list = this.stopReasonsList;

            if(stop_reason.code === '' || stop_reason.description === '' || stop_reason.type === '') {
                return false;
            } else if (stop_reasons_list !== null) {
                for (let i = 0; i < stop_reasons_list.length; i++) {
                    if (stop_reason.code.toUpperCase() === stop_reasons_list[i].code.toUpperCase() ||
                        stop_reason.description.toUpperCase() === stop_reasons_list[i].description.toUpperCase()) {
                        return false;
                    }
                }
            }
            stop_reason.type = stop_reason.type.type;
            return answer;
        },
        //adicionar um novo motivo de parada
        addStopReasons() {
            this.disableButton = true;
            if (this.checkStopReasons(this.newStopReason)) {
                this.stopReasonsService.addStopReasons(this.organizationSelected.id, [this.newStopReason])
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso!',
                            detail: 'Motivo de parada cadastrado',
                            life: 5000
                        });
                    }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação. Tente novamente',
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Você não tem acesso a esta funcionalidade',
                        life: 5000
                    });
                }).finally(() => {
                    this.disableButton = false;
                    this.displayAddStopReasons = false;
                    this.newStopReason = {"code": '', "description": '', "type": ''};
                    this.handleOrganizationChange(this.organizationSelected);
                })
            } else {
                if (this.newStopReason.code === '' || this.newStopReason.description === '' || this.newStopReason.type === '') {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Preencha todos os campos',
                        life: 5000
                    });
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Código ou Descrição já existentes!',
                        life: 5000
                    });
                }
                this.disableButton = false;
            }
        },
        //deletar um motivo de parada
        deleteStopReasons() {
            this.disableButton = true;
            this.stopReasonsService.deleteStopReasons(this.deleteStopReason.id)
                .then((response) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso!',
                        detail: 'Motivo de parada deletado',
                        life: 5000
                    });
                }).catch((error) => {
                console.log(error);
                if(error.response && error.response.status === 409){
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Não foi possível deletar o motivo de parada, pois está presente em uma lista de configuração',
                        life: 5000
                    });
                    return
                }
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.disableButton = false;
                this.displayDeleteStopReasons = false;
                this.deleteStopReason = '';
                this.handleOrganizationChange(this.organizationSelected);
            })
        },
        //verificação da edição de motivo de parada
        checkStopReasonsEdit(stop_reason) {
            let edited_reason = this.stopReasonsList.filter(list => list.id === stop_reason.id);
            let stop_reasons_list = this.stopReasonsList.filter(list => list.id !== stop_reason.id);
            if((stop_reason.code === edited_reason[0].code && stop_reason.description === edited_reason[0].description
            && stop_reason.type.type === edited_reason[0].type) || (stop_reason.code === '' || stop_reason.description === '')){
                return false;
            } else if(stop_reasons_list !== null){
                for (let i = 0; i < stop_reasons_list.length; i++) {
                    if (stop_reason.code.toUpperCase() === stop_reasons_list[i].code.toUpperCase() ||
                        stop_reason.description.toUpperCase() === stop_reasons_list[i].description.toUpperCase()) {
                        return false;
                    }
                }
            }
            return true;
        },
        //função para seguir para próxima página na caixa de diálogo de importar motivo de parada
        importNextPage() {
            if (this.organizationImportSelected === null) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Selecione uma organização.',
                    life: 5000
                });
            } else {
                this.importSelected = true;
                this.importedStopReasonsLoading = true;
                this.getImportedOrgStopReasons();
            }
        },
        //a função solicita ao backend a lista de motivos de parada da organização selecionada para importar
        getImportedOrgStopReasons() {
            this.stopReasonsService.getStopReasonsByOrganization(this.organizationImportSelected.id)
                .then((response) => {
                    this.importedStopReasons = response;
                    this.getStopTypes(this.importedStopReasons);
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.importedStopReasonsLoading = false;
                if (!this.importedStopReasons) {
                    this.importSelected = false;
                }
            })
        },
        //verifica se a organização já possui o motivo de parada (por código ou descrição) que o usuário está tentando importar
        checkImportedStopReasons(stop_reasons) {
            let stop_reasons_org = this.stopReasonsList;
            if(stop_reasons_org !== null){
                for (let i = 0; i < stop_reasons.length; i++) {
                    let equal_code = stop_reasons_org.find(item => item.code.toUpperCase() === stop_reasons[i].code.toUpperCase());
                    let equal_description = stop_reasons_org.find(item => item.description.toUpperCase() === stop_reasons[i].description.toUpperCase());
                    if(equal_code || equal_description){
                        return false
                    }
                }
            }
            return true;
        },
        //realiza a importação dos motivos de parada
        importStopReasons() {
            this.disableButton = true;
            //Retorna erro se o usuário não selecionar nenhum motivo de parada
            if (this.selectedImportedStopReasons === null || this.selectedImportedStopReasons.length === 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Selecione um motivo de parada',
                    life: 5000
                });
                this.disableButton = false;
                return;
            }


            console.log(this.selectedImportedStopReasons)
            if (this.checkImportedStopReasons(this.selectedImportedStopReasons)) {
                this.stopReasonsService.addStopReasons(this.organizationSelected.id, this.selectedImportedStopReasons)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso!',
                            detail: 'Motivos de parada cadastradas',
                            life: 5000
                        });
                    }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação. Tente novamente',
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Você não tem acesso a esta funcionalidade',
                        life: 5000
                    });
                }).finally(() => {
                    this.disableButton = false;
                    this.displayImportStopReasons = false;
                    this.importedStopReasons = null;
                    this.importSelected = false;
                    this.handleOrganizationChange(this.organizationSelected);
                    this.selectedImportedStopReasons = null
                    this.organizationImportSelected = null;
                })
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro!',
                    detail: 'Você já possui um dos motivos de parada selecionadas',
                    life: 5000
                });
                this.disableButton = false;
            }


        },
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    components: {
        AppButton,
        AppInput,
        Button,
        Dropdown,
        DataTable,
        Listbox,
        Column,
        Dialog,
        OrganizationsService,
        StopReasonsService,
        stopReasonsTypes
    }
}

</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";


.stop-reasons-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.white-background {
    background: white;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 5px;
    margin: 0rem;
}

.stop-reasons-list {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.stop-reasons-list_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stop-reasons-list_table {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem 2rem 0rem;
}

.stop-reasons-add {
    display: flex;
    justify-content: center;
}

.dialog-body_add {
    justify-content: space-between;
}

.delete-btn {
    border: none;
    background-color: #E51111;
}

.delete-btn:enabled:hover {
    background-color: #AD1111;
}

.width-button{
    max-width: 55px;
    margin: 5px;
}

@media(max-width: 1024px){
    .stop-reasons-list {
        width: 95%;
        margin: 0rem 2rem 0rem 2rem;
    }

    .margin-25-right{
        margin: 0px !important;
    }

    .dropdown-width{
        width: 250px;
    }
}


</style>
