export const STATUS = [
    {name: 'Regular', code: 'REGULAR'},
    {name: 'Desvio', code: 'DEVIATION'},
    {name: 'Protocolizado', code: 'PROTOCOL'},
    {name: 'Não autorizado', code: 'NO_AUTHORIZATION'},
    {name: 'Desconhecido', code: 'UNKNOWN'},
];

export const PROCESSES = [
    {name: 'Colheita', code: 'HARVEST'},
    {name: 'DTI', code: 'DTI'},
    {name: 'Inteligência Patrimonial', code: 'PI'},
    {name: 'Logística', code: 'LOGISTICS'},
    {name: 'Silvicultura', code: 'FORESTRY'},
    {name: 'Viveiro', code: 'PLANT_NURSERY'},
    {name: 'Estrada', code: 'ROAD'},
    {name: 'Desconhecido', code: 'UNKNOWN'},
];

export const ACTIVITIES = [
    {name: 'Aplicação de defensivos', code: 'PESTICIDE'},
    {name: 'Combate a incêndio', code: 'FIRE_FIGHTING'},
    {name: 'Lavagem de máquinas', code: 'MACHINE_WASHING'},
    {name: 'Plantio, replantio e irrigação', code: 'PLANTING'},
    {name: 'Produção de mudas', code: 'SEEDLING_PRODUCTION'},
    {name: 'Terraplanagem', code: 'LANDSCAPING'},
    {name: 'Umectação de estradas', code: 'ROAD_WETTING'},
    {name: 'Construção e manutenção de estradas', code: 'ROAD_CONSTRUCTION'},
    {name: 'Desconhecido', code: 'UNKNOWN'},
];
