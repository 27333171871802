<template>
    <Dialog header="Ordem de Serviço" :visible.sync="showDialog"
            @hide="handleClose" :modal="true" :style="{width: width_dialog}">
        <div class="row">
            <div :class="selectVehiclesPageClass">
                {{ finishOrderButtonText }} Ordem de Serviço
                <i class="pi pi-calendar-plus pl-2"></i>
            </div>
            <div :class="machineryPageClass">
                Maquinário
                <i class="pi pi-briefcase pl-2"></i>
            </div>
            <div :class="reviewPageClass">
                Revisar
                <i class="pi pi-check-square pl-2"></i>
            </div>
        </div>

        <div v-if="currentPage === 0" style="width: 100%" class="mt-4">
            <div class="row no-gutters">
                <div class="col">
                    <div style="font-weight: bold;font-size: 14px" class="center">
                        Data
                    </div>
                    <div class="center mt-2">
                        Dia
                    </div>
                    <div class="center">
                        <Calendar  v-model="dateModelDay" :inline="true" :showWeek="true"/>
                    </div>
                    <div class="center mt-4">
                        Horário
                    </div>
                    <div class="center">
                        <Calendar class="time-calendar" v-model="dateModelTime" :timeOnly="true" hourFormat="24"
                                  :inline="true"/>
                    </div>
                </div>
                <div class="col">
                    <div style="font-weight: bold;font-size: 14px" class="center">
                        Maquinário
                    </div>
                    <div class="mt-2">
                        <label for="motoniveladoras" class="center">Motoniveladoras</label>
                        <div class="center">
                            <InputNumber id="motoniveladoras" v-model="motoniveladoras" showButtons buttonLayout="horizontal"
                                         decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label for="pa-carregadeira" class="center">Pás-Carregadeiras</label>
                        <div class="center">
                            <InputNumber id="pa-carregadeira" v-model="pasCarregadeiras" showButtons buttonLayout="horizontal"
                                         decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label for="basculante" class="center">Caminhões Basculantes</label>
                        <div class="center">
                            <InputNumber id="basculante" v-model="caminhoesBasculantes" showButtons buttonLayout="horizontal"
                                         decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                        </div>
                    </div>
                    <div class="mt-3">
                        <label for="pipa" class="center">Caminhões Pipa</label>
                        <div class="center">
                            <InputNumber id="pipa" v-model="caminhoesPipa" showButtons buttonLayout="horizontal"
                                         decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label for="pipa" class="center">Escavadeiras</label>
                        <div class="center">
                            <InputNumber id="escavadeira" v-model="escavadeira" showButtons buttonLayout="horizontal"
                                         decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label for="pipa" class="center">Retro-Escavadeira</label>
                        <div class="center">
                            <InputNumber id="retro-escavadeira" v-model="retroEscavadeira" showButtons buttonLayout="horizontal"
                                         decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                        </div>
                    </div>
                    <div class="mt-3">
                        *Observação
                        <Textarea v-model="observation" :autoResize="true" rows="4" style="width: 100%" @keydown="inputEvent"/>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="currentPage === 1" style="width: 100%" class="mt-4">
            <div v-if="machinery.length > 0">
                <div class="no-padding" v-for="(machine, index) in machinery" v-bind:key="index">
                    <h5>{{getTitleFromType(machine.type)}}</h5>
                    <div class="row pb-4">
                        <div  class="col-md-4 col-sm-12">
                            <label>Nome</label>
                            <div>
                                <InputText type="text" v-model="machine.name" style="width: 100%"/>
                            </div>
                        </div>
                        <div v-if="machine.time_control === 'DAILY'" class="col-md-4 col-sm-12">
                            <label>Número de Diárias</label>
                            <div style="width: 100%">
                                <InputText v-model="machine.final_hour_meter" type="number" style="width: 100%"/>
                            </div>
                        </div>
                        <div v-if="machine.time_control === 'HOURLY'" class="col-md-4 col-sm-12">
                            <label>Horímetro Inicial</label>
                            <div style="width: 100%">
                                <InputText v-model="machine.initial_hour_meter" step="0.01" type="number" style="width: 100%"/>
                            </div>
                        </div>
                        <div v-if="machine.time_control === 'HOURLY'" class="col-md-4 col-sm-12">
                            <label>Horímetro Final</label>
                            <div style="width: 100%">
                                <InputText v-model="machine.final_hour_meter" step="0.01" type="number" style="width: 100%"
                                           :disabled="create_order"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="center">
                Nenhum maquinário selecionado
            </div>
        </div>

        <div v-if="currentPage === 2" style="width: 100%" class="mt-4">
            <div class="row no-gutters">
                <div class="col">
                    <div style="display: flex;align-items: center; margin-bottom: 20px">
                        <Dropdown v-model="selectedFieldDropDown" :options="fieldsNamesDropDown"
                                  optionLabel="name" placeholder="Adicione mais Trechos" dataKey="id"
                                  :filter="true" emptyFilterMessage="Não há talhões com este filtro">
                            <template #option="slotProps">
                                <div  style="width:20vw !important" v-tooltip=slotProps.option.name>
                                    <span>{{slotProps.option.name}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <Button style="margin-left: 10px" @click="addFieldDropDown">
                            Adicionar <span class="ml-2 pi pi-plus-circle"/>
                        </Button>
                    </div>
                    <div style="overflow: hidden;">
                        <app-anomalias-google-maps-review :polygons_data="selectedPolygonsGoogleMaps"
                                                          :center_location="centerLocation"
                                                          :update="updateGoogleMaps"
                                                          :show_fields="true"
                                                          :fix_point_markers="fix_point_markers"
                                                          id="google-maps-review"
                                                          class="google-maps">
                        </app-anomalias-google-maps-review>
                        <div class="mt-2" style="font-weight: bold">
                            Lista de Trechos Selecionados
                        </div>
                        <div style="max-height: 120px;overflow-y: scroll;" v-html="fieldsNamesListToShow">
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="pl-4" style="overflow: hidden;" >
                        <div class="review-title">
                            Número de trechos:
                        </div>
                        <div>
                            {{ fieldsIdsList.length }}
                        </div>

                        <div class="review-title mt-4">
                            Número de pontos isolados:
                        </div>
                        <div>
                            {{ fix_point_markers.length }}
                        </div>

                        <div class="review-title mt-4">
                            Dia e Horário:
                        </div>
                        <div>
                            {{ selectedDate.toLocaleString() }}
                        </div>

                        <div class="review-title mt-4">
                            Motoniveladoras:
                        </div>
                        <div>
                            x{{ motoniveladoras }}
                        </div>

                        <div class="review-title mt-4">
                            Pás-Carregadeiras:
                        </div>
                        <div>
                            x{{ pasCarregadeiras }}
                        </div>

                        <div class="review-title mt-4">
                            Caminhões Basculantes:
                        </div>
                        <div>
                            x{{ caminhoesBasculantes }}
                        </div>

                        <div class="review-title mt-4">
                            Caminhões Pipa:
                        </div>
                        <div>
                            x{{ caminhoesPipa }}
                        </div>

                        <div class="review-title mt-4">
                            Escavadeiras:
                        </div>
                        <div>
                            x{{ escavadeira }}
                        </div>

                        <div class="review-title mt-4">
                            Retro-Escavadeira:
                        </div>
                        <div>
                            x{{ retroEscavadeira }}
                        </div>

                        <div class="review-title mt-4">
                            Observação:
                        </div>
                        <div>
                            {{ observation }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <app-button v-if="currentPage > 0" @handleClick="handleBack" type="danger"
                        style="position: fixed;left: 20px">
                <span class="pi pi-chevron-left"></span>
                Voltar
            </app-button>

            <app-button @handleClick="handleNext" type="primary" :disabled="loadingUpdateOrder" :class="spinnerClass">
                {{nextButtonTitle}}
                <span :class="nextButtonIcon"></span>
            </app-button>
        </template>
    </Dialog>

</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import AppButton from "../../common/AppButton";
import Calendar from 'primevue/calendar';
import AppAnomaliasGoogleMapsReview from "./AppAnomaliasGoogleMapsReview";
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import AnomaliasOrderService from '../../../services/AnomaliasOrderService'
import Tooltip from "primevue/tooltip";
import {ANOMALIAS_MACHINERY} from "@/components/views/anomalias/AppAnomaliasOptions";

export default {
    mounted() {
        this.anomaliasOrderService = new AnomaliasOrderService();
        this.width_dialog = this.isScreenMobile ? '85vw' : '50vw';
    },
    data() {
        return {
            width_dialog: '',
            showDialog: false,
            currentPage: 0,
            dateModelDay: new Date(),
            dateModelTime: new Date(),
            centerLocation: null,
            selectedPolygonsGoogleMaps: [],
            updateGoogleMaps: false,
            observation: '',
            motoniveladoras: 2,
            pasCarregadeiras: 1,
            caminhoesBasculantes: 1,
            caminhoesPipa: 1,
            escavadeira: 1,
            retroEscavadeira: 1,
            anomaliasOrderService: null,
            updateOrderId: -1,
            loadingUpdateOrder: false,
            machinery: null,
            machineryFromProps: [],
            status: 0,
            selectedFieldDropDown: null,
            fieldsAddedInDropDown: [],
            maxNumberOfVehicles: 10,
            minNumberOfVehicles: 0,
            anomaliasMachinery: ANOMALIAS_MACHINERY
        }
    },
    methods: {
        getTitleFromType(type) {
            let machinery = this.anomaliasMachinery.find(obj => obj.code === type);
            return machinery.name;
        },
        inputEvent(event) {
            setTimeout(function () { event.target.focus() }.bind(this), 1)
        },
        addFieldDropDown() {
            if (!this.selectedFieldDropDown) {
                return;
            }

            this.fieldsAddedInDropDown.push(this.selectedFieldDropDown);
            this.processFieldsToMaps();
        },
        createOrUpdateOrder() {
            let machinery_list = [];
            this.machinery.forEach(machine => {
                machinery_list.push({
                    type: machine.type,
                    name: machine.name,
                    initial_hour_meter: parseFloat(machine.initial_hour_meter),
                    final_hour_meter: parseFloat(machine.final_hour_meter),
                })
            })

            let points_list = [];
            this.fix_point_markers.forEach(point => {
                points_list.push({
                    latitude: point.lat,
                    longitude: point.lng
                })
            })

            let order = {
                observation: this.observation,
                start_date: this.selectedDate.valueOf() / 1000,
                end_date: this.end_date,
                field_ids: this.fieldsIdsList,
                order_id: this.updateOrderId,
                status: this.status,
                machinery_list: machinery_list,
                points_list: points_list
            };

            this.loadingUpdateOrder = true;

            if (this.create_order) {
                this.anomaliasOrderService.createOrder(this.organization_id, order)
                    .then((response) => {
                        this.$toast.add({severity: 'success', summary: 'Ordem criada com sucesso', life: 3000});
                        this.handleClose()
                    }).catch((error) => {
                        if (error.response && error.response.status === 403) {
                            this.$toast.add({severity: 'error', summary: error.response.data.details, life: 3000});
                        } else {
                            this.$toast.add({severity: 'error', summary: 'Ordem não pôde ser criada', life: 3000});
                        }
                    }).finally(() => {
                        this.loadingUpdateOrder = false;
                    })
                return;
            }

            this.anomaliasOrderService.updateOrder(order.order_id, order)
                .then((response) => {
                    this.$toast.add({severity: 'success', summary: 'Ordem atualizada com sucesso', life: 3000});
                    this.handleClose()
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        this.$toast.add({severity: 'error', summary: error.response.data.details, life: 3000});
                    } else {
                        this.$toast.add({severity: 'error', summary: 'Ordem não pôde ser criada', life: 3000});
                    }
                }).finally(() => {
                    this.loadingUpdateOrder = false;
                })
        },
        handleBack() {
            this.currentPage--;
        },
        async handleNext() {
            if (this.currentPage === 2) {
                await this.createOrUpdateOrder();
                return;
            }

            this.currentPage++;

            if (this.currentPage === 1) {
                this.buildMachineryList();
                return;
            }

            await this.sleep(1);
            this.processFieldsToMaps();
        },
        getMachineFromProps(type, arrayOfMachinery) {
            for (let j = 0; j < this.machineryFromProps.length; j++) {
                if (this.machineryFromProps[j].type === type
                        && !arrayOfMachinery.find(obj => {return obj.name === this.machineryFromProps[j].name})) {
                    return this.machineryFromProps[j]
                }
            }
        },
        buildMachineryList() {
            let arrayOfMachinery = [];
            for (let i = 1; i <= this.motoniveladoras; i++) {
                let machineFromProps = this.getMachineFromProps(this.anomaliasMachinery[0].code, arrayOfMachinery);

                if (machineFromProps) {
                    machineFromProps['time_control'] = this.anomaliasMachinery[0].time_control;
                    arrayOfMachinery.push(machineFromProps);
                    continue;
                }

                arrayOfMachinery.push({
                    type: this.anomaliasMachinery[0].code,
                    time_control: this.anomaliasMachinery[0].time_control,
                    name: 'Motoniveladora ' + i,
                    initial_hour_meter: 0,
                    final_hour_meter: 0,
                })
            }
            for (let i = 1; i <= this.pasCarregadeiras; i++) {
                let machineFromProps = this.getMachineFromProps(this.anomaliasMachinery[1].code, arrayOfMachinery);

                if (machineFromProps) {
                    machineFromProps['time_control'] = this.anomaliasMachinery[1].time_control;
                    arrayOfMachinery.push(machineFromProps);
                    continue;
                }

                arrayOfMachinery.push({
                    type: this.anomaliasMachinery[1].code,
                    time_control: this.anomaliasMachinery[1].time_control,
                    name: 'Pá-Carregadeira ' + i,
                    initial_hour_meter: 0,
                    final_hour_meter: 0,
                })
            }
            for (let i = 1; i <= this.caminhoesBasculantes; i++) {
                let machineFromProps = this.getMachineFromProps(this.anomaliasMachinery[2].code, arrayOfMachinery);

                if (machineFromProps) {
                    machineFromProps['time_control'] = this.anomaliasMachinery[2].time_control;
                    arrayOfMachinery.push(machineFromProps);
                    continue;
                }

                arrayOfMachinery.push({
                    type: this.anomaliasMachinery[2].code,
                    time_control: this.anomaliasMachinery[2].time_control,
                    name: 'Caminhão Basculante ' + i,
                    initial_hour_meter: 0,
                    final_hour_meter: 0,
                })
            }
            for (let i = 1; i <= this.caminhoesPipa; i++) {
                let machineFromProps = this.getMachineFromProps(this.anomaliasMachinery[3].code, arrayOfMachinery);

                if (machineFromProps) {
                    machineFromProps['time_control'] = this.anomaliasMachinery[3].time_control;
                    arrayOfMachinery.push(machineFromProps);
                    continue;
                }

                arrayOfMachinery.push({
                    type: this.anomaliasMachinery[3].code,
                    time_control: this.anomaliasMachinery[3].time_control,
                    name: 'Caminhão Pipa ' + i,
                    initial_hour_meter: 0,
                    final_hour_meter: 0,
                })
            }

            for (let i = 1; i <= this.escavadeira; i++) {
                let machineFromProps = this.getMachineFromProps(this.anomaliasMachinery[4].code, arrayOfMachinery);

                if (machineFromProps) {
                    machineFromProps['time_control'] = this.anomaliasMachinery[4].time_control;
                    arrayOfMachinery.push(machineFromProps);
                    continue;
                }

                arrayOfMachinery.push({
                    type: this.anomaliasMachinery[4].code,
                    time_control: this.anomaliasMachinery[4].time_control,
                    name: 'Escavadeira ' + i,
                    initial_hour_meter: 0,
                    final_hour_meter: 0,
                })
            }

            for (let i = 1; i <= this.retroEscavadeira; i++) {
                let machineFromProps = this.getMachineFromProps(this.anomaliasMachinery[5].code, arrayOfMachinery);

                if (machineFromProps) {
                    machineFromProps['time_control'] = this.anomaliasMachinery[5].time_control;
                    arrayOfMachinery.push(machineFromProps);
                    continue;
                }

                arrayOfMachinery.push({
                    type: this.anomaliasMachinery[5].code,
                    time_control: this.anomaliasMachinery[5].time_control,
                    name: 'Retro-Escavadeira ' + i,
                    initial_hour_meter: 0,
                    final_hour_meter: 0,
                })
            }
            this.machinery = arrayOfMachinery;
        },
        clearData() {
            this.dateModelDay = new Date();
            this.dateModelTime = new Date();
            this.currentPage = 0;
            this.selectedPolygonsGoogleMaps = [];
            this.motoniveladoras = 2;
            this.pasCarregadeiras = 1;
            this.caminhoesBasculantes = 1;
            this.caminhoesPipa = 1;
            this.escavadeira = 1;
            this.retroEscavadeira = 1;
            this.observation = '';
            this.updateOrderId = -1;
            this.machinery = null;
            this.machineryFromProps = [];
            this.selectedFieldDropDown = null;
            this.fieldsAddedInDropDown = [];
        },
        handleClose() {
            this.clearData();
            this.$emit('handleClose');
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        processFieldsToMaps() {
            let tempSelectedPolygonsGoogleMaps = [];
            this.polygons_google_maps.forEach(polygon => {
                let foundField = false;
                this.selected_fields.forEach(selectedField => {
                    if (polygon['id'] === selectedField.id) {
                        tempSelectedPolygonsGoogleMaps.push(polygon);
                        foundField = true;
                        return
                    }
                })

                if (foundField) {
                    return;
                }

                this.fieldsAddedInDropDown.forEach(polygonInDropDown => {
                    if (polygonInDropDown['id'] === polygon.id) {
                        tempSelectedPolygonsGoogleMaps.push(polygon);
                        return;
                    }
                })
            })
            this.selectedPolygonsGoogleMaps = tempSelectedPolygonsGoogleMaps;

            this.processCenterLocation()
        },
        processCenterLocation() {
            if (this.selectedPolygonsGoogleMaps.length !== 0) {
                this.centerLocation ={
                    lat: this.selectedPolygonsGoogleMaps[0].geom.getCoordinates()[0].y,
                    lng: this.selectedPolygonsGoogleMaps[0].geom.getCoordinates()[0].x
                }
                return;
            }

            if (this.fix_point_markers.length !== 0) {
                this.centerLocation = {lat: this.fix_point_markers[0].lat, lng: this.fix_point_markers[0].lng};
                return;
            }

            this.centerLocation = {lat: -25.407688, lng: -49.253990};
        },
        getNumberOfMachinesByType(machineryArray, type) {
            let numberOfMachinesByType = 0;
            machineryArray.forEach(machine => {
                if (machine.type === type) {
                    numberOfMachinesByType++;
                }
            })

            return numberOfMachinesByType;
        }
    },
    props: {
        display_dialog: {
            type: Boolean,
            default: false,
            required: true
        },
        selected_fields: {
            type: Array,
            required: true
        },
        polygons_google_maps: {
            type: Array,
            required: true
        },
        order_data: {
            type: Object,
        },
        create_order: {
            type: Boolean,
            default: true
        },
        organization_id: {
            type: String,
            required: true
        },
        fix_point_markers: {
            type: Array,
            required: true
        },
    },
    watch: {
        display_dialog: function (val) {
            this.showDialog = val;
        },
        order_data: function (val) {
            this.motoniveladoras = this.getNumberOfMachinesByType(val.machinery_list, this.anomaliasMachinery[0].code);
            this.pasCarregadeiras = this.getNumberOfMachinesByType(val.machinery_list, this.anomaliasMachinery[1].code)
            this.caminhoesBasculantes = this.getNumberOfMachinesByType(val.machinery_list, this.anomaliasMachinery[2].code)
            this.caminhoesPipa = this.getNumberOfMachinesByType(val.machinery_list, this.anomaliasMachinery[3].code)
            this.escavadeira = this.getNumberOfMachinesByType(val.machinery_list, this.anomaliasMachinery[4].code)
            this.retroEscavadeira = this.getNumberOfMachinesByType(val.machinery_list, this.anomaliasMachinery[5].code)
            this.observation = val.observation;
            this.dateModelDay =  new Date(0);
            this.dateModelDay.setUTCSeconds(val.start_date);
            this.dateModelTime = new Date(0);
            this.dateModelTime.setUTCSeconds(val.start_date);
            this.updateOrderId = val.order_id;
            this.machineryFromProps = val.machinery_list;
            this.end_date = val.end_date;
            this.status = val.status === 'CLOSED' ? 2 : 0; //Não salva status atrasado
        },
        motoniveladoras: function (val) {
            if (val < this.minNumberOfVehicles) {
                this.motoniveladoras = this.minNumberOfVehicles;
                return;
            }
            if (val > this.maxNumberOfVehicles) {
                this.motoniveladoras = this.maxNumberOfVehicles;
                return;
            }
        },
        pasCarregadeiras: function (val) {
            if (val < this.minNumberOfVehicles) {
                this.pasCarregadeiras = this.minNumberOfVehicles;
                return;
            }
            if (val > this.maxNumberOfVehicles) {
                this.pasCarregadeiras = this.maxNumberOfVehicles;
                return;
            }
        },
        caminhoesBasculantes: function (val) {
            if (val < this.minNumberOfVehicles) {
                this.caminhoesBasculantes = this.minNumberOfVehicles;
                return;
            }
            if (val > this.maxNumberOfVehicles) {
                this.caminhoesBasculantes = this.maxNumberOfVehicles;
                return;
            }
        },
        caminhoesPipa: function (val) {
            if (val < this.minNumberOfVehicles) {
                this.caminhoesPipa = this.minNumberOfVehicles;
                return;
            }
            if (val > this.maxNumberOfVehicles) {
                this.caminhoesPipa = this.maxNumberOfVehicles;
                return;
            }
        },
        escavadeira: function (val) {
            if (val < this.minNumberOfVehicles) {
                this.escavadeira = this.minNumberOfVehicles;
                return;
            }
            if (val > this.maxNumberOfVehicles) {
                this.escavadeira = this.maxNumberOfVehicles;
                return;
            }
        },
        retroEscavadeira: function (val) {
            if (val < this.minNumberOfVehicles) {
                this.retroEscavadeira = this.minNumberOfVehicles;
                return;
            }
            if (val > this.maxNumberOfVehicles) {
                this.retroEscavadeira = this.maxNumberOfVehicles;
                return;
            }
        },
    },
    components: {
        Dialog, Button, AppButton, Calendar, AppAnomaliasGoogleMapsReview, Dropdown, Textarea, InputNumber, InputText
    },
    computed: {
        selectVehiclesPageClass: function () {
            return this.currentPage === 0 ? 'col step-current' : 'col step-old';
        },
        machineryPageClass: function () {
            return this.currentPage === 1 ? 'col step-current' : 'col step-old';
        },
        reviewPageClass: function () {
            return this.currentPage === 2 ? 'col step-current' : 'col step-old';
        },
        nextButtonTitle: function () {
            return this.currentPage < 2 ? 'Próximo' : this.finishOrderButtonText;
        },
        nextButtonIcon: function () {
            return this.currentPage < 2 ? 'pi pi-chevron-right pl-2' : 'pi pi-check pl-2';
        },
        fieldsNamesDropDown: function () {
            let fieldsNotSelected = [];
            this.polygons_google_maps.forEach(polygon => {
                let foundField = false
                this.selected_fields.forEach(selectedField => {
                    if (polygon['id'] === selectedField.id) {
                        foundField = true;
                        return;
                    }
                })

                this.fieldsAddedInDropDown.forEach(fieldInDropDown => {
                    if (polygon['id'] === fieldInDropDown.id) {
                        foundField = true;
                        return;
                    }
                })

                if (!foundField) {
                    fieldsNotSelected.push({name: polygon.name, id: polygon['id']})
                }
            })
            return fieldsNotSelected;
        },
        fieldsNamesListToShow: function () {
            let stringToShow = "";
            this.polygons_google_maps.forEach(polygon => {
                let foundField = false;
                this.selected_fields.forEach(selectedField => {
                    if (polygon['id'] === selectedField.id) {
                        stringToShow += polygon.name + '<br/>';
                        foundField = true;
                        return;
                    }
                })

                if (foundField) {
                    return;
                }

                this.fieldsAddedInDropDown.forEach(polygonInDropDown => {
                    if (polygonInDropDown['id'] === polygon.id) {
                        stringToShow += polygon.name + '<br/>';
                        return;
                    }
                })
            })
            return stringToShow;
        },
        selectedDate: function () {
            let newDate = this.dateModelDay;
            newDate.setHours(this.dateModelTime.getHours());
            newDate.setMinutes(this.dateModelTime.getMinutes());
            return newDate;
        },
        fieldsIdsList: function () {
            let idsList = [];
            this.polygons_google_maps.forEach(polygon => {
                let foundField = false;
                this.selected_fields.forEach(selectedField => {
                    if (polygon['id'] === selectedField.id) {
                        idsList.push(selectedField.id);
                        foundField = true;
                        return;
                    }
                })

                if (foundField) {
                    return;
                }

                this.fieldsAddedInDropDown.forEach(polygonInDropDown => {
                    if (polygonInDropDown['id'] === polygon.id) {
                        idsList.push(polygon.id);
                        return;
                    }
                })
            })
            return idsList;
        },
        finishOrderButtonText: function () {
            return this.create_order ? 'Agendar' : 'Atualizar';
        },
        spinnerClass: function () {
            return this.loadingUpdateOrder ?
                    'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark' : ''
        },
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>

.step-current {
    border-bottom: 1px solid #2196f3;
    color: #2196f3;
}

.step-old {
    border-bottom: 1px solid #bed3e8;
    color: #50575e;
}

.center {
    display:flex;
    justify-content:center;
    align-items:center;
}

.google-maps {
    height: 30vh;
}

.p-field * {
    display: block;
}

.review-title {
    font-weight: bold;
    font-size: 14px;
}

.p-dropdown	 {
    width: 10vw !important;
}

</style>
