<template>
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                    <div class="row center">
                        <Dropdown v-model="selectedOrg" :options="orgsList"
                                  optionLabel="display_name" placeholder="Organização"
                                  class="auto-center dropdown-width-org" :dataKey="'id'"
                                  :filter="true"
                                  style="width: 250px">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingClients"> Carregando, aguarde</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                        <Dropdown v-model="selectedField" :options="fieldsList"
                                  optionLabel="name" placeholder="Talhões"
                                  class="auto-center dropdown-width-field" :filter="true" :dataKey="'id'"
                                  style="width: 250px;" ref="fieldsDropDown">
                            <template #option="slotProps" style="max-width: 250px">
                                <div v-if="loadingFields"> Carregando, aguarde</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.name }}
                                </div>
                            </template>
                        </Dropdown>

                        <div v-show="show_back" class="button-back">
                            <app-button type="secondary" @handleClick="backToTasksList">
                                <i class="glyphicons glyphicons-arrow-left"></i> <span v-show="!is_col_12">Voltar</span>
                            </app-button>
                        </div>
                    </div>

                </div>
                <div class="kt-portlet__body no-padding">
                    <div class="agro-body" v-show="selectedField && !loadingTasks && !taskToCompare">
                        <splitpanes class="default-theme" horizontal style="height: calc(100vh - 81px) !important;"
                                    @resize="redrawTimeline">
                            <pane size="70">
                                <span>
                                    <app-tasks-list v-if="!taskAnalyze" ref="tasksList"
                                                    :tasks="tasksToShow" :selected_field="selectedField"
                                                    @allClicked="allClicked"
                                                    @scheduledClicked="scheduledClicked"
                                                    @doneClicked="doneClicked"
                                                    @taskClickedFromList="taskClickedFromList"
                                                    @hoverTask="hoverTask"
                                                    @leaveTask="leaveTask"/>

                                    <app-tasks-analyze v-else class="tasks-analyze" :selected_task="selectedTask"
                                                       :tasks="tasks"
                                                       :field_center="fieldCenter"
                                                       :selected_field="selectedField"
                                                       :selected_org="selectedOrg"
                                                       :field_work_dates="fieldWorkDates"
                                                       @compareTasks="compareTasks"
                                                       @taskChangeAnalyze="taskChangeAnalyze"
                                                       @backToTasksList="backToTasksList"></app-tasks-analyze>
                                </span>
                            </pane>
                            <pane size="30">
                                <span>
                                    <div class="timeline-body">
                                        <app-timeline ref="timeline" class="analyze-tasks-timeline"
                                                      :items="itemsToShow" :options="options"
                                                      @handleItemOver="handleItemOver"
                                                      @handleItemOut="handleItemOut"
                                                      @handleSelect="handleSelect"/>
                                    </div>
                                </span>
                            </pane>
                        </splitpanes>
                    </div>
                    <div v-show="!selectedField">
                        <div class="row no-gutters wraper text-center">
                            <div class="col-12">
                                <h3>Selecione a organização e um talhão para analisar os trabalhos</h3>
                            </div>
                        </div>
                    </div>
                    <div v-show="loadingTasks">
                        <ProgressSpinner
                            style="display: flex;align-items: center;justify-content: center;height: 40px"/>
                    </div>
                    <div v-if="taskToCompare" class="tasks-compare">
                        <app-tasks-compare class="tasks-compare"
                                           :selected_task="selectedTask"
                                           :task_to_compare="taskToCompare"
                                           :tasks="tasks"
                                           :field_center="fieldCenter"
                                           :selected_field="selectedField"
                                           :field_work_dates="fieldWorkDates"
                                           @compareTasks="compareTasks"
                                           @backToTasksList="backToTasksList"></app-tasks-compare>
                    </div>
                </div>
            </div>
        </div>
</template>


<script>
import CropsService from "@/services/CropsService";
import TasksService from "@/services/TasksService";
import FieldsService from "@/services/FieldsService";
import FieldWorksService from "@/services/FieldWorksService";
import OrganizationsService from "@/services/OrganizationsService";

import AppButton from "@/components/common/AppButton";
import AppTimeline from "@/components/common/AppTimeline";
import AppTasksAnalyze from "@/components/views/agriculture/tasks/AppTasksAnalyze";
import AppTasksList from "@/components/views/agriculture/tasks/AppTasksList";
import AppTasksCompare from "@/components/views/agriculture/tasks/AppTasksCompare";
import {CROPS_LIST, TASKS_LIST} from "@/components/views/agriculture/AppAgricultureOptions";

import 'splitpanes/dist/splitpanes.css'
import {Splitpanes, Pane} from 'splitpanes'

import Button from 'primevue/button'
import Tooltip from "primevue/tooltip";
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from "primevue/progressspinner";

import * as jsts from 'jsts';
import moment from "moment/moment";
import dateFormat from "@/mixins/DateMixin";
import gmapsInit from "@/utils/gmaps";
import jstsWithoutHolesToGoogleMaps from "@/mixins/GeometryMixin";

export default {
    mixins: [dateFormat, jstsWithoutHolesToGoogleMaps],
    async mounted() {
        this.google_maps_reference = await gmapsInit();
        this.organizationsService = new OrganizationsService();
        this.getOrgs();
        this.fieldsService = new FieldsService();
        this.cropsService = new CropsService();
        this.tasksService = new TasksService();
        this.fieldWorksService = new FieldWorksService();
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            google_maps_reference: null,
            is_col_12: false,
            tasks: [],
            show_back: false,
            tasksToShow: [],
            timelineHoveredItem: null,
            orgsList: null,
            selectedOrg: null,
            loadingClients: true,
            fieldsService: null,
            cropsService: null,
            fieldsList: [],
            loadingFields: false,
            selectedField: null,
            loadingTasks: false,
            taskAnalyze: false,
            selectedTask: null,
            taskToCompare: null,
            items: [],
            itemsToShow: [],
            options: {
                locale: "pt",
                locales: {
                    pt: {
                        current: "atual",
                        time: "tempo",
                    },
                },
                orientation: {
                    axis: 'bottom',
                    item: 'bottom'
                },
                min: new Date(2021, 5, 1), // data mínima
                max: new Date(2027, 0, 1), // data máxima
                zoomMin: 1000 * 60 * 60 * 24 * 7, // uma semana em ms
                zoomMax: 1000 * 60 * 60 * 24 * 31 * 24, // dois anos em ms
                multiselect: false,
                showCurrentTime: true,
                editable: false,
                moveable: true,
                stack: false,
            },
            tasksService: null,
            timeline: null,
            minZoom: 4,
            maxZoom: 19,
            fieldCenter: {lat: -25.407688, lng: -49.253990},
            cropsListDefault: CROPS_LIST,
            tasksListDefault: TASKS_LIST,
            changedOrg: false,
            fieldWorksService: null,
            SECONDS_TO_NOON: 43200,
            fieldWorkDates: [],
        }
    },
    methods: {
        allClicked() {
            this.tasksToShow = this.getTasksFromItems();
            this.initializeItemsToShowFromTasks(this.tasksToShow);
        },
        scheduledClicked() {
            let tasksTemp = [];
            this.items.forEach(item => {
                if (item.group === 'task' && item.status === 'scheduled') {
                    tasksTemp.push(item)
                }
            })
            this.tasksToShow = tasksTemp;
            this.initializeItemsToShowFromTasks(this.tasksToShow);
        },
        doneClicked() {
            let tasksTemp = [];
            this.items.forEach(item => {
                if (item.group === 'task' && item.status === 'done') {
                    tasksTemp.push(item)
                }
            })
            this.tasksToShow = tasksTemp;
            this.initializeItemsToShowFromTasks(this.tasksToShow);
        },
        handleSelect(event) {
            this.selectTaskFromTimeline(event.items[0])
        },
        handleItemOver(event) {
            if (!this.selectedTask) {
                this.$refs.tasksList.timelineHover(event.item);
            }
        },
        handleItemOut(event) {
            if (!this.selectedTask) {
                this.$refs.tasksList.timelineHoverOut(event.item);
            }
        },
        compareTasks(taskToCompare) {
            this.taskToCompare = taskToCompare;
            this.show_back = true;
        },
        backToTasksList() {
            this.show_back = false;
            this.taskAnalyze = false;
            this.taskToCompare = null;
            this.selectedTask = null;
            this.allClicked();
        },
        taskChangeCompare(task) {
            this.selectedTask = task;
        },
        taskChangeAnalyze(task) {
            this.timeline.setFocusItem(task);
            this.taskClickedFromList(task);
        },
        async taskClickedFromList(task) {
            this.allClicked();
            await this.sleep(1);

            this.selectedTask = task;
            this.timeline.unselectAll();
            this.timeline.clearHover(task);
            this.timeline.setSelection(task.id);
            this.timeline.setFocusItem(task);
            this.goToAnalyzeTask();
        },
        selectTaskFromTimeline(itemId) {
            let task = this.tasks.find(obj => {
                return obj.id === itemId
            })

            if (!task) {
                return;
            }

            this.taskClickedFromList(task);
        },
        goToAnalyzeTask() {
            this.taskAnalyze = true;
        },
        getStatusIcon(status) {
            return status === 'done' ? 'pi pi-check-circle' : 'pi pi-calendar-times';
        },
        taskStatusTooltip(status) {
            return status === 'done' ? 'Finalizada' : 'Agendada';
        },
        hoverTask(task) {
            this.timeline.setFocusItem(task);
            this.timeline.setHover(task);
        },
        leaveTask(task) {
            this.timeline.clearHover(task);
        },
        timelineHover(item) {
            if (this.taskAnalyze) {
                return;
            }
            this.$refs.tasksList.timelineHover(item);
        },
        timelineHoverOut(item) {
            if (this.taskAnalyze) {
                return;
            }
            this.$refs.tasksList.timelineHoverOut(item);
        },
        redrawTimeline() {
            this.timeline.redraw();
        },
        getOrgs() {
            this.organizationsService.getAllV3('AGRICULTURE')
                .then((response) => {
                    this.setOrgsList(response);
                    this.selectSavedOrg();
                }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.loadingClients = false;
            })
        },
        setOrgsList(response) {
            this.orgsList = response.sort(function (a, b) {
                return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
            });
        },
        selectSavedOrg() {
            //se so tem um já seleciona
            if (this.orgsList.length === 1) {
                this.selectedOrg = this.orgsList[0];
                return;
            }

            let url_org_id = this.$route.query.org_id;
            let storedChosenOptions = {};
            if (url_org_id) {
                storedChosenOptions["selectedOrg"] = this.orgsList.find(obj => {return obj.id === url_org_id});
            } else {
                storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS));
            }
            if (storedChosenOptions && storedChosenOptions.selectedOrg && this.orgsList.find(obj => {return obj.id === storedChosenOptions.selectedOrg.id})) {
                this.selectedOrg = storedChosenOptions.selectedOrg;
            }
        },
        getFieldsFromOrg(val) {
            this.loadingFields = true;
            this.fieldsList = [];
            let fieldSelected = false
            this.fieldsService.getFieldsSummaryV3(val.id).then((response) => {
                this.fieldsList = response.fields.sort((a, b) => a.name.localeCompare(b.name));

                this.fieldsList.forEach(field => {
                    field.area = this.calculateArea(field);
                })

                if (!this.changedOrg || this.$route.query.field_id) {
                    fieldSelected = this.selectSavedField();
                }
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possivel completar a operação. Tente novamente',
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Você não tem acesso a esta funcionalidade',
                    life: 5000
                });
            }).finally(() => {
                this.loadingFields = false;
                if (!fieldSelected) {
                    this.$refs.fieldsDropDown.show();
                }
                this.changedOrg = true;
            })
        },
        selectSavedField() {
            //se so tem um já seleciona
            if (this.fieldsList.length === 1) {
                this.selectedField = this.fieldsList[0];
                return true;
            }

            let url_field_id = this.$route.query.field_id;
            let storedChosenOptions = {};
            if (url_field_id) {
                storedChosenOptions["selectedField"] = this.fieldsList.find(obj => obj.id.toString() === url_field_id);
            } else {
                storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS));
            }

            if (storedChosenOptions && storedChosenOptions.selectedField && this.fieldsList.find(obj => {return obj.id === storedChosenOptions.selectedField.id})) {
                this.selectedField = storedChosenOptions.selectedField;
                return true;
            }

            return false;
        },
        getTasksFromItems() {
            let tasksTemp = [];
            this.items.forEach(item => {
                if (item.group === 'task') {
                    tasksTemp.push(item)
                }
            })

            return tasksTemp;
        },
        getCropsAndTasks() {
            const promises = [];
            let vm = this;
            this.loadingTasks = true;

            promises.push(this.cropsService.getCrops(this.selectedField.id));
            promises.push(this.tasksService.getTasks(this.selectedField.id));
            promises.push(this.fieldWorksService.getFieldWorksDates(this.selectedField.id));

            Promise.all(promises)
                .then(async (results) => {
                    await vm.initCropsFromDatabase(results[0]);
                    await vm.initTasksFromDatabase(results[1]);
                    await vm.initWorkDatesDatabase(results[2]);
                    await vm.initTimeline()
                    vm.timeline.refresh()
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possível buscar os items.',
                        life: 5000
                    });
                }).finally(() => {
                this.loadingTasks = false;
            })
        },
        getContentIcon(name, className) {
            if (className === 'crop') {
                for (let i = 0; i < this.cropsListDefault.length; i++) {
                    if (this.cropsListDefault[i].name === name) {
                        return this.cropsListDefault[i].src;
                    }
                }
                return require("../../../../assets/icons/icone-leaf.svg");
            }

            for (let i = 0; i < this.tasksListDefault.length; i++) {
                if (this.tasksListDefault[i].name === name) {
                    return this.tasksListDefault[i].src;
                }
            }
            return require("../../../../assets/icons/icone-task.svg");
        },
        createTimelineContent(name, className) {
            let wrapper = document.createElement("div");
            let content = document.createElement("div");

            content.textContent = name;
            wrapper.appendChild(content);

            let wrapperImg = document.createElement("div");

            let img = document.createElement("IMG");
            img.src = this.getContentIcon(name, className);
            img.width = "30";
            img.color = "white";
            wrapperImg.appendChild(img);

            wrapper.appendChild(wrapperImg);

            return wrapper;
        },
        initCropsFromDatabase(response) {
            let itemsTemp = [];

            response.crops.forEach(crop => {
                itemsTemp.push({
                    id: crop.id,
                    type: 'background',
                    name: crop.name,
                    content: this.createTimelineContent(crop.name, 'crop'),
                    className: 'crop',
                    group: 'crop',
                    expected_yield: crop.expected_yield,
                    start: new Date(crop.start * 1000),
                    end: new Date(crop.end * 1000),
                    selectable: false,
                })
            })

            this.items = itemsTemp;
            this.itemsToShow = itemsTemp;
        },
        initTasksFromDatabase(response) {
            let itemsTemp = [];

            response.tasks.forEach(task => {
                let status;

                if (new Date(task.end * 1000) < new Date()) {
                    status = 'done'
                } else {
                    status = 'scheduled'
                }

                itemsTemp.push({
                    id: task.id,
                    type: 'range',
                    name: task.name,
                    content: this.createTimelineContent(task.name, 'task'),
                    className: 'task-analyze',
                    group: 'task',
                    expected_yield: task.expected_yield,
                    start: new Date(task.start * 1000),
                    end: new Date(task.end * 1000),
                    status: status
                })
            })

            this.tasks = itemsTemp;
            this.tasksToShow = this.tasks;
            this.items = this.items.concat(itemsTemp);
            this.itemsToShow = this.itemsToShow.concat(itemsTemp);
        },
        async initTimeline() {
            await this.sleep(1);
            this.timeline = this.$refs.timeline;
            this.timeline.redraw();
        },
        initializeItemsToShowFromTasks(tasksToShow) {
            let itemsTemp = [];
            this.items.forEach(item => {
                if (item.group !== 'task') {
                    itemsTemp.push(item);
                    return;
                }

                let taskItemToShow = tasksToShow.find((obj) => {
                    return obj.id === item.id
                });
                if (taskItemToShow) {
                    itemsTemp.push(item);
                }
            })


            this.itemsToShow = itemsTemp;
        },
        initWorkDatesDatabase(response) {
            let itemsTemp = [];
            this.fieldWorkDates = response.map((obj) => {return moment((obj.occurrence_date + this.SECONDS_TO_NOON) * 1000).startOf('day').toDate().valueOf()});
            response.forEach(workDate => {
                itemsTemp.push(
                    {
                        id: this.guidGenerator(),
                        content: "",
                        editable: false,
                        start: new Date((workDate.occurrence_date + this.SECONDS_TO_NOON) * 1000),
                        group: 'hist-manage',
                        className: 'hist-manage',
                        cultural_practice: workDate.cultural_practice,
                        title: '<b>Trabalho identificado no dia ' + this.dateFormat(workDate.occurrence_date + this.SECONDS_TO_NOON) + '</b>',
                        selectable: false,
                    }
                )
            })
            this.items.push(...itemsTemp);
            this.itemsToShow.push(...itemsTemp);
        },
        guidGenerator() {
            let S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
        },
        clearData() {
            this.items = [];
            this.tasks = [];
            this.tasksToShow = [];
            this.itemsToShow = [];
            this.selectedField = null;
            this.selectedTask = null;
            this.taskToCompare = null;
            this.taskAnalyze = false;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        calculateArea(field) {
            return (this.google_maps_reference.maps.geometry.spherical.computeArea(
                this.jstsWithoutHolesToGoogleMaps(this.buildPolygonFromCoordinates(field.coordinates),
                    this.google_maps_reference.maps)[0]) / 10000).toFixed(3);
        },
        buildPolygonFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        calculateFieldCenter(val) {
            let fieldPolygon = this.buildPolygonFromCoordinates(val.coordinates);
            let envelopeCoordinates = (fieldPolygon.getEnvelope().getCoordinates())

            let maxLat = -90;
            let maxLng = -180;
            let minLat = 90;
            let minLng = 180;

            envelopeCoordinates.forEach(coordinate => {
                if (coordinate.y > maxLat) {
                    maxLat = coordinate.y;
                }
                if (coordinate.y < minLat) {
                    minLat = coordinate.y;
                }
                if (coordinate.x > maxLng) {
                    maxLng = coordinate.x;
                }
                if (coordinate.x < minLng) {
                    minLng = coordinate.x;
                }
            })

            this.fieldCenter = {lat: (maxLat + minLat) / 2, lng: (maxLng + minLng) / 2};
        },
        saveFilterOptions() {
            let storedChosenOptions = {}
            storedChosenOptions["selectedOrg"] = this.selectedOrg;
            storedChosenOptions["selectedField"] = this.selectedField;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS, JSON.stringify(storedChosenOptions));
        },
    },
    components: {
        Button,
        AppTimeline,
        Tooltip,
        Dropdown,
        AppTasksAnalyze,
        AppTasksList,
        ProgressSpinner,
        AppTasksCompare,
        AppButton,
        Splitpanes,
        Pane
    },
    watch: {
        selectedOrg: function (val) {
            this.clearData();
            this.getFieldsFromOrg(val);
        },
        selectedField: function (val) {
            if (val) {
                this.saveFilterOptions();
                this.selectedTask = null;
                this.taskToCompare = null;
                this.taskAnalyze = false;
                this.calculateFieldCenter(val);
                this.getCropsAndTasks();
            }
        },
        taskToCompare: async function (val) {
            if (!val) {
                await this.sleep(1)
                this.timeline.refresh()
            }
        },
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    directives: {
        tooltip: Tooltip
    },
}
</script>


<style scoped lang="scss">

.splitpanes.default-theme .splitpanes__pane {
    background-color: #fff;
}

.agro-body {
    position: relative;
    height: calc(100vh - 81px) !important;
}

.tasks-compare {
    height: calc(100vh - 81px) !important;
}

.tasks-list {
    margin-left: 40px;
    margin-right: 40px;
}

.tasks-analyze {
    height: 100% !important;
}

.timeline-body {
    width: 100%;
    height: 100%;
}

.menu-dropdown{
    width: 100%;
    margin-left: 0px;
}

.button-back{
    margin-left: 10px;
    justify-content: end;
}

.dropdown-width-cliente{
    margin-right: 10px;
    width: 48%;
}

.dropdown-width-cliente-compare{
    margin-right: 10px;
    width: 38%;
}

.dropdown-width-talhoes{
    width: 48%;
}

.dropdown-width-talhoes-compare{
    margin-right: 10px;
    width: 38%;
}

.width-250{
    width: 250px;
    margin-right: 10px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-width-field{
    margin-left: 10px;
}

@media(max-width: 1024px){
    .glyphicons{
        margin: 0;
        padding: 0;
    }
}

@media(max-width: 519px){
    .dropdown-width-org{
        margin: 10px 10px 10px 10px !important;
    }

    .dropdown-width-field{
        margin: 0px 10px 10px 10px !important;
    }

    .button-back{
        margin-bottom: 8px;
    }
}
</style>
