export const optionsPieCharts = [
    {
        name: 'Análise', code: 'ANALISE',
        chart: [
            {cname: 'Análise de Atividades', code: 'ANALISE_ATIVIDADE'},
            {cname: 'Análise de Combustível', code: 'ANALISE_COMBUSTIVEL'},
        ]
    },
    {
        name: 'Motivos de paradas', code: 'PARADAS',
        chart: [
            {cname: 'Motivos de paradas', code: 'PARADAS'}
        ]
    },
    {
        name: 'Atividade', code: 'ATIVIDADE',
        chart: [
            {cname: 'Atividade x Área', code: 'ATIVIDADE_AREA'},
            {cname: 'Atividade x Tempo', code: 'ATIVIDADE_TEMPO'},
            {cname: 'Atividade x Combustível', code: 'ATIVIDADE_COMBUSTIVEL'},
            {cname: 'Atividade x Distância', code: 'ATIVIDADE_DISTANCIA'},
        ]
    },
    {
        name: 'Locais', code: 'LOCAIS',
        chart: [
            {cname: 'Locais x Área', code: 'LOCAIS_AREA'},
            {cname: 'Locais x Tempo', code: 'LOCAIS_TEMPO'},
            {cname: 'Locais x Combustível', code: 'LOCAIS_COMBUSTIVEL'},
            {cname: 'Locais x Distância', code: 'LOCAIS_DISTANCIA'},
        ]
    },
    {
        name: 'Máquina', code: 'MAQUINA',
        chart: [
            {cname: 'Máquina x Área', code: 'MAQUINA_AREA'},
            {cname: 'Máquina x Tempo', code: 'MAQUINA_TEMPO'},
            {cname: 'Máquina x Combustível', code: 'MAQUINA_COMBUSTIVEL'},
            {cname: 'Máquina x Distância', code: 'MAQUINA_DISTANCIA'},
        ]
    },
    {
        name: 'Operadores', code: 'OPERADORES',
        chart: [
            {cname: 'Operadores x Área', code: 'OPERADORES_AREA'},
            {cname: 'Operadores x Tempo', code: 'OPERADORES_TEMPO'},
            {cname: 'Operadores x Combustível', code: 'OPERADORES_COMBUSTIVEL'},
            {cname: 'Operadores x Distância', code: 'OPERADORES_DISTANCIA'},
        ]
    },
    {
        name: 'Turnos', code: 'TURNOS',
        chart: [
            {cname: 'Turnos x Área', code: 'TURNOS_AREA'},
            {cname: 'Turnos x Tempo', code: 'TURNOS_TEMPO'},
            {cname: 'Turnos x Combustível', code: 'TURNOS_COMBUSTIVEL'},
            {cname: 'Turnos x Distância', code: 'TURNOS_DISTANCIA'},
        ]
    },
];

export const optionsColumnCharts = [
    {name: 'Análise de Atividades (modos)', code: 'ANALISE_ATIVIDADES', sumInGroupMode: true},
    {name: 'Análise de Combustível (modos)', code: 'ANALISE_COMBUSTIVEL', sumInGroupMode: true},
    {name: 'Área (ha)', code: 'AREA', sumInGroupMode: true},
    {name: 'Produtividade (ha/h)', code: 'PRODUTIVIDADE', sumInGroupMode: false},
    {name: 'Disponibilidade Mecânica', code: 'DISP_MECANICA', sumInGroupMode: false},
    {name: 'Rendimento (L/ha)', code: 'RENDIMENTO', sumInGroupMode: false},
    {name: 'Consumo (L/h)', code: 'CONSUMO', sumInGroupMode: false},
    {name: 'Quilômetros por Litro (km/L)', code: 'QUILOMETRO_LITRO', sumInGroupMode: false},
    {name: 'Combustível Médio(Litros)', code: 'COMBUSTIVEL_MEDIO', sumInGroupMode: true},
    {name: 'Velocidade', code: 'VELOCIDADE', sumInGroupMode: false},
    {name: 'Horas Trabalhadas', code: 'HORAS_TRABALHADAS', sumInGroupMode: true},
    {name: 'Motivos de paradas', code: 'MOTIVOS_DE_PARADAS', sumInGroupMode: true}
];

export const optionsLineCharts = [
    {name: 'Área (ha)', code: 'AREA'},
    {name: 'Produtividade (ha/h)', code: 'PRODUTIVIDADE'},
    {name: 'Rendimento (L/ha)', code: 'RENDIMENTO'},
    {name: 'Consumo (L/h)', code: 'CONSUMO'},
    {name: 'Combustível Médio(Litros)', code: 'COMBUSTIVEL_MEDIO'},
    {name: 'Velocidade', code: 'VELOCIDADE'},
];

export const optionsSideCards = [
    {name: 'Área (ha) ', code: 'AREA'},
    {name: 'Produtividade (ha/h) ', code: 'PRODUTIVIDADE'},
    {name: 'Disponibilidade Mecânica(%)', code: 'DISP_MECANICA'},
    {name: 'Rendimento (L/ha) ', code: 'RENDIMENTO'},
    {name: 'Consumo (L/h) ', code: 'CONSUMO'},
    {name: 'Combustível (Litros) ', code: 'COMBUSTIVEL'},
    {name: 'Velocidade Média(km/h) ', code: 'VELOCIDADE_MEDIA'},
    {name: 'Eficiência(%) ', code: 'EFICIENCIA'},
    {name: 'Tempo de Motor Ligado', code: 'TEMPO'},
    {name: 'Tempo Trabalhando ', code: 'TEMPO_TRABALHANDO'},
    {name: 'Tempo Manobrando ', code: 'TEMPO_MANOBRANDO'},
    {name: 'Tempo Deslocando ', code: 'TEMPO_DESLOCANDO'},
    {name: 'Tempo Parado ', code: 'TEMPO_PARADO'},
    {name: 'Consumo Trabalhando ', code: 'CONSUMO_TRABALHANDO'},
    {name: 'Consumo Manobrando ', code: 'CONSUMO_MANOBRANDO'},
    {name: 'Consumo Deslocando ', code: 'CONSUMO_DESLOCANDO'},
    {name: 'Consumo Parado ', code: 'CONSUMO_PARADO'}

];

