export const alarmsTypes = [
    {
        type: "RPM_MAX",
        description: "RPM máximo permitido",
        suffix: "RPM",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "RPM_WORK_MIN",
        description: "RPM mínimo permitido em trabalho",
        suffix: "RPM",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPEED_WORK_MIN",
        description: "Velocidade mínima permitida em trabalho",
        suffix: "km/h",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPEED_WORK_MAX",
        description: "Velocidade máxima permitida em trabalho",
        suffix: "km/h",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPEED_TRAVEL_MAX",
        description: "Velocidade máxima permitida em deslocamento",
        suffix: "km/h",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "IDLE_TIME",
        description: "Tempo máximo permitido ocioso",
        suffix: "RPM",
        value: null,
        active: false,
        time: null,
        showEditValue: false
    },
    {
        type: "SPN_ONE_MAX",
        description: "Valor máximo permitido para o SPN1",
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_ONE_MIN",
        description: "Valor mínimo permitido para o SPN1",
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_TWO_MAX",
        description: "Valor máximo permitido para o SPN2",
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_TWO_MIN",
        description: "Valor mínimo permitido para o SPN2",
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_THREE_MAX",
        description: "Valor máximo permitido para o SPN3",
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
    {
        type: "SPN_THREE_MIN",
        description: "Valor mínimo permitido para o SPN3",
        suffix: "",
        value: null,
        active: false,
        time: null,
        showEditValue: true
    },
]
