<template>
    <div class="shadow-lg p-3 mt-4 ml-4 mr-4 bg-light rounded">
        <div class="row no-gutters mt-4">
            <div class="col-3 col-sm-2 center-trator">
                <img :src="imgUrl" @error="defaultImage" alt="Imagem de Trator"
                     class="img-trator"/>
            </div>
            <div class="col-9 col-sm-10 pl-3">
                <div class="mb-2 name-trator" style="">
                    {{ displayId }} 
                </div>
                <div class="mb-2 some-configuration">
                    Aplicar mesmas configurações
                    <Checkbox v-model="checkValue" :binary="true"
                                v-tooltip=tooltipEnum.sameConfiguration />
                </div>
                <div class="row no-gutters mt-3" style="width: 100%;">
                    <div class="col-md-3 col-6 pr-2 mt-2 mt-md-0">
                        <div class="dashboard-vehicle-card center"  style="background-color: #d62728;">
                            <div class="row no-gutters full-width">
                                <div class="col-10 col-sm-11 card-text">
                                    <div class="card-vehicle-title">
                                        Custos Totais
                                    </div>
                                    <div class="card-vehicle-value">
                                        <p style="display: inline;">R$</p>
                                        {{getDisplayValue(fixedCosts + variableCosts)}}
                                    </div>
                                </div>
                                <div class="col-2 col-sm-1">
                                    <span class="pi pi-question-circle help-style"
                                          v-tooltip=tooltipEnum.totalCosts />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 pl-2 mt-2 mt-md-0">
                        <div class="dashboard-vehicle-card center"  style="background-color: #2ca02c;">
                            <div class="row no-gutters full-width">
                                <div class="col-10 col-sm-11 card-text">
                                    <div class="card-vehicle-title">
                                        Economia Potencial
                                    </div>
                                    <div class="card-vehicle-value">
                                        <p style="display: inline;">R$</p>
                                        {{getDisplayValue(potentialSavings)}}
                                    </div>
                                </div>
                                <div class="col-2 col-sm-1">
                                    <span class="pi pi-question-circle help-style"
                                          v-tooltip=tooltipEnum.potentialSavings />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-3 mb-3">
                    <div class="row ">
                        <div class="col-md-3 col-sm-3 col-6 left">
                            <div>
                                <div class="left">Preço Total (R$)</div>
                                <div>
                                    <InputNumber style="width: 100%;" v-model="newVehiclePriceInput" :min="0" :max="10000000"
                                                 :maxFracionDigits="2" :minFractionDigits="2"
                                                 v-tooltip=tooltipEnum.newVehiclePrice
                                                 @input="fieldChanged('newVehiclePriceInput')"
                                                 mode="currency" currency="BRL"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-6 left">
                            <div>
                                <div class="">Sucata (R$)</div>
                                <div>
                                    <InputNumber style="width: 100%;" v-model="salvageInput" :min="0" :max="10000000"
                                                 :maxFracionDigits="2" :minFractionDigits="2"
                                                 mode="currency" currency="BRL"
                                                 @input="fieldChanged('salvageInput')"
                                                 v-tooltip=tooltipEnum.salvage />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-6 left">
                            <div>
                                <div class="left">Vida Útil (h)</div>
                                <div>
                                    <InputNumber style="width: 100%;" v-model="lifespanInput" :min="1" :max="100000"
                                                 @input="fieldChanged('lifespanInput')"
                                                 v-tooltip=tooltipEnum.lifespan />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-6 left">
                            <div>
                                <div class="left">Seguro (%)</div>
                                <div>
                                    <InputNumber style="width: 100%;" v-model="insuranceInput" :min="0" :max="100"
                                                 :maxFracionDigits="2" :minFractionDigits="2" mode="decimal"
                                                 @input="fieldChanged('insuranceInput')"
                                                 v-tooltip=tooltipEnum.insurance />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-6 left">
                            <div>
                                <div class="left">Manobrando (%)</div>
                                <div>
                                    <InputNumber style="width: 100%;" v-model="maneuverSavingsPercentage" :min="0" :max="100"
                                                 @input="fieldChanged('maneuverSavingsPercentage')"
                                                 v-tooltip=tooltipEnum.maneuverSavings />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-6 left">
                            <div>
                                <div class="left">Deslocando (%)</div>
                                <div>
                                    <InputNumber style="width: 100%;" v-model="travelSavingsPercentage" :min="0" :max="100"
                                                 @input="fieldChanged('travelSavingsPercentage')"
                                                 v-tooltip=tooltipEnum.travelSavings />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-6 left">
                            <div>
                                <div class="left">Ocioso (%)</div>
                                <div>
                                    <InputNumber style="width: 100%;" v-model="idleSavingsPercentage" :min="1" :max="100"
                                                 @input="fieldChanged('idleSavingsPercentage')"
                                                 v-tooltip=tooltipEnum.idleSavings />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import InputNumber from 'primevue/inputnumber';
import maskMacAddress from "../../../mixins/StringsMaskMixin";
import isMacAddress from "../../../mixins/StringsMixins"
import orderVehiclesByNaturalOrder from "../../../mixins/ArrayMixin";
import Tooltip from 'primevue/tooltip';
import Checkbox from 'primevue/checkbox';
import { tooltipTexts } from './AppCostsTooltipTextsEnum'

export default {
    mixins: [maskMacAddress, isMacAddress, orderVehiclesByNaturalOrder],
    mounted() {
        this.setVehicleImageAndChassis();
        this.calculateVariableCosts();
    },
    data() {
        return {
            imgUrl: 'https://static.iotag.com.br/vehicles/vehicle.png',
            checkValue: false,
            newVehiclePriceInput: 200000.00,
            salvageInput: 60000.00,
            lifespanInput: 10000,
            insuranceInput: 1.2,
            totalTime: 0,
            variableCosts: 0,
            maneuverSavingsPercentage: 2,
            travelSavingsPercentage: 20,
            idleSavingsPercentage: 50,
            displayId: 'Frota',
            tooltipEnum: tooltipTexts,
        }
    },
    props: {
        vehicle_statistics: {
            type: Object
        },
        labor_price: {
            type: Number
        },
        diesel_price: {
            type: Number
        },
        interest: {
            type: Number
        },
        number_of_days_filtered: {
            type: Number
        },
        chassis: {
            type: String
        },
        field_and_value_to_apply_same_config: {
            type: Object
        },
        disable_check_box: {
            type: Boolean
        },
        maintenance_price: {
            type: Number
        }
    },
    methods: {
        fieldChanged(field) {
            if (!this.checkValue) {
                return;
            }

            this.$emit('applySameConfiguration', {field: field, value: this[field]})
        },
        setVehicleImageAndChassis() {
            if (!this.vehicle_statistics.vehicle) {
                return;
            }
            this.displayId = 'Máquina ' + this.vehicle_statistics.vehicle.display_id;
            if (this.vehicle_statistics.vehicle.model) {
                let model = this.vehicle_statistics.vehicle.model.replace(/ /g, "_").toUpperCase();
                this.imgUrl = 'https://static.iotag.com.br/vehicles/' + model + '.png';
                this.displayId += ' (' + this.vehicle_statistics.vehicle.model + ')'
            }
        },
        defaultImage(event) {
            event.target.src = 'https://static.iotag.com.br/vehicles/vehicle.png';
        },
        calculateVariableCosts() {
            let variableCostsTemp = 0;
            variableCostsTemp += this.vehicle_statistics.maneuver_time * this.labor_price + this.vehicle_statistics.maneuver_fuel * this.diesel_price;
            variableCostsTemp += this.vehicle_statistics.travel_time * this.labor_price + this.vehicle_statistics.travel_fuel * this.diesel_price;
            variableCostsTemp += this.vehicle_statistics.idle_time * this.labor_price +
                    this.vehicle_statistics.idle_fuel * this.diesel_price +
                    this.vehicle_statistics.maintenance_time * this.maintenance_price;
            variableCostsTemp += this.vehicle_statistics.work_time * this.labor_price +
                    this.vehicle_statistics.work_fuel * this.diesel_price;

            this.variableCosts = variableCostsTemp;
            this.totalTime = this.vehicle_statistics.engine_on_time;
        },
        getDisplayValue(value) {
            if (!value || isNaN(value) || value < 0) {
                return 0;
            }
            return value.toFixed(2);
        },
    },
    computed: {
        potentialSavings: function () {

            let potentialManeuverSavings = (this.maneuverSavingsPercentage/100) *
                    (this.vehicle_statistics.maneuver_time * this.labor_price + this.vehicle_statistics.maneuver_fuel * this.diesel_price
                    + this.deprecationCost * (this.vehicle_statistics.maneuver_time/this.totalTime));

            let potentialTravelSavings = (this.travelSavingsPercentage/100) *
                    (this.vehicle_statistics.travel_time * this.labor_price +
                            this.vehicle_statistics.travel_fuel * this.diesel_price)
                    + this.deprecationCost * (this.vehicle_statistics.travel_time/this.totalTime);

            let potentialIdleSavings = (this.idleSavingsPercentage/100) *
                    (this.vehicle_statistics.idle_time * this.labor_price +
                            this.vehicle_statistics.idle_fuel * this.diesel_price +
                            this.vehicle_statistics.maintenance_time * this.maintenance_price
                            + this.deprecationCost * (this.vehicle_statistics.idle_time/this.totalTime));

            let potentialSavings = potentialManeuverSavings + potentialTravelSavings + potentialIdleSavings;

            this.$emit('potentialSavingsChanged', {potentialSavings: potentialSavings, chassis: this.chassis});

            return potentialSavings;
        },
        deprecationCost: function() {
            if (this.totalTime === 0) {
                return 0;
            }
            return ((this.newVehiclePriceInput - this.salvageInput) / this.lifespanInput) * this.totalTime;
        },
        insuranceCost: function() {
            if (this.totalTime === 0) {
                return 0;
            }
            return (((this.newVehiclePriceInput + this.salvageInput) / 2 * (this.insuranceInput / 100)) / 365 * this.number_of_days_filtered);
        },
        interestCost: function() {
            if (this.totalTime === 0) {
                return 0;
            }
            return (((this.newVehiclePriceInput + this.salvageInput) / 2 * (this.interest / 100)) / 365 * this.number_of_days_filtered);
        },
        fixedCosts: function() {

            if (this.totalTime === 0) {
                return 0;
            }

            let fixedCosts = this.deprecationCost + this.insuranceCost + this.interestCost;
            this.$emit('costsChanged', {deprecation: this.deprecationCost, insurance: this.insuranceCost,
                interest: this.interestCost, chassis: this.chassis, variableCost: this.variableCosts});

            return fixedCosts;
        },
    },
    watch: {
        field_and_value_to_apply_same_config: {
            deep: true,
            handler(val) {
                if (this.checkValue) {
                    this[val.field] = val.value;
                }
            }
        },
        vehicle_statistics: {
            deep: true,
            handler() {
                this.calculateVariableCosts();
            }
        },
        labor_price: {
            deep: true,
            handler() {
                this.calculateVariableCosts();
            }
        },
        diesel_price: {
            deep: true,
            handler() {
                this.calculateVariableCosts();
            }
        },
        maintenance_price: {
            deep: true,
            handler() {
                this.calculateVariableCosts();
            }
        },
    },
    components: {
        InputNumber, Tooltip, Checkbox
    },
    directives: {
        'tooltip': Tooltip
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-text {
    color: white;
}

.full-width {
    width: 100%;
}

.help-style {
    color: white;
}

.machine-card {
    border-radius: 10px;
    border: 1px solid gray;
    margin: 10px;
    background-color: #e0e0e0;
}

.dashboard-vehicle-card {
    width: 100%;
    height: 50px;
    border-radius: 10px;
}

.card-vehicle-title {
    font-size: 10px;
    margin: 0;
    padding-left: 10px;
}

.card-vehicle-value {
    font-size: 18px;
    padding-left: 10px;
}

.img-trator{
    height: 100px;
    width: 100px;
}

.center-trator{
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-trator{
    color: black;
    font-size: 18px;    
}

@media (max-width:1023px) {
    .img-trator{
        height: 60px;
        width: 60px;
    }

    .card-vehicle-value {
        font-size: 15px;
        padding-left: 10px;
    }

    .center-trator{
        border-right: 0.3px gray solid;
    }

    .name-trator{
        color: black;
        font-size: 18px;
        text-align: center;
    }

    .some-configuration{
        float: right;
        margin-right: 10px;
        font-size: 12px;
    }
}

@media (max-width: 390px){
    .img-trator{
        height: 50px;
        width: 50px;
    }

    .dashboard-vehicle-card {
        height: 70px;
    }

    .card-vehicle-value {
        font-size: 11px;
        padding-left: 10px;
    }
}

</style>
