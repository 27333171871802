<template>
    <div>
        <div v-if="selected_org === null" class="container-config no-value">
            Selecione uma organização para editar as inscrições
        </div>
        <div v-else class="container-config">
            <!-- Primeira coluna impressa na tela-->
            <Accordion class="width-accordion" :multiple="true">
                <AccordionTab v-for="tab in contentOne" :key="tab.name" style="margin-bottom: 30px" v-show="tab.show">
                    <template #header>
                        <img :src="tab.icon" alt="" class="img-width">
                        <span class="title">{{ tab.name }}</span>
                        <div class="align-right">
                            <div v-if="tab.isActive" class="style-active">Ativo</div>
                            <div v-if="!tab.isActive && tab.url != ''" class="style-inactive">Inativo</div>
                            <Button v-if="tab.url != ''" icon="pi pi-pencil" class="p-button-rounded p-button-success btn-edit" @click="handleCreateOrEdit(tab.type)"/>
                            <Button v-else icon="pi pi-plus" class="p-button-rounded p-button-success" @click="handleCreateOrEdit(tab.type)"/>
                        </div>
                    </template>

                    <div class="padding-content-accordion">
                        {{ tab.smallText}}
                    </div>

                    <Accordion class="sub-accordion">
                        <AccordionTab>
                            <template #header>
                                <i class="pi pi-info-circle img-width"></i>
                                <span class="more-info">Mais Informações</span>
                            </template>
                            <div class="padding-content-accordion">
                                <p>
                                    {{ tab.text }}
                                </p>

                                <h4 style="padding-top: 10px !important;">Exemplo de Requisição</h4>
                                <div style="background-color: #333333; border-radius: 5px;">
                                    <pre style="color: white;">{{ tab.json_response }}</pre>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>
            </Accordion>

            <!-- Segunda coluna impressa na tela -->
            <Accordion class="width-accordion" :multiple="true">
                <AccordionTab v-for="tab in contentTwo" :key="tab.name" style="margin-bottom: 30px" v-show="tab.show">
                    <template #header>
                        <img :src="tab.icon" alt="" class="img-width">
                        <span class="title">{{ tab.name }}</span>
                        <div class="align-right">
                            <div v-if="tab.isActive" class="style-active">Ativo</div>
                            <div v-if="!tab.isActive && tab.url != ''" class="style-inactive">Inativo</div>
                            <Button v-if="tab.url != ''" icon="pi pi-pencil" class="p-button-rounded p-button-success btn-edit" @click="handleCreateOrEdit(tab.type)"/>
                            <Button v-else icon="pi pi-plus" class="p-button-rounded p-button-success" @click="handleCreateOrEdit(tab.type)"/>
                        </div>
                    </template>

                    <div class="padding-content-accordion">
                        {{ tab.smallText}}
                    </div>

                    <Accordion class="sub-accordion">
                        <AccordionTab>
                            <template #header>
                                <i class="pi pi-info-circle img-width"></i>
                                <span class="more-info">Mais Informações</span>
                            </template>
                            <div class="padding-content-accordion">
                                <p>
                                    {{ tab.text }}
                                </p>

                                <h4 style="padding-top: 10px !important;">Exemplo de Requisição</h4>
                                <div style="background-color: #333333; border-radius: 5px;">
                                    <pre style="color: white;">{{ tab.json_response }}</pre>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </AccordionTab>
            </Accordion>


            <Dialog :header="isEditingSubscription ? 'Editar' : 'Criar'" :visible.sync="showDialog" :modal="true">
                <div style="padding: 0px 15px 0px 15px;">
                    <p class="title-input">URL</p>
                    <InputText class="input-style" type="text" v-model="inputUrl" />
                    <p class="title-input">Autenticação (Opcional)</p>
                    <InputText class="input-style" type="text" v-model="inputAuth" />
                    <p style="text-align: center; margin-top: 20px; font-size: 14px;">Status</p>

                    <div class="align-center">
                        <div @click="inputStatus = false" :class="{'inactive-style': inputStatus, 'inactive-highlight': !inputStatus}">Inativo</div>
                        <div @click="inputStatus = true" :class="{'active-highlight': inputStatus, 'active-style': !inputStatus}">Ativo</div>
                    </div>
                </div>
                <template #footer>
                    <Button @click="saveForm()" label="Salvar" autofocus />
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

export default {
    data() {
        return {
            createOrEdit: '',
            isEditingSubscription: false,
            // contentOne e contentTwo são referentes a primeira e segunda coluna impressas na tela
            // json_response deve ser escrito desta forma para ser exibidos corretamente quando impresso na tela
            contentOne: [
                {
                    name: "Tempo Real",
                    type: "REAL_TIME",
                    id: null,
                    url: "",
                    isActive: false,
                    authorization: null,
                    show: false,
                    smallText: `A inscrição de tempo real permite que o cliente receba informações de localização de uma máquina, assim como outros atributos essenciais para monitoramento da frota à distância. `,
                    text: `Cada veículo, caso esteja conectado, pode enviar uma requisição a cada intervalo de 5 segundos. Quando estiver offline, o dispositivo armazenará as informações localmente até que retorne online, possibilitando então o envio dos dados acumulados.
                        O conteúdo de cada JSON terá um identificador único para cada veículo, o chassi da máquina, nome da frota, timestamp (yyyy-MM-dd'T'HH:mm:ssZ), latitude (graus decimais), longitude (graus decimais), velocidade (km/h), compass_bearing (graus), nível do tanque de combustível (%),  horímetro da máquina (horas),
                        id da organização (uuid) e lista de locais que a máquina se encontra.`,
                    json_response: `{
    "vehicle_id": "db90847aaa9144d1936e72d3a2e44b65",
    "vin": "CHASSIS1234",
    "vehicle_identification": "Frota 1234",
    "hour_meter": 2035.34,
    "fuel_level": 95.5,
    "compass_bearing": 255,
    "speed": 7.65,
    "latitude": -25.4369282,
    "longitude": -49.2346756,
    "ts": "2023-09-25T19:48:52Z",
    "org_id": "48b60dd4fe7d4ff2ba60b0caad304e05",
    "fields": ["Local01, Local02"]
}`,
                    icon: require("@/assets/icons/icone-mapa.svg")
                },
                {
                    name: "Dados Históricos",
                    type: "HISTORICAL_DATA",
                    id: null,
                    url: "",
                    isActive: false,
                    authorization: null,
                    show: false,
                    smallText: `A inscrição de Dados Históricos permite que o cliente receba requisições com tipo de conteúdo JSON contendo todas informações que percorrem
                        no barramento uma máquina. `,
                    text: `Cada veículo, caso esteja conectado, pode enviar uma requisição a cada intervalo de 30 minutos. Quando estiver offline, o dispositivo armazenará
                        as informações localmente até que retorne online, possibilitando então o envio dos dados acumulados. O conteúdo de cada JSON terá vin, mac,
                        e os dados da máquina. Cada json é resultado de 30 minutos de coleta de dados da máquina. `,
                    json_response: `{
  "vin": "CHASSI",
  "mac_address": "D8A01D55C1B9",
  "data": [
    {
      \\"timestamp\\": "2023-04-20 10:50:39.000\\",
      \\"lat\\": -23.2661545,
      \\"lng\\": -51.092734,
      \\"Modo\\": 4,
      \\"Compass Bearing\\": 243.1640625,
      \\"Engine Speed\\": 1304,
      \\"Wheel-Based Vehicle Speed\\": 18.7,
      ...
    },
    ...
  ]
}`,
                    icon: require("@/assets/icons/icone-webhook-dados-historicos.svg")
                }
            ],
            contentTwo: [],
            showDialog: false,
            inputId: '',
            inputUrl: '',
            inputAuth: '',
            inputStatus: true,
        }
    },
    props: {
        grid_data: {
            type: Array,
        },
        selected_org: {
            type: Object,
        },
        org_feature_flags: {
            type: Array,
        },
    },
    methods: {
        handleCreateOrEdit(type){
            this.isEditingSubscription = false;
            this.contentOne.concat(this.contentTwo).forEach(element => {
                if(type === element.type){
                    this.inputId = element.id
                    this.inputUrl = element.url
                    this.inputAuth = element.authorization
                    this.inputStatus = element.isActive

                    if(element.url === '') {
                        this.inputStatus = true
                        return;
                    }

                    this.isEditingSubscription = true;
                }
            });

            this.typeSelected = type
            this.showDialog = true;
        },
        saveForm(){
            if(!this.inputUrl || this.inputUrl === ''){
                this.$toast.add({
                    severity: 'error',
                    summary: 'URL inválida',
                    detail: this.inputUrl === '' ? 'O campo da URL é obrigatório': 'Digite uma URL válida',
                    life: 5000
                })
                return;
            }

            let data;
            if(!this.isEditingSubscription){
                data = {
                    type: this.typeSelected,
                    endpoint_url: this.inputUrl,
                    is_active: this.inputStatus,
                    authorization_header_value: this.inputAuth === '' ? null : this.inputAuth
                }

                this.$emit('createData', data)
                this.showDialog = false;
                return;
            }

            data = {
                type: this.typeSelected,
                endpoint_url: this.inputUrl,
                is_active: this.inputStatus,
                authorization_header_value: this.inputAuth === '' ? null : this.inputAuth
            }

            this.$emit('updateData', data, this.inputId)
            this.showDialog = false;
        },
        cleanContents(){
            this.contentOne.concat(this.contentTwo).forEach(element => {
                element.id = null
                element.url = ""
                element.isActive = false
                element.authorization = null
            });
        },
        separatingContents(){
            let elementsToMove = this.contentOne.filter(element => element.show === true);
            let elementsToMoveImpar = elementsToMove.filter((element, index) => index % 2 != 0);

            elementsToMoveImpar.forEach(element => {
                let index = this.contentOne.indexOf(element)
                this.contentTwo.push(JSON.parse(JSON.stringify(element)))
                this.contentOne[index].show = false
            });
        }
    },
    watch: {
        org_feature_flags: function(val) {
            this.contentTwo = []
            this.contentOne.forEach(element => {
                if(val.includes(element.type)){
                    element.show = true;
                    return;
                }
                element.show = false;
            });

            this.separatingContents()
        },
        grid_data: function(val){
            // limpa os dados de contentOne e contentTwo antes de receber novos dados
            this.cleanContents();
            val.forEach(data => {
                this.contentOne.concat(this.contentTwo).forEach(element => {
                    if(data.type === element.type){
                        element.id = data.id
                        element.url = data.endpoint_url
                        element.isActive = data.is_active
                        element.authorization = data.authorization_header_value
                    }
                });
            });
        },
    },
    components: {
        AccordionTab, Accordion, Button, Dialog, InputText
    },
}
</script>

<style scoped>

.no-value{
    font-size: 27px;
    font-weight: bold;
    justify-content: center;
}

.container-config{
    background-color: white;
    border-radius: 10px;
    padding: 50px 50px;
    box-shadow: 1px 1px 10px rgb(214, 214, 214);
    margin-bottom: 50px !important;
    display: flex;
    min-height: 700px;
}

.title{
    font-size: 15px;
}

.img-width{
    width: 17px;
    height: 17px;
    margin-right: 8px;
    font-size: 17px;
}

.style-active{
    width: 70px;
    height: 27px;
    background-color: #81C681;
    text-align: center;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    padding-top: 2px;
    margin-right: 15px;
    margin-top: 2px;
}

.style-inactive{
    width: 80px;
    height: 27px;
    background-color: #FF8686;
    text-align: center;
    color: white;
    font-size: 14px;
    border-radius: 5px;
    padding-top: 2px;
    margin-right: 10px;
    margin-top: 2px;
}

.align-right{
    margin: auto 0 auto auto;
    display: flex;
}

.there-is-url{
    display: flex;
}

.post{
    width: 65px;
    height: 27px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    text-align: center;
    background-color: #49CC90;
    padding-top: 3px;
    margin-left: 30px;
    margin-top: 5px;
}

.url{
    margin: 6px 0 5px 15px;
}

.sub-accordion{
    width: calc(100% + 28px);
    position: relative;
    right: 14px;
    top: 13px;
}

.input-style{
    width: 300px;
}

.title-input{
    margin: 10px 0px 5px 15px;
    font-size: 14px;
}

.inactive-highlight{
    width: 70px;
    height: 40px;
    background-color: rgb(255, 130, 130);
    text-align: center;
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 14px;
    padding-top: 9px;
    font-weight: bold;
    cursor: pointer;
}

.active-highlight{
    width: 60px;
    height: 40px;
    background-color: rgb(92, 202, 92);
    text-align: center;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    padding-top: 9px;
    font-weight: bold;
    cursor: pointer;
}

.inactive-style{
    width: 70px;
    height: 40px;
    background-color: white;
    text-align: center;
    color: rgb(116, 116, 116);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: solid 1px rgb(196, 196, 196);
    font-size: 14px;
    padding-top: 9px;
    cursor: pointer;
}

.active-style{
    width: 60px;
    height: 40px;
    background-color: white;
    text-align: center;
    color: rgb(116, 116, 116);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: solid 1px rgb(196, 196, 196);
    font-size: 14px;
    padding-top: 9px;
    cursor: pointer;
}

.align-center{
    display: flex;
    justify-content: center;
}

.more-info{
    font-size: 14px;
}

.button-z-index{
    z-index: 2;
}

.padding-content-accordion{
    padding: 5px 20px;
}

.width-accordion{
    width: 47.5%;
    margin: 0 1.75%;
}

.btn-plus{
    background-color: rgb(25, 86, 255) !important;
    border-color: rgb(25, 86, 255) !important;
}

.btn-edit{
    background-color: #FFC107 !important;
    border-color: #FFC107 !important;
}

@media (max-width: 1024px){

    .container-config{
        display: block;
    }

    .width-accordion{
        width: 100% !important;
        margin: 0;
    }

    .sub-accordion{
        width: calc(100% + 26px);
        position: relative;
        right: 13px;
        top: 13px;
    }

    .second-accordion{
        margin-top: 30px;
    }
}

</style>

<style>

.p-accordion-header-link{
    border: 1px solid #dee2e6 !important;
    border-radius: 3px !important;
}

</style>