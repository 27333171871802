export const workParameters = [
    {
        type: "RPM",
        description: "Rotação (RPM)",
        active: false,
        valueWork: null,
        valueSecondaryWork: null,
        valueManeuver: null,
        valueSecondaryManeuver: null,
        valueTravel: null,
        valueSecondaryTravel: null,
        valueIdle: null,
        valueSecondaryIdle: null,
        parameterSpn: false,
        optionWork:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionManeuver:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionTravel:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionIdle:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        parameterOptions: [
            {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
            {name: 'MAIOR QUE', code: 'GREATER_THAN', valueEnable: true},
            {name: 'MENOR QUE', code: 'LESS_THAN', valueEnable: true},
            {name: 'ENTRE', code: 'BETWEEN', valueEnable: true}
        ],
    },
    {
        type: "SPEED",
        description: "Velocidade (km/h)",
        active: false,
        valueWork: null,
        valueSecondaryWork: null,
        valueManeuver: null,
        valueSecondaryManeuver: null,
        valueTravel: null,
        valueSecondaryTravel: null,
        valueIdle: null,
        valueSecondaryIdle: null,
        parameterSpn: false,
        optionWork:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionManeuver:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionTravel:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionIdle:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        parameterOptions: [
            {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
            {name: 'MAIOR QUE', code: 'GREATER_THAN', valueEnable: true},
            {name: 'MENOR QUE', code: 'LESS_THAN', valueEnable: true},
            {name: 'ENTRE', code: 'BETWEEN', valueEnable: true}
        ],
    },
    {
        type: "TORQUE",
        description: "Torque (%)",
        active: false,
        valueWork: null,
        valueSecondaryWork: null,
        valueManeuver: null,
        valueSecondaryManeuver: null,
        valueTravel: null,
        valueSecondaryTravel: null,
        valueIdle: null,
        valueSecondaryIdle: null,
        parameterSpn: false,
        optionWork:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionManeuver:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionTravel:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionIdle:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        parameterOptions: [
            {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
            {name: 'MAIOR QUE', code: 'GREATER_THAN', valueEnable: true},
            {name: 'MENOR QUE', code: 'LESS_THAN', valueEnable: true},
            {name: 'ENTRE', code: 'BETWEEN', valueEnable: true}
        ],
    },
    {
        type: "SPN_ONE",
        description: null,
        spn: null,
        active: false,
        valueWork: null,
        valueSecondaryWork: null,
        valueManeuver: null,
        valueSecondaryManeuver: null,
        valueTravel: null,
        valueSecondaryTravel: null,
        valueIdle: null,
        valueSecondaryIdle: null,
        parameterSpn: true,
        optionWork:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionManeuver:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionTravel:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionIdle:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        parameterOptions: [
            {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
            {name: 'MAIOR QUE', code: 'GREATER_THAN', valueEnable: true},
            {name: 'MENOR QUE', code: 'LESS_THAN', valueEnable: true},
            {name: 'ENTRE', code: 'BETWEEN', valueEnable: true},
            {name: 'HABILITADO', code: 'ENABLED', valueEnable: false},
            {name: 'DESABILITADO', code: 'DISABLED', valueEnable: false}
        ],
    },
    {
        type: "SPN_TWO",
        description: null,
        spn: null,
        active: false,
        valueWork: null,
        valueSecondaryWork: null,
        valueManeuver: null,
        valueSecondaryManeuver: null,
        valueTravel: null,
        valueSecondaryTravel: null,
        valueIdle: null,
        valueSecondaryIdle: null,
        parameterSpn: true,
        optionWork:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionManeuver:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionTravel:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionIdle:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        parameterOptions: [
            {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
            {name: 'MAIOR QUE', code: 'GREATER_THAN', valueEnable: true},
            {name: 'MENOR QUE', code: 'LESS_THAN', valueEnable: true},
            {name: 'ENTRE', code: 'BETWEEN', valueEnable: true},
            {name: 'HABILITADO', code: 'ENABLED', valueEnable: false},
            {name: 'DESABILITADO', code: 'DISABLED', valueEnable: false}
        ],
    },
    {
        type: "SPN_THREE",
        description: null,
        spn: null,
        active: false,
        valueWork: null,
        valueSecondaryWork: null,
        valueManeuver: null,
        valueSecondaryManeuver: null,
        valueTravel: null,
        valueSecondaryTravel: null,
        valueIdle: null,
        valueSecondaryIdle: null,
        parameterSpn: true,
        optionWork:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionManeuver:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionTravel:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        optionIdle:  {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
        parameterOptions: [
            {name: 'N/A', code: 'NON_APPLICABLE', valueEnable: false},
            {name: 'MAIOR QUE', code: 'GREATER_THAN', valueEnable: true},
            {name: 'MENOR QUE', code: 'LESS_THAN', valueEnable: true},
            {name: 'ENTRE', code: 'BETWEEN', valueEnable: true},
            {name: 'HABILITADO', code: 'ENABLED', valueEnable: false},
            {name: 'DESABILITADO', code: 'DISABLED', valueEnable: false}
        ],
    },
]
