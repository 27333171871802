<template>
    <div>
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1100"
            :filename="'Captação_de_água_' + Date.now()"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
            <section slot="pdf-content" class="pdf-dashboard">
                <section class="pdf-item">
                    <div class="brand-background">
                        <img alt="logo" style="height: 80px;width: 150px" :src="headerImage"/>
                    </div>
                    <div class="pdf-title">
                        <h3>
                            Recursos Hídricos - Filtrado de {{ dateFormat(startDate) }} à {{ dateFormat(endDate) }}
                        </h3>
                    </div>
                    <div style="height: 6rem">
                        <app-water-withdrawal-resume class="chart-resize" :showLabel="true"
                                                     :resume="resume"></app-water-withdrawal-resume>
                    </div>
                    <div style="height: 390px">
                        <app-water-withdrawal-month-card class="chart-resize" :showLabel="true"
                                                         :info="byMonth"></app-water-withdrawal-month-card>
                    </div>
                    <div style="height: 390px">
                        <app-water-withdrawal-type-card class="chart-resize" :typeProcess="byProcess" :showLabel="true"
                                                        :typeState="byState"></app-water-withdrawal-type-card>
                    </div>

                </section>

                <div class="html2pdf__page-break"/>

                <section class="pdf-item" style="height: 1070px">
                    <div style="display: flex; justify-content: space-around; margin-top: 4rem">
                        <app-water-withdrawal-pie-card style="width: 350px" :title="'processo'" :showLabel="true"
                                                       :info="byProcess"></app-water-withdrawal-pie-card>
                        <app-water-withdrawal-pie-card style="width: 350px" :title="'estado'" :showLabel="true"
                                                       :info="byState"></app-water-withdrawal-pie-card>
                    </div>
                    <div>
                        <app-water-withdrawal-type-month-card :processes="byProcess" :showLabel="true"
                                                              :info="byMonth"></app-water-withdrawal-type-month-card>
                    </div>
                </section>
            </section>

        </VueHtml2pdf>
    </div>
</template>

<script>

import VueHtml2pdf from 'vue-html2pdf'
import dateHourFormat from "@/mixins/DateMixin";
import AppWaterWithdrawalCharts from "@/components/views/water_withdrawal/AppWaterWithdrawalCharts.vue";
import AppWaterWithdrawalResume from "@/components/views/water_withdrawal/AppWaterWithdrawalResume.vue";
import AppWaterWithdrawalMonthCard from "@/components/views/water_withdrawal/AppWaterWithdrawalMonthCard.vue";
import AppWaterWithdrawalTypeCard from "@/components/views/water_withdrawal/AppWaterWithdrawalTypeCard.vue";
import AppWaterWithdrawalPieCard from "@/components/views/water_withdrawal/AppWaterWithdrawalPieCard.vue";
import AppWaterWithdrawalTypeMonthCard from "@/components/views/water_withdrawal/AppWaterWithdrawalTypeMonthCard.vue";

export default {
    mixins: [dateHourFormat],
    data() {
        return {
            startDate: null,
            endDate: null,
            headerImage: require("../../../assets/images/brands/suzano.png"),

        }
    },
    props: {
        generate_report: {
            type: Boolean
        },
        date_interval: {
            type: Array
        },
        resume: {
            type: Object
        },
        byProcess: {
            type: Array
        },
        byState: {
            type: Array
        },
        byMonth: {
            type: Array
        }
    },
    watch: {
        generate_report: function () {
            this.$refs.html2Pdf.generatePdf();
        },
        date_interval: {
            deep: true,
            immediate: true,
            handler(val) {
                this.startDate = Math.floor(val[0].getTime() / 1000)
                this.endDate = Math.floor(val[1].getTime() / 1000)
            }
        },
        resume: {
            deep: true,
            handler(val) {
                let total = parseInt(val.total.replace(".", ""));
                this.$store.dispatch('updateTotalValue', total);
            }
        }
    },
    methods: {},
    components: {
        AppWaterWithdrawalTypeMonthCard,
        AppWaterWithdrawalPieCard,
        AppWaterWithdrawalTypeCard, AppWaterWithdrawalMonthCard,
        AppWaterWithdrawalResume,
        AppWaterWithdrawalCharts,
        VueHtml2pdf
    },
}

</script>

<style scoped lang="scss">

.pdf-dashboard {
    width: 100%;
    margin-left: -10px;
    background-color: #f4f4f4
}

.brand-background {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: #004da6;
}

.pdf-title {
    display: flex;
    justify-content: center;
}

.chart-resize {
    transform: scale(0.85);
}
</style>
