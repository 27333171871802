<template>
    <div class="form-group">
        <!-------------------------FOR: SEÇÃO----------------------------->
        <div class="white-background" v-for="(item, itemKey) in checklist"
             :class="{'section': item.type =='TITLE', 'sub-style': item.is_sub}">

            <!--TÍTULO DO ITEM: DIFERE EM SEÇÃO, PERGUNTA E PERGUNTA DEPENDENTE-->
            <div>
                <!-- SE FOR SEÇÃO -->
                <div class="section-title" v-if="item.type === 'TITLE'">
                    <div class="section-title_row">
                        <h3>{{ item.identification }}ª Seção de perguntas</h3>
                        <Button icon="pi pi-times" class="delete-button" @click="deleteSection(itemKey)"></Button>
                    </div>
                    <span class="p-float-label section-title_input">
                        <InputText class="form-control" type="text" v-model="item.text"/>
                        <label>Título da seção</label>
                    </span>
                </div>

                <!-- SE FOR PERGUNTA -->
                <div v-else-if="item.type !== 'TITLE' && item.is_sub === false">
                    <hr class="hr-solid">
                    <h3 class="title-size">Pergunta {{ item.identification }}</h3>
                </div>

                <!-- SE FOR PERGUNTA DEPENDENTE -->
                <div v-else-if="item.type !== 'TITLE' && item.is_sub === true">
                    <hr class="hr-dotted">
                    <h3 class="title-size">&#x2022; Pergunta dependente da resposta {{ item.dependency.sorting_order }}</h3>
                </div>
            </div>

            <!-------------------------------- SE O ITEM FOR UMA PERGUNTA ------------------------------->
            <div class="question-dropdown" v-if="item.type !== 'TITLE'">
                <!------------------------- DROPDOWN SELEÇÃO TIPO DE ITEM ------------------------------------------->
                <Dropdown v-model="dropdown[itemKey]" :options="inputOptions" style="max-height: 38px;"
                          optionLabel="title" class="input_dropdown" @change="updateChecklist(itemKey, $event.value)"
                          placeholder="Selecione tipo de pergunta" :class="{input_dropdown_sub: item.is_sub}">
                </Dropdown>
                <!------------------------- BOTÕES DO ITEM ------------------------------------------->
                <app-button type="secundary" v-if="!item.is_sub" @handleClick="deleteItem(itemKey)"
                            class="delete-button">DELETAR PERGUNTA
                </app-button>
                <app-button type="secundary" v-else @handleClick="deleteItem(itemKey)"
                            class="delete-button">DELETAR SUB-PERGUNTA
                </app-button>
            </div>

            <!-----------------------------------------TEXTO DA PERGUNTA------------------------------------------>
            <div v-if="item.type !== 'TITLE' && item.type !== ''">
                <div v-if="item.type === 'TEXT'">
                    <h6>Texto da Pergunta:</h6>
                    <app-input type="text" v-model="item.text" placeholder="Exemplo: Qual é o seu nome?"></app-input>
                </div>
                <div v-if="item.type === 'NUMBER_TYPING'">
                    <h6>Texto da Pergunta:</h6>
                    <app-input type="text" v-model="item.text" placeholder="Exemplo: Qual é a sua idade?"></app-input>
                </div>
                <div v-if="item.type === 'CHECKBOX_EXCLUSIVE'">
                    <h6>Texto da Pergunta:</h6>
                    <app-input type="text" v-model="item.text"
                               placeholder="Exemplo: Você trabalhou no dia de hoje?"></app-input>
                </div>
            </div>

            <!--------------------------V-IF PARA CADA TIPO DE PERGUNTA------------------------------->
            <div v-if="item.type === 'TEXT'" class="text-complement">
                <h5>Determine o número máximo de caracteres para esta resposta:</h5>
                <input-number class="input-width" v-model="item.option_list[0].option_text"></input-number>
            </div>

            <div v-else-if="item.type === 'NUMBER_TYPING'" class="number-complement">
                <h5>A resposta deve ser um número entre </h5>
                <div class="combo-number-complement">
                    <input-number class="input-number-width" v-model="item.option_list[0].option_text"></input-number>
                    <h6 class="and-width"> e </h6>
                    <input-number class="input-number-width" v-model="item.option_list[1].option_text"></input-number>
                </div>
            </div>
            <div v-else-if="item.type === 'CHECKBOX_EXCLUSIVE'">

                <div v-for="(optionObject, optionObjectKey) in checklist[itemKey].option_list"
                     class="options-complement">
                    <h5>Resposta {{ optionObjectKey + 1 }}:</h5>
                    <app-input type="text" placeholder="Exemplo: Sim" v-if="optionObject.sorting_order === '1'"
                               v-model="optionObject.option_text"
                               style="width: 90%">
                    </app-input>
                    <app-input type="text" placeholder="Exemplo: Não" v-else-if="optionObject.sorting_order === '2'"
                               v-model="optionObject.option_text"
                               style="width: 90%">
                    </app-input>
                    <app-input type="text" placeholder="Exemplo: Outros..." v-else
                               v-model="optionObject.option_text"
                               style="width: 90%">
                    </app-input>
                    <div class="options-buttons">
                        <Button icon="pi pi-ellipsis-v" @click="addSubItem(item, itemKey, optionObject)"
                                class="p-button-rounded actions" v-if="!item.is_sub"
                                v-tooltip.bottom="'Adicionar Pergunta Dependente'"/>
                        <Button icon="pi pi-trash" @click="deleteOption(itemKey, optionObjectKey, optionObject)"
                                class="p-button-rounded delete-button" v-tooltip.bottom="'Excluir Resposta'"/>
                    </div>
                </div>
                <div class="add-option">
                    <app-button v-if="item.type === 'CHECKBOX_EXCLUSIVE'" type="primary" class="blue-btn"
                                style="border-radius: 25px"
                                @handleClick="addOption(item)">ADICIONAR RESPOSTA
                    </app-button>
                </div>

            </div>

            <!-------------------CHECKBOX DE OBRIGATORIEDADE E BOTÃO DE ADICIONAR OPÇÃO------------------------->
            <div v-if="item.type !== 'TITLE'" class="checkbox-mandatory">
                <Checkbox name="verifyRequirement" value="verifyRequirement" :binary="true"
                          v-model="checklist[itemKey].mandatory">
                </Checkbox>
                <p>Resposta obrigatória</p>
            </div>


            <!---------------------------BOTÃO DE ADICIONAR PERGUNTA------------------------------------->
            <div v-if="item === checklist[checklist.length -1] || checklist[itemKey + 1].type === 'TITLE'"
                 class="add-question-btn">
                <app-button class="blue-btn" type="primary" @handleClick="addItem(item, itemKey)">ADICIONAR PERGUNTA
                </app-button>
            </div>

        </div>

        <div class="add-section-btn">
            <hr class="hr-dotted" style="width: 7rem;">
            <app-button type="primary" class="blue-btn" @handleClick="addSection">ADICIONAR SEÇÃO</app-button>
            <hr class="hr-dotted" style="width: 7rem;">
        </div>

    </div>
</template>

<script>
import AppInput from "../../../common/AppInput";
import AppButton from "../../../common/AppButton";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import {inputTypes} from './inputTypes';
import Tooltip from 'primevue/tooltip';

export default {
    data() {
        return {
            inputOptions: inputTypes,
            dropdown: [],
            checklist: [],
            checklist_final: [],
            update_body_list: true,
            sectionCounter: 0,
        }
    },
    watch: {
        body_list: {
            deep: true,
            handler(val) {
                if (this.update_body_list && this.body_list.length > 1) {
                    this.updateBodyList();
                }
                this.update_body_list = false;
            }
        },
        checklist_final: {
            deep: true,
            handler(val) {
                this.$emit('checklistUpdate', val);
            }
        },
        checklist: {
            deep: true,
            handler(val) {
                this.updateBackendChecklist(val);
            }
        },
    },
    methods: {
        updateBodyList() {
            let temporary_checklist = JSON.parse(JSON.stringify(this.body_list));
            for (let i = 0; i < this.body_list.length; i++) {
                let dropdown_obj = this.inputOptions.find(obj => obj.type === this.body_list[i].type);
                this.dropdown.push(dropdown_obj);

                if (temporary_checklist[i].type === 'TITLE') {
                    this.sectionCounter += 1;
                }

                if (temporary_checklist[i].identification.split(".").length >= 3) {
                    temporary_checklist[i].is_sub = true;
                } else {
                    temporary_checklist[i].is_sub = false;
                }

                if (temporary_checklist[i].type === 'TEXT' && temporary_checklist[i].option_list.length === 0) {
                    temporary_checklist[i].option_list.push({
                        "sorting_order": '1',
                        "option_text": null,
                        "dependent_items": [],
                    });
                } else if (temporary_checklist[i].type === 'TEXT' && temporary_checklist[i].option_list.length > 0) {
                    temporary_checklist[i].option_list[0].option_text = parseInt(temporary_checklist[i].option_list[0].option_text);
                }

                if (temporary_checklist[i].type === 'NUMBER_TYPING' && temporary_checklist[i].option_list.length === 0) {
                    temporary_checklist[i].option_list.push({
                            "sorting_order": '1',
                            "option_text": null,
                            "dependent_items": [],
                        },
                        {
                            "sorting_order": '2',
                            "option_text": null,
                            "dependent_items": [],
                        })
                } else if (temporary_checklist[i].type === 'NUMBER_TYPING' && temporary_checklist[i].option_list.length > 0) {
                    temporary_checklist[i].option_list[0].option_text = parseInt(temporary_checklist[i].option_list[0].option_text);
                    temporary_checklist[i].option_list[1].option_text = parseInt(temporary_checklist[i].option_list[1].option_text);
                }
            }
            this.checklist = temporary_checklist;
        },
        checkLength(){
            if(this.checklist.length < 99){
                return true
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Limite de Seções e Perguntas atingido!',
                    life: 5000
                });
                return false
            }
        },
        addSection() { //BOTÃO DE ADICIONAR SEÇÃO
            if(this.checkLength()){
                this.sectionCounter += 1;
                this.dropdown.push(null);
                this.checklist.push({
                    "identification": '' + this.sectionCounter,
                    "type": 'TITLE',
                    "text": '',
                    "is_sub": false,
                    "mandatory": false,
                    "dependency": {}
                })
            }
        },
        addItem(item, itemKey) { //BOTÃO DE ADICIONAR PERGUNTA
            if(this.checkLength()){
                let newObj = {
                    "identification": '',
                    "type": '',
                    "text": '',
                    "is_sub": false,
                    "mandatory": false,
                    "dependency": {},
                    "option_list": []
                };
                this.checklist.splice(itemKey + 1, 0, newObj);
                this.reorderId();
            }
        },
        addSubItem(item, itemKey, optionObject) { //BOTÃO DE ADICIONAR SUB-PERGUNTA
            if(this.checkLength()){
                let dep_identification = item.identification;
                let dep_sorting_order = optionObject.sorting_order;

                let newObj = {
                    "identification": '',
                    "type": '',
                    "text": '',
                    "is_sub": true,
                    "mandatory": false,
                    "dependency": {
                        'identification': dep_identification,
                        'sorting_order': dep_sorting_order
                    },
                    "option_list": []
                };

                let new_key = itemKey + 1;
                let flag = false;
                while (!flag) {
                    if (new_key == this.checklist.length) {
                        flag = true;
                    } else if (this.checklist[new_key].is_sub) {
                        new_key += 1;
                    } else {
                        flag = true;
                    }
                }

                this.checklist.splice(new_key, 0, newObj);
                this.reorderId();
                this.reorderDependentItems();
            }
        },
        addOption(item) { //BOTÃO DE ADICIONAR OPÇÃO
            if(item.option_list.length < 20){
                let new_sorting_order = parseInt(item.option_list[item.option_list.length - 1].sorting_order) + 1;
                item.option_list.push({
                    "sorting_order": '' + new_sorting_order,
                    "option_text": null,
                    "dependent_items": [],
                })
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Limite de respostas atingido!',
                    life: 5000
                });
            }
        },
        deleteSection(itemKey) {
            let search = true;
            let key = itemKey + 1;
            let questions = 1;
            while (search) {
                if (this.checklist[key]) {
                    if (itemKey < (this.checklist.length - 1) && this.checklist[itemKey].identification == this.checklist[key].identification.charAt(0)) {
                        key += 1;
                        questions += 1;
                    } else {
                        search = false;
                    }
                } else {
                    search = false;
                }
            }

            this.checklist.splice(itemKey, questions);
            this.dropdown.splice(itemKey, questions);
            this.sectionCounter -= 1;
            this.reorderId();
            this.reorderDependentItems();
        },
        deleteItem(itemKey) { //Delete pergunta ou subpergunta
            if (!this.checklist[itemKey].is_sub && this.checklist[itemKey].type == "CHECKBOX_EXCLUSIVE") {
                let index = itemKey + 1;
                let n_subs = 1;
                while (index <= (this.checklist.length - 1)) {
                    let id = this.checklist[index].identification;
                    if (id.slice(0, 3) == this.checklist[itemKey].identification) {
                        n_subs += 1;
                        index += 1;
                    } else {
                        break;
                    }
                }

                this.checklist.splice(itemKey, n_subs); //deleta o item e a quantidade de sub-items
                this.dropdown.splice(itemKey, n_subs); //deleta o item e a quantidade de sub-items

            } else {
                this.checklist.splice(itemKey, 1);
                this.dropdown.splice(itemKey, 1);
            }
            this.reorderId(this.checklist);
            this.reorderDependentItems();
        },
        deleteOption(itemKey, optionKey, optionObject) {
            const option_list_arr = this.checklist[itemKey].option_list;
            if (option_list_arr.length > 1) {
                option_list_arr.splice(optionKey, 1); //deleta resposta n
                this.deleteDependentItems(optionObject); //deleta sub-perguntas dependentes da resposta n
                this.reorderSorting(itemKey);
                this.reorderId();
                this.reorderDependentItems();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Múltipla Escolha precisa de ao menos uma resposta',
                    life: 5000
                });
            }
        },
        deleteDependentItems(option) {
            if (option.dependent_items.length > 0) {
                for (let i = 0; i < option.dependent_items.length; i++) {
                    for (let j = 0; j < this.checklist.length; j++) {
                        if (option.dependent_items[i] == this.checklist[j].identification) {
                            this.checklist.splice(j, 1);
                        }
                    }
                }
            }

        },
        updateChecklist(itemKey, item) { //ATUALIZAÇÃO DO CHECKLIST QUANDO MUDA O DROPDOWN DE ALGUMA PERGUNTA
            if ((this.checklist[itemKey].type === 'CHECKBOX_EXCLUSIVE' && item.type !== 'CHECKBOX_EXCLUSIVE') && !this.checklist[itemKey].is_sub) {
                this.clearDependencies(itemKey);
            }
            let type = item.type;
            let option_list = JSON.parse(JSON.stringify(item.option_list));

            this.checklist[itemKey].type = type;
            this.checklist[itemKey].option_list = option_list;
        },
        clearDependencies(itemKey) {
            let key = itemKey + 1;
            while (key <= (this.checklist.length - 1)) {
                if (this.checklist[key].is_sub) {
                    this.deleteItem(key);
                } else {
                    key += 1;
                }
            }
        },
        reorderId() { //REORGANIZAÇÃO DE 'IDENTIFICATION' DE TODAS AS PERGUNTAS
            let sectionCounter = 0;
            let idCounter = 0;
            let subIdCounter = 0;
            let tempArray = JSON.parse(JSON.stringify(this.checklist));

            for (let i = 0; i < tempArray.length; i++) {
                if (tempArray[i].type === 'TITLE') {
                    idCounter = 0;
                    subIdCounter = 0;
                    sectionCounter += 1;
                    tempArray[i].identification = '' + sectionCounter;
                } else if (tempArray[i].type !== 'TITLE' && !tempArray[i].is_sub) {
                    subIdCounter = 0;
                    idCounter += 1;
                    tempArray[i].identification = sectionCounter + '.' + idCounter;
                } else {
                    subIdCounter += 1;
                    tempArray[i].identification = sectionCounter + '.' + idCounter + '.' + subIdCounter;
                }

                if (tempArray[i].dependency && tempArray[i].dependency.identification) {
                    let temp_id  = tempArray[i].identification.split('.');
                    tempArray[i].dependency.identification = temp_id[0] + '.' + temp_id[1];
                }
            }

            this.checklist = JSON.parse(JSON.stringify(tempArray));
        },
        reorderSorting(itemKey) {
            let option_list = this.checklist[itemKey].option_list;
            for (let i = 0; i < option_list.length; i++) {
                option_list[i].sorting_order = '' + (i + 1);
                if (option_list[i].dependent_items.length > 0) {
                    this.reorderDependency(option_list[i].dependent_items, option_list[i].sorting_order);
                }
            }
        },
        reorderDependency(dep_items, sorting_order) {
            for (let i = 0; i < dep_items.length; i++) {
                for (let j = 0; j < this.checklist.length; j++) {
                    if (dep_items[i] === this.checklist[j].identification) {
                        this.checklist[j].dependency.sorting_order = sorting_order;
                    }
                }
            }
        },
        reorderDependentItems() { //REORGANIZAÇÃO DOS 'DEPENDENT_ITEMS' DE CADA OPÇÃO
            //ZERA TODOS OS DEPENDENT_ITEMS
            for (let i = 0; i < this.checklist.length; i++) {
                if (this.checklist[i].option_list) {
                    if (this.checklist[i].option_list.length > 0) {
                        for (let j = 0; j < this.checklist[i].option_list.length; j++) {
                            this.checklist[i].option_list[j].dependent_items = []
                        }
                    }
                }
            }

            for (let i = 0; i < this.checklist.length; i++) { //achar dependencia
                let dependency = this.checklist[i].dependency;
                if (dependency && dependency.identification != null) {
                    let temp_arr = this.checklist[i].identification.split('.');
                    let temp_id = temp_arr[0] + '.' + temp_arr[1];
                    let item = this.checklist.find(item => item.identification == temp_id);
                    if (item.option_list != null) {
                        let op_list = item.option_list;
                        for (let j = 0; j < op_list.length; j++) {
                            if (op_list[j].sorting_order === dependency.sorting_order) {
                                op_list[j].dependent_items.push(this.checklist[i].identification)
                            }
                        }
                    }
                }
            }
        },
        updateBackendChecklist(checklist) {
            let arr = JSON.parse(JSON.stringify(checklist));
            for (let i = 0; i < arr.length; i++) {
                delete arr[i].is_sub;

                if (arr[i].option_list) {
                    for (let j = 0; j < arr[i].option_list.length; j++) {
                        delete arr[i].option_list[j].identification;
                    }

                    if (arr[i].type === 'TEXT' && arr[i].option_list[0].option_text == null) {
                        delete arr[i].option_list;
                    } else if ((arr[i].type === 'NUMBER_TYPING' && arr[i].option_list[0].option_text == null && arr[i].option_list[1].option_text == null)) {
                        delete arr[i].option_list;
                    }
                }
            }
            this.checklist_final = arr;
        },
        checkForm() {
            this.$v.vehicle.$touch();
            if (!this.$v.vehicle.$invalid) {
                this.handleSalve();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Por favor preencha todos os campos obrigatórios',
                    life: 5000
                });
            }
        },
    },
    props: {
        body_list: {
            type: Array
        }
    },
    directives: {
        tooltip: Tooltip
    },
    components: {
        AppInput, InputText, InputNumber, AppButton, Button, Dropdown, Checkbox, inputTypes, Tooltip
    }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";

.form-group {
    width: 80%;
    margin: auto;
}

.hr-dotted {
    border-top: 3px dotted #bbb;
}

.hr-solid {
    border-top: 3px solid #bbb;
}

.section {
    margin-top: 1rem;
}

.sub-style {
    padding-left: 5rem;
    padding-right: 5rem;
}

.section-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-title_row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.section-title_row Button {
    margin-left: auto;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
}

.section h3 {
    margin: 1rem;
}

.section-title_input {
    width: 100%;
    margin-top: 1rem;
}

.question-dropdown {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.input_dropdown {
    width: 80%;
    margin-right: 2rem;
}

.input_dropdown_sub {
    width: 75%;
    margin-right: 2rem;
}

.delete-button {
    color: white;
    background-color: #FF3346;
    border: none;
}

.delete-button:enabled:hover {
    color: white;
    background-color: #CA1F2F;
}

.p-input-question {
    margin-top: 2rem;
}

.text-complement {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
}

.text-complement h5 {
    margin-right: 1rem;
}

.number-complement {
    margin: 1rem;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.number-complement h5 {
    margin: 0rem 1rem 0rem 1rem;
}

.options-complement {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.options-complement h5 {
    width: 120px;
    margin-bottom: 0px;
}

.options-buttons {
    display: flex;
    align-items: center;
}

.options-buttons Button {
    margin-left: 1rem;
}

.add-option {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 1rem;
}

.checkbox-mandatory {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.checkbox-mandatory p {
    margin: 0 0 0 1rem;
}

.add-question-btn {
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.blue-btn {
    background-color: #0f9af0;
}

.blue-btn:hover {
    background-color: #0d89ec;
}

.add-section-btn {
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.white-background {
    background: white;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 5px;
}

.and-width{
    font-size: none;
    margin: 1rem 0.5rem 0rem 0.5rem;
}

.input-number-width{
    width: 100%;
    margin: 0.5rem 0rem;
}

.combo-number-complement{
    display: flex;
    width: 100%;
}

.number-complement{
    display:flex;
    margin: 1rem 0rem 0rem 0rem;
}

@media(max-width: 1024px){
    .form-group {
        width: 100%;
    }

    .section-title_row h3{
        position: relative;
        right: 12px;
        font-size: 20px;
    }

    .title-size{
        font-size: 17px;
    }

    .delete-button {
        font-size: 10px;
    }

    .section-title_input{
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .text-complement {
        display:block;
    }

    .text-complement h5 {
        font-size: 13px;
    }

    .input-width{
        width: 100%;
    }

    .input-number-width{
        width: 100%;
        margin: 0.5rem 0rem;
    }

    .number-complement{
        display:block;
        width: 100%;
        margin: 1rem 0rem 0rem 0rem;
    }

    .number-complement h5 {
        font-size: 13px;
        margin: 0rem 0rem 0rem 0rem;
    }

    .options-complement h5{
        font-size: 13px;
    }

    .and-width{
        font-size: 13px;
        margin: 1rem 1rem 0rem 1rem;
    }

    .combo-number-complement{
        display: flex;
    }
}
</style>
