<template>
    <transition name="container-transitions" mode="out-in">
        <div v-if="show">
            <div class="background kt-grid kt-grid--ver kt-grid--root kt-page">
                <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin" id="kt_login">
                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                            <div class="kt-login__container">
                                <div class="kt-login__logo">
                                    <a href="#">
                                        <img :src="loginImage" class="img-fluid" style="max-height: 182px">
                                    </a>
                                </div>
                                <div class="kt-login__head">
                                    <h3 class="kt-login__title">Quero me cadastrar</h3>
                                    <div class="kt-login__desc">
                                    </div>
                                </div>
                                <form class="kt-form" @submit.prevent autocomplete="off">
                                    <div class="input-group">
                                        <app-input class="form-control" type="text" placeholder="Nome"
                                                   v-model="form.first_name"
                                                   :class="{'is-invalid' : $v.form.first_name.$invalid && $v.form.first_name.$dirty}"
                                        ></app-input>
                                        <span class="form-text error"
                                              v-if="$v.form.first_name.$invalid && $v.form.first_name.$dirty">
                                            Nome obrigatório
                                        </span>
                                    </div>
                                    <div class="input-group">
                                        <app-input class="form-control" type="text" placeholder="Sobrenome"
                                                   v-model="form.last_name"
                                                   :class="{'is-invalid' : $v.form.last_name.$invalid && $v.form.last_name.$dirty}">
                                        </app-input>
                                        <span class="form-text error"
                                              v-if="$v.form.last_name.$invalid && $v.form.last_name.$dirty">
                                            Sobrenome obrigatório
                                        </span>
                                    </div>
                                    <div class="input-group">
                                        <app-input class="form-control" type="password" placeholder="Senha"
                                                   v-model="form.password"
                                                   :class="{'is-invalid' : $v.form.password.$invalid && $v.form.password.$dirty}">
                                        </app-input>
                                        <span class="form-text error"
                                              v-if="$v.form.password.$invalid && $v.form.password.$dirty">
                                            A senha deve ter de 6 a 60 caracteres
                                        </span>
                                    </div>
                                    <div class="input-group">
                                        <app-input class="form-control" type="password" placeholder="Confirmar senha"
                                                   v-model="form.confirm_password"
                                                   :class="{'is-invalid' : $v.form.confirm_password.$invalid && $v.form.confirm_password.$dirty}">
                                        </app-input>
                                        <span class="form-text error"
                                              v-if="$v.form.confirm_password.$invalid && $v.form.confirm_password.$dirty">
                                            A confirmar senha não confere
                                        </span>
                                    </div>
                                    <br>
                                    <div class="form-group">
                                        <label class="kt-checkbox tos-text">
                                            <input type="checkbox" v-model="$v.form.tos_accepted.$model">
                                            Aceito os
                                            <!--                                            <router-link :to="{name:'tos'}" target="_blank">-->
                                            <!--                                                Termos de Uso e Política de-->
                                            <!--                                                Privacidade-->
                                            <!--                                            </router-link>-->

                                            <a href="https://iotag.com.br/termos/" target="_blank">Termos de Uso</a> e
                                            <a href="https://iotag.com.br/privacidade/" target="_blank">Política de Privacidade</a>

                                            <!--                                            <a href="#" @click="openModalTos">Termos de Uso e Política de-->
                                            <!--                                            Privacidade</a>-->
                                            <span
                                                :class="{'is-invalid' : $v.form.tos_accepted.$invalid && $v.form.tos_accepted.$dirty}"></span>
                                        </label>
                                        <span class="form-text error"
                                              v-if="$v.form.tos_accepted.$invalid && $v.form.tos_accepted.$dirty">
                                                                                   É obrigatorio aceitar os termos e condições
                                                                                </span>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 text-center">
                                            <app-button type="primary" @handleClick="validateForm" size="lg">
                                                Cadastrar
                                            </app-button>&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header=" " :visible.sync="openModalAccCreated" :style="{width: '30vw'}" :modal="true"
                    style="text-align: center">
                <span class="pi pi-check-circle" style="font-size: 7em"></span>
                <br><br>
                <p style="text-align: center; font-size: 1.3em">
                    Conta criada com sucesso.
                </p>
                <template #footer>
                    <Button label="Fechar" @click="closeModalAccCreated" autofocus/>
                </template>
            </Dialog>
        </div>
    </transition>
</template>
<script>

    import AppButton from '@/components/common/AppButton.vue'
    import AppInput from '@/components/common/AppRegisterInput.vue'

    import {maxLength, minLength, sameAs, required} from "vuelidate/lib/validators";
    import {router} from '../../../router';
    import axios from 'axios';
    import CPFValidations from "../../../validations/CPFValidation";
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog'

    const mustBeTrue = (value) => value === true;

    export default {
        data() {
            this.$i18n.locale = navigator.language || navigator.userLanguage;
            return {
                show: false,
                loginImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_LOGIN + ".png"),
                locale: navigator.language || navigator.userLanguage,
                form: {
                    password: null,
                    confirm_password: null,
                    first_name: null,
                    last_name: null,
                    tos_accepted: null
                },
                open_tos: false,
                openModalAccCreated: false,
            }
        },
        mounted() {
            this.inviteId = this.$route.query.invite_id;
            this.fade();
        },
        beforeDestroy() {
            this.fade();
        },
        watch: {
            locale(val) {
                this.$i18n.locale = val
            }
        },
        methods: {
            closeModalTos() {
                this.open_tos = false;
            },
            fade() {
                this.show = !this.show;
            },
            validateForm() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Formulário com valores inválidos',
                        life: 5000
                    });
                } else {
                    this.createUser();
                }
            },
            createUser() {
                var vm = this;
                axios.post(process.env.VUE_APP_BACKEND_ADDRESS + '/api/v3/invites/' + this.inviteId + '/sign-up', {
                        password: this.form.password,
                        first_name: this.form.first_name,
                        last_name: this.form.last_name,
                        tos_accepted: this.form.tos_accepted
                    }
                ).then(function (response) {
                    vm.showModalAccCreated();
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.details) {
                        this.$toast.add({
                            severity: 'error',
                            summary: error.response.data.details,
                            life: 5000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possível criar a sua conta, tente mais tarde',
                            life: 5000
                        });
                    }

                });
            },
            showModalAccCreated() {
                this.openModalAccCreated = true;
            },
            closeModalAccCreated() {
                this.openModalAccCreated = false;
                router.push({name: 'login'});
            },
        },
        components: {
            AppButton, AppInput, Dialog, Button
        },
        validations: {
            form: {
                password: {required, minLength: minLength(6), maxLength: maxLength(60)},
                confirm_password: {required, sameAsPassword: sameAs('password')},
                first_name: {required},
                last_name: {required},
                tos_accepted: {required, mustBeTrue},
            }
        },

    }
</script>

<style scoped>
    @import "../../../assets/styles/login-4.css";

    a {
        color: #00aff0;
        text-decoration: underline;
    }

    .error {
        width: 100%;
        margin-top: .25rem;
        font-size: 100%;
        color: #fd397a;
    }


    .is-invalid {
        border-color: #fd397a;
        -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);
        box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.25);
    }

    .container-transitions-enter-active {
        transition-duration: 2.1s !important;
        transition-property: opacity !important;
        transition-timing-function: ease !important;
    }

    .container-transitions-enter, .container-transitions-leave-active {
        opacity: 0 !important;
    }

    .tos-text {
        font-size: 1.25rem;
        color: #ffffff;
    }


</style>

<i18n>
    {
        "pt-BR": {
            "name": "Nome"
        },
        "en-US": {
            "name": "Full name"
        }
    }
</i18n>
