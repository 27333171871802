<template>
    <div class="card-chart">
        <div class="header">
            <span style="font-size: large; color: #000;">Caráter da captação por mês e processo</span>
            <dropdown v-model="selectedProcess" :options="processes" optionLabel="type.name" scrollHeight="350px"
                      @change="updateProcessSelection"/>
        </div>
        <div>
            <app-water-withdrawal-type-month-chart :process-display="getSelectedProcessSeries"
                                                   :months="months"></app-water-withdrawal-type-month-chart>
        </div>
    </div>
</template>

<script>
import AppWaterWithdrawalTypeMonthChart from "@/components/views/water_withdrawal/AppWaterWithdrawalTypeMonthChart";
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        AppWaterWithdrawalTypeMonthChart, Dropdown,
    },
    data() {
        return {
            selectedProcess: null,
            months: [],
            processByType: [],
        }
    },
    mounted() {
        this.selectedProcess = this.processes[0]
    },
    props: {
        info: {
            type: Array
        },
        processes: {
            type: Array
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        info: {
            deep: true,
            handler(val) {
                let vm = this;
                this.cleanData()
                this.processes.forEach(process => {
                    this.processByType.push({
                        name: process.type.name,
                        series: [{
                            name: 'Regular',
                            type: 'bar',
                            stack: 'total',
                            color: '#43912d',
                            data: [],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)"
                                }
                            },
                            label: {
                                show: this.showLabel,
                                color: '#fff',
                                formatter: function (params) {
                                    return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                                }
                            }
                        },
                            {
                                name: 'Desvio',
                                type: 'bar',
                                stack: 'total',
                                color: '#0ca1cb',
                                data: [],
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)"
                                    }
                                },
                                label: {
                                    show: this.showLabel,
                                    color: '#fff',
                                    formatter: function (params) {
                                        return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                                    }
                                }
                            },
                            {
                                name: 'Não autorizado',
                                type: 'bar',
                                stack: 'total',
                                color: '#b60909',
                                data: [],
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)"
                                    }
                                },
                                label: {
                                    show: this.showLabel,
                                    color: '#fff',
                                    formatter: function (params) {
                                        return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                                    }
                                }
                            },
                            {
                                name: 'Protocolizado',
                                type: 'bar',
                                stack: 'total',
                                color: '#888888',
                                data: [],
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)"
                                    }
                                },
                                label: {
                                    show: this.showLabel,
                                    color: '#fff',
                                    formatter: function (params) {
                                        return params.value > (vm.$store.getters.getTotalValue * 0.2) ? params.value : '';
                                    }
                                }
                            }]
                    })
                })

                val.forEach(month => {
                    this.months.push(month.name)
                    month.byProcess.forEach(process => {
                        for (let i = 0; i < this.processByType.length; i++) {
                            if (process.type.name === this.processByType[i].name) {
                                this.processByType[i].series[0].data.push(process.regular)
                                this.processByType[i].series[1].data.push(process.deviation)
                                this.processByType[i].series[2].data.push(process.no_permission)
                                this.processByType[i].series[3].data.push(process.protocol)
                            }
                        }
                    })
                })
            }
        }
    },
    methods: {
        cleanData() {
            while (this.months.length > 0) {
                this.months.pop()
            }

            while (this.processByType.length > 0) {
                this.processByType.pop()
            }
        },
        updateProcessSelection() {
            this.$store.dispatch('updateProcessType', this.selectedProcess);
        }
    },
    computed: {
        getSelectedProcessSeries: function () {
            if (this.processByType.length > 0) {
                let index = this.processByType.findIndex((obj) => {
                    if (this.$store.getters.getProcessType !== null) {
                        return this.$store.getters.getProcessType.type.name === obj.name
                    } else if (typeof this.selectedProcess === 'undefined') {
                        return true
                    }
                    return this.selectedProcess.type.name === obj.name
                });

                if (index < 0) {
                    return []
                }

                return this.processByType[index].series;
            }
            return []
        }
    }
}
</script>

<style scoped>
.card-chart {
    margin: 1.3rem;
    background-color: #fff;
    border-radius: 0.5rem;
    height: 35rem;
    padding: 2rem;
}

.header {
    padding-inline: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>
