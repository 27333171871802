export const inputTypes = [
    {
        title: "Múltipla escolha",
        type: "CHECKBOX_EXCLUSIVE",
        text: '',
        mandatory: 'false',
        option_list: [{
            "sorting_order": '1',
            "option_text": '',
            "dependent_items": []
        },{
            "sorting_order": '2',
            "option_text": '',
            "dependent_items": []
        }]
    },
    {
        title: "Pergunta aberta",
        type: "TEXT",
        text: '',
        mandatory: 'false',
        option_list: [{
            "sorting_order": '1',
            "option_text": null,
        }]
    },
    {
        title: "Pergunta numérica",
        type: "NUMBER_TYPING",
        text: '',
        mandatory: 'false',
        option_list: [{
            "sorting_order": '1',
            "option_text": null,
        }, {
            "sorting_order": '2',
            "option_text": null,
        }]
    },
]
