export const tooltipTexts = Object.freeze({
    'fixedCosts': "Os custos fixos são aqueles que devem ser debitados, independentemente de a máquina ser ou não utilizada. Entram para a conta depreciação, juros e seguro.",
    'variableCosts': 'Os custos variáveis são aqueles que dependem da quantidade de uso que se faz da máquina e são constituídos por: combustíveis, manutenção e salário do operador.',
    'totalCosts': 'Contituídos por: depreciação, juros, seguro, combustíveis, manutenção e salário do operador',
    'potentialSavings': 'Economia potencial dos custos totais.',
    'dieselPrice': 'Preço do diesel na época em que foi comprado.',
    'laborPrice': 'Preço da mão de obra (incluindo encargos) por hora.',
    'interest': 'Taxa de juros anual atribuída ao financiamento da máquina (ex: 8,5%, 4%).',
    'maintenancePrice': 'Preço da manutenção por hora.',
    'deprecationTotal': 'Total gasto com depreciação de todos os veículos no período selecionado.',
    'insuranceTotal': 'Total gasto com seguro de todos os veículos no período selecionado.',
    'interestTotal': 'Total gasto com juros de todos os veículos no período selecionado',
    'operation': 'Tipo de operação.',
    'timePercentage': 'Percentual do tempo para cada tipo de operação.',
    'timeTotal': 'Tempo absoluto para cada tipo de operação.',
    'laborCost': 'Custo de mão-de-obra vezes tempo de operação.',
    'maintenanceCost': 'Custo de manutenção vezes tempo de operação.',
    'fuelCost': 'Preço do diesel vezes litros gastos para cada operação.',
    'loss': 'Soma do preço de mão-de-obra, preço da manutenção e preço do combustível para cada operação.',
    'newVehiclePrice': 'Valor que aquisição (Valor compra do produto novo ou usado).',
    'salvage': 'Valor de sucata ou de revenda (pode variar de 10% a 60% do Valor inicial, dependendo da máquina).',
    'lifespan': 'Vida útil em horas (número de horas de uso).',
    'insurance': 'Taxa de seguro (depende da região e da máquina, varia entre 0,5% a 2%)',
    'maneuverSavings': 'Porcentagem dos gastos variáveis em modo Manobrando que poderia ter sido economizada.',
    'travelSavings': 'Porcentagem dos gastos variáveis em modo Deslocando que poderia ter sido economizada.',
    'idleSavings': 'Porcentagem dos gastos variáveis em modo Ocioso que poderia ter sido economizada.',
    'sameConfiguration': 'Aplicar mesmas configurações de custos e economia potencial para máquinas também selecionadas.',
    'others': 'Representam os custos fixos, que são aqueles que devem ser debitados, independentemente de a máquina ser ou não utilizada. Entram para a conta depreciação, juros e seguro.',
})
