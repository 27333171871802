export const CSV_FIELDS_HOUR = ['date', 'MOTONIVELADORA', 'RETRO_ESCAVADEIRA'];

export const CSV_LABELS_HOUR = ['Data', 'Horas de Motoniveladoras', 'Horas de Retro-Escavadeiras'];

export const CSV_FIELDS_DAILY = ['date', 'PA_CARREGADEIRA', 'BASCULANTE', 'PIPA', 'ESCAVADEIRA'];

export const CSV_LABELS_DAILY = ['Data', 'Diárias de Pás-Carregadeiras', 'Diárias de Caminhões Basculantes', 'Diárias de Caminhões Pipa', 'Diárias de Escavadeiras'];

export const ANOMALIAS_LAYERS_LIST = [
    {name: "Qualidade", code: "QUALITY"},
    {name: "Mapa de Calor", code: "HEAT_MAP"},
    {name: "Trechos", code: "STRETCHES"},
];

export const ANOMALIAS_MACHINERY = [
    {name: "Motoniveladora", code: "MOTONIVELADORA", time_control: 'HOURLY'},
    {name: "Pá-Carregadeira", code: "PA_CARREGADEIRA", time_control: 'DAILY'},
    {name: "Caminhão Basculante", code: "BASCULANTE", time_control: 'DAILY'},
    {name: "Caminhão Pipa", code: "PIPA", time_control: 'DAILY'},
    {name: "Escavadeira", code: "ESCAVADEIRA", time_control: 'DAILY'},
    {name: "Retro-Escavadeira", code: "RETRO_ESCAVADEIRA", time_control: 'HOURLY'},
];
