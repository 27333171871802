<template>
    <div @click="hover = !hover" v-bind:class="{'show': hover}"
         class="kt-header__topbar-item kt-header__topbar-item--langs ">
        <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="true">
            <span class="kt-header__topbar-icon">
                <img class="" :src="flag" alt="">
            </span>
        </div>
        <div v-bind:class="{'show': hover}"
             class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
             x-placement="bottom-end"
             :style="style">
            <ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
                <li class="kt-nav__item kt-nav__item" @click.prevent="updateLanguage('pt-BR')">
                    <a href="#" class="kt-nav__link">
                        <span class="kt-nav__link-icon">
                            <img src="../../assets/images/flags/pt-BR.svg" alt="">
                        </span>
                        <span class="kt-nav__link-text">Português</span>
                    </a>
                </li>
                <li class="kt-nav__item" @click.prevent="updateLanguage('en-US')">
                    <a href="#" class="kt-nav__link">
                        <span class="kt-nav__link-icon">
                            <img src="../../assets/images/flags/en-US.svg" alt="">
                        </span>
                        <span class="kt-nav__link-text">English</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import axios from "../../axios/axios-auth";
    import BR from '../../assets/images/flags/pt-BR.svg';
    import US from '../../assets/images/flags/en-US.svg';

    export default {
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
            this.updateFlag(this.$store.getters.user.language);
        },
        data() {
            return {
                hover: false,
                window: {
                    width: 0,
                },
                flag: "",
            }
        },
        methods: {
            updateLanguage(language) {
                if (language !== this.$store.getters.user.language) {
                    //Tenta atualizar no backend
                    this.postLanguage(language);

                    //atualiza a bandeira
                    this.updateFlag(language);

                    //Atualiza no vuex o idioma
                    this.$store.dispatch('updateLanguage', language);

                    //Atualiza o locale do i18n
                    this.$i18n.locale = language;

                    //Atualiza o localStorange
                    let user = this.$store.getters.user;
                    user.language = language;
                    let user_local_storage = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE));
                    user_local_storage.language = language;
                    localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE, JSON.stringify(user_local_storage));
                }
            },
            updateFlag(language) {
                switch (language) {
                    case 'pt-BR':
                        this.flag = BR;
                        break;
                    case 'en-US':
                        this.flag = US;
                        break;
                    default:
                        this.flag = BR;
                }
            },
            handleResize() {
                this.window.width = window.innerWidth;
            },
            postLanguage(language) {
                axios.post('/me/settings', {
                    'language': language
                })
            },
        },
        computed: {
            style() {
                if (this.window.width >= 1024) {
                    return {transform: 'translate3d(' + (this.window.width - 380) + 'px, 0px, 0px)'}
                } else if (this.window.width >= 576) {
                    return {transform: 'translate3d(' + (this.window.width - 230) + 'px, 0px, 0px)'}
                } else if (this.window.width >= 0) {
                    return {transform: 'translate3d(' + (this.window.width - 230) + 'px, 0px, 0px)'};
                } else if (this.window.width >= 768) {
                    return {transform: 'translate3d(' + (this.window.width - 390) + 'px, 0px, 0px)'}
                } else if (this.window.width < 768) {
                    return {transform: 'translate3d(' + (this.window.width - 310) + 'px, px, 0px)'};
                } else{

                    return {transform: 'translate3d(' + (this.window.width - 310) + 'px, px, 0px)'};
                }
            }
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>


<style scoped>

</style>
