<template>
    <div class="preview-body">
        <div v-for="(item, itemKey) in temp_checklist" class="preview-item">
            <div v-if="item.type ==='CHECKBOX_EXCLUSIVE' && (Object.keys(item.dependency).length === 0 || arrayOfOptions.includes(item.identification))">
                <h5>{{ item.identification }}) {{ item.text }}</h5>
                <div v-for="(option, optionKey) in item.option_list" :key="option.key" class="preview-radiobutton">
                    <RadioButton v-if="option.dependent_items && option.dependent_items.length > 0"
                                 :id="option.sorting_order" name="option" :value="option.dependent_items"
                                 v-model="selectedOption[itemKey]"/>

                    <RadioButton v-if="option.dependent_items && option.dependent_items.length === 0"
                                 :id="option.sorting_order" name="option" :value="option"
                                 v-model="selectedOption[itemKey]"/>

                    <label for="option.key">{{ option.option_text }}</label>
                </div>
            </div>

            <div
                v-else-if="item.type === 'TITLE' && (Object.keys(item.dependency).length === 0 || arrayOfOptions.includes(item.identification))">
                <h5>{{ item.identification }}) {{ item.text }}</h5>
            </div>

            <div v-else-if="item.type === 'TEXT' && (Object.keys(item.dependency).length === 0 || arrayOfOptions.includes(item.identification))">
                <h5>{{ item.identification }}) {{ item.text }}</h5>
                <app-input type="text" v-model="selectedOption[itemKey]" placeholder="Insira a resposta"
                            :class="{invalid:(item.mandatory && selectedOption[itemKey] === '')}"/>
                <span v-if="item.option_list[0].option_text > 0 && (selectedOption[itemKey] === undefined || selectedOption[itemKey] === '' || selectedOption[itemKey].length > item.option_list[0].option_text)">
                        <p>O número máximo de caracteres é: {{ item.option_list[0].option_text }}.</p>
                </span>
                <div v-if="item.mandatory && item.identification.length > 3">
                    <p>* Resposta Obrigatória</p>
                </div>
            </div>

            <div v-else-if="(item.type === 'NUMBER_TYPING' && (Object.keys(item.dependency).length === 0 || arrayOfOptions.includes(item.identification)))">
                <h5>{{ item.identification }}) {{ item.text }}</h5>
                <app-input type="number" v-model="selectedOption[itemKey]" placeholder="Insira a resposta"
                           :class="{invalid:selectedOption[itemKey] < parseInt(item.option_list[0].option_text) || selectedOption[itemKey] > parseInt(item.option_list[1].option_text) }"/>
                <span v-if="(item.option_list[0].option_text >=0 && item.option_list[1].option_text > 0) && (selectedOption[itemKey] < parseInt(item.option_list[0].option_text) || selectedOption[itemKey] > parseInt(item.option_list[1].option_text))">
                        <p>Digite um número válido entre {{ parseInt(item.option_list[0].option_text) }} e {{ parseInt(item.option_list[1].option_text) }}.</p>
                </span>
            </div>

            <div v-if="item.mandatory && item.identification.length<=3">
                <p>* Resposta Obrigatória</p>
            </div>
        </div>
    </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton';
import AppInput from "../../../common/AppInput";

export default {
    beforeMount(){
        this.temp_checklist = JSON.parse(JSON.stringify(this.checklist));
        for(let i=0; i < this.temp_checklist.length; i++){
            if(!this.temp_checklist[i].dependency){
                this.temp_checklist[i].dependency = {};
            }
            if(this.temp_checklist[i].type === 'TEXT' && this.temp_checklist[i].option_list == null){
                this.temp_checklist[i].option_list = [{
                    "sorting_order": '1',
                    "option_text": 0,
                    "dependent_items": [],
                }]
            } else if (this.temp_checklist[i].type === 'NUMBER_TYPING' && this.temp_checklist[i].option_list == null){
                this.temp_checklist[i].option_list = [{
                    "sorting_order": '1',
                    "option_text": 0,
                    "dependent_items": [],
                }, {
                    "sorting_order": '2',
                    "option_text": 0,
                    "dependent_items": [],
                }]
            }
        }
    },
    data() {
        return {
            temp_checklist: null,
            selectedOption: [],
            arrayOfOptions: [],
        }
    },
    watch: {
        selectedOption: {
            deep: true,
            handler(val) {
                this.concatOptions(val);
            }
        },
    },
    methods: {
        closeDialog() {
            this.$emit('closePreview');
            this.selectedOption = [];
            this.arrayOfOptions = [];
        },
        concatOptions(response) {
            let arr = JSON.parse(JSON.stringify(response));
            this.arrayOfOptions = [];
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] !== null && arr[i] instanceof Array) {
                    this.arrayOfOptions = this.arrayOfOptions.concat(arr[i]);
                }
            }
        }
    },
    props: {
        checklist: {
            type: Array
        },
    },
    components: {
        RadioButton, AppInput
    },
}

</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";

.preview-item {
    margin-bottom: 2rem;
}

.preview-radiobutton {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.preview-item label {
    margin: 0 0 0 1rem;
}

.invalid {
    border-color: red;
}

</style>
