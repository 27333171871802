<template>
    <Dialog header="Alerta" :visible.sync="showDialog" :contentStyle="{'overflow': 'visible'}"
            @hide="handleClose" :style="{width: width_dialog}" :modal="true">
        <form class="kt-form" @submit.prevent>
            <div class="kt-portlet__body">
                <div class="form-group">
                    <label>Código</label>
                    <app-input class="form-control" type="number" placeholder=""
                               :disabled="updating"
                               v-model="form.code"
                               :class="{'is-invalid' : $v.form.code.$invalid && $v.form.code.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="$v.form.code.$invalid && $v.form.code.$dirty">
                            Obrigatório informar o código
                    </span>
                </div>
                <div class="form-group">
                    <label>Descrição</label>
                    <app-input class="form-control" type="text" placeholder=""
                               v-model="form.description"
                               :class="{'is-invalid' : $v.form.description.$invalid && $v.form.description.$dirty}">
                    </app-input>
                    <span class="form-text error"
                          v-if="$v.form.description.$invalid && $v.form.description.$dirty">
                            Obrigatório informar a descrição
                    </span>
                </div>
                <div class="form-group">
                    <label>Prioridade</label>
                    <br>
                    <Dropdown v-model="form.priority" :options="priorities" placeholder="Selecione" style="width: 100%"
                              class="p-column-filter" :showClear="true" optionLabel="name" optionValue="code"
                              :class="{'p-invalid-custom': $v.form.priority.$invalid && $v.form.priority.$dirty}">
                        <template #option="slotProps">
                            <span :class="'badge priorities-' + slotProps.option.code">{{slotProps.option.name}}</span>
                        </template>
                    </Dropdown>
                    <span class="form-text error"
                          v-if="$v.form.priority.$invalid && $v.form.priority.$dirty">
                            Obrigatório informar a prioridade
                    </span>
                </div>
            </div>
        </form>

        <div v-show="showDefaultValues">
            <hr>
            <b>Descrição Padrão:</b> {{defaultValues.description}} <br>
            <b>Prioridade Padrão:</b> {{getPriority(defaultValues.priority)}}
        </div>

        <template #footer>
            <app-button @handleClick="handleShowDefaultValues" type="secondary" style="float: left"
                        v-if="updating">
                <span :class=" showDefaultValues ? 'pi pi-angle-double-up' : 'pi pi-angle-double-down'"></span>
                Valores Originais
            </app-button>

            <app-button @handleClick="handleClose" type="secondary">
                <span class="pi pi-times"></span>
                Fechar
            </app-button>
            <app-button @handleClick="handleSalve" type="primary">
                <span class="pi pi-check"></span>
                Salvar
            </app-button>

        </template>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressSpinner from 'primevue/progressspinner';
    import Button from 'primevue/button';
    import AppButton from "../../../../common/AppButton";
    import AppInput from "../../../../common/AppInput";
    import Dropdown from 'primevue/dropdown';
    import {STATUSES} from '../AppOptions';
    import {required, maxValue} from "vuelidate/lib/validators";
    import TextArea from 'primevue/textarea';
    import {PRIORITIES} from '../AppOptions';
    import AlertsDictionaryService from "../../../../../services/AlertsDictionaryService";


    export default {
        beforeMount() {
            this.alertsDictionaryService = new AlertsDictionaryService();
        },
        mounted(){
            this.width_dialog = this.isScreenMobile ? '90vw' : '40vw';
        },
        data() {
            return {
                width_dialog: null,
                showDefaultValues: false,
                defaultValues: {},
                showDialog: false,
                statuses: STATUSES,
                priorities: PRIORITIES,
                form: {
                    id: null,
                    code: null,
                    description: null,
                    priority: null
                },
                alertsDictionaryService: null,
            }
        },
        methods: {
            handleClose() {
                this.$emit('handleClose');
                this.cleanDefaults();
                this.$v.$reset();
            },
            cleanDefaults() {
                this.defaultValues = {};
                this.showDefaultValues = false;
            },
            handleSalve() {

                this.$v.form.$touch();

                if (!this.$v.form.$invalid) {
                    this.$emit('handleSalve', {
                        id: this.form.id,
                        code: parseInt(this.form.code),
                        description: this.form.description,
                        priority: this.form.priority
                    });
                    this.cleanDefaults();
                }
            },
            fillDefaultProperties(code) {
                this.alertsDictionaryService.getByCodeV3(code)
                    .then(response => {
                        this.form.description = response.description;
                        this.form.priority = response.priority;
                    }).catch(error => {
                    console.log(error);
                    return {};
                });

            },
            getAlertByCode(code) {
                this.alertsDictionaryService.getByCodeV3(code)
                    .then(response => {
                        this.defaultValues = response;
                    }).catch(error => {
                    console.log(error);
                })
            },
            handleShowDefaultValues() {
                this.showDefaultValues = !this.showDefaultValues;
                if (!this.defaultValues.description) {
                    this.getAlertByCode(this.form.code);
                }
            },
            getPriority(priority) {
                let result = this.priorities.find(({code}) => code === priority);
                if (result) {
                    return result.name;
                }
            },
        },
        props: {
            displayDialogEditAlert: {
                type: Boolean,
                default: false,
                required: true
            },
            alertEditDetails: {
                required: true
            }
        },
        watch: {
            displayDialogEditAlert: function (val) {
                this.showDialog = val;
            },
            alertEditDetails: function (val) {
                this.form.id = val.id;
                this.form.code = val.code;
                this.form.description = val.description;
                this.form.priority = val.priority;
            },
            'form.code': function (val) {
                if (!this.updating && val) {
                    if (val >= 111 && val <= 19999) {
                        let vm = this;
                        setTimeout(function () {
                            vm.fillDefaultProperties(val)
                        }, 500);
                    }
                }
            }
        },
        components: {
            Dialog, ProgressSpinner, Button, AppButton, AppInput, Dropdown, TextArea
        },
        validations: {
            form: {
                code: {required, maxValue: maxValue(2147483647)},
                description: {required},
                priority: {required}
            }
        },
        computed: {
            updating: function () {
                return !!this.form.id;
            },
            isScreenMobile: function() {
                return window.screen.width <= 1024;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/components/views/alerts/style";
</style>
