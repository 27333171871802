<template>
    <div class="kt-portlet__body no-padding">
        <app-alert :type="'danger'" :icon="'glyphicons-exclamation-sign'" @handleClick="errors = []"
                   v-show="errors.length">
            <b>Por favor, verifique os erros informados</b>
            <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </app-alert>
        <div class="kt-form">
            <form class="kt-form kt-form--label-right" v-on:submit.prevent>
                <div class="kt-portlet__body shadow-lg bg-white pl-5 pr-5" style="border-radius: 10px;">
                    <div class="form-group row">
                        <div class="title col-12 p-1">
                            Alterar Senha
                            <img src="../../../../assets/icons/icone-trocar-senha.svg" alt=""
                                 class="mb-1 ml-1"
                                 width="20"
                                 height="20">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label>Senha atual</label>
                        <app-input class="col-12" v-model="old_password" type="password"/>
                    </div>
                    <div class="form-group row">
                        <label>Nova senha </label>
                        <app-input class="col-12" v-model="new_password" type="password"/>
                    </div>
                    <div class="form-group row">
                        <label>Confirmar nova senha </label>
                        <app-input class="col-12" v-model="new_password_verify" type="password"/>
                    </div>
                    <div class="form-group row">
                        <app-button class="mt-4 col-12 col-lg-3 ml-lg-auto btn-sucess" type="primary" @handleClick="validateForm">
                            <span class="pi pi-save mr-2"/>
                            Salvar
                        </app-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>


<script>
    import AppButton from '../../../common/AppButton';
    import AppInput from '../../../common/AppInput';
    import AppAlert from '../../../common/AppAlert';

    import axios from '../../../../axios/axios-auth';
    import {router} from '../../../../router';

    export default {
        data() {
            this.$i18n.locale = this.$store.getters.user.language;
            return {
                old_password: null,
                new_password: null,
                new_password_verify: null,
                errors: []
            }
        },
        components: {
             AppButton, AppInput, AppAlert
        },
        methods: {
            back() {
                router.go(-1);
            },
            validateForm() {
                this.errors = [];
                if (!this.new_password) {
                    this.errors.push("Informe a nova senha");
                }
                if (this.new_password !== this.new_password_verify) {
                    this.errors.push("A nova senha não confere");
                }

                if (this.new_password.length < 6 || this.new_password.length > 60) {
                    this.errors.push("A senha deve ter entre 6 e 60 caracteres");
                }

                if (!this.old_password) {
                    this.errors.push("Informe a senha atual");
                }
                if (this.errors.length === 0) {
                    this.updatePassword();
                }
            },
            updatePassword() {
                let vm = this;
                axios.post(process.env.VUE_APP_BACKEND_ADDRESS + '/auth/api/v1/me/update-password', {
                    old_password: vm.old_password,
                    new_password: vm.new_password
                }).then((response) => {
                    if (response.status === 200) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Senha atualizada',
                            detail: 'A senha foi atualizada com sucesso',
                            life: 3000
                        });
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 400) {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Senha não atualizada',
                                detail: 'A senha atual está incorreta',
                                life: 3000
                            });
                        }
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Senha não atualizada',
                            detail: 'Não foi possível trocar a senha',
                            life: 3000
                        });
                    }
                })
            },
        },
        watch: {
            locale(val) {
                this.$i18n.locale = val
            }
        },
    }
</script>

<style scoped lang="scss">
@import "../../../../assets/styles/constants";
    .form-group {
        margin-bottom: 1rem;
    }

    label {
        margin-bottom: 0;
    }

    img {
        filter: $aside-menu-item-inactive;
    }

    .title{
        border-bottom: 0.5px rgb(219, 219, 219) solid;
        padding-bottom: 15px;
        font-weight: bold;
        text-align: center;
        padding-top: 8px;
        font-size: 14px;
    }
    .kt-form{
        width: 80%;
        margin: 0 auto;
    }

    @media (max-width: 768px) {
        .kt-form {
            width: 95%;
        }
    }

    @media (max-width: 1024px) and (min-width: 768px) {
        .kt-form {
            width: 95%;
        }
    }
</style>

<i18n>
    {
    "pt-BR": {
    "update_password": "Atualizar senha"
    },
    "en-US": {
    "update_password": "Update Password"
    }
    }

</i18n>
